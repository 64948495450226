import styled from 'styled-components/macro';
import { ANSWER_TYPE } from '.';

export const Container = styled.div`
  width: 1440px;
  height: 100%;
  margin: 0px 0px;
  padding: 0 0 70px;
  box-shadow: 0 2px 4px 0 #b6b6b6;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  width: 1168px;
  height: 39px;
  padding: 30px 137px 23px 135px;
  background-color: #fff;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  width: 370px;
  height: 39px;
  font-size: 30px;
  color: #2cabe0;
`;

export const ContentContainer = styled.div`
  ${({ answerType }) =>
    answerType === ANSWER_TYPE.short ? 'width: 632px;' : 'width: 100%;'}
  ${({ answerType }) =>
    answerType === ANSWER_TYPE.short ? 'height: 600px;' : ''}
  ${({ answerType }) =>
    answerType === ANSWER_TYPE.short
      ? 'margin: 33px 70px 112px 135px;'
      : 'margin: 35px 135px 0px 135px;'}
  ${({ answerType }) =>
    answerType === ANSWER_TYPE.short
      ? 'padding: 43px 98px 132px 100px;'
      : 'padding: 40px 100px 40px 100px;'}
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #c9c9c9;
  border: solid 1px #c9c9c9;
  background-color: #fff;

  .links {
    display: flex;
    font-size: 12px;
    color: #2cabe0;
    clear: both;
    margin-top: 5px;
    &.flexStart {
      align-self: flex-start;
    }
    &.flexEnd {
      align-self: flex-end;
    }
  }
`;

export const Input = styled.input`
  width: 610px;
  height: 16px;
  margin: 3px 0;
  padding: 7px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
`;

export const Label = styled.label`
  width: 570px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 32px 3px 30px;
  font-size: 20px;
  text-align: middle;
  color: #424242;
`;
export const LabelLeft = styled(Label)`
  text-align: left;
  margin: 10px 32px 3px 0px;
  display: flex;
  align-items: left;
  justify-content: left;
`;
export const TextAreaShortHorizontal = styled.textarea`
  width: 388px;
  height: 147px;
  margin: 3px 123px 5px 45px;
  padding: 9px 11px 8px 12px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  display: flex;
  margin: 0 auto;
  ::placeholder,
  ::-webkit-input-placeholder {
    width: 365px;
    height: 130px;
    font-size: 12px;
    color: #b6b6b6;
  }

  textarea + div {
    width: 388px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #c9c9c9;
  }
`;

export const TextAreaShortVertical = styled(TextAreaShortHorizontal)`
  width: 360px;
  height: 355px;
  margin: 3px 30px 3px 0;
  padding: 9px 10px 9px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
`;

export const ExtraOptions = styled.div`
  width: 300px;
  margin: 33px 0px;
`;

export const Select = styled.select`
  width: 193px;
  height: 30px;
  margin: 5px 77px 0 0;
  padding: 3px 0 3px 0px;
  border-radius: 1px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
`;

export const Radio = styled.input`
  width: 13px;
  height: 13px;
  margin: 8px 0px 50px 2px;
`;

export const PreviewStudent = styled.img`
  margin: 43px 0;
`;

export const PreviewStudentFloated = styled.img`
  margin: 39px 0 0 85px;
`;
