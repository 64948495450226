import React from 'react';
import { SnackbarProvider, useSnackbar } from "./Snackbar";
import { ModalProvider, useModal } from "./Modal";
import { ThemeProvider } from 'styled-components/macro';
import theme from '../../theme';


const UiProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <ModalProvider>
          {children}
        </ModalProvider>
      </SnackbarProvider>
    </ThemeProvider>

  );
}

export { UiProvider, useSnackbar, useModal }
