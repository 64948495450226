import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const ReorderPages = React.createContext();

function ReorderPagesProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // payload => uuid[] the order of questiongroups
  const reorderPages = useCallback(async (id, pageNumbers) => {
    try {
      setLoading(true);
      await axiosInstance.put(`/packages/${id}/page-order`, {
        pageNumbers,
      });

      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    reorderPages,
    errorMessage,
    hasLoaded,
  };

  return (
    <ReorderPages.Provider value={value}>{children}</ReorderPages.Provider>
  );
}

/**
 * @typedef {
 *  string
 * } PageNumber the id of a question group
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  reorderPages: (id: string, pageNumbers: PageNumbers[] ) => Promise<{ success: boolean; errorMessage: string; }>
 *  errorMessage: string
 * }}
 */

function useReorderPages() {
  const context = React.useContext(ReorderPages);

  if (context === undefined) {
    throw new Error('useReorderPages must be used within a ReorderPages');
  }

  return context;
}

export { ReorderPagesProvider, useReorderPages };
