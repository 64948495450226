import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const PagesContext = React.createContext();

function PagesProvider({ children }) {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const getPages = useCallback(async (packageId) => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: `/packages/${packageId}/pages`,
      });

      const pages = response.data?.pages || [];

      setPages(
        pages
          .map((p) => ({
            ...p,
            title: p.title?.replaceAll('\\n', ' '),
            subject: p.subject?.replaceAll('\\n', ' '),
            description: p.description?.replaceAll('\\n', ' '),
          }))
          .sort((p1, p2) => p1.pageNumber - p2.pageNumber),
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    pages,
    loading,
    hasLoaded,
    error,
    getPages,
  };

  return (
    <PagesContext.Provider value={value}>{children}</PagesContext.Provider>
  );
}

/**
 * @returns {{
 *  error: Error,
 *  getPages: () => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  pages: object[],
 * }}
 */
function usePages(packageId) {
  const context = React.useContext(PagesContext);

  if (context === undefined) {
    throw new Error('usePages must be used within a PagesProvider');
  }

  if (!context.loading && !context.hasLoaded && !!packageId) {
    context.getPages(packageId);
  }

  return context;
}

export { PagesProvider, usePages };
