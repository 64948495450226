import React from 'react';
import { CreatePackageProvider, useCreatePackage } from './createPackage';
import { PackagesProvider, usePackages } from './packages';
import { preparePackage, preparePackageForFrontEnd } from './common';
import { UpdatePackageProvider, useUpdatePackage } from './updatePackage';
import { DeletePackageProvider, useDeletePackage } from './deletePackage'
import { PublishPackageProvider, usePublishPackage } from './publishPackage';

function PackageProvider({ children }) {
  return (
    <PackagesProvider>
      <CreatePackageProvider>
        <UpdatePackageProvider>
          <DeletePackageProvider>
            <PublishPackageProvider>
              {children}
            </PublishPackageProvider>
          </DeletePackageProvider>
        </UpdatePackageProvider>
      </CreatePackageProvider>
    </PackagesProvider>
  );
}

export {
  PackageProvider,
  useCreatePackage,
  useDeletePackage,
  usePackages,
  preparePackage,
  preparePackageForFrontEnd,
  useUpdatePackage,
  usePublishPackage
};
