import React from 'react';
import styled from 'styled-components/macro';

const TogglePin = styled.div`
  width: 1em;
  height: 1em;
  ::before {
    transition: all 0.2s ease-out;
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    width: 1em;
    height: 1em;
    z-index: 4;
    margin: 2px;
    content: '';
    border-radius: 50%;
    transform: translateX(0);
  }
`;

const Circle = styled.div`
  display: none;
  width: 1em;
  height: 1em;
  margin: 2px;
  border-radius: 50%;
  background-color: white;
`;

const Hint = styled.span`
  display: none;
  ::before {
    z-index: 5;
    top: 50%;
    transform: translateY(-50%);
    content: 'yes';
    position: absolute;
    left: 0.25em;
    font-size: 1em;
    color: white;
    text-transform: capitalize;
  }
`;

const Label = styled.label`
  display: block;
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.grey};
`;

const Cb = styled.input`
  appearance: none;
  &:checked ~ ${Hint} {
    display: inline;
  }
  &:checked ~ ${Circle} {
    display: block;
  }
  &:checked ~ ${TogglePin} {
    ::before {
      transform: translateX(100%);
      left: 0.75em;
    }
    ::after {
      position: absolute;
      top: -3em;
      bottom: -3em;
      left: -3em;
      right: -3em;
      background-color: ${({ theme }) => theme.colors.green};
      z-index: 3;
      content: '';
      pointer-events: none;
    }
  }

  :checked ~ ${Label} {
    background-color: ${({ theme }) => theme.colors.green};
  }
`;

const ToggleContainer = styled.label`
  border-radius: 1em;
  cursor: pointer;
  width: 3em;
  height: 1.25em;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.grey};
  overflow: hidden;
`;

export const variants = {
  circle: 'circle',
  toggle: 'toggle',
};

export const Checkbox = ({ label, variant, ...rest }) => {
  switch (variant) {
    case variants.circle:
      return (
        <Label htmlFor={label}>
          <Cb type="checkbox" id={label} {...rest} />
          <Circle />
        </Label>
      );
    case variants.toggle:
      return (
        <ToggleContainer htmlFor={label}>
          <Cb type="checkbox" id={label} {...rest} />
          <Hint />
          <TogglePin />
        </ToggleContainer>
      );
    default:
      return null;
  }
};
