import React, { useRef, useState } from 'react';
import Xarrow from 'react-xarrows';

const connectPointStyle = {
  position: 'absolute',
  width: 15,
  height: 15,
  borderRadius: '50%',
  background: 'black',
  zIndex: 1,
};
const connectPointOffset = {
  left: { left: 0, top: '50%', transform: 'translate(-50%, -50%)' },
  right: { left: '100%', top: '50%', transform: 'translate(-50%, -50%)' },
  top: { left: '50%', top: 0, transform: 'translate(-50%, -50%)' },
  bottom: { left: '50%', top: '100%', transform: 'translate(-50%, -50%)' },
};

const ConnectPointsWrapper = ({ containerId, handler }) => {
  const draggablePointRef = useRef();

  const [position, setPosition] = useState({});
  const [beingDragged, setBeingDragged] = useState(false);
  return (
    <React.Fragment>
      <div
        className="connectPointsWrapper"
        ref={draggablePointRef} // <---- referencing the point
        style={{
          ...connectPointStyle,
          ...connectPointOffset[handler],
          ...position, // <----- Updating the position as we drags
        }}
        draggable
        onDragStart={(e) => {
          setBeingDragged(true);
          e.dataTransfer.setData('arrow', containerId);
        }}
        onDrag={(e) => {
          setPosition({
            // <---- Setting up the position to draw line as we drags
            position: 'fixed',
            left: e.clientX,
            top: e.clientY,
            transform: 'none',
            opacity: 0,
          });
        }}
        onDragEnd={(e) => {
          setPosition({});
          setBeingDragged(false);
        }}
      />
      {/* this will draw the arrow between startPointRef and draggablePointRef */}
      {beingDragged ? (
        <Xarrow
          start={containerId}
          end={draggablePointRef}
          showHead={false}
          showTail={false}
        />
      ) : null}
    </React.Fragment>
  );
};
export default React.memo(ConnectPointsWrapper);
