import React from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout';
import Navigation from '../../components/Settings/Navigation';
import { SETTINGS_LINKS } from '../../constants/navigation';
import { Routes, Route } from 'react-router-dom';
import { Container } from '../../components/UI';
import { border } from 'styled-system';
import routes from '../../routes';
import styled from 'styled-components/macro';
import Profile from '../../components/Settings/Profile';
import ChangePassword from '../../components/Settings/PasswordChange';
import { selectUser } from '../Authentication/selectors';
import { SettingsProvider } from '../../contexts/api/settings/';

const SettingsPanel = styled(Container)`
  ${border};
  min-height: 400px;
`;

const Divider = styled.hr`
  margin: 20px 0 80px 0;
  border: 1px solid ${({ theme }) => theme.colors.lightgrey};
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 98px);
  width: 100%;
  margin-top: 10px;
  background: ${({ theme }) => theme.colors.white};
`;
const Settings = (props) => {
  const { email, firstName, lastName, id: userId } = useSelector(selectUser);
  const relativePath = (path) => path.replace(routes.client.settings, '');

  return (
    <SettingsProvider>
      <Layout noBodyPadding bgColor="var(--color-offwhite)">
        <ContentContainer>
          <Container>
            <Navigation links={SETTINGS_LINKS} />
            <Divider />
            <SettingsPanel py={7} px={12}>
              <Routes>
                <Route
                  path={relativePath(routes.client.profile)}
                  element={
                    <Profile
                      exact
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      userId={userId}
                    />
                  }
                />

                <Route
                  path={relativePath(routes.client.changePassword)}
                  element={
                    <ChangePassword
                      // passing in routes.client.password does not render component
                      email={email}
                    />
                  }
                />
              </Routes>
            </SettingsPanel>
          </Container>
        </ContentContainer>
      </Layout>
    </SettingsProvider>
  );
};

export default Settings;
