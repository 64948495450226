import React, { useCallback, useReducer } from 'react';

const getInitialState = () => ({
    properties: {
        title: '',
        notes: '',
        pdf: '',
    },
    type: '',
    asset: null,
    questionGroups: [],
    id: null,
    pageNumber: null,
});

const reducer = (state, updates) => {
    if (typeof updates === 'object') {
        return {
            ...state,
            ...updates,
        };
    }

    return state;
};

const PackagePdfPageStoreContext = React.createContext();

function PackagePdfPageStoreProvider({ children }) {
    const [state, setState] = useReducer(reducer, getInitialState());

    const resetState = useCallback(() => {
        setState(getInitialState());
    }, []);

    const value = {
        update: setState,
        reset: resetState,
        state,
    };

    return (
        <PackagePdfPageStoreContext.Provider value={value}>
            {children}
        </PackagePdfPageStoreContext.Provider>
    );
}

export { PackagePdfPageStoreContext, PackagePdfPageStoreProvider };
