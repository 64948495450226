import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const StudentsContext = React.createContext();

function parseISOString(s) {
  var b = s.split(/\D+/);
  return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
}

function prepareStudents(students) {
  return students
    .map((s) => ({ ...s, name: `${s.firstName} ${s.lastName}` }))
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return parseISOString(b.createdAt) - parseISOString(a.createdAt);
    });
}

function StudentsProvider({ children }) {
  const [students, setStudents] = useState([]);
  const [pendingInvitation, setPendingInvitation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const getStudents = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: '/students',
      });

      const { students, pendingInvitation } = getStudentsFromResponse(response);

      setStudents(prepareStudents(students));
      setPendingInvitation(prepareStudents(pendingInvitation));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const getStudentById = useCallback(
    (id) => {
      let student = students.find((s) => s.id === id);

      if (!student) {
        student = pendingInvitation.find((s) => s.id === id);
      }

      return student;
    },
    [pendingInvitation, students],
  );

  const value = {
    students,
    pendingInvitation,
    loading,
    hasLoaded,
    error,
    getStudents,
    getStudentById,
  };

  return (
    <StudentsContext.Provider value={value}>
      {children}
    </StudentsContext.Provider>
  );
}

/**
 * @returns {{
 *  error: Error,
 *  getStudents: () => Promise<void>,
 *  getStudentById: (id: string) => object,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  students: object[],
 *  pendingInvitation: object[],
 * }}
 */
function useStudents() {
  const context = React.useContext(StudentsContext);

  if (context === undefined) {
    throw new Error('useStudents must be used within a StudentsProvider');
  }

  if (!context.loading && !context.hasLoaded) {
    context.getStudents();
  }

  return context;
}

function getStudentsFromResponse(response) {
  const allStudents = response.data?.users || [];

  const students = allStudents.filter((s) => !s.needsEmailVerified);
  const pendingInvitation = allStudents.filter((s) => s.needsEmailVerified);

  return {
    students,
    pendingInvitation,
  };
}

export { StudentsProvider, useStudents };
