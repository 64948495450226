import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const DeleteQuestionGroupFromPage = React.createContext();

function DeleteQuestionGroupFromPageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const deleteQuestionGroupFromPage = useCallback(
    async (id, questionGroupId) => {
      try {
        setLoading(true);
        await axiosInstance.delete(
          `/pages/${id}/question-groups/${questionGroupId}`,
        );

        return {
          success: true,
          error: null,
        };
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setErrorMessage(errorMessage);
        return { success: false, errorMessage };
      } finally {
        setLoading(false);
        setHasLoaded(true);
      }
    },
    [],
  );

  const value = {
    loading,
    deleteQuestionGroupFromPage,
    errorMessage,
    hasLoaded,
  };

  return (
    <DeleteQuestionGroupFromPage.Provider value={value}>
      {children}
    </DeleteQuestionGroupFromPage.Provider>
  );
}

/**
 *
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  deleteQuestionGroupFromPage: (id: string, questionGroupId: string) => Promise<{ success: boolean; errorMessage: string; }>
 *  errorMessage: string
 * }}
 */

function useDeleteQuestionGroupFromPage() {
  const context = React.useContext(DeleteQuestionGroupFromPage);

  if (context === undefined) {
    throw new Error(
      'useDeleteQuestionGroupFromPage must be used within a DeleteQuestionGroupFromPageProvider',
    );
  }

  return context;
}

export { DeleteQuestionGroupFromPageProvider, useDeleteQuestionGroupFromPage };
