import React, { useReducer } from 'react';
import { API_RESOURCES_LIST } from '../constants/api';

export const ResourceStalenessContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STALE':
      return { ...state, [action.payload.resource]: true };
    case 'UNSET_STALE':
      return { ...state, [action.payload.resource]: false };
    default:
      return state;
  }
};

const initialState = API_RESOURCES_LIST.reduce((obj, resource) => {
  obj[resource] = true;
  return obj;
}, {});

/**
 * hoc to handle the management of api resources
 * this will tell primary getter functions to only fetch resources when they are perceived to be stale
 * @returns {Component}
 * @returns {Component.value}
 * @returns {Component.value.resources}
 * @returns {Component.value.setResource}
 */
export const ResourceStalenessProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Wraps a dispatch call to the local reducer state of ResourceStalenessProvider
   * @param {String} resource the api resource from API_RESOURCES
   * @param {Boolean} staleness true/false to set or unset staleness
   */
  const setResource = (resource, staleness = false) =>
    dispatch({
      type: staleness ? 'SET_STALE' : 'UNSET_STALE',
      payload: { resource },
    });

  const isResourceStale = resource => state[resource];
  return (
    <ResourceStalenessContext.Provider
      value={{ resources: state, setResource, isResourceStale }}
    >
      {children}
    </ResourceStalenessContext.Provider>
  );
};
