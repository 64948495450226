import React, { useState } from 'react';
import styled from 'styled-components/macro';

export const PasswordInput = props => {
  // we use a state here to dynamically update the input type
  // from password to text when user wants to see their password
  const [type, setType] = useState('password');

  const toggleShow = () => {
    setType(current => (current === 'password' ? 'text' : 'password'));
  };

  return (
    <Container>
      <input type={type} {...props} />
      <div onClick={toggleShow}>{type === 'password' ? 'Show' : 'Hide'}</div>
    </Container>
  );
};

export const Container = styled.div`
  border-radius: 8px;
  border: solid 1px var(--color-lightgrey);
  margin: 5px 0 10px;

  padding: 0 5px 0 0;

  display: grid;
  grid-template-columns: 10.5fr 1.5fr;

  input,
  div {
    border: none;
    padding: 0;
    margin: 0;
    background-color: var(--color-white);
  }
  div {
    margin-left: 2px;
    cursor: pointer;
    color: var(--color-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div:focus {
    outline: none;
  }

  input {
    padding: 0 15px;
    outline-color: ${({ theme }) => theme.colors.blue};
  }
`;
