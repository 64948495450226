import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { boxShadow } from '../../styleTokens';

const Header = styled.div`
  ${boxShadow};
  display: flex;
  justify-content: center;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  z-index: ${(props) => props.zIndex};

  ${({ theme }) => theme.mediaQueries.lg} {
    position: fixed;
  }
`;

export const TEST_IDS = {
  container: 'header-container',
  betalink: 'header-betalink',
};

export const HeaderWrapper = ({ children, ...rest }) => (
  <Header
    as="header"
    data-testid={TEST_IDS.container}
    alignItems="center"
    justifyContent="space-between"
    flexDirection="row"
    {...rest}
  >
    {children}
  </Header>
);

HeaderWrapper.propTypes = {
  children: PropTypes.node,
};

export default HeaderWrapper;
