import styled from 'styled-components/macro';

export const Input = styled.input`
  display: none;
`;

export const Label = styled.label`
  position: relative;
`;

export const Container = styled.div`
  position: relative;
`;
