import ReactDOM from 'react-dom';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Flex } from 'rebass';
import { PageFormats } from '../../../../../../../contexts/api/package/common';
import {
  prepareBackendQuestionPageForFrontend,
  prepareTestPageForBackend,
  usePages,
} from '../../../../../../../contexts/api/pages';
import { useCreatePage } from '../../../../../../../contexts/api/pages/createPage';
import { PackageTestPageStoreContext } from '../../../../../../../contexts/reducers/package/TestPage';
import routes from '../../../../../../../routes';

import { CancelButton, SaveButton } from '../../../../../../UI/Button';
import PackageLibraryPageTestPageProperties from './Properties';
import PackageLibraryPageTestPageQuestionGroups from './QuestionGroups';
import {
  Container,
  TopContainer,
  Title,
  PageNav,
  DeleteButtonContainer,
  DeleteButton,
} from './styled';
import NotFound from '../../../../FromScratch/NotFound';
import Layout from '../../../../../../Layout';
import { useDelayedSideEffectOnChange } from '../../../../../../../utils/useDelayedSideEffectOnChange';
import { useUpdatePage } from '../../../../../../../contexts/api/pages/updatePage';
import { useModal, useSnackbar } from '../../../../../../../contexts/ui';
import { FaTrash } from 'react-icons/fa';
import { useDeletePage } from '../../../../../../../contexts/api/pages/deletePage';
import strings from '../../../../../../../constants/strings';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const tabs = [
  {
    id: 1,
    text: 'Properties',
    ContentComponent: PackageLibraryPageTestPageProperties,
  },
  {
    id: 2,
    text: 'Question Groups',
    ContentComponent: PackageLibraryPageTestPageQuestionGroups,
  },
];

const PackageLibraryPageTestPage = () => {
  let { pageId, id } = useParams();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [loadPageError, setLoadPageError] = useState(false);
  const { ContentComponent } = selectedTab;
  const { state, update, reset } = useContext(PackageTestPageStoreContext);
  const { showSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const { createPage } = useCreatePage();
  const { updatePage } = useUpdatePage();
  const { deletePage } = useDeletePage();
  const navigate = useNavigate();
  const onCancel = () => {
    navigate(routes.client.editPackages(id));
  };
  const { pages, loading, getPages } = usePages(id);

  useEffect(() => {
    if (id !== null) {
      getPages(id);
    }
  }, [getPages, id]);

  const isEditing = Boolean(pageId);

  // reset page form state if:
  // state id and page id in url mismatch
  // or if switched to 'create' mode from 'edit'
  useEffect(() => {
    if (
      (state.id !== null && pageId && state.id !== pageId) ||
      (!pageId && /pages\/question-page\/new$/i.test(location.pathname))
    ) {
      reset();
    }
  }, [id, pageId, location.pathname, state.id, reset]);

  // handle page number setting
  const search = useMemo(
    () => new URLSearchParams(location.search.slice(1)),
    [location.search],
  );

  useEffect(() => {
    if (
      search.has('pageNumber') &&
      state.pageNumber !== search.get('pageNumber')
    ) {
      const pageNumber = search.get('pageNumber');
      if (!isNaN(parseInt(pageNumber))) {
        update({ pageNumber });
      }
    }
  }, [search, state.pageNumber, update]);

  useEffect(() => {
    if (pageId && pageId !== state.id && pages.length > 0 && !loading) {
      const loadedPage = pages.find((p) => p.id === pageId);

      if (!loadedPage) {
        setLoadPageError(true);
      } else {
        const preparedPage = prepareBackendQuestionPageForFrontend(loadedPage);

        update(preparedPage);
        setLoadPageError(false);
      }
    }
  }, [loading, pageId, pages, setLoadPageError, state.id, update]);

  /**
   * @param {Object} s is the state of the page
   */
  const handleSave = useCallback(async () => {
    const payload = prepareTestPageForBackend(state, PageFormats.QUESTIONS, id);

    const { success, errorMessage, page } = !isEditing
      ? await createPage(id, payload)
      : await updatePage(pageId, payload);

    await getPages(id);

    if (success) {
      showSnackbar('Page saved');
    } else {
      showSnackbar(errorMessage, 'error');
    }

    setTimeout(() => {
      if (!isEditing) {
        navigate(routes.client.editQuestionPage(id, page.id));
      }
    }, 250);
  }, [
    createPage,
    getPages,
    id,
    isEditing,
    pageId,
    showSnackbar,
    state,
    updatePage,
  ]);

  const { debounce, stop: stopDebounce } = useDelayedSideEffectOnChange(
    3000,
    handleSave,
    [state],
  );

  const deletePageCall = async () => {
    const { success, errorMessage } = await deletePage(pageId);
    if (success) {
      navigate(routes.client.editPackages(id));
      showSnackbar('Page deleted!');
    } else {
      showSnackbar(errorMessage, 'error');
    }
  };

  const handleDelete = () => {
    openModal({
      title: `Delete`,
      content: strings.en.confirmModal.testPage.confirmDelete,
      action: deletePageCall,
      actionLabel: 'Delete',
      actionColor: 'red',
    });
  };

  return (
    <Layout>
      <Container id="testpage">
        <TopContainer>
          <Title>Test Page</Title>
          <PageNav>
            {tabs.map((t) => (
              <li key={t.id}>
                <button
                  onClick={() => setSelectedTab(t)}
                  className={selectedTab.id === t.id ? 'active' : ''}
                >
                  {t.text}
                </button>
              </li>
            ))}
          </PageNav>
          <Flex justifyContent="flex-end" mb={3}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CancelButton
                onClick={onCancel}
                size="s"
                style={{
                  border: 'none',
                  marginRight: '10px',
                  minWidth: '95px',
                }}
              >
                Cancel
              </CancelButton>

              <SaveButton
                onClick={() => {
                  stopDebounce();
                  handleSave();
                }}
                size="s"
                noIcon
              >
                Save
              </SaveButton>
            </div>
          </Flex>
        </TopContainer>
        {pageId && (
          <DeleteButtonContainer>
            <FaTrash />
            <DeleteButton onClick={handleDelete}>Delete Test Page</DeleteButton>
          </DeleteButtonContainer>
        )}
        {/* Content component receives a debounce wrapper that allows child component to trigger a debounced save */}
        <ContentComponent debounce={debounce} />
        {loadPageError &&
          ReactDOM.createPortal(
            <NotFound />,
            document.getElementById('testpage'),
          )}
      </Container>
    </Layout>
  );
};

export default PackageLibraryPageTestPage;
