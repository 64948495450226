export const ADD_PACKAGE = 'PACKAGE_LIBRARY_EDITOR.ADD_PACKAGE';
export const ADD_PACKAGE_START = 'PACKAGE_LIBRARY_EDITOR.ADD_PACKAGE_START';
export const ADD_PACKAGE_FAIL = 'PACKAGE_LIBRARY_EDITOR.ADD_PACKAGE_FAIL';
export const ADD_PACKAGE_SUCCESS = 'PACKAGE_LIBRARY_EDITOR.ADD_PACKAGE_SUCCESS';

export const UPDATE_PACKAGE = 'PACKAGE_LIBRARY_EDITOR.UPDATE_PACKAGE';
export const UPDATE_PACKAGE_START =
  'PACKAGE_LIBRARY_EDITOR.UPDATE_PACKAGE_START';
export const UPDATE_PACKAGE_FAIL = 'PACKAGE_LIBRARY_EDITOR.UPDATE_PACKAGE_FAIL';
export const UPDATE_PACKAGE_SUCCESS =
  'PACKAGE_LIBRARY_EDITOR.UPDATE_PACKAGE_SUCCESS';

export const GET_PAGES = 'PACKAGE_LIBRARY_EDITOR.GET_PAGES';
export const GET_PAGES_START = 'PACKAGE_LIBRARY_EDITOR.GET_PAGES_START';
export const GET_PAGES_FAIL = 'PACKAGE_LIBRARY_EDITOR.GET_PAGES_FAIL';
export const GET_PAGES_SUCCESS = 'PACKAGE_LIBRARY_EDITOR.GET_PAGES_SUCCESS';

export const GET_PAGE_QUESTION_GROUPS =
  'PACKAGE_LIBRARY_EDIITOR.GET_PAGE_QUESTION_GROUPS';

export const RESET = 'PACKAGE_LIBRARY_EDITOR.RESET';

export const SET_PACKAGE_LIBRARY_EDITOR =
  'PACKAGE_LIBRARY_EDITOR.SET_PACKAGE_LIBRARY_EDITOR';

export const ADD_QUESTION_GROUP_TO_PAGE =
  'PACKAGE_LIBRARY_EDITOR.ADD_QUESTION_GROUP_TO_PAGE';
export const ADD_QUESTION_GROUP_TO_PAGE_START =
  'PACKAGE_LIBRARY_EDITOR.ADD_QUESTION_GROUP_TO_PAGE_START';
export const ADD_QUESTION_GROUP_TO_PAGE_FAIL =
  'PACKAGE_LIBRARY_EDITOR.ADD_QUESTION_GROUP_TO_PAGE_FAIL';
export const ADD_QUESTION_GROUP_TO_PAGE_SUCCESS =
  'PACKAGE_LIBRARY_EDITOR.ADD_QUESTION_GROUP_TO_PAGE_SUCCESS';

export const DELETE_QUESTION_GROUP_FROM_PAGE =
  'PACKAGE_LIBRARY_EDITOR.DELETE_QUESTION_GROUP_FROM_PAGE';
export const DELETE_QUESTION_GROUP_FROM_PAGE_START =
  'PACKAGE_LIBRARY_EDITOR.DELETE_QUESTION_GROUP_FROM_PAGE_START';
export const DELETE_QUESTION_GROUP_FROM_PAGE_FAIL =
  'PACKAGE_LIBRARY_EDITOR.DELETE_QUESTION_GROUP_FROM_PAGE_FAIL';
export const DELETE_QUESTION_GROUP_FROM_PAGE_SUCCESS =
  'PACKAGE_LIBRARY_EDITOR.DELETE_QUESTION_GROUP_FROM_PAGE_SUCCESS';

export const ADD_PAGE_TO_PACKAGE = 'PACKAGE_LIBRARY_EDITOR.ADD_PAGE_TO_PACKAGE';
export const ADD_PAGE_TO_PACKAGE_START =
  'PACKAGE_LIBRARY_EDITOR.ADD_PAGE_TO_PACKAGE_START';
export const ADD_PAGE_TO_PACKAGE_FAIL =
  'PACKAGE_LIBRARY_EDITOR.ADD_PAGE_TO_PACKAGE_FAIL';
export const ADD_PAGE_TO_PACKAGE_SUCCESS =
  'PACKAGE_LIBRARY_EDITOR.ADD_PAGE_TO_PACKAGE_SUCCESS';

export const DELETE_PAGE_FROM_PACKAGE =
  'PACKAGE_LIBRARY_EDITOR.DELETE_PAGE_FROM_PACKAGE';
export const DELETE_PAGE_FROM_PACKAGE_START =
  'PACKAGE_LIBRARY_EDITOR.DELETE_PAGE_FROM_PACKAGE_START';
export const DELETE_PAGE_FROM_PACKAGE_FAIL =
  'PACKAGE_LIBRARY_EDITOR.DELETE_PAGE_FROM_PACKAGE_FAIL';
export const DELETE_PAGE_FROM_PACKAGE_SUCCESS =
  'PACKAGE_LIBRARY_EDITOR.DELETE_PAGE_FROM_PACKAGE_SUCCESS';
