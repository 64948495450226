import React, { useState, useCallback, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { ContextModal, Overlay } from "../../components/UI/Modal/ContextModal";

const ModalContext = React.createContext();

const initialConfig = {
  type: 'confirm',
  open: false,
  title: '',
  content: '',
  action: null,
  actionLabel: 'Confirm',
  actionColor: '',
  cancelAction: true,
  cancelLabel: 'Cancel',
  customModal: null,
  size: 'sm',
};

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(initialConfig)
  /**
   * Opens the global modal with a specified config on top of base config
   * @function openModal
   * @param {Object} modalConfig
   */
  const openModal = useCallback((modalConfig) => {
    setModal({
      ...initialConfig, open: true, ...modalConfig
    })
  }, [])

  /**
   * Updates current modal configuration
   * @function updateModal
   * @param {object} configUpdates
   */
  const updateModal = useCallback((configUpdates) => {
    setModal({ ...modal, configUpdates })
  }, [modal])

  /**
   * Closes and resets current modal
   * @function closeModal
   */
  const closeModal = useCallback(() => {
    setModal(initialConfig)
  }, []);

  const value = useMemo(() =>
    ({ openModal, closeModal, updateModal }),
    [openModal, closeModal, updateModal]
  );

  const onEscKeyDown = useCallback((e) => {
    if (e.key !== 'Escape') return;
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (modal.open) window.addEventListener('keydown', onEscKeyDown);
    return () => {
      window.removeEventListener('keydown', onEscKeyDown);
    };
  }, [modal.open, onEscKeyDown]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {createPortal(
        <Overlay className={modal.type} visible={modal.open} onClick={(e) => {
          if (e.target.isEqualNode(e.currentTarget)) { setModal({ initialConfig, open: false }) }
        }} >
          <ContextModal
            type={modal.type}
            open={modal.open}
            title={modal.title}
            content={modal.content}
            action={modal.action}
            actionLabel={modal.actionLabel}
            actionColor={modal.actionColor}
            cancelLabel={modal.cancelLabel}
            onClose={closeModal}
            showCancelAction={modal.cancelAction}
            size={modal.size}
          />
        </Overlay>,
        document.getElementById('root')
      )
      }
    </ModalContext.Provider >
  )
}


/**
 * Some features are missing from the confirm modal
 * @returns {{
 *  openModal : ({
 *    type: 'confirm' | 'modal'
 *    title : String | React.Component, 
 *    content: String | React.Component, 
 *    action : Function,
 *    actionLabel : String, 
 *    actionColor : COLORS
 *    cancelAction : Function,
 *    cancelLabel: String,
 *    customModal: React.Component,
 *    size: 'sm'
 *  }),
 * closeModal : function,
 * updateModal: function,
 * }}
 **/
const useModal = () => {
  const context = React.useContext(ModalContext);

  if (context === undefined) {
    throw new Error(
      'useModal must be used within a ModalProvider',
    );
  }

  return context;
}

export { ModalProvider, useModal }