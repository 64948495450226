import React from 'react';
import { bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components/macro';
import { useNavigate, useLocation } from 'react-router-dom';
import newPageIcon from '../../assets/images/new-page-icon.svg';
import templatePageIcon from '../../assets/images/template-page-icon.svg';

const PackageCreateDialog = ({ onClose, open }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Create Package
      </DialogTitle>
      <DialogContent dividers>
        <H3>How do you want to start?</H3>
        <P>Select an option:</P>
        <OptionsContainer>
          <Option onClick={() => navigate(`${location.pathname}/from-scratch`)}>
            <Icon src={newPageIcon} />
            <h4>Start from Scratch</h4>
            <p>Start off with an empty package.</p>
          </Option>
          <Option>
            <Icon src={templatePageIcon} />
            <h4>Duplicate Existing Package</h4>
            <p>Save time by duplicating an existing package.</p>
          </Option>
        </OptionsContainer>
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <H2>{children}</H2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const H2 = styled.h2`
  margin: 0;
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.blue};
`;

const P = styled.p`
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkgrey};

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-rows: unset;
    grid-template-columns: 50% 50%;
    padding: 0 185px;
  }
`;

const Icon = styled.img`
  width: 113px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 223px;
  }
`;
const OptionsContainer = styled.div`
  display: grid;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-rows: unset;
    grid-template-columns: 50% 50%;
    padding: 0 185px;
  }
`;
const Option = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  border: solid 5px #ededed;
  background-color: #ffffff;

  margin: 10px 5px 0;
  padding: 5px 0;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 50px 25px 50px;
    padding: 77px 0;
  }

  text-align: center;
  &:hover {
    border-color: ${({ theme }) => theme.colors.blue};
    cursor: pointer;
  }

  h4 {
    font-size: 24px;
    font-weight: normal;
  }

  p {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.grey};
  }
`;

PackageCreateDialog.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
};

export default PackageCreateDialog;
