import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';
import { getQuestionFromResponse, prepareQuestion } from './common';

const UpdateQuestionContext = React.createContext();

function UpdateQuestionProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateQuestion = useCallback(async (id, question) => {
    try {
      setLoading(true);
      const payload = prepareQuestion(question);

      const response = await axiosInstance.patch(`/questions/${id}`, payload);

      return {
        question: getQuestionFromResponse(response.data),
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    updateQuestion,
    hasLoaded,
    errorMessage,
  };

  return (
    <UpdateQuestionContext.Provider value={value}>
      {children}
    </UpdateQuestionContext.Provider>
  );
}

/**
 * @returns {{
 *  loading: boolean,
 *  updateQuestion: (
 *    id: string,
 *    question: import('./common').FrontEndQuestion
 *  ) => Promise<{
 *    success: boolean;
 *    errorMessage: string;
 *    question?: import('./common').FrontEndQuestion
 *  }>
 * }}
 */
function useUpdateQuestion() {
  const context = React.useContext(UpdateQuestionContext);

  if (context === undefined) {
    throw new Error(
      'useUpdateQuestion must be used within a UpdateQuestionProvider',
    );
  }

  return context;
}

export { UpdateQuestionProvider, useUpdateQuestion };
