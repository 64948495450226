import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 100px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: solid 1px #b6b6b6;
  .wrapper{
    padding:0 23px;
    height:100%;
    width:100%;
    max-width:1440px;
    display:flex;
    align-items:center;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  flex-grow:1;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    margin-left: 8px;
  }
`;

export const CancelButton = styled.div`
  color: #2cabe0;
  font-size: 16px;
  cursor: pointer;
`;
