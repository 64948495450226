import React, { useState } from 'react';
import { SketchPicker, TwitterPicker, BlockPicker, GithubPicker, CirclePicker } from 'react-color';

import './picker.css';

const ColorPicker = ({ color: initialColor, onChangeComplete, type }) => {
  const [color, setColor] = useState(initialColor)

  switch (type) {
    case 'twitter':
      return <TwitterPicker 
        width="100%"
        className={color === '#ffffff' ? 'white-selected' : ''}
        color={color}
        onChange={(newColor) => setColor(newColor)} 
        onChangeComplete={onChangeComplete}
        colors={['#FFFFFF', '#000000']}
      />
    case 'block':
      return <BlockPicker
        width="100%"
        className={color === '#ffffff' ? 'white-selected' : ''}
        color={color}
        onChange={(newColor) => setColor(newColor)}
        onChangeComplete={onChangeComplete}
        colors={['#FFFFFF', '#000000']}
      />
    case 'github':
      return <GithubPicker
        width="100%"
        className={color === '#ffffff' ? 'white-selected' : ''}
        color={color}
        onChange={(newColor) => setColor(newColor)}
        onChangeComplete={onChangeComplete}
        colors={['#FFFFFF', '#000000']}
      />
    case 'circle':
      return <CirclePicker
        width="100%"
        className={color === '#ffffff' ? 'white-selected' : ''}
        color={color}
        onChange={(newColor) => setColor(newColor)}
        onChangeComplete={onChangeComplete}
        colors={['#FFFFFF', '#000000']}
      />
    default:
      return <SketchPicker 
        width="100%" 
        className={color === '#ffffff' ? 'white-selected' : ''} 
        color={color} 
        onChange={(newColor) => setColor(newColor)} 
        onChangeComplete={onChangeComplete} 
      />
  }
};

export default ColorPicker;
