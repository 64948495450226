import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Link } from '../UI';
import PackageCard from './Card';
import routes from '../../routes';
import styled from 'styled-components';
import { useGetAssets } from '../../contexts/api/asset';

const StyledLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

const PackageGrid = ({ packages }) => {
  const { assets } = useGetAssets()
  const packagesWithSrc = useMemo(() => {
    return packages.map(p => {
      const { src: thumbnailImage } = assets.find(asset => asset.id === p.thumbnailImage) || {}
      return { ...p, thumbnailImage }
    })
  }, [assets, packages])

  return (
    <Grid mx={-2} width="100%">
      {packagesWithSrc.map((p) => (
        <StyledLink to={routes.client.editPackages(p.id)} key={p.id}>
          <PackageCard {...p} />
        </StyledLink>
      ))}
    </Grid>
  )


};

PackageGrid.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object),
  viewPackage: PropTypes.func,
};

export default PackageGrid;
