import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text } from 'rebass';
import { Navlink } from '../UI';

export const TEST_IDS = {
  container: 'settings-navigation',
};

const Navigation = ({ links }) => (
  <Flex alignItems="center" data-testid={TEST_IDS.container}>
    <Text
      mr={4}
      pb={1}
      as="h2"
      color="darkgrey"
      fontSize={[7, 6]}
      fontWeight={400}
      my={0}
    >
      Settings
    </Text>
    <Flex alignItems="center">
      {links.map(l => (
        <Navlink
          key={l.to}
          py={5}
          px={4}
          to={l.to}
          exact={l.exact}
          fontSize={[4, 3]}
          data-testid={l.testId}
        >
          {l.text}
        </Navlink>
      ))}
    </Flex>
  </Flex>
);

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

Navigation.displayName = 'Settings.Navigation';

export default Navigation;
