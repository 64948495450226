import React from 'react';
import { DeleteStudentProvider, useDeleteStudent } from './deleteStudent';
import { UpdateStudentProvider, useUpdateStudent } from './updateStudent';
import {
  LinkStudentToPackageProvider,
  useLinkStudentToPackage,
} from './linkStudentToPackage';
import { StudentsProvider, useStudents } from './students';
import {
  UnlinkStudentFromPackageProvider,
  useUnlinkStudentFromPackage,
} from './unlinkStudentFromPackage';
import { InviteStudentsProvider, useInviteStudents } from './inviteStudents';

function StudentProvider({ children }) {
  return (
    <StudentsProvider>
      <InviteStudentsProvider>
        <LinkStudentToPackageProvider>
          <UnlinkStudentFromPackageProvider>
            <UpdateStudentProvider>
              <DeleteStudentProvider>{children}</DeleteStudentProvider>
            </UpdateStudentProvider>
          </UnlinkStudentFromPackageProvider>
        </LinkStudentToPackageProvider>
      </InviteStudentsProvider>
    </StudentsProvider>
  );
}

export {
  StudentProvider,
  useUpdateStudent,
  useDeleteStudent,
  useInviteStudents,
  useLinkStudentToPackage,
  useStudents,
  useUnlinkStudentFromPackage,
};
