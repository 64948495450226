import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const UnlinkStudentFromPackageContext = React.createContext();

function UnlinkStudentFromPackageProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const unlinkStudentFromPackage = useCallback(async (studentId, packageId) => {
    try {
      setLoading(true);

      await axiosInstance.delete(
        `/packages/${packageId}/students/${studentId}`,
      );

      return { studentId, packageId, success: true, error: null };
    } catch (error) {
      return { studentId, packageId, success: false, error };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    unlinkStudentFromPackage,
  };

  return (
    <UnlinkStudentFromPackageContext.Provider value={value}>
      {children}
    </UnlinkStudentFromPackageContext.Provider>
  );
}

/**
 * @returns {{
 *  unlinkStudentFromPackage: (studentId: string, packageId: string) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useUnlinkStudentFromPackage() {
  const context = React.useContext(UnlinkStudentFromPackageContext);

  if (context === undefined) {
    throw new Error(
      'useUnlinkStudentFromPackage must be used within a UnlinkStudentFromPackageContext',
    );
  }

  return context;
}

export { UnlinkStudentFromPackageProvider, useUnlinkStudentFromPackage };
