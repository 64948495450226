import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';
import routes from '../../../routes';

const DeletePackageContext = React.createContext();

function DeletePackageProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const deletePackage = useCallback(async (packageId) => {
    try {
      setLoading(true);
      await axiosInstance.delete(routes.api.package(packageId));
      return { success: true, message: 'Package deleted' };
    } catch (error) {
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    deletePackage,
  };

  return (
    <DeletePackageContext.Provider value={value}>
      {children}
    </DeletePackageContext.Provider>
  );
}

/**
 * @returns {{
 *  deletePackage: (packageId: string) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useDeletePackage() {
  const context = React.useContext(DeletePackageContext);

  if (context === undefined) {
    throw new Error(
      'useDeletePackage must be used within a DeletePackageContext',
    );
  }

  return context;
}

export { DeletePackageProvider, useDeletePackage };
