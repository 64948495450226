import styled from "styled-components";
import { Flex } from "@rebass/emotion";

export const Container = styled(Flex)`
  height: 408px;
  width: 340px;
  background: #f7f7f7;
  color: #b6b6b6;
  text-align: center;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius:20px;
  cursor: pointer;
  overflow: hidden;
  flex-direction:column;
  > svg{
      width: 112px;
      height:112px;
  }
  span {
    color: var(--color-red);
  }
`
