import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  Container,
  Title,
  DraggableContainer,
  DragContainer,
  DragContentContainer,
  DragIconContainer,
  QuestionGroupsContainer,
} from './styled';
import DraggableItem from '../../../../UI/DraggableItem';

const TestLibraryQuestionGroupBuildSidebar = ({ id, items, icons }) => (
  <Droppable droppableId={id} isDropDisabled>
    {(provided) => (
      <Container ref={provided.innerRef}>
        <Title>Question Templates</Title>
        <QuestionGroupsContainer>
          {items.map((item, index) => (
            <DraggableContainer key={item.id}>
              <DraggableItem
                id={`${id}-${item.id}`}
                index={index}
                DragContainer={DragContainer}
                DragContentContainer={DragContentContainer}
                DragIconContainer={DragIconContainer}
              >
                {icons[item.questionFormat] && (
                  <img src={icons[item.questionFormat]} alt="Item Icon" />
                )}
                {item.content}
              </DraggableItem>
            </DraggableContainer>
          ))}
        </QuestionGroupsContainer>
      </Container>
    )}
  </Droppable>
);

export default TestLibraryQuestionGroupBuildSidebar;
