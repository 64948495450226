import styled from 'styled-components/macro';

export const StyledStudentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  & > * {
    margin-bottom: 16px !important;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: baseline;

    & > * {
      margin-right: 36px !important;
      margin-bottom: 0 !important;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledActionContainer = styled.div`
  padding: 16px;
  margin: 16px;
  text-align: right;

  & > button {
    margin-left: 20px;
  }
`;

export const H2 = styled.h2`
  margin: 0;
`;
