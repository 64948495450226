import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const DeleteQuestionContext = React.createContext();

function DeleteQuestionProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const deleteQuestion = useCallback(async (id) => {
    try {
      setLoading(true);
      await axiosInstance.delete(`/questions/${id}`);

      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    deleteQuestion,
    hasLoaded,
    errorMessage,
  };

  return (
    <DeleteQuestionContext.Provider value={value}>
      {children}
    </DeleteQuestionContext.Provider>
  );
}

/**
 * @typedef {import('./common').FrontEndQuestion} FrontEndQuestion
 * @returns {{
 *  loading: boolean,
 *  deleteQuestion: (
 *    id: string,
 *    question: FrontEndQuestion
 *  ) => Promise<{
 *    success: boolean;
 *    errorMessage: string;
 *    question?: FrontEndQuestion
 *  }>,
 *  hasLoaded: boolean,
 *  errorMessage: string
 * }}
 */
function useDeleteQuestion() {
  const context = React.useContext(DeleteQuestionContext);

  if (context === undefined) {
    throw new Error(
      'useDeleteQuestion must be used within a DeleteQuestionProvider',
    );
  }

  return context;
}

export { DeleteQuestionProvider, useDeleteQuestion };
