import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';
import { imageContentDeTransformer } from '../../../utils/mediaLibrary';

const PackagesContext = React.createContext();

function PackagesProvider({ children }) {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const getPackages = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: '/packages',
      });

      const packages = response.data?.packages || [];

      setPackages(
        packages.map((p) => ({
          ...p,
          title: p.title?.replaceAll('\\n', ' '),
          subject: p.subject?.replaceAll('\\n', ' '),
          description: p.description?.replaceAll('\\n', ' '),
          thumbnailImage: imageContentDeTransformer(p.thumbnailImage),
        })),
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    packages,
    loading,
    hasLoaded,
    error,
    getPackages,
  };

  return (
    <PackagesContext.Provider value={value}>
      {children}
    </PackagesContext.Provider>
  );
}

/**
 * @returns {{
 *  error: Error,
 *  getPackages: () => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  packages: object[],
 * }}
 */
function usePackages() {
  const context = React.useContext(PackagesContext);

  if (context === undefined) {
    throw new Error('usePackages must be used within a PackagesProvider');
  }

  if (!context.loading && !context.hasLoaded) {
    context.getPackages();
  }

  return context;
}

export { PackagesProvider, usePackages };
