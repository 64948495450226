import React from 'react';
import styled from 'styled-components/macro';
import strings from '../../constants/strings';
import NoPackagesIcon from '../../assets/images/menu-book.svg';

export const PackageEmptyList = ({ onClick }) => (
  <Container onClick={onClick}>
    <Image src={NoPackagesIcon} />
    <Title>{strings.en.packages.noPackages.title}</Title>
  </Container>
);

const Container = styled.div`
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 700px;
  }
`;

const Title = styled.h2``;

const Image = styled.img`
  width: 200px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: unset;
  }
`;
