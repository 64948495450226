import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from '../../../axios';
import { getErrorMessage } from '../../../utils/request';
import { getQuestionFromResponse } from './common';

const QuestionsContext = React.createContext();

function QuestionsProvider({ children }) {
  const [questionGroupId, setQuestionGroupId] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setQuestions([]);
  }, [questionGroupId]);

  const getQuestions = useCallback(async (questionGroupId) => {
    try {
      setQuestionGroupId(questionGroupId);

      if (!questionGroupId) return;

      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: `/question-groups/${questionGroupId}/questions`,
      });
      setQuestions(getQuestionsFromResponse(response.data?.questions));
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    errorMessage,
    getQuestions,
    hasLoaded,
    loading,
    questions,
  };

  return (
    <QuestionsContext.Provider value={value}>
      {children}
    </QuestionsContext.Provider>
  );
}

/**
 * @typedef {import('./common').FrontEndQuestion} FrontEndQuestion
 * @returns {{
 *  errorMessage: string,
 *  getQuestions: (questionGroupId: string) => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  questions: FrontEndQuestion[],
 * }}
 */
function useQuestions() {
  const context = React.useContext(QuestionsContext);

  if (context === undefined) {
    throw new Error('useQuestions must be used within a QuestionsProvider');
  }

  return context;
}

/**
 * @typedef {import('./common').BackEndQuestion} BackEndQuestion
 * @param {BackEndQuestion[]} questions
 */
function getQuestionsFromResponse(questions) {
  return (questions || []).map((question) => getQuestionFromResponse(question));
}

export { QuestionsProvider, useQuestions };
