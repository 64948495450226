import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ExternalLayout } from '../../components/Layout';
import { Form } from 'react-final-form';
import { Text } from 'rebass';
import SignupForm from '../../components/Authentication/SignupForm';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthLoading, selectAuthError } from './selectors';
import { registerThunk } from './actions';

const Registration = () => {
  const loading = useSelector(selectAuthLoading);
  const error = useSelector(selectAuthError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ExternalLayout forTeachers>
      <Text as="h2">Sign Up</Text>
      <Form
        onSubmit={(userData) => dispatch(registerThunk(userData, navigate))}
        render={({ handleSubmit, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <SignupForm {...rest} loading={loading} error={error} />
          </form>
        )}
      />
    </ExternalLayout>
  );
};

export default Registration;
