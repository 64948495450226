import React from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components/macro';
import { InputField, Button } from '../UI';
import { PERSONA_ROLES } from '../../constants';
import { required } from '../../utils/form';
import { HelperText } from '../UI/HelperText';
import { Message } from '../UI/Message';

export const TEST_IDS = {
  email: 'signup-email',
  emailHelper: 'signup-email-helper',
  firstName: 'signup-firstName',
  firstNameHelper: 'signup-firstName-helper',
  lastName: 'signup-lastName',
  lastNameHelper: 'signup-lastName-helper',
  password: 'signup-password',
  passwordHelper: 'signup-password-helper',
  confirmPassword: 'signup-confirm-password',
  confirmPasswordHelper: 'signup-confirm-password-helper',
  submit: 'signup-submit',
};

const Div = styled.div`
  position: relative;
`;

const SignupForm = ({ error, loading }) => (
  <React.Fragment>
    <Field name="firstName" validate={required} type="text">
      {({ input, meta }) => (
        <Div>
          <InputField
            label="First Name"
            id="signup-first-name"
            data-testid={TEST_IDS.firstName}
            {...input}
          />
          {meta.touched && meta.error && (
            <HelperText data-testid={TEST_IDS.firstNameHelper}>
              {meta.error}
            </HelperText>
          )}
        </Div>
      )}
    </Field>
    <Field name="lastName" validate={required} type="text">
      {({ input, meta }) => (
        <Div>
          <InputField
            label="Last Name"
            id="signup-last-name"
            data-testid={TEST_IDS.lastName}
            {...input}
          />
          {meta.touched && meta.error && (
            <HelperText data-testid={TEST_IDS.lastNameHelper}>
              {meta.error}
            </HelperText>
          )}
        </Div>
      )}
    </Field>
    <Field name="email" type="email" validate={required}>
      {({ input, meta }) => (
        <Div>
          <InputField
            label="email"
            data-testid={TEST_IDS.email}
            id="signup-email"
            {...input}
          />
          {meta.touched && meta.error && (
            <HelperText data-testid={TEST_IDS.emailHelper}>
              {meta.error}
            </HelperText>
          )}
        </Div>
      )}
    </Field>
    <Field name="password" validate={required} type="password">
      {({ input, meta }) => (
        <Div>
          <InputField
            label="Password"
            data-testid={TEST_IDS.password}
            id="signup-password"
            type="password"
            {...input}
          />
          {meta.touched && meta.error && (
            <HelperText data-testid={TEST_IDS.passwordHelper}>
              {meta.error}
            </HelperText>
          )}
        </Div>
      )}
    </Field>
    <Field type="hidden" name="role" initialValue={PERSONA_ROLES.teacher}>
      {({ input, meta }) => <input {...input} />}
    </Field>

    {loading && <p>Processing your request...</p>}
    {error && <Message>{error}</Message>}

    <Button outlined disabled="true" width={400} mt={5} data-testid={TEST_IDS.submit}>
      Sign Up
    </Button>
  </React.Fragment>
);

export default SignupForm;
