import React from 'react';
import PropTypes from 'prop-types';
import { ColorLabelContainer, ColorPreview, Label } from './styled';

/**
 * @param {object} props
 * @param {string} [props.hex]
 * @param {Function} [props.onClick]
 * @returns {React.Component}
 */
const ColorLabel = ({ hex, onClick }) => {
  const clickable = Boolean(onClick);

  return (
    <ColorLabelContainer clickable={clickable} onClick={onClick}>
      <ColorPreview hex={hex} />
      <Label>{hex?.toUpperCase()}</Label>
    </ColorLabelContainer>
  );
};

ColorLabel.propTypes = {
  hex: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ColorLabel;
