import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const UpdateAssetContext = React.createContext();

function UpdateAssetProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const updateAsset = useCallback(async (id, updates) => {
    try {
      setLoading(true);

      const response = await axiosInstance.patch(`/assets/${id}`, updates);

      return {
        asset: getAssetFromResponse(response),
        success: true,
        error: null,
      };
    } catch (error) {
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    updateAsset,
  };

  return (
    <UpdateAssetContext.Provider value={value}>
      {children}
    </UpdateAssetContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  title: string;
 *  mimetype: string;
 * }} Asset
 */

/**
 * @returns {{
 *  updateAsset: (assetId: string, updates: object) => Promise<{ success: boolean; error: Error; asset: Asset}>,
 *  loading: boolean
 * }}
 */
function useUpdateAsset() {
  const context = React.useContext(UpdateAssetContext);

  if (context === undefined) {
    throw new Error('useUpdateAsset must be used within a UpdateAssetContext');
  }

  return context;
}

function getAssetFromResponse(response) {
  if (!response.data) {
    return null;
  }

  const asset = response.data;
  return {
    id: asset.id,
    name: asset.title,
    folder: asset.folder,
    src: asset.url,
    mimetype: asset.mimetype,
    title: asset.title,
    type: (asset.mimetype || '').split('/')[0],
  };
}

export { UpdateAssetProvider, useUpdateAsset };
