import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components/macro';
import { boxShadow, borderRadius } from '../../styleTokens';
import { position } from 'styled-system';

const STYLES = (function() {
  const styles = {
    maxWidth: 1440,
    sidePanelWidth: 350,
  };

  styles.editorWorkingArea = styles.maxWidth - styles.sidePanelWidth;
  return styles;
})();

export const PositionedBoxContainer = styled(Box)`
  ${position};
`;
// general purpose container that ensures all components auto center and fit to layout's max width
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: ${STYLES.maxWidth}px;
  flex-direction: column;
  overflow-x: auto;
`;

export const EditorContainer = styled(Container)`
  && {
    flex-direction: row;
  }
`;

export const ModalEditorContainer = ({ children, ...rest }) => (
  <PositionedBoxContainer style={{ maxWidth: STYLES.maxWidth }} {...rest}>
    {children}
  </PositionedBoxContainer>
);
/**
 * Usage should be in conjunction with a Editor Container and a FullScreenLayout
 * @param {Object} props
 */
export const SidePanelContainer = styled(Box)`
  flex: 0 0 ${STYLES.sidePanelWidth}px;
  position: relative;
  ${boxShadow};
  height: 100%;
`;

export const EditorWorkingArea = styled(Box)`
  flex: 1 1 auto;
  height: 100%;
  max-width: ${STYLES.editorWorkingArea}px;
`;

export const QuestionEditorContainer = styled(Box)`
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.25);
  ${borderRadius};
  padding: ${({ theme }) => theme.space[5]};
`;

export default Container;
