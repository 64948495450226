import React, { useEffect, useState } from 'react';
import { Flex } from 'rebass';
import Layout from '../Layout';
import { Container } from '../UI';
import { AddButton, ModalActionButton } from '../UI/Button';
import strings from '../../constants/strings';
import { NoQuestionGroups } from './NoQuestionGroups';
import { LoadingCaption } from '../UI/Loaders';
import TestLibraryQuestionGroupGrid from './QuestionGroup/Grid';
import {
  useCreateQuestionGroup,
  useQuestionGroups,
} from '../../contexts/api/question-group';
import { useSnackbar } from '../../contexts/ui';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes';

const SearchBar = ({ questionGroups, setFilteredQuestionGroups }) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const filteredQuestionGroups = questionGroups.filter((questionGroup) =>
        questionGroup.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredQuestionGroups(filteredQuestionGroups);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [query, questionGroups, setFilteredQuestionGroups]);

  const handleSearch = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
  };

  return (
    <input
      type="text"
      placeholder="Search"
      value={query}
      onChange={handleSearch}
    />
  );
};

export const TestLibrary = () => {
  const { questionGroups, loading, error } = useQuestionGroups();
  const { showSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [filteredQuestionGroups, setFilteredQuestionGroups] = useState(questionGroups);
  const navigate = useNavigate();
  const { createQuestionGroup, loading: createInProgress } =
    useCreateQuestionGroup();
  const { getQuestionGroups } = useQuestionGroups();

  useEffect(() => {
    if (error) {
      showSnackbar(error, 'error');
    }
  }, [error, showSnackbar]);

  const hasQuestionGroups = !loading && questionGroups.length > 0;

  const handleCreateQuestionGroup = async () => {
    const { success, questionGroup } = await createQuestionGroup({
      name: newGroupName,
    });
    if (success) {
      showSnackbar(
        strings.en.testLibrary.addQuestionGroup.successMessage(newGroupName),
      );
      await getQuestionGroups();
      navigate(routes.client.editTestSet(questionGroup.id));
    } else {
      showSnackbar(
        strings.en.testLibrary.addQuestionGroup.errorMessage(newGroupName),
        'error',
      );
    }
  };

  return (
    <Layout>
      <Container>
        <Flex justifyContent="space-between" alignItems="center" mb={3}>
          <SearchBar questionGroups={questionGroups} setFilteredQuestionGroups={setFilteredQuestionGroups} />
          <AddButton size="s" onClick={() => setModal(true)}>
            {strings.en.testLibrary.addQuestionGroup.title}
          </AddButton>
        </Flex>
        {loading ? (
          <LoadingCaption />
        ) : (
          <TestLibraryQuestionGroupGrid questionGroups={filteredQuestionGroups} />
        )}
        {!hasQuestionGroups && !loading && <NoQuestionGroups />}
      </Container>
      <Dialog
        onClose={() => setModal(false)}
        aria-labelledby="customized-dialog-title"
        open={modal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => setModal(false)}
        >
          {strings.en.testLibrary.addQuestionGroup.title}
        </DialogTitle>
        <DialogContent id="dialog-content" dividers>
          <div
            style={{
              minHeight: '300px',
              maxWidth: '620px',
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            <p>{strings.en.testLibrary.addQuestionGroup.nameInfo}</p>
            <br></br>
            <TextField
              label="Question Group Name"
              name="firstName"
              variant="outlined"
              style={{ width: '100%' }}
              size="small"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ModalActionButton ghost size="s" onClick={() => setModal(false)}>
            Cancel
          </ModalActionButton>
          <ModalActionButton
            size="s"
            onClick={handleCreateQuestionGroup}
            disabled={createInProgress || newGroupName.trim() === ''}
          >
            Next
          </ModalActionButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default TestLibrary;
