import React from 'react';
import styled from 'styled-components/macro';
import { COLORS } from '../../theme';

const ActionLabelSizes = {
  Normal: '',
};

const ActionLabelDimensions = {
  [ActionLabelSizes.Normal]: {
    fontSize: '14px',
  },
};

export const ActionLabel = styled.label`
  color: ${(props) => (props.delete ? COLORS.red : COLORS.blue)};
  font-size: ${(props) => ActionLabelDimensions[props.size].fontSize};
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
`;

ActionLabel.defaultProps = {
  size: ActionLabelSizes.Normal,
};

export const DeleteActionLabel = ({ children, ...rest }) => (
  <ActionLabel delete {...rest}>
    {children}
  </ActionLabel>
);

export default ActionLabel;
