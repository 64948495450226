import React from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components/macro';
import { required } from '../../../utils/form';
import { Button, HelperText, InputField } from '../../UI';
import { Message } from '../../UI/Message';

export const TEST_IDS = {
  email: 'forgot-password-email',
  emailHelper: 'forgot-password-email-helper',
  submit: 'forgot-password-submit',
  form: 'forgot-password-form',
};

const Div = styled.div`
  position: relative;
`;

export const ForgotPasswordForm = props => {
  const { error, forgotPassword } = props;
  return (
    <Form
      onSubmit={forgotPassword}
      render={({ handleSubmit, pristine, invalid }) => (
        <React.Fragment>
          <form onSubmit={handleSubmit} data-testid={TEST_IDS.form}>
            <Field name="email" type="email" validate={required}>
              {({ input, meta }) => (
                <Div>
                  <InputField
                    data-testid={TEST_IDS.email}
                    id="email"
                    width={400}
                    placeholder="Email"
                    {...input}
                  />
                  {meta.touched && meta.error && (
                    <HelperText data-testid={TEST_IDS.emailHelper}>
                      {meta.error}
                    </HelperText>
                  )}
                </Div>
              )}
            </Field>

            <Message>{error}</Message>

            <Button
              color="white"
              width={400}
              mt={5}
              data-testid={TEST_IDS.submit}
            >
              Send
            </Button>
          </form>
        </React.Fragment>
      )}
    />
  );
};
