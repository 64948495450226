import styled from 'styled-components/macro';
import { COLORS } from '../../../../../../../theme';

export const Container = styled.div`
  height: 100%;
  margin: 0px 0px;
  padding: 0 30px 70px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width:100%;
`;

export const PageNav = styled.ul`
margin: 0 20px;
padding:0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  li:not(:last-of-type) {
    margin-right: 29px;
  }
  li {
    min-width: 170px;
    text-transform: capitalize;
    button {
      cursor: pointer;
      :hover {
        border-bottom: 1px solid #424242;
      }
      border: none;
      :focus,
      :active {
        outline: none;
      }
      background-color: transparent;
      padding: 8px;
      font-size: 20px;
      color: #424242;
      white-space: nowrap;
    }
    button.active {
      color: ${COLORS.blue};

      border-bottom: 1px solid ${COLORS.blue};
    }
  }
`;

export const TopContainer = styled.div`
  height: 39px;
  padding: 35px 0 25px 0;
  background-color: #fff;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  width: 170px;
  height: 45px;
  margin: 1px 30px 0px 1px;
  font-size: 34px;
  color: #424242;
`;

export const ContentContainer = styled.div`
  height: 600px;
  margin: 33px 0 112px;
  border-radius: 3px;
  background-color: #fff;
  .links {
    width: 420px;
    margin: 5px auto;

    font-size: 12px;
    color: #2cabe0;
    clear: both;
  }
  .linksChoices {
    padding: 0px;
    margin: -20px -10px;
    height: 40px;
    font-size: 12px;
    color: #2cabe0;
    clear: both;
  }
`;

export const InputGroup = styled.div`
  margin-bottom:56px;
`;

export const Input = styled.input`
  width: 470px;
  height: 35px;
  margin: 10px 0 0 0;
  padding: 7px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  box-sizing: border-box;
`;

export const Label = styled.label`
  height: 26px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: middle;
  color: #424242;
  display: block;
  span, small{
    font-family: Roboto;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818181;
    display: block;
  }
  small{
    font-size:16px;
  }
  &.with-subtitle{
    height:64px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  max-width:470px;
  height: 408px;
  padding: 9px 11px 8px 12px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  display: flex;
  box-sizing: border-box;
  ::placeholder,
  ::-webkit-input-placeholder {
    width: 365px;
    height: 130px;
    font-size: 12px;
    color: #b6b6b6;
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }

  textarea + div {
    width: 388px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #c9c9c9;
  }
`;


export const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-red);
  padding: 15px 0;
  justify-content: flex-end;
`

export const DeleteButton = styled.div`
  margin-left: 6px;
  user-select: none;
  margin-top: 2px;
`;

export const Col = styled.div`
  flex-grow:1;
  max-width:500px;
`

export const ButtonContainer = styled.div`
  display: inline;
  align-items: center;
  cursor: pointer;
  color: var(--color-red);
  padding: 30px 100px 30px;
`;
