import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 100px;
`;

export const Subtitle = styled.div`
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 24px;
  margin-bottom: 32px;
`;
