import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ExternalLayout } from '../../components/Layout';
import { LinkButton } from '../../components/UI/Link';
import { signOut } from '../../utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthError } from './selectors';
import { forgotPasswordThunk } from './actions';
import { ForgotPasswordForm } from '../../components/Authentication/ForgotPassword/Form';
import { H3 as BaseH3 } from '../../components/Authentication/ForgotPassword/styles';

export const ForgotPassword = props => {
  const error = useSelector(selectAuthError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ExternalLayout>
      <Container>
        <h2>Forgot Password</h2>
        <H3>
          Forgot your password? No problem. Enter your email to reset your
          password.
        </H3>
        <ForgotPasswordForm
          error={error}
          forgotPassword={data => dispatch(forgotPasswordThunk(data, navigate))}
        />

        <MessageContainer>
          <LinkButton onClick={signOut}>Back to log in</LinkButton>
        </MessageContainer>
      </Container>
    </ExternalLayout>
  );
};

const Container = styled.div`
  margin: 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > :first-child {
    padding: 0 0 48px;
  }
`;

const H3 = styled(BaseH3)`
  margin: 0 0 40px;
`;

const MessageContainer = styled.div`
  margin: 20px 3px 91px 17px;
  opacity: 0.87;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
`;
