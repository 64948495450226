import * as actionTypes from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  assets: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_IMAGE_START:
      return { ...state, error: null, loading: true };
    case actionTypes.UPLOAD_IMAGE_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.UPLOAD_IMAGE_SUCCESS:
      return {
        error: null,
        loading: false,
        assets: [...state.assets].concat(action.payload),
      };
    case actionTypes.GET_UPLOADS_START:
      return { ...state, error: null, loading: true };
    case actionTypes.GET_UPLOADS_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.GET_UPLOADS_SUCCESS:
      return {
        error: null,
        loading: false,
        assets: action.payload.assets,
      };
    default:
      return state;
  }
};

export default reducer;
