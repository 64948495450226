import { markupInContent } from "./markup";

/**
 *
 * @param {String} id
 * @returns {String}
 */
export const imageContentTransformer = (id) => id && id.length > 0 ? `\\image(${id})` : id;

/**
 *
 * @param {String} id
 * @returns {String}
 */
export const imageContentDeTransformer = (image) => {
  if (image.indexOf('\\image(') === 0) {
    return image.replace(/\\image\(/, '').slice(0, -1);
  } else {
    return image;
  }
};

export const assetContentTransformer = ({ id, type }) => `\\${type}(${id})`

export const assetContentDeTransformer = (content) => {
  const asset = markupInContent(content)
  return asset ? asset.content : content
}