import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import auth from './containers/Authentication/reducer';
import mediaLibrary from './legacy/containers/MediaLibrary/reducer';
import users from './containers/Users/reducer';
import packageLibraryEditor from './legacy/containers/PackageLibraryEditor/reducer';
import testLibraryEditor from './containers/TestLibraryEditor/reducer';
import testLibrary from './containers/TestLibrary/reducer';

export const rootReducer = combineReducers({
  auth,
  mediaLibrary,
  users,
  packageLibraryEditor,
  testLibraryEditor,
  testLibrary,
});

export const loggerMiddleware = (store) => (next) => (action) => {
  // eslint-disable-next-line no-console
  console.log('dispatching', action);
  let result = next(action);
  // eslint-disable-next-line no-console
  console.log('next state', store.getState());
  return result;
};

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(loggerMiddleware);
}

export default createStore(
  rootReducer,
  applyMiddleware.apply(null, middlewares),
);
