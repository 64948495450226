/**
 * Returns the first first name and the last last name initials.
 * E.g.: "My First Name" will return "MN"
 * @param {String} string Text to get the initials from
 * @returns First and Last initials
 */
export const getInitials = (string = '') => {
  if (!string) return '';

  const words = string.toUpperCase().split(/\s/);

  if (words.length > 1) {
    const first = 0;
    const last = words.length - 1;

    return `${words[first][0]}${words[last][0]}`;
  }

  return words[0][0];
};
