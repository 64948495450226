import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  right: 0;

  & > * {
    margin: 10px;
  }
`;

export const MediaTitle = styled.h3`
 line-height:42px;
`

export const Input = styled.input`
  height: 40px;
  font-size: 16px;
  margin: 0;
  text-align: center;
`;
