import { QUESTION_TYPES, PAGE_TYPES, SUB_PAGE_TYPES } from './application';

export const API_MESSAGES = {
  REQUEST_CANCELLED: 'Request Cancelled By User',
};

const strings = {
  en: {
    generic: {
      save: 'Save',
      delete: 'Delete',
      cancel: 'Cancel',
      add: 'Add',
      progress: 'Progress',
      reset: 'Reset',
      tryAgain: 'Try Again',
    },
    httpStatus: {
      notFound: {
        title: 'Not Found',
        p1: "Oh darn. Couldn't grab what you were looking for :(",
      },
      unauthorized: {
        title: 'Not Authorized',
        p1:
          'Oh snap. Looks like you need to be logged in to access this part of the site',
      },
    },
    actions: {
      changePassword: {
        success: 'Password changed',
        fail: 'Unable to Change Password',
      },
      profileUpdate: {
        success: 'Profile updated',
        fail: 'Unable to update profile',
      },
    },
    questionEditor: {
      questionTitle: 'question',
    },
    questionTypes: {
      [QUESTION_TYPES.video]: 'draw',
      [QUESTION_TYPES.text]: 'multiple choice',
      [QUESTION_TYPES.audio]: 'long answer',
      [QUESTION_TYPES.image]: 'short answer',
      [QUESTION_TYPES.cloze]: 'cloze',
      [QUESTION_TYPES.match]: 'match',
      [QUESTION_TYPES.piano]: 'piano',
    },
    pageTypes: {
      [PAGE_TYPES.flashCards]: 'flash cards',
      [PAGE_TYPES.intro]: 'intro',
      [PAGE_TYPES.lessonPage]: 'lesson page',
      [PAGE_TYPES.pdf]: 'pdf page',
      [SUB_PAGE_TYPES.testQuestion]: 'test question',
      [PAGE_TYPES.testSection]: 'test section',
    },
    validations: {
      oneCorrect: 'At least one correct',
      required: 'Required',
      answersLeftBlank: 'Some answers were left blank',
    },
    testLibrary: {
      noTestSetFound: {
        p1: 'Unable to find this test set :(',
      },
      noQuestionGroups: {
        title: "You haven't created any test questions yet.",
      },
      addQuestionGroup: {
        title: 'Create Question Group',
        successMessage: (questionGroupName) =>
          `${questionGroupName} successfuly saved!`,
        errorMessage: (questionGroupName) =>
          `Error saving ${questionGroupName}. Please, try again later.`,
        nameInfo:
          'Enter a name for this question group. You can always change the name later.',
      },
      updateQuestionGroupOrder: {
        successMessage: 'saved',
        errorMessage:
          'Error changing the order of questions. Please try again later.',
      },
      deleteQuestionGroup: {
        confirmationMessage:
          'Are you sure you want to delete this question group?',
        successMessage: 'Question group successfully deleted!',
        errorMessage: 'Error deleting question group. Please, try again later.',
      },
      saveQuestion: {
        successMessage: 'Question updated',
        errorMessage: 'Error saving the question. Please, try again later.',
      },
      deleteQuestion: {
        confirmationMessage:
          'Are you sure you want to delete this question?\n\nThis will also remove all student answers and teacher marks.',
        successMessage: 'Question successfully deleted!',
      },
    },
    confirmModal: {
      questionGroup: {
        deleteFailNotice:
          'Only an empty question group can be deleted. Please remove all questions and try again',
        confirmDelete:
          'Are you sure you want to delete this question group?\n\nThis will delete all the questions in the group, along with all the student answers and teacher marks.',
      },
      question: {
        confirmDelete:
          'Are you sure you want to delete this question?\n\nThis will delete all the student answers and teacher marks as well.',
      },
      package: {
        confirmDelete:
          'Are you sure you want to delete this package?\n\nThis will delete all tests contained within the package, along with all the student answers and teacher marks.',
      },
      testPage: {
        confirmDelete:
          'Are you sure you want to delete this page?\n\nThis will also remove all student answers and teacher marks.',
      },
    },
    testLibraryEditor: {
      defaultTestSetName: 'New Test Set',
      sidebar: {
        title: 'Add New Test Question',
      },
    },
    packages: {
      noPackages: {
        title: "You haven't created any packages yet.",
      },
      addPackages: {
        title: 'Create Package',
      },
    },
    students: {
      noStudents: {
        title: 'You haven’t invited any students yet.',
      },
      inviteStudents: {
        title: 'Invite Student',
        emailError: 'Invalid format',
      },
      sendInvitation: {
        title: 'Send Invitation',
      },
      sendInvitations: {
        title: 'Send Invitations',
      },
      removePackage: {
        title: 'Unassign Package',
        content:
          'Are you sure you want to unassign this package?\n\nThis will mean the student will no longer have access to the contents of this package.',
        successMessage: 'Package unassigned',
        errorMessage:
          'Error trying to unassign the package. Please, try again later.',
      },
      addPackage: {
        title: 'Assign Package',
        content:
          'Are you sure you want to assign this package?\n\nThis will mean the student will have access to the contents of this package.',
        successMessage: 'Package assigned',
        errorMessage: 'Error assigning the package. Please, try again later.',
      },
      deleteStudent: {
        title: 'Delete Student',
        content:
          'Are you sure you want to delete this student?\n\nThis will remove the student from your account and remove all their question answers and all your marks.',
        successMessage: 'Student deleted',
        errorMessage: 'Error deleting student. Please, try again later.',
      },
      cancelChanges: {
        title: 'Unsaved changes',
        content: 'Are you sure you want to leave without saving your changes?',
      },
      saveChanges: {
        title: 'Save Changes',
        content: 'Are you sure you want to save your changes?',
        successMessage: 'Student updated',
        errorMessage: 'Error updating student. Please, try again later.',
      },
    },
    studentPackageEditor: {
      dragNDropStudents: {
        title: 'Drag and Drop Students',
      },
      confirmDeleteStudent: {
        title:
          'Are you sure you would like to remove this student from this package?',
      },
      noStudentsLeft: {
        // for when the side panel is empty
        title: 'No more students available.',
      },
    },
    packageLibraryEditor: {
      fetchPackageLoaderModal: {
        title: 'Fetching Packages',
      },
      dragNDropIntro: {
        title: 'Drag & Drop Intro Screen',
      },
      dragNDropContent: {
        title: 'Drag & Drop Content',
      },
      dragNDropTestQuestions: {
        title: 'Drag & Drop Test Questions',
      },
      testSetSidePanel: {
        title: 'Test Questions',
      },
      sidePanel: {
        pageTypeDisabled: 'This page is not enabled right now',
      },
      questionPageModal: {
        titleAdd: 'Add a Question Page',
        titleEdit: 'Edit Question Page',
      },
      deletePageConfirmation: {
        title: "Are you sure you'd like to delete this page?",
      },
    },
    mediaLibrary: {
      addMediaModal: {
        title: 'Upload Media',
        subtitle: '',
      },
      addMediaButton: {
        text: 'Add Media',
      },
      mediaPicker: {
        title: 'Select Media File',
      },
      deleteAssets: {
        confirmationMessage: (n) =>
          `Are you sure you want to delete ${n} asset${n > 1 ? 's' : ''}?`,
        inProgress: 'Deleting assets',
        successMessage: 'Assets successfully deleted!',
        errorMessage: 'Error deleting assets. Please, try again later.',
      },
      uploadAsset: {
        inProgress: 'Uploading asset',
        processing: 'Uploading asset',
        successMessage: 'Asset successfully uploaded!',
        errorMessage: 'Error uploading this asset. Please, try again later.',
      },
      updateAsset: {
        inProgress: 'Updating asset',
        successMessage: 'Asset successfully updated!',
        errorMessage: 'Error updating this asset. Please, try again later.',
      },
    },
    lessonPage: {
      addLessonPage: {
        title: 'Add Lesson Page',
      },
      editLessonPage: {
        title: 'Change Lesson Page',
      },
    },
  },
};

export default strings;
