import * as actionTypes from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  questionGroup: {},
  questions: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.concat(action.payload.question),
      };
    case actionTypes.UPDATE_QUESTION_SUCCESS:
      const question = action.payload.question;
      return {
        ...state,
        questions: state.questions.map(q => {
          if (q.id === question.id) {
            return question;
          } else {
            return q;
          }
        }),
      };
    case actionTypes.ADD_QUESTION_GROUP_START:
      return { ...state, error: null, loading: true };
    case actionTypes.ADD_QUESTION_GROUP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        questionGroup: action.payload.questionGroup,
      };
    case actionTypes.ADD_QUESTION_GROUP_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.UPDATE_QUESTION_GROUP_START:
      return { ...state, error: null, loading: true };
    case actionTypes.UPDATE_QUESTION_GROUP_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        questionGroup: action.payload.questionGroup,
      };
    case actionTypes.UPDATE_QUESTION_GROUP_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.RESET:
      return initialState;
    case actionTypes.SET_TEST_LIBRARY_EDITOR:
      const { questionGroup, questions } = action.payload;
      return {
        ...state,
        error: null,
        loading: false,
        questionGroup: { ...questionGroup },
        questions: questions.map(q => ({ ...q })),
      };
    case actionTypes.SET_QUESTIONS:
      return { ...state, questions: action.payload.questions };
    case actionTypes.GET_QUESTION_GROUP_QUESTIONS_START:
      return { ...state, error: null, loading: true };
    case actionTypes.GET_QUESTION_GROUP_QUESTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        questions: action.payload.questionGroup.questions,
      };
    case actionTypes.DELETE_QUESTION_START:
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question.id === action.payload.questionId) {
            return { ...question, isDeleting: true };
          } else {
            return question;
          }
        }),
      };
    case actionTypes.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        questions: state.questions.filter(
          question => question.id !== action.payload.questionId,
        ),
      };
    case actionTypes.DELETE_QUESTION_FAIL:
      return {
        ...state,
        questions: state.questions.map(question => {
          if (question.id === action.payload.questionId) {
            const { isDeleting, ...newQuestion } = question;
            return newQuestion;
          } else {
            return question;
          }
        }),
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export default reducer;
