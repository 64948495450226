import styled from 'styled-components/macro';
import Card from '../../../../UI/Card';

export const TabsContainer = styled.div`
  display: flex;
  margin-top: 65px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 10%;
  padding-bottom: 50px;
  background-color: #ffffff;
  overflow: auto;
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const StyledCard = styled(Card)`
  margin-top: 30px;
  margin-right: 30px;
`;
