// generic utilities for selectors/reducers etc
import { groupBy, mapValues } from 'lodash';
import { extractResourceAndNamespace } from '../legacy/containers/PackageLibraryEditor/helpers';
import { PHI } from '../constants/math';
// selector utilities

// we get raw user information, it is helpful to have full names combined

export const reduceUsers = users => ({
  ...users,
  fullName: `${users.firstName} ${users.lastName}`,
  initial: `${users.firstName.charAt(0)}${users.lastName.charAt(0)}`,
});

/**
 * converts the form submission for a question to the question object model that is expected from the
 * api
 * @param {Object} submission
 * @param {Array <Object>} submission.answers
 * @param {String} submission.answers[].answer
 * @param {Boolean} submission.answers[].isCorrect
 * @param {String} submission.question
 * @param {Number} submission.difficulty
 * @param {Boolean} submission.multiAnswer
 * @param {String} submission.type
 * @param {Number} questionNumber the number of the question in the test set / question group
 * @returns {Object}
 */
export const reduceQuestionFormToQuestion = (submission, questionNumber) => {
  const { question, difficulty, type } = submission;
  const { answers, correctAnswers } = submission.answers.reduce(
    (answers, answer, index) => {
      const answerText = answer.text;
      answers.answers.push(answerText);
      if (answer.isCorrect) {
        answers.correctAnswers.push(index);
      }
      return answers;
    },
    { answers: [], correctAnswers: [] },
  );

  return {
    ...submission,
    id: submission.id || null,
    question,
    difficulty,
    type,
    answers: answers,
    correctAnswers: correctAnswers,
    questionNumber,
  };
};

export const reduceListToMapById = list =>
  list.reduce((map, listItem) => {
    map[listItem.id] = { ...listItem };
    return map;
  }, {});

export const reduceListToListOfIds = list => list.map(l => l.id);

/**
 * Utility that returns a closure to get fontSize for a given component
 * @param {Number} minCharacters the min characters before causing the reduction
 * @param {Number} baseFontSize the base font size to reduce from
 * @param {Number} reductionFactor a percentage ie .85 means reduce by 85%
 * @returns {Function}
 * @param {String} text
 * @returns {String} the fontsize  ie '15px' rounded down to the nearest whole integer
 */
export const createDynamicFontSizeBasedOnLength = (
  minCharacters,
  baseFontSize,
  reductionFactor,
) => text => {
  if (text.length > minCharacters)
    return Math.floor(baseFontSize * reductionFactor) + 'px';

  return baseFontSize + 'px';
};

/**
 * extracts the namespaces from the drag and drop compponents and returns all drag an drop properties
 * plus the namespaces. the draggable id is the actual unique draggable id instead of it plus the namespace
 * @param {Object}  dropProps
 */
export const namespacedDragEvent = ({
  draggableId: originalDragId,
  source,
  destination,
}) => {
  const {
    id: draggableId,
    namespace: draggableNamespace,
  } = extractResourceAndNamespace(originalDragId);
  const {
    id: droppableId,
    namespace: droppableNamespace,
  } = extractResourceAndNamespace(destination.droppableId);

  const {
    id: sourceId,
    namespace: sourceNamespace,
  } = extractResourceAndNamespace(source.droppableId);

  return {
    source,
    destination,
    originalDragId,
    draggableId,
    draggableNamespace,
    droppableId,
    droppableNamespace,
    sourceId,
    sourceNamespace,
  };
};

export const goldenRatio = num => Math.round(num * PHI);

/**
 * validates whether a draggable is dropping over the correct namespace
 * useful for applying dynamic styles to drag component
 * @param {String} dragoverId
 * @param {String} droppableNamespace
 * @returns {Boolean}
 */
export const isDraggableOverCorrectDrop = (dragoverId, droppableNamespace) => {
  if (dragoverId) {
    const { namespace } = extractResourceAndNamespace(dragoverId);
    return namespace === droppableNamespace;
  }
  return false;
};

/**
 * organizes a list of items alphabetically
 * @param {Array} items items should be sorted prior to entering this func
 * @param {String} groupByKey the key that is going to be grouped by, note the value
 * against this key must be a string since .charAt(0) is used
 * @returns {Object}
 */
export const organizeItemsAlphabetically = (items, groupByKey) => {
  const itemsWithInitial = items.map(i => ({
    ...i,
    _initial: i[groupByKey].charAt(0).toLowerCase(),
  }));
  const alphabetizedItems = groupBy(itemsWithInitial, '_initial');
  // stripping out _initial
  return mapValues(alphabetizedItems, items =>
    items.map(({ _initial, ...rest }) => rest),
  );
};
