import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core';
import Card from '../UI/Card';

export const StudentCard = ({ id, firstName, lastName, email }) => (
  <StyledCard key={id} title={`${firstName} ${lastName}`} subtitle={email} />
);

StudentCard.propTypes = {
  id: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const StyledCard = styled(Card)`
  margin-top: 30px;
  margin-right: 30px;
  max-width: none;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 25%);
`;

export default StudentCard;
