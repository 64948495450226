import { types } from './Media';

export const tabs = [
  { label: 'All' },
  { label: 'Image', type: types.IMAGE },
  { label: 'Video', type: types.VIDEO },
  { label: 'Audio', type: types.AUDIO },
  { label: 'PDF', type: types.PDF },
  { label: 'Lottie', type: types.LOTTIE },
];

export const getFilteredMedia = (media, type) => {
  switch (type) {
    case types.IMAGE:
      return media.filter((m) => m.type === 'image');
    case types.VIDEO:
      return media.filter((m) => m.type === 'video');
    case types.AUDIO:
      return media.filter((m) => m.type === 'audio');
    case types.PDF:
      return media.filter((m) => m.type === 'pdf');
    case types.LOTTIE:
      return media.filter((m) => m.type === 'json');
    default:
      return media;
  }
};
