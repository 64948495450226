import { Flex } from 'rebass';
import { border, typography } from 'styled-system';
import styled from 'styled-components/macro';

/**
 * ensure you pass the width property to set the diameter of the circle
 */
export const Circle = styled(Flex)`
  height: ${({ width }) => `${width}px`};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
  ${border};
  ${typography};
`;
