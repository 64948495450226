import React from 'react';
import { arrayOf, string, func, number } from 'prop-types';
import { Line, Tab, TabsContainer, TabText } from './styled';

const Tabs = ({
  Container = React.Fragment,
  tabs,
  selectedTabIndex,
  onSelectTab,
  tabTextColor,
}) => {
  const isSelected = (index) => index === selectedTabIndex;

  return (
    <Container>
      {tabs.map((text, index) => (
        <Tab key={index} onClick={() => onSelectTab(index)}>
          <TabText color={tabTextColor} selected={isSelected(index)}>
            {text}
          </TabText>
          {isSelected(index) ? <Line /> : null}
        </Tab>
      ))}
    </Container>
  );
};

Tabs.propTypes = {
  tabs: arrayOf(string).isRequired,
  selectedTabIndex: number.isRequired,
  onSelectTab: func.isRequired,
  tabTextColor: string,
};

Tabs.defaultProps = {
  Container: TabsContainer,
  tabTextColor: '#2cabe0',
};

export default Tabs;
