import React, { useMemo } from 'react';
import { Box } from 'rebass';
import { useGetAssets } from '../../../../contexts/api/asset';
import { markupInContent } from '../../../../utils/markup';
import { Link } from '../../../UI';
import MediaElement from './MediaElement';
import { AnswerItem, MediaItemContainer } from './styled';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf';

const MatchField = ({
  text,
  identifier,
  onChange,
  onSelectAsset,
  onRemoveAsset,
  onDeleteItem,
  ...rest
}) => {
  const { assets } = useGetAssets();
  const markup = markupInContent(text);
  const answerAsset = useMemo(() => {
    return markup ? assets.find((asset) => asset.id === markup.content) : null;
  }, [markup, assets]);

  return (
    <Box {...rest}>
      {answerAsset ? (
        <div className="identifier-wrap">
          <span className="identifier-code media">{identifier}</span>
          <MediaItemContainer>
            <MediaElement asset={answerAsset} />
            <small>{answerAsset.name}</small>
          </MediaItemContainer>
        </div>
      ) : (
        <div className="identifier-wrap">
          <span className="identifier-code text">{identifier}</span>
          <AnswerItem
            onChange={onChange}
            value={text}
            placeholder="Insert text (including markups) or media here."
          />
        </div>
      )}
      <div className="links">
        <Link
          anchor
          to={ClassedUpMarkupGuide}
          name="markup-guide"
          target="_blank"
          rel="noreferrer"
        >
          Markup guide
        </Link>{' '}
        &nbsp; | &nbsp;
        {answerAsset ? (
          <Link to="#" onClick={onRemoveAsset}>
            Remove Media File
          </Link>
        ) : (
          <Link to="#" onClick={onSelectAsset}>
            Add Media File
          </Link>
        )}
        &nbsp; | &nbsp;
        <Link to="#" onClick={onDeleteItem}>
          Remove Item
        </Link>
      </div>
    </Box>
  );
};

export default React.memo(MatchField);
