import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2cabe0;
  width: 70px;
  height: 100%;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;

export const Line = styled.div`
  position: absolute;
  bottom: 13px;
  margin-top: 5px;
  width: 100%;
  box-shadow: 0 2px 4px 0 #2cabe0;
  border: 1px solid #2cabe0;
  height: 0.5px;
  background: #2cabe0;
`;
