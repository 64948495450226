import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Actions } from './styled';
import { ActionLabel, DeleteActionLabel } from '../../../../UI/ActionLabel';
import { TextField } from '@material-ui/core';
import { COLORS } from '../../../../../theme/theme';
import { SaveButton } from '../../../../UI/Button';

const StudentInformation = ({ student, onCancel, onDelete, onUpdate }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  useEffect(() => {
    setFirstName(student?.firstName || '')
    setLastName(student?.lastName || '')
  }, [student])

  const namesModified = firstName !== student?.firstName || lastName !== student?.lastName
  const namesNotEmpty = firstName.trim() !== '' && lastName.trim() !== ''
  const canSave = (namesModified && namesNotEmpty)

  const handleDelete = () => {
    window.scrollTo({ top: 0 });
    onDelete();
  };

  const handleCancel = () => {
    window.scrollTo({ top: 0 });
    onCancel(canSave);
  };

  const handleUpdate = () => {
    window.scrollTo({ top: 0 });
    onUpdate(firstName, lastName);
  }

  return (
    <Container>
      <Content>
        <TextField
          id="firstName"
          name="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          helperText="First Name"
          FormHelperTextProps={{
            style: {
              color: '#979797',
            },
          }}
          InputProps={{
            style: {
              fontSize: 24,
              color: COLORS.darkgrey,
            },
          }}
        />
        <TextField
          id="lastName"
          name="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          helperText="Last Name"
          FormHelperTextProps={{
            style: {
              color: '#979797',
            },
          }}
          InputProps={{
            style: {
              fontSize: 24,
              color: COLORS.darkgrey,
            },
          }}
        />
      </Content>
      <Actions>
        <DeleteActionLabel onClick={() => handleDelete()}>
          Delete Student
        </DeleteActionLabel>
        <ActionLabel onClick={() => handleCancel()}>Cancel</ActionLabel>
        <SaveButton disabled={!canSave} size="s" onClick={handleUpdate} noIcon>Save</SaveButton>
      </Actions >
    </Container >
  );
};

StudentInformation.propTypes = {
  name: PropTypes.string,
  student: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default StudentInformation;
