import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';
import { getErrorMessage } from '../../../utils/request';

const QuestionGroupsContext = React.createContext();

function QuestionGroupsProvider({ children }) {
  const [questionGroups, setQuestionGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getQuestionGroups = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: '/question-groups',
      });

      const frontEndQuestionGroups = getQuestionGroupsFromResponse(response);
      const sortedQuestionGroups = sortQuestionGroups(frontEndQuestionGroups);

      setQuestionGroups(sortedQuestionGroups);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    questionGroups,
    loading,
    hasLoaded,
    errorMessage,
    getQuestionGroups,
  };

  return (
    <QuestionGroupsContext.Provider value={value}>
      {children}
    </QuestionGroupsContext.Provider>
  );
}

/**
 * @returns {{
 *  errorMessage: string,
 *  getQuestionGroups: () => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  questionGroups: object[],
 * }}
 */
function useQuestionGroups() {
  const context = React.useContext(QuestionGroupsContext);

  if (context === undefined) {
    throw new Error(
      'useQuestionGroups must be used within a QuestionGroupsProvider',
    );
  }

  if (!context.loading && !context.hasLoaded) {
    context.getQuestionGroups();
  }

  return context;
}

const defaultColor = '#f57067';

function getQuestionGroupsFromResponse(response) {
  return (response?.data?.questionGroups || []).map((questionGroup) => ({
    ...questionGroup,
    id: questionGroup.id,
    name: questionGroup.name,
    color: defaultColor,
    quantity: 0,
    description: '',
  }));
}

/**
 * Sorts QuestionGroups by their Name
 * @param {QuestionGroup[]} questionGroups
 * @param {Boolean} sortAscending defaults to true
 * @returns {QuestionGroup[]}
 */
function sortQuestionGroups(questionGroups, sortAscending = true) {
  return questionGroups.sort((a, b) => {
    if (sortAscending) {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
}
export { QuestionGroupsProvider, useQuestionGroups };
