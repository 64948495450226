import React, { useEffect } from 'react';
import Layout from '../../Layout';
import { LoadingCaption } from '../../UI/Loaders';
import { usePackages } from '../../../contexts/api/package';
import { Container } from '../../UI';
import { useNavigate, useParams } from 'react-router-dom';
import StudentLibraryPackages from './Content/Packages';
import StudentInformation from './Content/Information';
import strings from '../../../constants/strings';
import routes from '../../../routes';
import {
  useDeleteStudent,
  useLinkStudentToPackage,
  useStudents,
  useUnlinkStudentFromPackage,
  useUpdateStudent,
} from '../../../contexts/api/student';
import { useModal, useSnackbar } from '../../../contexts/ui';

export const StudentEdit = () => {
  const { showSnackbar } = useSnackbar();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const {
    packages,
    loading: packagesAreLoading,
    error: errorFetchingPackages,
  } = usePackages();
  const {
    getStudents,
    getStudentById,
    loading: studentsAreLoading,
    error: errorFetchingStudents,
  } = useStudents();
  const { updateStudent } = useUpdateStudent();
  const { linkStudentToPackage } = useLinkStudentToPackage();
  const { unlinkStudentFromPackage } = useUnlinkStudentFromPackage();
  const { deleteStudent } = useDeleteStudent();

  const { id: studentId } = useParams();

  const student = getStudentById(studentId);

  const loading = packagesAreLoading || studentsAreLoading;
  const error = errorFetchingPackages || errorFetchingStudents;
  const errorMessage = error?.message || '';

  useEffect(() => {
    if (errorMessage) {
      showSnackbar(errorMessage, 'error');
    }
  }, [errorMessage, showSnackbar]);

  const leavePage = () => {
    navigate(routes.client.students);
  };

  const handleCancel = (hasChanges = false) => {
    if (!hasChanges) return leavePage();
    return openModal({
      action: leavePage,
      actionLabel: 'Yes',
      cancelLabel: 'No',
      title: strings.en.students.cancelChanges.title,
      content: strings.en.students.cancelChanges.content,
    });
  };

  const removePackage = async (packageId) => {
    const result = await unlinkStudentFromPackage(studentId, packageId);
    await getStudents();

    if (result.success) {
      showSnackbar(strings.en.students.removePackage.successMessage);
    } else {
      showSnackbar(strings.en.students.removePackage.errorMessage, 'error');
    }
  };

  const addPackage = async (packageId) => {
    const result = await linkStudentToPackage(studentId, packageId);
    await getStudents();

    if (result.success) {
      showSnackbar(strings.en.students.addPackage.successMessage);
    } else {
      showSnackbar(strings.en.students.addPackage.errorMessage, 'error');
    }
  };

  const performUpdateStudent = async (firstName, lastName) => {
    const result = await updateStudent(student?.id, firstName, lastName);
    await getStudents();

    if (result.success) {
      showSnackbar(strings.en.students.saveChanges.successMessage);
    } else {
      showSnackbar(strings.en.students.saveChanges.errorMessage, 'error');
    }
  };

  const performDeleteStudent = async () => {
    const result = await deleteStudent(studentId);
    await getStudents();

    if (result.success) {
      showSnackbar(strings.en.students.removePackage.successMessage);
      navigate(routes.client.students);
    } else {
      showSnackbar(strings.en.students.removePackage.errorMessage, 'error');
    }
  };

  const handleDeleteStudent = () => {
    openModal({
      action: performDeleteStudent,
      actionLabel: 'Delete',
      actionColor: 'red',
      cancelLabel: 'Cancel',
      title: strings.en.students.deleteStudent.title,
      content: strings.en.students.deleteStudent.content,
    });
  };

  const handlePackageAdd = (packageId) => {
    openModal({
      action: () => addPackage(packageId),
      actionLabel: 'Assign',
      cancelLabel: 'Cancel',
      title: strings.en.students.addPackage.title,
      content: strings.en.students.addPackage.content,
    });
  };

  const handlePackageRemove = (packageId) => {
    openModal({
      action: () => removePackage(packageId),
      actionLabel: 'Unassign',
      cancelLabel: 'Cancel',
      actionColor: 'red',
      title: strings.en.students.removePackage.title,
      content: strings.en.students.removePackage.content,
    });
  };

  return (
    <Layout>
      <Container>
        {loading ? (
          <LoadingCaption />
        ) : (
          <>
            <StudentInformation
              student={student}
              onCancel={handleCancel}
              onDelete={handleDeleteStudent}
              onUpdate={performUpdateStudent}
            />
            <StudentLibraryPackages
              student={student}
              packages={packages}
              onPackageAdd={handlePackageAdd}
              onPackageRemove={handlePackageRemove}
            />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default StudentEdit;
