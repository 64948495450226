import React from 'react';
import { ProfileProvider, useProfile } from './profile';
import { ChangePasswordProvider, useChangePassword } from './password'


function SettingsProvider({ children }) {
  return (
    <ProfileProvider>
      <ChangePasswordProvider>
        {children}
      </ChangePasswordProvider>
    </ProfileProvider>
  );
}

export {
  SettingsProvider,
  ChangePasswordProvider,
  useProfile,
  useChangePassword,
}