import React from 'react';
import { StudentProvider } from './student';
import { QuestionProvider } from './question';
import { AssetProvider } from './asset';
import { QuestionGroupProvider } from './question-group';
import { PackageProvider } from './package';
import { PagesProvider } from './pages';

export function ApiProvider({ children }) {
  return (
    <AssetProvider>
      <PackageProvider>
        <QuestionProvider>
          <QuestionGroupProvider>
            <StudentProvider>
              <PagesProvider>{children}</PagesProvider>
            </StudentProvider>
          </QuestionGroupProvider>
        </QuestionProvider>
      </PackageProvider>
    </AssetProvider>
  );
}
