import React from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components/macro';
import { passwordsMatch, required } from '../../utils/form';
import { Button, HelperText, InputField } from '../UI';
import { Message } from '../UI/Message';

export const TEST_IDS = {
  password: 'reset-password-password',
  passwordHelper: 'reset-password-password-helper',
  confirmPassword: 'reset-password-password',
  confirmPasswordHelper: 'reset-password-password-helper',
  submit: 'reset-password-submit',
  form: 'reset-password-form',
};

const Div = styled.div`
  position: relative;
`;

export const ResetPasswordForm = props => {
  const { error, resetPassword } = props;
  return (
    <Form
      onSubmit={resetPassword}
      render={props => {
        const { handleSubmit, values } = props;
        return (
          <React.Fragment>
            <form onSubmit={handleSubmit} data-testid={TEST_IDS.form}>
              <Field name="password" type="password" validate={required}>
                {({ input, meta }) => (
                  <Div>
                    <InputField
                      data-testid={TEST_IDS.password}
                      id="password"
                      width={400}
                      placeholder="New Password"
                      {...input}
                    />
                    {meta.touched && meta.error && (
                      <HelperText data-testid={TEST_IDS.passwordHelper}>
                        {meta.error}
                      </HelperText>
                    )}
                  </Div>
                )}
              </Field>
              <Field
                name="confirmPassword"
                type="password"
                validate={value => {
                  const isRequired = required(value);
                  const doThePasswordsMatch = passwordsMatch({
                    ...values,
                    confirmPassword: value,
                  });

                  return isRequired || doThePasswordsMatch;
                }}
              >
                {({ input, meta }) => (
                  <Div>
                    <InputField
                      data-testid={TEST_IDS.confirmPassword}
                      id="confirmPassword"
                      width={400}
                      placeholder="Confirm Password"
                      {...input}
                    />
                    {meta.touched && meta.error && (
                      <HelperText data-testid={TEST_IDS.confirmPasswordHelper}>
                        {meta.error}
                      </HelperText>
                    )}
                  </Div>
                )}
              </Field>

              <Message>{error}</Message>

              <Button
                color="white"
                width={400}
                mt={5}
                data-testid={TEST_IDS.submit}
              >
                Reset Password
              </Button>
            </form>
          </React.Fragment>
        );
      }}
    />
  );
};
