import React from 'react';
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass';
import AnswerBox from './AnswerBox';
import MediaElement from './MediaElement';
import { Link } from '../../../UI';
import { InputGroup, Label, MediaItemContainer, TextAreaShortHorizontal } from './styled';
import { useGetAssets } from '../../../../contexts/api/asset';
import { markupInContent } from '../../../../utils/markup';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf'


const QuestionHorizontalLayout = ({ answers, answerIndicator, content, setContent, handleChangeAnswerText, openMediaPicker }) => {
  const { assets, hasLoaded } = useGetAssets()
  const AnswerLetters = Object.keys(answerIndicator);
  const markup = markupInContent(content)

  const contentAsset = useMemo(() => {
    return markup
      ? assets.find(asset => asset.id === markup.content)
      : null
  }, [assets, markup])

  return hasLoaded && (
    <Flex flexDirection="row" mx="auto" maxWidth="700px">
      <Box flexGrow={1} paddingBottom={8}>
        <InputGroup >
          <Label alignSelf="flex-start">Content</Label>
          {contentAsset
            ? <MediaItemContainer className="content horiz">
              <MediaElement asset={contentAsset} />
              <small>{contentAsset.name}</small>
            </MediaItemContainer>
            : <TextAreaShortHorizontal
              flexGrow={1}
              placeholder="Insert text here (including markups) or click below to add media as a content."
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          }

          {typeof content === 'object' && <img src={content.src} alt={content.name} />}
          <div className="links flexStart">
            <Link anchor href={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link> &nbsp; | &nbsp;
            {contentAsset
              ? <Link to="#" onClick={() => setContent('')}>Remove Media File</Link>
              : <Link to="#" onClick={() => openMediaPicker()}>Add Media File</Link>
            }
          </div>
        </InputGroup>
      </Box>
      <Flex className="horiz" flexDirection="column" maxWidth="300px" pl={10} >
        <Label alignSelf="flex-start" > Answers</Label>
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          {answers.map((answer, index) => (
            <AnswerBox
              key={index}
              width={1} display="flex" flexDirection="column" pb={10}
              answerText={answer.text}
              onChange={(e) => handleChangeAnswerText(index, e.target.value)}
              onSelectAsset={() => openMediaPicker(index)}
              onRemoveAsset={() => handleChangeAnswerText(index, '')}
              letter={AnswerLetters[index]} />
          ))}
        </Flex>

      </Flex>
    </Flex >
  )
}


QuestionHorizontalLayout.propTypes = {
  answers: PropTypes.array.isRequired,
  answerIndicator: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired,
  handleChangeAnswerText: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['vert', 'horiz'])
}

export default QuestionHorizontalLayout