import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Layout from '../Layout';
import {
  FixedContainer,
  Title,
  ContentContainer,
  MediaContainer,
  SelectButtonsContainer,
  TitleContainer,
  SubTitle,
  Row,
} from './styled';
import { AddButton } from '../UI/Button';
import { ActionLabel } from '../UI/ActionLabel';
import Uploader from '../Uploader';
import Media from './Media';
import MediaPreviewEdit from './Preview/Edit';
import { Loading } from '../UI/Loaders';
import strings from '../../constants/strings';
import { tabs, getFilteredMedia } from './common';
import Tabs from '../UI/Tabs';
import MediaLibraryContent from './Content';
import {
  useDeleteAssets,
  useGetAssets,
  useUploadAsset,
} from '../../contexts/api/asset';
import { useSnackbar, useModal } from '../../contexts/ui';

const MediaLibrary = () => {
  const {
    assets,
    loading,
    error: errorGettingAssets,
    getAssets,
  } = useGetAssets();

  const {
    uploadAsset,
    loading: uploadInProgress,
    processing: processingUpload,
    error: errorUploadingAsset,
  } = useUploadAsset();

  const {
    deleteAssets,
    error: errorDeletingAssets,
    loading: deleteAssetsInProgress,
  } = useDeleteAssets();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selecting, setSelecting] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState({});

  const numberOfSelectedAssets = Object.keys(selectedAssets).length;

  const { showSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const error =
    errorGettingAssets || errorDeletingAssets || errorUploadingAsset;
  const errorMessage = error?.message || '';

  useEffect(() => {
    if (Boolean(errorMessage)) {
      showSnackbar(errorMessage, 'error');
    }
  }, [errorMessage, showSnackbar]);

  const cancelSelecting = () => {
    setSelectedAssets({});
    setSelecting(false);
  };

  const getSelectedTabType = () => tabs[selectedTabIndex]?.type;

  const selectAll = () => {
    const selected = {};

    getFilteredMedia(assets, getSelectedTabType()).forEach((m) => {
      selected[m.id] = m;
    });

    setSelectedAssets(selected);
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteAssets(Object.values(selectedAssets));
    await getAssets();

    if (result.success) {
      showSnackbar(strings.en.mediaLibrary.deleteAssets.successMessage);
      cancelSelecting();
    } else {
      showSnackbar(strings.en.mediaLibrary.deleteAssets.errorMessage, 'error');
    }
  };

  const handleDelete = () => {
    if (numberOfSelectedAssets) {
      openModal({
        title: 'Delete',
        content: strings.en.mediaLibrary.deleteAssets.confirmationMessage(
          numberOfSelectedAssets,
        ),
        action: handleDeleteConfirm,
        actionLabel: 'Delete',
        actionColor: 'red',
      });
    }
  };

  const handlePreviewMedia = (asset) => {
    openModal({
      type: 'modal',
      size: 'lg',
      action: null,
      cancelLabel: 'Close',
      title: <MediaPreviewEdit media={asset} />,
      content: <Media preview media={asset} />,
    });
  };

  const handleMediaClick = (m) => {
    if (selecting) {
      if (selectedAssets[m.id]) {
        setSelectedAssets(_.omit(selectedAssets, m.id));
      } else {
        setSelectedAssets({ ...selectedAssets, [m.id]: m });
      }
    } else {
      handlePreviewMedia(m);
    }
  };

  const uploadMedia = async (m) => {
    const result = await uploadAsset(m);
    await getAssets();

    if (result.success) {
      showSnackbar(strings.en.mediaLibrary.uploadAsset.successMessage);
    } else {
      showSnackbar(strings.en.mediaLibrary.uploadAsset.errorMessage, 'error');
    }
  };

  const actionInProgress = uploadInProgress || deleteAssetsInProgress;

  const action = getActionMessage({
    processingUpload,
    uploadInProgress,
    deleteAssetsInProgress,
    strings,
  });

  return (
    <Layout noBodyPadding bgColor="var(--color-offwhite)">
      <ContentContainer>
        <FixedContainer>
          <Row>
            <TitleContainer>
              <Title>{`Media Library`}</Title>
              <SubTitle>
                {action}
                {actionInProgress && <Loading />}
              </SubTitle>
            </TitleContainer>
            <Uploader
              id="package-card"
              accept="video/*,audio/*,image/*,application/pdf,application/json"
              onChange={uploadMedia}
            >
              <AddButton
                as="div"
                size="s"
                height="inherit"
                disabled={actionInProgress}
              >
                {'Upload New Media'}
              </AddButton>
            </Uploader>
          </Row>
          <Row>
            <Tabs
              tabs={tabs.map((tab) => tab.label)}
              onSelectTab={setSelectedTabIndex}
              selectedTabIndex={selectedTabIndex}
            />
            {selecting ? (
              <SelectButtonsContainer>
                <ActionLabel delete onClick={handleDelete}>
                  Delete
                </ActionLabel>
                <ActionLabel onClick={selectAll}>Select All</ActionLabel>
                <ActionLabel onClick={cancelSelecting}>Cancel</ActionLabel>
              </SelectButtonsContainer>
            ) : (
              <ActionLabel onClick={() => setSelecting(true)}>
                Select Files
              </ActionLabel>
            )}
          </Row>
        </FixedContainer>
        <MediaContainer>
          {loading ? (
            <Loading />
          ) : (
            <MediaLibraryContent
              media={getFilteredMedia(assets, getSelectedTabType())}
              onClickMedia={handleMediaClick}
              isSelected={(m) => selectedAssets[m.id]}
            />
          )}
        </MediaContainer>
      </ContentContainer>
    </Layout>
  );
};

function getActionMessage({
  processingUpload,
  uploadInProgress,
  deleteAssetsInProgress,
  strings,
}) {
  return processingUpload
    ? strings.en.mediaLibrary.uploadAsset.processing
    : uploadInProgress
    ? strings.en.mediaLibrary.uploadAsset.inProgress
    : deleteAssetsInProgress
    ? strings.en.mediaLibrary.deleteAssets.inProgress
    : '';
}

export default MediaLibrary;
