import styled from 'styled-components/macro';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  overflow-x: hidden;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  min-width: 30%;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #424242;
`;

export const SubTitle = styled(Title)`
  && {
    font-size: 20px;
    font-style: italic;
  }
`;

export const FixedContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width:90%;
`

export const MediaContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-shrink:1;
  height:auto;
  overflow-x:auto;
  padding: 0 5%;
  max-width:1440px;
`;


export const SelectButtonsContainer = styled.div`
  & > * {
    margin: 10px;
  }
  display: flex;
`;
