import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';
import routes from '../../../routes';

const PublishPackageContext = React.createContext();

function PublishPackageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const publishPackage = useCallback(async (packageId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(routes.api.packagePublish(packageId));

      return {
        success: response.status === 200,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const unpublishPackage = useCallback(async (packageId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(routes.api.packagePublish(packageId));

      return {
        success: response.status === 200,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    publishPackage,
    unpublishPackage,
    hasLoaded,
    errorMessage,
  };

  return (
    <PublishPackageContext.Provider value={value}>
      {children}
    </PublishPackageContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  name: string;
 * }} Package
 */

/**
 * @returns {{
 *  loading: boolean,
 *  createPackage: (package: Package) => Promise<{ package: Package; success: boolean; errorMessage: string; }>
 *  hasLoaded: boolean,
 *  errorMessage: string
 * }}
 */

function usePublishPackage() {
  const context = React.useContext(PublishPackageContext);

  if (context === undefined) {
    throw new Error(
      'usePublishPackage must be used within a PublishPackageProvider',
    );
  }

  return context;
}

export { PublishPackageProvider, usePublishPackage };
