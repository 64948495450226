import React from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components/macro';
import backgroundSVG from '../../assets/images/background-teacher-tools.svg';
import logoSVG from '../../assets/images/logo.svg';

export const ExternalLayout = ({ children, forTeachers = false }) => {
  return (
    <ExternalLayoutContainer>
      <LogoConainer>
        <Logo src={logoSVG} />
        {forTeachers && <h3>for teachers</h3>}
      </LogoConainer>
      <ChildrenContainer>
        <Box>{children}</Box>
      </ChildrenContainer>
    </ExternalLayoutContainer>
  );
};

ExternalLayout.propTypes = {
  forTeachers: bool,
};

const ExternalLayoutContainer = styled.div`
  display: grid;
  height: 100vh;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 58% auto;
  }
`;

const LogoConainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.md} {
    background-position-x: 31.7%;
    background-size: cover;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.21) 23%,
      #2cabe0 250%
    );
    background-image: url(${backgroundSVG}),
      linear-gradient(to bottom, rgba(255, 255, 255, 0.21) 23%, #2cabe0 250%);
  }
`;

const Logo = styled.img`
  height: 7vh;
  padding-top: 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: inherit;
    width: 470px;
    height: 98px;
    object-fit: contain;
  }
`;

const ChildrenContainer = styled.div`
  padding: 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const Box = styled.div`
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 40px;
  }
`;
