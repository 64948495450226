import { types as assetTypes } from "../components/MediaLibrary/Media"

/**
 * Return any markup in the content 
 * ex: \image(ID)
 * @param {String} content - string that might include any available markups
 * @returns {{match: String, type: String, content: String} | null} 
 * 
 */
export const markupInContent = (content, filtered) => {
    if (!content) return null;
    const assetMarkups = Object.values(assetTypes)
    const otherMarkups = ['latex', 'staff'] // TODO get a full list of all other supported markups
    const markups = [...assetMarkups, ...otherMarkups].reduce((filtered, markup) => {
        if (!filtered.includes(markup)) {
            filtered.push(markup)
        }
        return filtered
    }, [])
    const regex = new RegExp(`^\\\\(${markups.join("|")})\\((.*)\\)$`)
    const found = content.match(regex);
    return found ? { match: found[0], type: found[1], content: found[2] } : null
}