import React from 'react';
import { Container, Text, Line } from './styled';

const Tab = ({ IconComponent, text, active, ...props }) => (
  <Container {...props}>
    <IconComponent size={35} />
    <Text>{text}</Text>
    {active ? <Line /> : null}
  </Container>
);

export default Tab;
