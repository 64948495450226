import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { QuestionFormats } from '../../../contexts/api/question/common';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import routes from '../../../routes';
import strings from '../../../constants/strings';
import TestLibraryQuestionsLongAnswer from './LongAnswer';
import TestLibraryQuestionsTextAnswer from './TextAnswer';
import TestLibraryQuestionsMultipleChoice from './MultipleChoice';
import TestLibraryQuestionsMatchingLines from './MatchingLines';
import TestLibraryQuestionsOrder from './Order';
import TestLibraryQuestionsMath from './Math';
import {
  useCreateQuestion,
  useDeleteQuestion,
  useQuestions,
  // useGetQuestion,
  useUpdateQuestion,
} from '../../../contexts/api/question';
import { useSnackbar, useModal } from '../../../contexts/ui';
import axiosInstance from '../../../axios';
import { getQuestionFromResponse } from '../../../contexts/api/question/common';
import { getErrorMessage } from '../../../utils/request';

const QuestionPages = {
  [QuestionFormats.SHORT_ANSWER]: {
    Component: TestLibraryQuestionsTextAnswer,
  },
  [QuestionFormats.LONG_ANSWER]: {
    Component: TestLibraryQuestionsLongAnswer,
  },
  [QuestionFormats.MULTIPLE_CHOICE]: {
    Component: TestLibraryQuestionsMultipleChoice,
  },
  [QuestionFormats.MATCHING_LINES]: {
    Component: TestLibraryQuestionsMatchingLines,
  },
  [QuestionFormats.ORDER]: {
    Component: TestLibraryQuestionsOrder,
  },
  [QuestionFormats.MATH]: {
    Component: TestLibraryQuestionsMath,
  },
};

const DefaultQuestionPage = TestLibraryQuestionsLongAnswer;

const questionDifficulties = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
];

const TestLibraryQuestion = ({ ...props }) => {
  const { showSnackbar } = useSnackbar();
  const { questionGroupId, questionId, questionFormat: questionFormatParam, questionNumber } = useParams()
  const {questions, getQuestions } = useQuestions();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState(null);
  const [questionLoading, setQuestionLoading] = useState(false);
  // const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { createQuestion, loading: createInProgress } = useCreateQuestion();
  const { updateQuestion, loading: updateInProgress } = useUpdateQuestion();
  const { deleteQuestion, loading: deleteInProgress } = useDeleteQuestion();

  const currentQuestionIndex = useMemo(() => questions.findIndex((question) => question.id === questionId), [questions, questionId]);
  const nextQuestionIndex = useMemo(() => currentQuestionIndex + 1, [currentQuestionIndex]);
  const previousQuestionIndex = useMemo(() => currentQuestionIndex - 1, [currentQuestionIndex]);

  const hasNextQuestion = useMemo(() => Boolean(questions[nextQuestionIndex]), [questions, nextQuestionIndex]);
  const hasPreviousQuestion = useMemo(() => Boolean(questions[previousQuestionIndex]), [questions, previousQuestionIndex]);

  const goNextQuestion = useCallback(() => {
    const nextQuestion = questions[nextQuestionIndex];
    if (nextQuestion) {
      navigate(routes.client.editQuestion(questionGroupId, nextQuestion.id));
    }
  }, [
    questions,
    nextQuestionIndex,
    navigate,
    questionGroupId,
  ]);

  const goPreviousQuestion = useCallback(() => {
    const previousQuestion = questions[previousQuestionIndex];
    if (previousQuestion) {
      navigate(routes.client.editQuestion(questionGroupId, previousQuestion.id));
    }
  }, [
    questions,
    previousQuestionIndex,
    navigate,
    questionGroupId,
  ]);

  // const {
  //   getQuestion,
  //   loading: questionIsLoading,
  //   errorMessage: errorGettingQuestion,
  //   question: questionData,
  // } = useGetQuestion();

  // CTP-173: There is a bug with the useGetQuestion hook. Because of the way
  // react handles its render cycle, the hook is not able to update the the question data
  // in time even though the id is correct. The old data presists and is passed off to the
  // question page.
  const getQuestion = useCallback(async (id) => {
    try {
      if (!id) return;

      setQuestionLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: `/questions/${id}`,
      });
      
      setQuestionData(getQuestionFromResponse(response.data));
    } catch (error) {
      setErrorMessage(getErrorMessage(error));
    } finally {
      setQuestionLoading(false);
      // setHasLoaded(true);
    }
  }, []);

  useEffect(() => {
    getQuestion(questionId);
  }, [getQuestion, questionId]);

  useEffect(() => {
    getQuestions(questionGroupId);
  }, [getQuestions, questionGroupId]);

  useEffect(() => {
    if (errorMessage) {
      showSnackbar(errorMessage, 'error');
    }
  }, [errorMessage, showSnackbar]);

  const goBack = () => {
    navigate(routes.client.editQuestionGroup(questionGroupId));
  };

  const isEditing = Boolean(questionId);

  const saveQuestion = isEditing
    ? (question) => updateQuestion(questionId, question)
    : (question) => createQuestion(questionGroupId, question);

  let questionFormat = questionData ? questionData?.questionFormat : questionFormatParam;

  const handleSave = async (question) => {
    const { success, errorMessage } = await saveQuestion({
      ...question,
      questionFormat,
    });
    if (success) {
      showSnackbar(strings.en.testLibrary.saveQuestion.successMessage);
      if (!isEditing) {
        setTimeout(goBack, 750);
      }
    } else {
      showSnackbar(errorMessage, 'error');
    }
  };

  const handleDeleteConfirm = async () => {
    const { success, errorMessage } = await deleteQuestion(questionId);
    if (success) {
      showSnackbar(strings.en.testLibrary.deleteQuestion.successMessage);
      setTimeout(goBack, 750);
    } else {
      showSnackbar(errorMessage, 'error');
    }
  };

  const handleDelete = () => {
    openModal({
      title: 'Delete',
      content: strings.en.testLibrary.deleteQuestion.confirmationMessage,
      action: handleDeleteConfirm,
      actionLabel: 'Delete',
      actionColor: 'red',
    });
  };

  const loading = questionLoading || updateInProgress || createInProgress || deleteInProgress;
  const QuestionPage = QuestionPages[questionFormat]?.Component || DefaultQuestionPage;

  return (
    <>
      <QuestionPage
        key={questionId}
        id={questionId}
        answers={questionData?.answers}
        content={questionData?.content}
        difficulties={questionDifficulties}
        difficulty={questionData?.difficulty}
        layoutOrientation={questionData?.layoutOrientation}
        loading={loading}
        onCancel={goBack}
        onDelete={handleDelete}
        onSave={handleSave}
        onNext={hasNextQuestion ? goNextQuestion : undefined}
        onPrevious={hasPreviousQuestion ? goPreviousQuestion : undefined}
        question={questionData?.question}
        questionGroupId={questionGroupId}
        questionNumber={questionData?.questionNumber || questionNumber}
        showButton={isEditing}
        matchLeftItems={questionData?.matchLeftItems}
        matchRightItems={questionData?.matchRightItems}
        matchAnswers={questionData?.matchAnswers}
        orderItems={questionData?.orderItems}
        orderAnswers={questionData?.orderAnswers}
        mathItems={questionData?.mathItems}
        mathAnswer={questionData?.mathAnswer}
        mathNumAnswerItems={questionData?.mathNumAnswerItems}
      />
    </>
  );
};

TestLibraryQuestion.propTypes = {
  questionGroupId: PropTypes.string.isRequired,
  questionId: PropTypes.string,
  questionType: PropTypes.oneOf(Object.keys(QuestionPages)),
};

export default TestLibraryQuestion;
