import styled from 'styled-components';

export const SelectedBoxContainer = styled.div`
  position: absolute;
  bottom: 5px;
  left: 10px;
  z-index: 1;
`;

export const MediaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => (props.noMargin ? 0 : '13px 26px 13px 0')};
`;

export const MediaName = styled.span`
  font-size: 16px;
  color: #424242;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
`;

export const SquareContainer = styled.div`
  position: relative;
`;

export const Square = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${(props) => (props.preview ? 340 : 170)}px;
  width: ${(props) => (props.preview ? 510 : 170)}px;
  border: 1px solid #979797;
  background-color: #c9c9c9;
  cursor: pointer;
  opacity: ${(props) => (props.selected ? 0.5 : 1)};
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const IconContainer = styled.div`
  position: absolute;
  color: #ffffff;
  opacity: 0.8;
`;
