import React from 'react';

import { bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components/macro';
import addManuallyIcon from '../../assets/images/form.svg';
import importCSV from '../../assets/images/rectangle.svg';
import { Link } from '../UI';
import routes from '../../routes';
import StudentImportCSV from './ImportCSV';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../contexts/ui';

const StudentInviteDialog = ({ onClose, open }) => {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // const [errorMessage, setErrorMessage] = useState('');
  // const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleCSVFileError = (error) => {
    showSnackbar(error.message, 'error');
  };

  const handleCSVFileLoaded = (students) => {
    showSnackbar(`Students imported from CSV!`);
    navigate(routes.client.addStudentsManually, { state: { students } });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Invite Student
      </DialogTitle>
      <DialogContent dividers>
        <P>Select an option:</P>
        <OptionsContainer>
          <Option>
            <Link to={routes.client.addStudentsManually} ml="auto">
              <Icon src={addManuallyIcon} />
              <h4>Add Manually</h4>
              <p>Add students with a form</p>
            </Link>
          </Option>
          <Option>
            <StudentImportCSV
              onFileLoaded={handleCSVFileLoaded}
              onError={handleCSVFileError}
            >
              <RectangleWrapper>
                <Icon src={importCSV} />
                <TextContainer>CSV</TextContainer>
              </RectangleWrapper>
              <h4>Import CSV</h4>
              <p>Add students from a spreadsheet</p>
            </StudentImportCSV>
          </Option>
        </OptionsContainer>
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <H2>{children}</H2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const H2 = styled.h2`
  margin: 0;
`;

const P = styled.p`
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkgrey};

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-rows: unset;
    grid-template-columns: 50% 50%;
    padding: 0 185px;
  }
`;

const Icon = styled.img`
  width: 113px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 223px;
  }
`;
const OptionsContainer = styled.div`
  display: grid;

  a:hover {
    text-decoration: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-rows: unset;
    grid-template-columns: 50% 50%;
    padding: 0 185px;
  }
`;
const Option = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(228, 228, 228, 0.5);
  border: solid 5px #ededed;
  background-color: #ffffff;
  min-width: 370px;

  margin: 10px 5px 0;
  padding: 5px 0;
  ${({ theme }) => theme.mediaQueries.md} {
    margin: 50px 25px 50px;
    padding: 77px 0;
  }

  text-align: center;
  &:hover {
    border-color: ${({ theme }) => theme.colors.blue};
    cursor: pointer;
  }

  h4 {
    font-size: 24px;
    font-weight: normal;
  }

  p {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.grey};
  }

  .csv-input {
    display: none;
  }

  .csv-label {
    cursor: pointer;
  }
`;

const RectangleWrapper = styled.div`
  position: relative;
`;

const TextContainer = styled.span`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: ${({ theme }) => theme.colors.blue};
`;

StudentInviteDialog.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
};

export default StudentInviteDialog;
