import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  & > div {
    margin-right: 16px;
  }
  ${({ theme }) => theme.mediaQueries.md} {
    width: 500px;
    flex-direction: row;
  }
`;

export const Actions = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 32px;

  & > * {
    margin-right: 32px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 400px;
    flex-direction: row;
    text-align: right;
    margin-top: 0;
  }
`;
