import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex } from 'rebass';
import Layout from '../Layout';
import { Container, Link } from '../UI';
import { StudentEmptyList } from './EmptyList';
import routes from '../../routes';
import { AddButton } from '../UI/Button';
import strings from '../../constants/strings';
import { LoadingCaption } from '../UI/Loaders';
import StudentInviteDialog from './InviteDialog';
import StudentAddManuallyDialog from './AddManuallyDialog';
import StudentPaginatedList from './PaginatedList';
import { useStudents } from '../../contexts/api/student';
import { useSnackbar } from '../../contexts/ui';

const TEST_IDS = {
  inviteStudents: 'Students.inviteStudent',
};

const PAGE_SIZE = 21;

export const StudentLibrary = () => {
  const { showSnackbar } = useSnackbar();
  const { getStudents, error, loading, pendingInvitation, students } =
    useStudents();
  const navigate = useNavigate();
  const errorMessage = error?.message || '';
  useEffect(() => {
    if (errorMessage) {
      showSnackbar(errorMessage, 'error');
    }
  }, [errorMessage, showSnackbar]);

  const location = useLocation();

  const isCreateDialogOpen = location.pathname.endsWith('invite');
  const isAddManually = location.pathname.endsWith('add-manually');

  console.log(isAddManually, location.state)
  const studentsToAdd = (location.state && location.state.students) || [];

  const handleCloseCreateDialog = () => {
    const baseUrl = location.pathname.replace('invite', '');
    navigate(baseUrl);
  };

  const handleCloseAddStudentDialog = () => {
    const baseUrl = location.pathname.replace('invite/add-manually', '');
    navigate(baseUrl);
  };

  const handleUpdateStudentList = () => {
    getStudents();
  };

  const hasStudents =
    !loading && (students.length > 0 || pendingInvitation.length > 0);

  return (
    <Layout>
      <Container>
        <Flex justifyContent="flex-end" mb={6}>
          <Link
            to={routes.client.inviteStudents}
            ml="auto"
            data-testid={TEST_IDS.inviteStudents}
          >
            <AddButton size="s">
              {strings.en.students.inviteStudents.title}
            </AddButton>
          </Link>
        </Flex>
        <StudentInviteDialog
          onClose={handleCloseCreateDialog}
          open={isCreateDialogOpen}
        />
        <StudentAddManuallyDialog
          initialStudents={studentsToAdd}
          onClose={handleCloseAddStudentDialog}
          onInviteStudents={handleUpdateStudentList}
          open={isAddManually}
        />
        {loading ? (
          <LoadingCaption />
        ) : (
          <>
            <StudentPaginatedList students={students} pageSize={PAGE_SIZE} />
            <StudentPaginatedList
              title="Pending Invitation"
              students={pendingInvitation}
              pageSize={PAGE_SIZE}
            />
          </>
        )}
        {!hasStudents && <StudentEmptyList />}
      </Container>
    </Layout>
  );
};

export default StudentLibrary;
