import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from '../UI';
import StudentCard from './Card';
import routes from '../../routes';

const StudentGrid = ({ students }) => (
  <Container>
    {students.map((s) => (
      <StyledLink to={routes.client.editStudents(s.id)} key={s.id}>
        <StudentCard {...s} />
      </StyledLink>
    ))}
  </Container>
);

StudentGrid.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
};

const Container = styled.div`
  margin-top: 24px !important;
  display: flex;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  margin: 12px;
  :hover {
    text-decoration: none;
  }
`;

export default StudentGrid;
