import React, { useCallback, useReducer } from 'react';
import {
  questionOrders,
  restartModes,
} from '../../../components/Package/Create/Content/Build/constants';

const getInitialState = () => ({
  properties: {
    title: '',
    header: '',
    numQuestions: '',
    restartMode: restartModes.ALWAYS,
    questionOrder: questionOrders.RANDOM,
    showResultsAnswers: false,
    showInstantFeedback: false,
    testDuration: null,
    image: null,
    backgroundImage: null,
  },
  questionGroups: [],
  id: null,
  pageNumber: null,
});

const reducer = (state, updates) => {
  if (typeof updates === 'object') {
    return {
      ...state,
      ...updates,
    };
  }

  return state;
};
// to do resrach final form docs for how to hoist state higher up so we don't need a reducer, this would solve problems wiht the form! !!!
const PackageTestPageStoreContext = React.createContext();

function PackageTestPageStoreProvider({ children }) {
  const [state, setState] = useReducer(reducer, getInitialState());

  const resetState = useCallback(() => {
    setState(getInitialState());
  }, []);

  const value = {
    update: setState,
    reset: resetState,
    state,
  };

  return (
    <PackageTestPageStoreContext.Provider value={value}>
      {children}
    </PackageTestPageStoreContext.Provider>
  );
}

export { PackageTestPageStoreContext, PackageTestPageStoreProvider };
