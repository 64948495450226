import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const ReorderQuestionGroupsInPage = React.createContext();

function ReorderQuestionGroupsInPageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // payload => uuid[] the order of questiongroups
  const reorderQuestionGroupsInPage = useCallback(
    async (id, questionGroupNumbers) => {
      try {
        setLoading(true);
        await axiosInstance.put(`/pages/${id}/question-group-order`, {
          questionGroupNumbers,
        });

        return {
          success: true,
          error: null,
        };
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setErrorMessage(errorMessage);
        return { success: false, errorMessage };
      } finally {
        setLoading(false);
        setHasLoaded(true);
      }
    },
    [],
  );

  const value = {
    loading,
    reorderQuestionGroupsInPage,
    errorMessage,
    hasLoaded,
  };

  return (
    <ReorderQuestionGroupsInPage.Provider value={value}>
      {children}
    </ReorderQuestionGroupsInPage.Provider>
  );
}

/**
 * @typedef {
 *  string
 * } QuestionGroupNumber the id of a question group
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  reorderQuestionGroupsInPage: (id: string, questionGroupNumbers: questionGroupNumber[] ) => Promise<{ success: boolean; errorMessage: string; }>
 *  errorMessage: string
 * }}
 */

function useReorderQuestionGroupsInPage() {
  const context = React.useContext(ReorderQuestionGroupsInPage);

  if (context === undefined) {
    throw new Error(
      'useReorderQuestionGroupsInPage must be used within a ReorderQuestionGroupsInPage',
    );
  }

  return context;
}

export { ReorderQuestionGroupsInPageProvider, useReorderQuestionGroupsInPage };
