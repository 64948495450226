import React, { useCallback, useReducer } from 'react';

const getInitialState = () => ({
  properties: {
    title: '',
    subject: '',
    description: '',
    templateBColor: '#ffffff',
    templateFColor: '#000000',
    packageCardImage: '',
    packageImages: [],
  },
  build: {},
  students: [],
  id: null,
});

const reducer = (state, updates) => {
  if (typeof updates === 'object') {
    return {
      ...state,
      ...updates,
    };
  }

  return state;
};

//

// TO DO: research final form docs for how to hoist state higher up so we don't need a reducer, this would solve problems with the form! !!!
const PackageStoreContext = React.createContext();

function PackageStoreProvider({ children }) {
  const [state, setState] = useReducer(reducer, getInitialState());

  const resetState = useCallback(() => {
    setState(getInitialState());
  }, []);

  const value = {
    updatePackage: setState,
    resetPackage: resetState,
    state,
    setState,
  };

  return (
    <PackageStoreContext.Provider value={value}>
      {children}
    </PackageStoreContext.Provider>
  );
}

function usePackageStore() {
  const context = React.useContext(PackageStoreContext);

  if (context === undefined) {
    throw new Error(
      'usePackageStore must be used within a PackageStoreProvider',
    );
  }

  return context;
}

export { usePackageStore, PackageStoreProvider, PackageStoreContext };
