import React from 'react';
import { types } from "../../../MediaLibrary/Media"

/**
 *  Return a diffrent type of element based on asset type
 * @param {{asset}} asset 
 * @returns React.Element
 */
const MediaElement = ({ asset }) => {
  switch (asset.type) {
    case types.IMAGE:
      return <img src={asset.src} alt={asset.name}></img>
    case types.AUDIO:
      return (
        <audio controls>
          <source src={asset.src} type={asset.mimetype} />
          Your browser does not support the audio element.
        </audio>
      )
    case types.VIDEO:
      return (
        <video controls>
          <source src={asset.src} type={asset.mimetype} />
          Your browser does not support the video tag.
        </video>
      )
    default:
      return <small>No valid media type detected on asset</small>
  }
}
export default MediaElement