import { PageFormats } from '../../../../../contexts/api/package/common';

export const sidebarDroppableId = 'drag-items-pages-list';
export const droppableId = 'dragged-items-pages-list';
export const testPageSidebarDroppableId = 'drag-items-question-groups-list';
export const testPageDroppableId = 'drag-items-question-groups-droppable';

export const pageTypes = [
  {
    id: 'Test',
    title: 'Test',
    type: PageFormats.QUESTIONS,
  },
  {
    id: 'PDF',
    title: 'PDF',
    type: PageFormats.PDF,
  },
];

export const restartModes = {
  ALWAYS: 'always',
  NEVER: 'never',
  PROMPT: 'prompt',
};

export const questionOrders = {
  RANDOM: 'random',
  RANDOM_PROP_DIST: 'random-prop-dist',
  RANDOM_EVEN_DIST: 'random-even-dist',
  IN_ORDER: 'inOrder',
  ADAPTIVE: 'adaptive',
};
