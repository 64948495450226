import React from 'react';
import styled from 'styled-components/macro';
import { Box, Text } from 'rebass';
import InputField from './InputField';
import { FaCheckCircle } from 'react-icons/fa';
import { Circle } from './Circle';

const CB = styled.input`
  appearance: none;
  position: absolute;
`;

const StyledInput = styled(InputField)`
  transition: all 0.15s ease-out;
`;

const PointerCloak = styled(Box)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  pointer-events: none;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  right: 0.5em;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5em;
  transform: translateY(0%) translateX(15%);
`;

export const AnswerFieldInput = ({
  checkboxProps,
  isCorrect,
  labelId, // used to connect to the checkbox
  label,
  inputConfig,
  ...rest
}) => (
  <Box style={{ position: 'relative', display: 'inline-block' }} {...rest}>
    <PointerCloak />
    <StyledInput
      inputConfig={{ ...inputConfig, bg: isCorrect ? 'green' : 'white', pr: 7 }}
      color={isCorrect ? 'white' : 'darkgrey'}
      label={label}
    />
    {isCorrect ? (
      <StyledLabel htmlFor={labelId}>
        <FaCheckCircle />
      </StyledLabel>
    ) : (
      <StyledLabel htmlFor={labelId} bg="grey">
        <Circle border="1px solid" borderColor="lightgrey" width={22} />
      </StyledLabel>
    )}
    {isCorrect && (
      <Text
        as="span"
        color="green"
        style={{ position: 'absolute', top: '1.5em', right: '0.5em' }}
      >
        Correct
      </Text>
    )}
  </Box>
);

export const AnswerFieldCheckbox = ({ ...props }) => (
  <CB {...props} type="checkbox" />
);
