import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import ToolsSVG from '../../../../assets/images/tools.svg';
import SettingsSVG from '../../../../assets/images/settings.svg';
import UserGroupSVG from '../../../../assets/images/user-group.svg';
import Layout from '../../../../components/Layout';
import { Container, ContentContainer } from './styled';
import Navbar from '../Navbar';
import ContentBuild from '../Content/Build';
import ContentProperties from '../Content/Properties';
import ContentStudents from '../Content/Students';
import { usePackageStore } from '../../../../contexts/reducers/package';
import {
  preparePackage,
  preparePackageForFrontEnd,
  useCreatePackage,
  usePackages,
  usePublishPackage,
  useUpdatePackage,
} from '../../../../contexts/api/package';
import NotFound from './NotFound';
import { usePages } from '../../../../contexts/api/pages';
import { PackageTestPageStoreContext } from '../../../../contexts/reducers/package/TestPage';
import { useSnackbar } from '../../../../contexts/ui';

const tabs = [
  {
    id: 1,
    IconComponent: () => <img alt="Properties" src={SettingsSVG} />,
    text: 'Properties',
    ContentComponent: ContentProperties,
  },
  {
    id: 2,
    IconComponent: () => <img alt="Build" src={ToolsSVG} />,
    text: 'Build',
    ContentComponent: ContentBuild,
  },
  {
    id: 3,
    IconComponent: () => <img alt="Students" src={UserGroupSVG} />,
    text: 'Students',
    ContentComponent: ContentStudents,
  },
];

const PackageCreateFromScratch = (props) => {
  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);
  const [loadPackageError, setLoadPackageError] = useState(false);
  const { ContentComponent } = tabs.find((tab) => tab.id === selectedTabId);
  const {
    state,
    setState: setPackageState,
    updatePackage: updatePackageState,
    resetPackage,
  } = usePackageStore();
  const { reset: resetTestPageState } = useContext(PackageTestPageStoreContext);
  const { createPackage } = useCreatePackage();
  const { updatePackage } = useUpdatePackage();
  const { publishPackage, unpublishPackage } = usePublishPackage();
  const { getPackages, packages } = usePackages();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const savePackage = state.id
    ? (payload) => updatePackage(state.id, payload)
    : (payload) => createPackage(payload);

  const changePublishState =
    state.properties.packageStatus === 'published'
      ? () => unpublishPackage(state.id)
      : () => publishPackage(state.id);
  let {id} = useParams();
  // resets package if there is an id mismatch from url to state or if on the create page
  // the caveat of this method is that when a package is created it MUST redirect to the edit page for the remaining state
  // to load correctly.
  useEffect(() => {
    if (
      (state.id !== null && id && state.id !== id) ||
      (!id && props.path === '/packages/create/from-scratch')
    ) {
      resetPackage();
      resetTestPageState();
    }
  }, [id, props.path, resetPackage, resetTestPageState, state.id]);

  useEffect(() => {
    if (id && id !== state.id && packages.length > 0) {
      const loadedPackage = packages.find((p) => p.id === id);

      if (!loadedPackage) {
        setLoadPackageError(true);
      } else {
        const preparedPackage = preparePackageForFrontEnd(loadedPackage);
        updatePackageState(preparedPackage);
        setLoadPackageError(false);
      }
    }
  }, [packages, id, state.id, updatePackageState]);

  // pre-emptively call use pages to preload pages prior to hitting the build tab
  usePages(state.id);

  const handleSave = async () => {
    if (!state.properties.title) {
      showSnackbar('Title is required.', 'error');
      return;
    }

    const payload = preparePackage(state);

    const result = await savePackage(payload);
    // if creating for first time
    await getPackages();

    if (result.success) {
      showSnackbar(state.id ? 'Package updated' : 'Package saved', 'success');
    } else {
      showSnackbar('Unable to save Package :(', 'error');
    }

    setTimeout(() => {
      if (!state.id) {
        navigate(`/packages/${result.package.id}/edit`);
      }
    }, 250);
  };

  const handleChangePublishState = async () => {
    const result = await changePublishState();
    const currentPublishState = state.properties.packageStatus;

    const isPublished = currentPublishState === 'published';

    if (result.success) {
      if (isPublished) {
        showSnackbar('Package is not available to students', 'success');
      } else {
        showSnackbar('Package is available to students', 'success');
      }
    } else {
      showSnackbar('Unable to change Package publish state :(', 'error');
    }

    setPackageState({
      properties: {
        ...state.properties,
        packageStatus: isPublished ? 'draft' : 'published',
      },
    });
  };

  return (
    <Layout noBodyPadding>
      <Container>
        <Navbar
          packageId={state.id}
          tabs={tabs}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          onSave={handleSave}
          onChangePublish={handleChangePublishState}
          isPublished={state.properties.packageStatus === 'published'}
        />
        <ContentContainer>
          <ContentComponent />
        </ContentContainer>
      </Container>
      {loadPackageError &&
        ReactDOM.createPortal(<NotFound />, document.getElementById('main'))}
    </Layout>
  );
};

export default PackageCreateFromScratch;
