import styled from 'styled-components/macro';

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.15s;
  border-radius: 3px;
  z-index: 2000;

  width: ${(props) => {
    switch (props.modalSize) {
      case 'lg':
        return '800';
      default:
        return '439';
    }
  }}px;

  margin: 40px auto;
  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }
  .background {
    background: #424242;
    opacity: 0.85;
    position: fixed;
    z-index: 1040;
    display: block;
    margin-top: 80px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .x-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 36px;
    cursor: pointer;
  }
  .box-dialog {
    z-index: 1050;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    .box-content {
      padding: 24px 16px;
      font-weight: normal;
    }
    .box-header {
      height: 48px;
      padding: 8px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #c7c7c7;
      .box-title {
        font-size: 18px;
        font-weight: normal;
        color: ${({ theme }) => theme.colors.darkgrey};
      }
    }
    .box-body {
      font-size: 14px;
      padding: 0px;
      width: auto;
      height: auto;
    }
    .box-footer {
      height: 48px;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      & > * {
        margin-left: 10px;
      }
    }
  }
`;

export default StyledModal;
