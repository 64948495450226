/**
 * @typedef { type: 'questions' | 'pdf' } PageType
 * @typedef {{
 *  title: string;
 *  subtitle?: string;
 *  numQuestions: number;
 *  restartMode: 'always' | 'in-order' | 'prompt';
 *  questionOrder: 'random' | 'order' | 'adaptive' | 'random-even-dist' | 'random-prop-dist';
 *  showInstantFeedback: Boolean;
 *  image: string;
 *  backgroundImage: string;
 *  showResultsAnswers: Boolean;
 *  duration?: number;
 * }} FrontEndTestPageProperties
 * @typedef {{
 *  id: string?;
 *  properties: FrontEndTestPageProperties;
 *  questionGroups: string[]
 *  pageNumber: string?
 * }} FrontEndTestPage
 * @typedef {{
 *  backgroundColor: string?
 *  backgroundImage: string?
 *  contains: string?
 *  createdAt: string?
 *  description: string?
 *  showInstantFeedback: boolean
 *  showResultsAnswers: boolean
 *  header: string?
 *  html: string?
 *  id: string?
 *  image: string?
 *  numQuestions: number
 *  packageId: string?
 *  pageNumber: number
 *  questionOrder: 'random' | 'order' | 'adaptive' | 'random-even-dist' | 'random-prop-dist'
 *  restartMode: 'always' | 'in-order' | 'prompt'
 *  subject: string?
 *  tallyMode: string?
 *  testDuration: number
 *  title: string
 *  type: PageType
 *  updatedAt: string
 * }} BackendQuestionPage
 * @typedef {{
 *    title: string
 *    pageNumber: string
 *    id: string?
 *    packageId: string
 *    isNew: boolean
 *    type: PageType
 * }} FrontEndPageItem
 * @typedef { type: 'questions' | 'pdf' } PageType
 */

import {
  assetContentDeTransformer,
  assetContentTransformer,
  imageContentDeTransformer,
  imageContentTransformer,
} from '../../../utils/mediaLibrary';

/**
 * @param {FrontEndTestPage} page
 * @param {PageType} type
 * @param {String} packageId
 */
export const prepareTestPageForBackend = (
  { id, properties, pageNumber, questionGroups },
  type,
  packageId,
) => {
  return {
    id,
    packageId,
    ...properties,
    image: properties.image ? imageContentTransformer(properties.image) : '',
    backgroundImage: properties.backgroundImage
      ? imageContentTransformer(properties.backgroundImage)
      : '',
    questionGroups,
    pageNumber,
    type,
  };
};

/**
 *
 * @param {BackendQuestionPage} page
 * @returns {FrontEndTestPage}
 */
export const prepareBackendQuestionPageForFrontend = (page) => ({
  id: page.id,
  type: page.type,
  pageNumber: page.pageNumber,
  properties: {
    title: page.title,
    header: page.header,
    numQuestions: page.numQuestions,
    restartMode: page.restartMode,
    questionOrder: page.questionOrder,
    showResultsAnswers: page.showResultsAnswers,
    showInstantFeedback: page.showInstantFeedback,
    testDuration: page.testDuration,
    image: imageContentDeTransformer(page.image || ''),
    backgroundImage: imageContentDeTransformer(page.backgroundImage || ''),
  },
});
/**
 * readies a backend question page to be used as a draggable item
 * @param {BackendQuestionPage} page
 * @returns {FrontEndPageItem}
 */
export const prepareBackendQuestionPageForFrontendPageItem = (page) => ({
  id: page.id,
  title: page.title,
  pageNumber: page.pageNumber,
  packageId: page.packageId,
  isNew: false,
  type: page.type,
});

/**
 * readies Front-end data to be stored in backend
 * @param {Object} PageProperties
 * @param {PageType} type
 * @param {PackageId} packageId
 * @returns {Object} payload
 */
export const preparePdfPageForBackend = (
  { id, properties, pageNumber, asset },
  type,
  packageId,
) => {
  return {
    id,
    packageId,
    ...properties,
    pdf: asset ? assetContentTransformer(asset) : '',
    pageNumber,
    type,
  };
};

export const prepareBackendPdfPageForFrontend = (page) => ({
  id: page.id,
  type: page.type,
  pageNumber: page.pageNumber,
  properties: {
    title: page.title,
    notes: page.notes,
    pdf: assetContentDeTransformer(page.pdf),
  },
});
