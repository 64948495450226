import * as actionTypes from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  package: {},
  pages: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET:
      return initialState;
    case actionTypes.SET_PACKAGE_LIBRARY_EDITOR:
      const { package: _package, pages } = action.payload;
      return {
        ...state,
        error: null,
        loading: false,
        package: { ..._package },
        pages: pages.map(q => ({ ...q })),
        pageMetadata: {},
      };
    case actionTypes.GET_PAGES_START:
      return { ...state, error: null, loading: true };
    case actionTypes.GET_PAGES_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.GET_PAGES_SUCCESS:
      const { pages: _pages, ...rest } = action.payload.pages;
      return {
        ...state,
        error: null,
        loading: false,
        pages: _pages.map(p => ({ ...p })),
        pageMetadata: rest,
      };
    case actionTypes.ADD_QUESTION_GROUP_TO_PAGE_SUCCESS:
      return state;
    case actionTypes.ADD_PAGE_TO_PACKAGE_START:
      return { ...state, error: null, loading: true };
    case actionTypes.ADD_PAGE_TO_PACKAGE_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.ADD_PAGE_TO_PACKAGE_SUCCESS:
      return { ...state, error: null, loading: false };
    case actionTypes.DELETE_PAGE_FROM_PACKAGE_START:
      return { ...state, error: null, loading: true };
    case actionTypes.DELETE_PAGE_FROM_PACKAGE_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.DELETE_PAGE_FROM_PACKAGE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        pages: state.pages.filter(p => p.id !== action.payload.pageId),
      };
    case actionTypes.ADD_PACKAGE_START:
      return { ...state, error: null, loading: true };
    case actionTypes.ADD_PACKAGE_FAIL:
      return { ...state, error: action.payload.message, loading: false };
    case actionTypes.ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
