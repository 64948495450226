import styled from 'styled-components/macro';

export const Container = styled.div`
  overflow: auto;
  width: 30%;
  max-width: 442px;
  background-color: #ffffff;
  border: solid 1px #b6b6b6;
  border-top: none;
  padding: 0 50px 50px;
`;

export const Title = styled.h1`
  width: 100%;
  margin: 40px 0;
  font-size: 24px;
  color: #2cabe0;
  text-align: center;
`;

export const DraggableContainer = styled.div``;

export const QuestionGroupsContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  display: flex;
`;

export const DragIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #2cabe0;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`;

export const DragContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  & > img {
    margin-bottom: 5px;
  }
`;

export const DragContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  min-height: 110px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #d7d7d7;
  background-color: #ffffff;
  font-size: 24px;
  color: #818181;
  width: 150px;
`;
