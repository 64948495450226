import React from 'react';
import { CheckBox, Headset } from '@material-ui/icons';
import {
  IconContainer,
  MediaContainer,
  MediaName,
  SelectedBoxContainer,
  Square,
  SquareContainer,
  Image,
} from './styled';
import playSVG from '../../../assets/images/play.svg';

export const types = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'pdf',
  LOTTIE: 'json',
};

const Media = ({ media, selected, onClick, preview }) => {
  const renderThumbnail = () =>
    media.thumbnail ? <Image src={media.thumbnail} alt={media.type} /> : null;
  const renderChildren = () => {
    switch (media.type) {
      case types.IMAGE: {
        return (
          <>
            <Image src={media.src} alt={media.type} />
          </>
        );
      }
      case types.VIDEO: {
        return (
          <>
            <IconContainer>
              <Image src={playSVG} alt={media.type} />
            </IconContainer>
            {renderThumbnail()}
          </>
        );
      }
      case types.AUDIO: {
        return (
          <>
            <IconContainer>
              <Headset style={{ width: 45, height: 45 }} />
            </IconContainer>
            {renderThumbnail()}
          </>
        );
      }
      default:
        return null;
    }
  };
  return (
    <MediaContainer noMargin={preview}>
      <SquareContainer onClick={onClick}>
        {selected ? (
          <SelectedBoxContainer>
            <CheckBox style={{ width: 30, height: 30 }} />
          </SelectedBoxContainer>
        ) : null}
        <Square preview={preview} selected={selected}>
          {renderChildren()}
        </Square>
      </SquareContainer>
      {preview ? null : (
        <MediaName title={media.title}>{media.title}</MediaName>
      )}
    </MediaContainer>
  );
};

export default Media;
