import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const UpdatePackageContext = React.createContext();

function UpdatePackageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updatePackage = useCallback(async (id, payload) => {
    try {
      setLoading(true);
      // TO DO handle 4xx error codes
      await axiosInstance.put(`/packages/${id}`, payload);

      return {
        package: payload,
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    updatePackage,
    hasLoaded,
    errorMessage,
  };

  return (
    <UpdatePackageContext.Provider value={value}>
      {children}
    </UpdatePackageContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  name: string;
 * }} Package
 */

/**
 * @returns {{
 *  loading: boolean,
 *  updatePackage: (package: Package) => Promise<{ success: boolean; errorMessage: string; }>
 *  hasLoaded: boolean,
 *  errorMessage: string
 * }}
 */

function useUpdatePackage() {
  const context = React.useContext(UpdatePackageContext);

  if (context === undefined) {
    throw new Error(
      'useUpdatePackage must be used within a UpdatePackageProvider',
    );
  }

  return context;
}

export { UpdatePackageProvider, useUpdatePackage };
