import styled from 'styled-components/macro';

export const ColorLabelContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => (props.clickable ? 'cursor: pointer;' : '')}
`;

export const ColorPreview = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.hex};
  border: 1px solid;
`;

export const Label = styled.span`
  margin: 10px;
`;
