import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 345px;
  max-width: 345px;
  height: 100px;
  padding: 13px 10px 10px 13px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  background-color: #ffffff;
  border: solid 1px transparent;

  &:hover {
    border: solid 1px ${({ theme }) => theme.colors.blue};
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: #424242;
  text-transform: capitalize;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: #818181;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
  height: 60px;
  max-width: 245px;
`;

export const IconContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #818181;
  cursor: pointer;
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  top: 0;
  ${(props) => props.left ? 'left: 0;' : 'right: 0;'}
  height: 20px;
  background-color: #424242;
  font-size: 11px;
  color: #ffffff;
`;
