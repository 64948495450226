import styled from 'styled-components/macro';

export const TabsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Tab = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2cabe0;
  cursor: pointer;
  margin-right: 50px;
`;

export const TabText = styled.div`
  font-size: 24px;
  font-weight: 200;
  color: ${(props) => (props.selected ? '#2cabe0' : props.color || '#2cabe0')};
`;

export const Line = styled.div`
  position: absolute;
  bottom: -5px;
  height: 1px;
  border: solid 1px #2cabe0;
  margin-top: 5px;
  width: 100%;
  background: #2cabe0;
`;
