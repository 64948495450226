/**
 * Generates a uuid v4
 *
 * Source: https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
 */
export function uuid() {
  return 'xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxxx-xxxx'.replace(/x/g, function(c) {
    const r = (Math.random() * 16) | 0;
    return r.toString(16);
  });
}
