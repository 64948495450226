import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { ExternalLayout } from '../../components/Layout';
import { LinkButton } from '../../components/UI/Link';
import { signOut } from '../../utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthError } from './selectors';
import { loginThunk, resetPasswordCheckThunk } from './actions';
import { ResetPasswordForm } from '../../components/Authentication/ResetPasswordForm';
import routes from '../../routes';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const ResetPassword = (props) => {
  const error = useSelector(selectAuthError);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { code, email } = Object.fromEntries([...searchParams]);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(resetPasswordCheckThunk({ code, email })).then((errorMessage) => {
      if (errorMessage) {
        navigate(routes.client.home, {
          state: { error: errorMessage },
        });
      }
    });
  }, [code, dispatch, email, navigate]);

  return (
    <ExternalLayout>
      <Container>
        <h2>Reset Password</h2>
        <H3>Please create a new password and confirm it!</H3>
        <ResetPasswordForm
          error={error}
          resetPassword={(data) =>
            dispatch(
              loginThunk({ code, email, ...data }, routes.api.resetPassword),
            ).then((status) => {
              navigate(routes.client.home, {
                state: {
                  error: getErrorMessage(status),
                  fromResetPassword: true,
                },
              });
            })
          }
        />

        <MessageContainer>
          <LinkButton onClick={signOut}>Back to log in</LinkButton>
        </MessageContainer>
      </Container>
    </ExternalLayout>
  );
};

const Container = styled.div`
  margin: 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > :first-child {
    padding: 0 0 48px;
  }
`;

const H3 = styled.h3`
  margin: 0 0 40px;
  max-width: 400px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.44px;
  text-align: center;
`;

const MessageContainer = styled.div`
  margin: 20px 3px 91px 17px;
  opacity: 0.87;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
`;

function getErrorMessage(status) {
  switch (status) {
    case 'expired':
      return 'Password reset code has expired.';
    case 'invalid':
      return 'Password reset code is invalid.';
    case 'success':
    default:
      // there is no error here.
      return null;
  }
}
