import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Header from '../Header';
import { MAIN_NAV_LINKS } from '../../constants/navigation';
import { Box } from 'rebass';
import {
  selectUser,
  selectAuthenticated,
} from '../../containers/Authentication/selectors';
import ProfileMenu from '../ProfileMenu';

export const Layout = ({ children, noBodyPadding, bgColor }) => {
  const authenticated = useSelector(selectAuthenticated);
  const user = useSelector(selectUser);
  return (
    <Container>
      {authenticated && (
        <Header
          links={MAIN_NAV_LINKS}
          render={() => (
            <Box ml="auto">
              <ProfileMenu
                firstName={user.firstName}
                lastName={user.lastName}
                email={user.email}
                outlined
                border="none"
              />
            </Box>
          )}
        />
      )}
      <Body id="main" noPadding={noBodyPadding} bgColor={bgColor}>
        {children}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Body = styled.div`
  box-sizing: border-box;
  margin: 0;
  justify-content: center;
  display: flex;
  width: 100%;
  ${(props) => props.bgColor ? `background-color:${props.bgColor};` : ''}

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-top: 88px;
  }

  ${(props) =>
    props.noPadding
      ? ''
      : `
    padding: 30px 0;
    ${props.theme.mediaQueries.md} {
      padding: 50px;
    }
  `}
`;
