import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const UpdatePageContext = React.createContext();

function UpdatePageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updatePage = useCallback(async (pageId, payload) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(`/pages/${pageId}`, payload);

      return {
        page: getPageFromResponse(response),
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    updatePage,
    hasLoaded,
    errorMessage,
  };

  return (
    <UpdatePageContext.Provider value={value}>
      {children}
    </UpdatePageContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  numQuestions: number;
 *  type: string
 *  title: string
 *  image: string
 *  header: string
 *  showInstantFeedback: boolean;
 *  showResultsAnswers: boolean;
 *  questionOrder: string;
 *  backgroundColor: string
 *  backgroundImage: string
 *  restartMode: string
 *  testDuration: string
 *  tallyMode: string
 * }} Page
 */

/**
 * @returns {{
 *  loading: boolean,
 *  updatePage: (page: Page) => Promise<{ page: Page; success: boolean; errorMessage: string; }>
 *  hasLoaded: boolean,
 *  errorMessage: string
 * }}
 */

function useUpdatePage() {
  const context = React.useContext(UpdatePageContext);

  if (context === undefined) {
    throw new Error('useUpdatePage must be used within a UpdatePageProvider');
  }

  return context;
}

function getPageFromResponse(response) {
  if (!response.data) {
    return null;
  }

  const pkg = response.data;
  return {
    id: pkg.id,
    name: pkg.name,
  };
}

export { UpdatePageProvider, useUpdatePage };
