import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Flex } from 'rebass';
import Input, { Textarea } from './Input';
import { PasswordInput } from './PasswordInput';

export const Label = styled.label`
  text-transform: capitalize;
  letter-spacing: 0.8px;
`;

// input field is a wrapper that contains a label and input + render props for dynamic helper messages
export const InputField = (props) => {
  const {
    label,
    key,
    children,
    labelConfig,
    inputConfig,
    type,
    name,
    value,
    placeholder,
    ...rest
  } = props;

  const { id } = rest;

  const labelProps = {
    ...labelConfig,
    htmlFor: id || '',
  };

  const renderByType = () => {
    switch (type) {
      case 'password':
        return (
          <PasswordInput
            {...inputConfig}
            placeholder={placeholder}
            name={name}
            value={value}
          />
        );
      case 'textarea':
        return (
          <Textarea {...inputConfig} placeholder={placeholder} name={name} value={value} />
        );
      default:
        return (
          <Input
            {...rest}
            {...inputConfig}
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
          />
        );
    }
  };

  return (
    <StyledFlex my={3} key={key} flexDirection="column" {...rest}>
      <Label as="label" mb={3} {...labelProps}>
        {label}
      </Label>

      {renderByType()}
    </StyledFlex>
  );
};

const StyledFlex = styled(Flex)``;

InputField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  labelConfig: PropTypes.object,
  inputConfig: PropTypes.object,
  children: PropTypes.node,
};

InputField.defaultProps = {
  key: null,
};

export default InputField;
