import Layout from '../../../Layout';
import React, { useEffect, useState } from 'react';
import { CancelButton, SaveButton, Button } from '../../../UI/Button';
import { Link } from '../../../UI';
import { Flex, Box } from 'rebass';
import PreviewShortHorizontalSVG from '../../../../assets/images/student-box-short-horizontal.svg';
import PreviewShortVerticalSVG from '../../../../assets/images/student-box-short-vertical.svg';
// import PreviewLongVerticalSVG from '../../../../assets/images/student-box-long-vertical.svg';
import PropTypes from 'prop-types';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  Label,
  TextAreaShortHorizontal,
  TextAreaShortVertical,
  ExtraOptions,
  Radio,
  LabelLeft,
  Select,
  PreviewStudent,
  PreviewStudentFloated,
} from './styled';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import { FaTrash } from 'react-icons/fa';
import { InputGroup, QuestionField } from '../MultipleChoice/styled';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf';

export const ANSWER_TYPE = {
  short: 'Short',
  long: 'Long',
};

const TestLibraryQuestionsTextAnswer = ({
  answerType,
  content: initialContent = '',
  difficulties = [],
  difficulty: initialDifficulty = '',
  layoutOrientation: initialLayoutOrientation = 'horiz',
  loading = false,
  onCancel = () => {},
  onDelete = () => {},
  onSave = () => {},
  questionNumber,
  question: initialQuestion = '',
  showButton = false,
  onNext,
  onPrevious,
}) => {
  if (answerType == null) {
    answerType = ANSWER_TYPE.short;
  }

  const [question, setQuestion] = useState(initialQuestion);
  useEffect(() => {
    setQuestion(initialQuestion);
  }, [initialQuestion]);

  const [content, setContent] = useState(initialContent);
  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const [difficulty, setQuestionDifficulty] = useState(initialDifficulty);
  useEffect(() => {
    setQuestionDifficulty(initialDifficulty);
  }, [initialDifficulty]);

  const [layoutOrientation, seetLayoutOrientation] = useState(
    initialLayoutOrientation,
  );
  useEffect(() => {
    seetLayoutOrientation(initialLayoutOrientation);
  }, [initialLayoutOrientation]);

  const handleChangeLayoutOrientation = (event) => {
    seetLayoutOrientation(event.target.value);
  };

  const handleChangeDifficulty = (e) => {
    setQuestionDifficulty(e.target.value);
  };

  const handleSave = () => {
    onSave({
      question,
      content,
      questionNumber,
      difficulty,
      layoutOrientation,
    });
  };

  return (
    <Layout noBodyPadding>
      <Container>
        <TopContainer>
          <Title> {answerType} Answer Question</Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end" alignItems="center">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>

            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>
        <Flex mb={3}>
          <ContentContainer answerType={answerType}>
            <InputGroup>
              <Label>Question</Label>
              <QuestionField
                rows={1}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <div className="links flexStart">
                <Link
                  anchor
                  href={ClassedUpMarkupGuide}
                  name="markup-guide"
                  target="_blank"
                  rel="noreferrer"
                >
                  Markup guide
                </Link>
              </div>
            </InputGroup>

            {layoutOrientation === 'vert' && answerType === ANSWER_TYPE.short && (
              <div>
                <Label>Content</Label>
                <TextAreaShortHorizontal
                  placeholder="Insert text here (including markups) or click below to add media as a content."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />

                <div className="links">
                  <Link to="/">Markup guide</Link> &nbsp; | &nbsp;
                  <Link to="/">Add Media File</Link>
                </div>

                <PreviewStudent src={PreviewShortHorizontalSVG} />
              </div>
            )}

            {/* Ted - The design Andrew has provided, did not include the following components. 
                So I made sure that only short answers would render the below conditional
            */}
            {layoutOrientation === 'horiz' && answerType === ANSWER_TYPE.short && (
              <Flex>
                <Box width={1 / 2}>
                  <LabelLeft>Content</LabelLeft>
                  <TextAreaShortVertical
                    placeholder="Insert text here (including markups) or click below to add media as a content."
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />

                  <div className="links">
                    <Link to="/">Markup guide</Link> &nbsp; | &nbsp;
                    <Link to="/">Add Media File</Link>
                  </div>
                </Box>
                <Box width={1 / 2}>
                  {answerType === ANSWER_TYPE.short && (
                    <PreviewStudentFloated src={PreviewShortVerticalSVG} />
                  )}
                  {/* Ted - The design Andrew has provided, did not include this component */}
                  {/* {answerType === ANSWER_TYPE.long && (
                    <PreviewStudentFloated src={PreviewLongVerticalSVG} />
                  )} */}
                </Box>
              </Flex>
            )}
          </ContentContainer>
          {/* Ted - As per Andrews request, only short answers will get the additional options on the right hand side.
              Long Answers a.k.a Essay questions will not have options
          */}
          {answerType === ANSWER_TYPE.short && (
            <ExtraOptions>
              <LabelLeft>Layout Display</LabelLeft>
              <Radio
                type="radio"
                name="questionDifficulty-vertical"
                value="vert"
                onChange={handleChangeLayoutOrientation}
                checked={layoutOrientation === 'vert'}
              />{' '}
              Vertical{' '}
              <Radio
                type="radio"
                name="questionDifficulty-horizontal"
                value="horiz"
                onChange={handleChangeLayoutOrientation}
                checked={layoutOrientation === 'horiz'}
              />{' '}
              Horizontal <LabelLeft>Question Difficulty Level</LabelLeft>
              <Select
                id="difficulty"
                name="difficulty"
                value={difficulty}
                onChange={handleChangeDifficulty}
              >
                <option key="-" value="">
                  {'-'}
                </option>
                {difficulties.map((difficultyOption) => (
                  <option key={difficultyOption} value={difficultyOption}>
                    {difficultyOption}
                  </option>
                ))}
              </Select>
            </ExtraOptions>
          )}
        </Flex>
      </Container>
    </Layout>
  );
};

TestLibraryQuestionsTextAnswer.propTypes = {
  answerType: PropTypes.oneOf(['Short', 'Long']),
  difficulties: PropTypes.arrayOf(PropTypes.string),
  questionNumber: PropTypes.arrayOf(PropTypes.string),
};

export default TestLibraryQuestionsTextAnswer;
