/**
 * @param {import("axios").AxiosError} error Error or Erroneous Response
 * @returns {string} Error message
 */
export const getErrorMessage = (error, defaultMessage = 'Unexpected error') => {
  if (process.env.NODE_ENV === 'development') {
    // log errors on development environment
    // eslint-disable-next-line no-console
    console.error(error);
  }

  const { isAxiosError, response } = error;
  if (isAxiosError) {
    const { title, description /*,  status */ } = response?.data?.error || {};
    return description || title;
  }

  return defaultMessage;
};
