import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';
import strings from '../../../constants/strings';
import routes from '../../../routes';

const PasswordContext = React.createContext();

function ChangePasswordProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const changePassword = useCallback(async (passwordData) => {
        try {
            setLoading(true);
            await axiosInstance.post(routes.api.changePassword, passwordData);
            return { success: true, message: strings.en.actions.changePassword.success };
        } catch (error) {
            const { description } = error.response?.data?.error
            return { success: false, message: description || strings.en.actions.changePassword.fail };
        } finally {
            setLoading(false);
        }
    }, []);

    const value = {
        loading,
        changePassword,
    };

    return (
        <PasswordContext.Provider value={value}>
            {children}
        </PasswordContext.Provider>
    );
}

/**
 * @returns {{
 *  changePassword: (passwordData : Object.sahpe( {oldPassword, newPassword, email} )) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useChangePassword() {
    const context = React.useContext(PasswordContext);

    if (context === undefined) {
        throw new Error(
            'useChangePassword must be used within a PasswordContext',
        );
    }

    return context;
}

export { ChangePasswordProvider, useChangePassword };