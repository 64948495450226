import React from 'react';
import PropTypes from 'prop-types';
import { FaUser, FaSignOutAlt, FaDollarSign, FaKey } from 'react-icons/fa';
import { Text, Box, Flex } from 'rebass';
import styled from 'styled-components/macro';
import routes from '../../routes';
import { signOut } from '../../utils/auth';
import DropDownMenu from '../UI/DropdownMenu';
import { StyledLink } from '../UI/Link';

const LinkProps = {
  color: 'blue',
  style: { textDecoration: 'none' },
};

const LogOut = styled(Flex)`
  cursor: pointer;
  align-items: center;
`;

export const ProfileMenu = ({ firstName, lastName, email, ...rest }) => {
  const label = `${firstName} ${lastName}`;
  return (
    <DropDownMenu title={label} offsetTop="55px" offsetRight="0px" {...rest}>
      <Flex
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box>
          <Text color="blue">{label}</Text>
          <Text>{email}</Text>
        </Box>
      </Flex>
      <StyledLink to={routes.client.profile} {...LinkProps}>
        <FaUser width="20px" />
        <Text as="span">Profile</Text>
      </StyledLink>
      <StyledLink to={routes.client.billingSettings} {...LinkProps}>
        <FaDollarSign />
        <Text as="span">Billing</Text>
      </StyledLink>
      <StyledLink to={routes.client.changePassword} {...LinkProps}>
        <FaKey />
        <Text as="span">Password</Text>
      </StyledLink>
      <LogOut {...LinkProps} onClick={signOut}>
        <FaSignOutAlt />
        <Text as="span">Sign Out</Text>
      </LogOut>
    </DropDownMenu>
  );
};

ProfileMenu.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
};

export default ProfileMenu;
