import React from 'react';
import { string } from 'prop-types';
import { VerificationLayout } from '../Layout';

export const HomePageForStudents = ({ title }) => {
  return (
    <VerificationLayout
      downloadLink
      title={title}
      subtitle="You will be able to access your packages in the Classed Up App on your iPad. Press the button below or scan the QR Code to download the App."
    />
  );
};

HomePageForStudents.propTypes = {
  title: string,
};
