export const UPDATE_USER = 'AUTHENTICATION.UPDATE_USER';

export const REGISTER_START = 'AUTHENTICATION.REGISTER.START';
export const REGISTER_FAIL = 'AUTHENTICATION.REGISTER.FAIL';
export const REGISTER_SUCCESS = 'AUTHENTICATION.REGISTER.SUCCESS';

export const LOGIN_START = 'AUTHENTICATION.LOGIN.START';
export const LOGIN_FAIL = 'AUTHENTICATION.LOGIN.FAIL';
export const LOGIN_SUCCESS = 'AUTHENTICATION.LOGIN.SUCCESS';

export const FORGOT_PASSWORD_START = 'AUTHENTICATION.FORGOT_PASSWORD.START';
export const FORGOT_PASSWORD_FAIL = 'AUTHENTICATION.FORGOT_PASSWORD.FAIL';
export const FORGOT_PASSWORD_SUCCESS = 'AUTHENTICATION.FORGOT_PASSWORD.SUCCESS';

export const RESET_PASSWORD_CHECK_START =
  'AUTHENTICATION.RESET_PASSWORD_CHECK.START';
export const RESET_PASSWORD_CHECK_FAIL =
  'AUTHENTICATION.RESET_PASSWORD_CHECK.FAIL';
export const RESET_PASSWORD_CHECK_SUCCESS =
  'AUTHENTICATION.RESET_PASSWORD_CHECK.SUCCESS';

export const TOKEN_CHECK_START = 'AUTHENTICATION.TOKEN_CHECK.START';
export const TOKEN_CHECK_FAIL = 'AUTHENTICATION.TOKEN_CHECK.FAIL';
export const TOKEN_CHECK_SUCCESS = 'AUTHENTICATION.TOKEN_CHECK.SUCCESS';