import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Card from '../../UI/Card';
import { Container, Title } from '../../UI/Card/styled';
import { Container as PictureContainer } from '../../UI/Card/Picture/styled';

const QGCardContainer = styled(Container)`
  height: 50px;
`;

const QGCardTitle = styled(Title)`
  font-size: 20px;
`;

const QGPictureContainer = styled(PictureContainer)`
  width: 45px;
  height: 45px;
  font-size: 18px;
`;

export const TestLibraryQuestionGroupCard = ({
  id,
  name,
  description,
  numQuestions,
  color,
}) => (
  <StyledCard
    key={id}
    quantity={numQuestions}
    color={color}
    title={name}
    subtitle={description}
    CardContainer={QGCardContainer}
    CardTitle={QGCardTitle}
    PictureContainer={QGPictureContainer}
  />
);

TestLibraryQuestionGroupCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  numQuestions: PropTypes.number,
};

const StyledCard = styled(Card)`
  margin-top: 30px;
  margin-right: 30px;
  max-width: none;
`;

export default TestLibraryQuestionGroupCard;
