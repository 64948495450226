import React from 'react';
import { Box, Flex } from 'rebass';

export const Grid = ({ children, ...rest }) => (
  <Flex flexWrap="wrap" mx={-4} {...rest}>
    {children}
  </Flex>
);

export const GridItem = ({ children, ...rest }) => (
  <Box px={2} py={2} m={2} width={1 / 4} {...rest}>
    {children}
  </Box>
);
