// final form validators and helpers

/**
 * checks if form.confirmPassword matches form.password
 * @param {Object} form
 * @returns {String} if invalid, undefined if valid
 */
export const passwordsMatch = ({ confirmPassword, password }) => {
  return confirmPassword !== '' &&
    confirmPassword !== void 0 &&
    confirmPassword === password
    ? undefined
    : 'Passwords do not match!';
};

/**
 * checks if a value is undefined
 * @param {String} value the text value
 */
export const required = (value) => (value ? undefined : 'Required');

/**
 * check if at least N values exist
 * @param {Array} values
 * @param {Function} validatorFn validator to test against value
 * @param {Function} cb optional, if passed, cb will be used as a getter to get string value to check for existence
 * ie values = [{text: 'foo'}]
 * cb: value => value.text
 *
 * please note that validatorFn is called using .apply, and so if you require multiple args to be passed in to the
 * validator cb, ensure the cb argument returns an array
 *
 * usage: atleastNValidates([...], 2, required)
 */
export const atleastNValidates = (values, minValidates, validatorFn, cb) => {
  const validatedValues = values.map((v) => {
    let value = v;
    if (cb) value = cb(v);
    if (!Array.isArray(value)) {
      value = [value];
    }
    return validatorFn.apply(null, value);
  });

  const valuesThatDidNotMeetValidator = validatedValues.filter((v) => !!v);
  const valuesThatMetValidator = validatedValues.filter((v) => !v);

  return valuesThatMetValidator.length < minValidates
    ? valuesThatDidNotMeetValidator[0]
    : undefined;
};

/**
 * at least one in a set meets a validation fn
 * @param {Array} values
 * @param {Function} validatorFn validator to test against value
 * @param {Function} cb optional, if passed, cb will be used as a getter to get string value to check for existence
 * please note that validatorFn is called using .apply, and so if you require multiple args to be passed in to the
 * validator cb, ensure the cb argument returns an array
 */
export const atleastOneValidates = (values, validatorFn, cb) =>
  atleastNValidates(values, 1, validatorFn, cb);

/**
 * utility
 * @param  {...Function} validators set of functions
 * usage is composeValidators(validator1, passwordsMatch, required)
 */
export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

// final form entry level validator functions

export const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmailAddress = (email) => {
  let emailAddressError = '';
  if (email.trim === '') emailAddressError = 'Email Address is required';
  else if (!emailValidator.test(email))
    emailAddressError = 'Email is not valid';

  return emailAddressError === '';
};
