import React from 'react';
import styled from 'styled-components/macro';
import strings from '../../constants/strings';
import NoQuestionGroupsIcon from '../../assets/images/online-quiz.svg';

export const NoQuestionGroups = ({ onClick }) => (
  <Container onClick={onClick}>
    <Image src={NoQuestionGroupsIcon} />
    <Title>{strings.en.testLibrary.noQuestionGroups.title}</Title>
  </Container>
);

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 700px;
    padding: 100px;
  }
`;

const Title = styled.h2``;

const Image = styled.img`
  width: 200px;
  margin-bottom: 50px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: unset;
  }
`;
