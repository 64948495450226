import React, { useMemo } from "react"
import { Box } from "rebass"
import { useGetAssets } from "../../../../contexts/api/asset"
import { markupInContent } from "../../../../utils/markup"
import { Link } from "../../../UI"
import MediaElement from "./MediaElement"
import { AnswerItem, MediaItemContainer } from "./styled"
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf'

const AnswerBox = ({ answerText, letter, onChange, onSelectAsset, onRemoveAsset, ...rest }) => {
  const { assets } = useGetAssets()
  const markup = markupInContent(answerText)
  const answerAsset = useMemo(() => {
    return markup
      ? assets.find(asset => asset.id === markup.content)
      : null
  }, [markup, assets])

  return (
    <Box {...rest} >
      {answerAsset
        ? (
          <MediaItemContainer answerLetter={letter}>
            <MediaElement asset={answerAsset} />
            <small>{answerAsset.name}</small>
          </MediaItemContainer>
        )
        : <AnswerItem onChange={onChange} answerLetter={letter}
          value={answerText}
          placeholder="Insert text (including markups) or media here." />
      }
      <div className="links">
        <Link anchor to={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link> &nbsp; | &nbsp;
        {answerAsset
          ? <Link to="#" onClick={onRemoveAsset}>Remove Media File</Link>
          : <Link to="#" onClick={onSelectAsset}>Add Media File</Link>
        }
      </div>
    </Box>
  )
}

export default AnswerBox