import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex } from 'rebass';
import Layout from '../Layout';
import { Container, Link } from '../UI';
import { PackageEmptyList } from './EmptyList';
import PackageGrid from './Grid';
import routes from '../../routes';
import { AddButton } from '../UI/Button';
import strings from '../../constants/strings';
import { LoadingCaption } from '../UI/Loaders';
import PackageCreateDialog from './CreateDialog';
import { usePackages } from '../../contexts/api/package';
import { useStudents } from '../../contexts/api/student';
import { useSnackbar } from '../../contexts/ui';

export const TEST_IDS = {
  addPackage: 'Packages.addPackage',
};

export const PackageLibrary = () => {
  const { packages, loading, error } = usePackages();
  const errorMessage = error?.message || '';
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  // fetch students in prep for viewing a package
  useStudents();

  useEffect(() => {
    if (errorMessage) {
      showSnackbar(errorMessage, 'error');
    }
  }, [errorMessage, showSnackbar]);

  const location = useLocation();

  const isCreateDialogOpen = location.pathname.endsWith('create');

  const handleCloseCreateDialog = () => {
    const baseUrl = location.pathname.replace('create', '');
    navigate(baseUrl);
  };

  const hasPackages = !loading && packages.length > 0;

  return (
    <Layout>
      <Container>
        <Flex justifyContent="flex-end" mb={3}>
          <Link
            to={routes.client.addPackagesFromScratch}
            ml="auto"
            data-testid={TEST_IDS.addPackage}
          >
            <AddButton size="s">
              {strings.en.packages.addPackages.title}
            </AddButton>
          </Link>
        </Flex>
        <PackageCreateDialog
          onClose={handleCloseCreateDialog}
          open={isCreateDialogOpen}
        />
        {loading ? (
          <LoadingCaption />
        ) : (
          <PackageGrid packages={packages} viewPackage={() => null} />
        )}
        {!hasPackages && !loading && <PackageEmptyList />}
      </Container>
    </Layout>
  );
};

export default PackageLibrary;
