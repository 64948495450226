import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components/macro';
import { InputField, Button } from '../UI';
import { required } from '../../utils/form';
import { HelperText } from '../UI/HelperText';
import { Message } from '../UI/Message';

export const TEST_IDS = {
  email: 'login-email',
  emailHelper: 'login-email-helper',
  password: 'login-password',
  passwordHelper: 'login-password-helper',
  submit: 'login-submit',
  form: 'login-form',
};

const Div = styled.div`
  position: relative;
`;

export const LoginForm = ({ error, loginUser }) => {
  return (
    <Form
      onSubmit={(userData) => loginUser(userData)}
      render={({ handleSubmit, pristine, invalid }) => (
        <React.Fragment>
          <h2>Log In</h2>
          <form onSubmit={handleSubmit} data-testid={TEST_IDS.form}>
            <Field name="email" type="email" validate={required}>
              {({ input, meta }) => (
                <Div>
                  <InputField
                    label="email"
                    data-testid={TEST_IDS.email}
                    id="email"
                    width={400}
                    {...input}
                  />
                  {meta.touched && meta.error && (
                    <HelperText data-testid={TEST_IDS.emailHelper}>
                      {meta.error}
                    </HelperText>
                  )}
                </Div>
              )}
            </Field>

            <Field name="password" validate={required} type="password">
              {({ input, meta }) => (
                <Div>
                  <InputField
                    label="Password"
                    data-testid={TEST_IDS.password}
                    id="signup-password"
                    type="password"
                    width={400}
                    {...input}
                  />
                  {meta.touched && meta.error && (
                    <HelperText data-testid={TEST_IDS.passwordHelper}>
                      {meta.error}
                    </HelperText>
                  )}
                </Div>
              )}
            </Field>

            <Message>{error}</Message>

            <Button
              color="white"
              width={400}
              mt={5}
              data-testid={TEST_IDS.submit}
            >
              Log In
            </Button>
          </form>
        </React.Fragment>
      )}
    />
  );
};

LoginForm.propTypes = {
  error: PropTypes.string,
  loginUser: PropTypes.func,
};

export default LoginForm;
