import axios from 'axios';
import { routes } from './routes';
import jwt_decode from 'jwt-decode'
import { getDataFromLocalStorage, saveDataInLocalStorage } from './utils/localStorage';
import { LOCAL_STORAGE } from './constants/application';
import { signOut } from './utils/auth';

const getTokens = () => {
  return getDataFromLocalStorage(LOCAL_STORAGE.AUTH) || {}
}

export const authenticateHeader = (token) => ({
  Authorization: `Bearer ${token}`,
});
// create shared axios instance already pre-configured with api required headers etc
const axiosInstance = axios.create({
  baseURL: routes.api.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

/**
 * checks to see if a jwt token is expired
 * @param {String} token 
 * @returns {Bool}
 */
const jtwIsExpired = (token) => {
  try {
    const { exp } = jwt_decode(token)
    const now = Math.round((new Date()).getTime() / 1000);
    return now > exp
  } catch (e) {
    return true
  }

}

/**
 * closured library to eject and use common interceptors for this axios instance
 * usage is axiosInterceptors.use/eject.fn();
 * @returns {Object}
 */
export const axiosInterceptors = (() => {
  let interceptors = {};

  return {
    use: {
      /**
       * register an interceptor to add the authorization bearer header to all request
       * @param {String} token the jwt token
       */
      authInterceptor: () => {
        interceptors.auth = axiosInstance.interceptors.request.use(async (config) => {
          const { token, refreshToken } = getTokens()
          if (!jtwIsExpired(token)) {
            config.headers = { ...config.headers, ...authenticateHeader(token) }
            return config
          }
          // try to refresh the token
          try {
            const { baseURL, tokenRefresh } = routes.api
            const { data } = await axios.post(`${baseURL}${tokenRefresh}`, { refreshToken })
            saveDataInLocalStorage(LOCAL_STORAGE.AUTH, { ...data })
            config.headers = {
              ...config.headers, ...authenticateHeader(data.token)
            }
            // log user out if could not refresh token
          } catch (error) { signOut() }

          return config
        });
      },
    },
    eject: {
      /**
       * unregister the authentication interceptor
       */
      authInterceptor: () => {
        axiosInstance.interceptors.request.eject(interceptors.auth);
        interceptors = { ...interceptors, auth: null };
      },
    },
  };
})();

export default axiosInstance;
