import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getQuestionFromResponse, prepareQuestion } from './common';
import { getErrorMessage } from '../../../utils/request';

const CreateQuestionContext = React.createContext();

function CreateQuestionProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const createQuestion = useCallback(async (questionGroupId, question) => {
    try {
      setLoading(true);
      const payload = prepareQuestion(question);
      console.log('payload', payload);
      const response = await axiosInstance.post(
        `/question-groups/${questionGroupId}/questions`,
        payload,
      );

      return {
        question: getQuestionFromResponse(response.data),
        success: true,
        error: null,
      };
    } catch (error) {
      return { success: false, errorMessage: getErrorMessage(error) };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    createQuestion,
  };

  return (
    <CreateQuestionContext.Provider value={value}>
      {children}
    </CreateQuestionContext.Provider>
  );
}

/**
 * @typedef {import('./common').FrontEndQuestion} FrontEndQuestion
 * @returns {{
 *  loading: boolean,
 *  createQuestion: (
 *    questionGroupId: string,
 *    question: FrontEndQuestion
 *  ) => Promise<{
 *    success: boolean;
 *    errorMessage: string;
 *    question: FrontEndQuestion
 *  }>
 * }}
 */
function useCreateQuestion() {
  const context = React.useContext(CreateQuestionContext);

  if (context === undefined) {
    throw new Error(
      'useCreateQuestion must be used within a CreateQuestionProvider',
    );
  }

  return context;
}

export { CreateQuestionProvider, useCreateQuestion };
