import React, { useState, useEffect } from 'react';
import { Box } from 'rebass';

import {
  typography,
  space,
  color,
  layout,
  size,
  border,
  position,
} from 'styled-system';
import styled from 'styled-components/macro';
import { lightThinBorder, borderRadius } from '../../styleTokens';
import { SPACING_FACTOR } from '../../theme';

// generic input field
export const Input = styled.input`
  outline-color: ${({ theme }) => theme.colors.blue};
  :disabled{
    cursor: not-allowed;
    background:${({ theme }) => theme.colors.offwhite};
    color: ${({ theme }) => theme.colors.grey};
    
  }
`;

export default Input;

export const TextareaBox = styled(Box)`
  ${typography};
  ${space};
  ${color};
  ${layout};
  ${size};
  ${border};
  ${position};
  ${lightThinBorder};
  ${borderRadius};
  font-weight: 400;
  box-sizing: border-box;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  position: relative;
  ::placeholder {
    color: ${({ theme }) => theme.colors.midgrey};
  }
  letter-spacing: 0.89px;
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  color: ${({ theme }) => theme.colors.darkgrey};
  outline: none;
  resize: none;
`;

export const Textarea = props => <TextareaBox as="textarea" {...props} />;

/**
 * a dynamically centered text area
 * adapted from https://codepen.io/desandro/pen/gICqd
 * @param {Object} props
 */
export const VerticallyCenteredInput = props => {
  const id = props.id;
  const wrapperId = `wrapper_centered_${id}`;
  const dummyId = `dummy_centered_${id}`;

  const initialPaddingTop =
    props.pt * SPACING_FACTOR || props.p * SPACING_FACTOR || 3;

  const getPtAndHeight = () => {
    const dummyHeight = document.getElementById(dummyId).clientHeight;
    const wrapperHeight = document.getElementById(wrapperId).clientHeight;

    const top = Math.max(initialPaddingTop, (wrapperHeight - dummyHeight) / 2);

    return { paddingTop: top };
  };

  const [style, setStyle] = useState({
    paddingTop: initialPaddingTop,
  });

  const [dummyText, setDummyText] = useState(props.value || 'hello');
  useEffect(() => {
    setStyle(getPtAndHeight());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dummyText]);

  const onChange = e => {
    if (e.target.value.trim() === '') {
      setDummyText('i');
    } else {
      setDummyText(e.target.value);
    }

    if (props.onChange) props.onChange(e);
  };
  const additionalStyles = props.style || {};

  return (
    <div id={wrapperId} style={{ position: 'relative', whiteSpace: 'pre' }}>
      <Box
        id={dummyId}
        width={props.width || 250}
        style={{
          visibility: 'hidden',
          position: 'absolute',
        }}
        p={props.p || 3}
      >
        {dummyText}
      </Box>
      <Textarea
        id={id}
        {...props}
        onChange={onChange}
        style={{ ...additionalStyles, ...style }}
      />
    </div>
  );
};
