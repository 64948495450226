import React from 'react';
import { bool, number, oneOf, oneOfType, string } from 'prop-types';
import { space } from 'styled-system';
import { FaSave, FaPlus, FaTrashAlt } from 'react-icons/fa';
import styled from 'styled-components/macro';
import { COLORS } from '../../theme';

const ButtonSizes = {
  ExtraLarge: 'xl',
  Large: 'l',
  Normal: '',
  Small: 's',
  ExtraSmall: 'xs',
};

const ButtonDimensions = {
  [ButtonSizes.Normal]: {
    fontSize: '20px',
    height: '50px',
    borderRadius: '6px',
  },
  [ButtonSizes.Small]: {
    fontSize: '16px',
    height: '40px',
    borderRadius: '5px',
  },
};

export const Button = styled.button`
  ${(props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props) => (props.fullWidth ? `width: 100%;` : '')}
  ${(props) => (props.mt ? `margin-top: ${props.mt}em;` : '')}
  ${(props) =>
    props.center
      ? `display: flex; align-items: center; justify-content: center;`
      : ''}
  background-color: ${(props) =>
    props.outlined ? COLORS.transparent : COLORS.blue};

  color: ${(props) => (props.outlined ? COLORS.blue : COLORS.white)};

  border-color: ${COLORS.blue};

  border-radius: ${(props) => ButtonDimensions[props.size].borderRadius};
  border-style: solid;

  ${(props) => (props.border ? `border: ${props.border};` : '')}

  font-size: ${(props) => ButtonDimensions[props.size].fontSize};
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: normal;
  letter-spacing: normal;
  height: ${(props) => props.height || ButtonDimensions[props.size].height};
  cursor: pointer;
  transition: background-color 0.15s ease-out;
  :focus {
    outline: none;
    box-shadow: inset 0 0 4px 0px
      ${({ theme, color, borderColor }) => {
        if (borderColor) return theme.colors[borderColor];
        if (color) return theme.colors[color];
        return theme.colors.white;
      }};
  }
  :disabled {
    background-color: ${({ theme }) => theme.colors.lightgrey};
    border-color: ${({ theme }) => theme.colors.lightgrey};
  }
  padding: 8px 17px;
`;

Button.propTypes = {
  mt: number,
  border: string,
  outlined: bool,
  size: oneOf(Object.values(ButtonSizes)),
  width: oneOfType([string, number]),
};

Button.defaultProps = {
  size: ButtonSizes.Normal,
};

export const StyledFaSave = styled(FaSave)`
  ${space}
`;

export const StyledFaPlus = styled(FaPlus)`
  ${space}
`;

export const StyledFaTrash = styled(FaTrashAlt)`
  ${space}
`;

export const SaveButton = ({ children, iconMr, noIcon = false, ...rest }) => (
  <Button variant="primary" {...rest}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {!noIcon && <StyledFaSave mr={iconMr} />} <span>{children}</span>
    </span>
  </Button>
);
SaveButton.defaultProps = {
  iconMr: 4,
};

export const AddButton = ({ children, iconMr, ...rest }) => (
  <Button {...rest}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <StyledFaPlus mr={iconMr} /> <span>{children}</span>
    </span>
  </Button>
);
AddButton.defaultProps = {
  iconMr: 4,
};

export const DeleteButton = ({ children, iconMr, ...rest }) => (
  <Button px={4} bg="red" {...rest}>
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <StyledFaTrash mr={iconMr} /> <span>{children}</span>
    </span>
  </Button>
);
DeleteButton.defaultProps = {
  iconMr: 4,
};

export const IconButton = ({ children, ...rest }) => (
  <Button
    style={{
      outline: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
    }}
    {...rest}
  >
    {children}
  </Button>
);

export const CancelButton = ({ children, ...rest }) => (
  <Button
    size="s"
    outlined
    width="120"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: COLORS.grey,
      border: `1px solid ${COLORS.grey} `,
    }}
    {...rest}
  >
    {children}
  </Button>
);

export const RemoveButton = ({ children, ...rest }) => (
  <Button
    size="s"
    outlined
    width="120"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: COLORS.white,
      backgroundColor: COLORS.red,
      border: 'none',
    }}
    {...rest}
  >
    {children}
  </Button>
);

export const ModalActionButton = styled.button`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ ghost, color }) =>
    ghost ? 'transparent' : COLORS[color] || COLORS.blue};
  border-radius: 4px;
  color: ${({ ghost }) => (ghost ? '#828282' : COLORS.white)};
  border: 1px solid
    ${({ ghost, color }) => (ghost ? '#828282' : COLORS[color] || COLORS.blue)};
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  font-weight: normal;
  line-height: normal;
  padding: 8px 17px;
  cursor: pointer;
  :focus {
    outline: none;
    box-shadow: inset 0 0 4px 0px ${COLORS.lightgrey};
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export default Button;
