import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { StyledActionContainer } from './styled';
import { Dialog } from '@material-ui/core';
import { Button } from '../../UI';
import strings from '../../../constants/strings';
import { validateEmailAddress } from '../../../utils/form';
import StudentInvitationFormRow from './InvitationFormRow';
import { H2 } from './styled';
import { useInviteStudents } from '../../../contexts/api/student';

const defaultStudents = [
  {
    firstName: '',
    lastName: '',
    email: '',
  },
];

function transformStudents(initialStudents) {
  if(initialStudents.length === 0) {
    initialStudents = defaultStudents;
  }
  return initialStudents.map((s, idx) => ({
    ...s,
    id: idx + 1,
    errors: { email: false },
  }));
}

const StudentAddManuallyDialog = ({
  initialStudents,
  onClose,
  onInviteStudents = () => { },
  open,
}) => {
  const [students, setStudents] = useState(transformStudents(initialStudents));
  const {
    invites,
    sendInvites,
    sendSingleInvite,
    resetInvites,
  } = useInviteStudents();

  const onChange = (e, id) => {
    const newStudents = students.slice(0);
    newStudents[id - 1][e.target.name] = e.target.value;
    if (e.target.name === 'email') {
      const isValid = validateEmailAddress(e.target.value);
      if (newStudents[id - 1].errors.email && isValid) {
        newStudents[id - 1].errors.email = false;
      }
    }
    setStudents(newStudents);
  };

  const onBlur = (e, id) => {
    const newStudents = students.slice(0);

    if (e.target.name === 'email') {
      const isValid = validateEmailAddress(e.target.value);
      if (!isValid) {
        newStudents[id - 1].errors.email = true;
      }
    }

    setStudents(newStudents);

    return;
  };

  const addStudent = () => {
    const newStudent = {
      id: students.length + 1,
      firstName: '',
      lastName: '',
      email: '',
      errors: { email: false },
    };

    setStudents([...students, newStudent]);
  };

  const removeStudent = (id) => {
    if (students.length <= 1) {
      return;
    }

    const studentIndex = students.findIndex((x) => x.id === id);
    students.splice(studentIndex, 1);
    students.map((student, index) => (student.id = index + 1));
    const newArray = students.slice(0);
    setStudents(newArray);
  };

  const handleInviteStudents = async () => {
    const studentsToInvite = students.filter((s) => isValidStudent(s));
    await sendInvites(studentsToInvite);
    onInviteStudents(studentsToInvite);
  };

  const handleTryAgain = (student, index) => {
    sendSingleInvite(student, index);
  };

  const handleReset = () => {
    setStudents(transformStudents(initialStudents));
    resetInvites();
  };

  const handleCloseDialog = () => {
    onClose();
    resetInvites();
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseDialog}>
        Invite Student
      </DialogTitle>
      <DialogContent id="dialog-content" dividers>
        {students.map((student, idx) => (
          <StudentInvitationFormRow
            key={student.id}
            id={student.id}
            firstName={student.firstName}
            lastName={student.lastName}
            email={student.email}
            handleChange={onChange}
            handleBlur={onBlur}
            addStudent={addStudent}
            removeStudent={removeStudent}
            tryAgain={() => handleTryAgain(student, idx)}
            formErrors={student.errors}
            removeable={students.length > 1}
            loading={invites[idx]?.loading}
            requestError={invites[idx]?.error}
            success={invites[idx]?.success}
          />
        ))}
      </DialogContent>
      <StyledActionContainer>
        <Button outlined size="s" onClick={handleReset}>
          {strings.en.generic.reset}
        </Button>
        <Button size="s" onClick={handleInviteStudents}>
          {students.length > 1
            ? strings.en.students.sendInvitation.title
            : strings.en.students.sendInvitations.title}
        </Button>
      </StyledActionContainer>
    </Dialog>
  );
};

function isValidStudent(student) {
  return Boolean(student?.firstName && student?.lastName && student?.email);
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <H2>{children}</H2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    textAlign: 'right',
    padding: '36px 80px',
    height: '740px',
  },
}))(MuiDialogContent);

StudentAddManuallyDialog.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
};

export default StudentAddManuallyDialog;
