import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  justify-content: center;
  color: darkgrey;
  margin-top: 36px;
  width: 100%;
  text-align: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 250px;
  }

  label {
    cursor: pointer;
  }

  span {
    font-weight: bold;
    cursor: pointer;
  }
`;

const LoadMore = ({ loadMore }) => (
  <Container>
    <div onClick={loadMore}>
      <label>Load more</label>
      <span>...</span>
    </div>
  </Container>
);

export default LoadMore;
