import theme, {
  BREAKPOINTS,
  COLORS,
  BREAKPOINTS_KEYS,
  BREAKPOINT_VALUES,
  CUSTOM_BREAKPOINTS,
  FONT_SIZES,
  FONT_SIZE_FACTOR,
  SPACING,
  SPACING_FACTOR,
  Z_SPACES,
  Z_SPACING,
  mediaQuery,
} from './theme';

export {
  BREAKPOINTS,
  COLORS,
  BREAKPOINTS_KEYS,
  BREAKPOINT_VALUES,
  CUSTOM_BREAKPOINTS,
  FONT_SIZES,
  FONT_SIZE_FACTOR,
  SPACING,
  SPACING_FACTOR,
  Z_SPACES,
  Z_SPACING,
  mediaQuery,
};

export default theme;
