import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { Image, Text, Flex, Heading } from 'rebass';
import styled from 'styled-components/macro';
import { variant, border } from 'styled-system';
import { boxShadow } from '../../styleTokens';
import { BadgeContainer } from './Card/styled';

const compactCardStyles = variant({
  key: 'compactCardStyles',
});

const StyledCard = styled(Flex)`
  ${border};
  ${compactCardStyles};
  ${boxShadow};
  height: 250px;
  overflow: hidden;
  position: relative;
`;

const StyledAction = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  text-align: right;
  cursor: pointer;
`;

/**
 * @param {Object} props
 * @param {String} props.title
 * @param {String} props.image
 * @param {Function | String} props.description either a plain string or render prop
 */
export const CompactCard = ({
  image,
  variant,
  title,
  description,
  customAction,
  badgeText,
  flexContent = false,
  ...rest
}) => (
  <StyledCard
    flexWrap="nowrap"
    flexDirection="column"
    borderRadius={2}
    m={[2]}
    variant={variant}
    {...rest}
  >
    {badgeText ? <BadgeContainer left>{badgeText}</BadgeContainer> : null}
    {isFunction(image) ? (
      image()
    ) : (
      <Image
        src={image}
        bg="red"
        flex="0 0 160px"
        style={{ maxHeight: '160px' }}
        width={1}
      />
    )}
    <Flex py={4} px={4} flex="1 0 auto" flexDirection="column" justifyContent="space-between"  >
      {/* Title */}
      <Heading fontSize={4} lineHeight={1} textAlign="left">
        {title}
      </Heading>

      {isFunction(description) ? (
        description()
      ) : (
        <Text fontSize={3} as="p" m={0} textAlign="left">
          {description}
        </Text>
      )}
      {customAction && <StyledAction>{customAction}</StyledAction>}
    </Flex>
  </StyledCard>
);

CompactCard.propTypes = {
  variant: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

CompactCard.defaultProps = {
  variant: 'normal',
};
