import styled from 'styled-components/macro';
import { COLORS } from '../../../../../../../theme';

export const Container = styled.div`
  height: 100%;
  margin: 0px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width:100%;
`;

export const PageNav = styled.ul`
margin: 0 20px;
padding:0;
  list-style: none;
  display: flex;
  align-items: flex-start;
  li:not(:last-of-type) {
    margin-right: 29px;
  }
  li {
    min-width: 170px;
    text-transform: capitalize;
    button {
      cursor: pointer;
      :hover {
        border-bottom: 1px solid #424242;
      }
      border: none;
      :focus,
      :active {
        outline: none;
      }
      background-color: transparent;
      padding: 8px;
      font-size: 20px;
      color: #424242;
      white-space: nowrap;
    }
    button.active {
      color: ${COLORS.blue};

      border-bottom: 1px solid ${COLORS.blue};
    }
  }
`;

export const TopContainer = styled.div`
  height: 39px;
  padding: 35px 0 25px 0;
  background-color: #fff;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  width: 170px;
  height: 45px;
  margin: 1px 30px 0px 1px;
  font-size: 34px;
  color: #424242;
`;

export const ContentContainer = styled.div`
  height: 600px;
  margin: 33px 0 30px;
  border-radius: 3px;
  background-color: #fff;
  .links {
    width: 420px;
    margin: 5px auto;

    font-size: 12px;
    color: #2cabe0;
    clear: both;
  }
  .linksChoices {
    padding: 0px;
    margin: -20px -10px;
    height: 40px;
    font-size: 12px;
    color: #2cabe0;
    clear: both;
  }
`;

export const InputGroup = styled.div`
  margin-bottom:35px;
`;

export const Input = styled.input`
  width: 470px;
  height: 35px;
  margin: 10px 0 0 0;
  padding: 7px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  box-sizing: border-box;
`;

export const NumInput = styled(Input)`
  margin-top: 0;
  width: 75px;
`;

export const Label = styled.label`
  height: 26px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: middle;
  color: #424242;
  display: block;
  span {
    height: 16px;
    margin-left: 5px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #424242;
  }
`;

export const TextAreaShortHorizontal = styled.textarea`
  width: 388px;
  height: 147px;
  margin: 3px 123px 5px 45px;
  padding: 9px 11px 8px 12px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  display: flex;
  margin: 0 auto;
  ::placeholder,
  ::-webkit-input-placeholder {
    width: 365px;
    height: 130px;
    font-size: 12px;
    color: #b6b6b6;
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }

  textarea + div {
    width: 388px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #c9c9c9;
  }
`;

export const TextAreaShortVertical = styled(TextAreaShortHorizontal)`
  width: 360px;
  height: 355px;
  margin: 3px 30px 3px 0;
  padding: 9px 10px 9px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
`;

export const HelperText = styled.p`
  margin-top:0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #818181;
`;

export const Radio = styled.input`
  width: 13px;
  height: 13px;
  margin: 8px 5px 0 2px;
`;
export const RadioLabel = styled.label`
  min-width: 80px;
  margin-right: 20px;
  margin-left: 5px;
  display: inline-block;
  height: 21px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #424242;
`;

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
  margin: 0;
`;

export const QuestionGroupContainer = styled.div`
  height: 63px;
  padding: 9px 16px 9px 7px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #d7d7d7;
  margin-bottom: 25px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0,0,0,0);
  :hover,:active{
    border-color: var(--color-blue)
  }
  h2 {
    text-align: left;
    margin: 0 0 3px 11px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #424242;
  }
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f5c255;
    width:100%;
    height:100%;
    max-width:48px;
    max-height:48px;
    span {
      font-family: Roboto;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
    }
  }

  .content{
    flex-grow:1;
    align-item
  }
  h3 {
    margin: 3px 0 0 11px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818181;
  }
`;

export const QuestionGroupSidebar = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width:410px;
   h2.title {
    text-align: left;
    margin: 0 0 0 5px;
    font-family: Roboto;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #424242;
  }
`;

export const QuestonGroupIconContainer = styled.div`
  color: #b6b6b6;
  transform: rotateZ(90deg);
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-red);
  padding: 15px 0;
  justify-content: flex-end;
`

export const DeleteButton = styled.div`
  margin-left: 6px;
  user-select: none;
  margin-top: 2px;
`;
