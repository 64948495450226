import React from 'react';
import useContextMenu from '../../hooks/useContextMenu';

// Props: {targetRef: ref, options: [{label: string, onClick: function, Icon: component, linebreak: boolean, style: object }]}
const ContextMenu = (props) => {
  const { targetRef, options } = props;
  const { anchorPoint, show } = useContextMenu({ targetRef });

  return show ? (
    <ul
      className="context-menu"
      style={{ top: anchorPoint.y, left: anchorPoint.x }}
    >
      {options &&
        options.length > 0 &&
        options.map(({ label, onClick, Icon, linebreak, style }, i) => (
          <React.Fragment key={`c-li-${label}-${i}`}>
            <li onClick={onClick} style={style}>
              {Icon && Icon}
              <span>{label}</span>
            </li>
            {linebreak && <hr />}
          </React.Fragment>
        ))}
    </ul>
  ) : (
    <></>
  );
};

export default ContextMenu;
