import React from 'react';
import {
  Container,
  TabsContainer,
  ButtonsContainer,
  CancelButton,
} from './styled';
import Tab from './Tab';
import Button from '../../../UI/Button';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../routes';

const PackageLibraryCreateNavbar = ({
  packageId,
  tabs,
  selectedTabId,
  setSelectedTabId,
  onSave,
  isPublished,
  onChangePublish,
}) => {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="wrapper">
        <TabsContainer>
          {tabs.map(({ id, IconComponent, text }) => (
            <Tab
              key={id}
              IconComponent={IconComponent}
              text={text}
              onClick={() => setSelectedTabId(id)}
              active={id === selectedTabId}
            />
          ))}
        </TabsContainer>
        <ButtonsContainer>
          <CancelButton onClick={() => navigate(routes.client.packages)}>
            Cancel
          </CancelButton>
          <Button size="s" onClick={onSave}>
            Save
          </Button>
          {packageId && (
            <Button size="s" onClick={onChangePublish}>
              {isPublished ? 'Unpublish' : 'Publish'} Package
            </Button>
          )}
        </ButtonsContainer>
      </div>
    </Container>
  );
};
export default PackageLibraryCreateNavbar;
