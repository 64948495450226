import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectAuthenticated,
  selectTokensPresent,
} from '../containers/Authentication/selectors';
import routes from '../routes';
import { Loading } from './UI/Loaders';
import styled from 'styled-components';
import { Navigate } from 'react-router-dom';

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
// prevents the rendering of a component if its considered private
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const authenticated = useSelector(selectAuthenticated);
  const hasTokens = useSelector(selectTokensPresent);

  if (authenticated) {
    return <Component {...rest} />;
  } else {
    return hasTokens ? (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    ) : (
      <Navigate to={routes.client.home} replace />
    );
  }
};

export default ProtectedRoute;
