import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const UpdateQuestionGroupOrderContext = React.createContext();

function UpdateQuestionGroupOrderProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // payload => uuid[] the order of questions
  const updateQuestionGroupOrder = useCallback(async (id, payload) => {
    try {
      setLoading(true);
      await axiosInstance.put(
        `/question-groups/${id}/question-order`,
        payload,
      );

      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    updateQuestionGroupOrder,
    errorMessage,
    hasLoaded,
  };

  return (
    <UpdateQuestionGroupOrderContext.Provider value={value}>
      {children}
    </UpdateQuestionGroupOrderContext.Provider>
  );
}

/**
 * where array is the ids of the questions in the order you want them to be in
 * @typedef {{
 *  numberOfQuestions: string[];
 * }} GroupOrderPayload
 */
/**
 *
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  updateQuestionGroupOrder: (id: string, payload: GroupOrderPayload) => Promise<{ success: boolean; errorMessage: string; }>
 *  errorMessage: string
 * }}
 */

function useUpdateQuestionGroupOrder() {
  const context = React.useContext(UpdateQuestionGroupOrderContext);

  if (context === undefined) {
    throw new Error(
      'useUpdateQuestionGroupOrder must be used within a UpdateQuestionGroupOrderProvider',
    );
  }

  return context;
}


export { UpdateQuestionGroupOrderProvider, useUpdateQuestionGroupOrder };
