import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { loginThunk } from './actions';
import routes from '../../routes';
import { useLocation, useNavigate } from 'react-router-dom';
export const EmailVerifyCallback = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const { code, email } = queryString.parse(search) || {};

    dispatch(loginThunk({ code, email }, routes.api.verifyemail)).then(
      (status) => {
        navigate(routes.client.home, {
          state: { error: getErrorMessage(status) },
        });
      },
    );
  }, [dispatch, navigate, search]);

  return <div>Loading...</div>;
};

function getErrorMessage(status) {
  switch (status) {
    case 'expired':
      return 'Registration code has expired.';
    case 'invalid':
      return 'Registration code is invalid.';
    case 'success':
    default:
      // there is no error here.
      return null;
  }
}
