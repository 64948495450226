import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
`;

export const ContentContainer = styled.div`
  display: flex;
  overflow: auto;
  height: calc(100vh - 200px);
  border: solid 1px #b6b6b6;
  border-top: none;
  border-left: none;
`;
