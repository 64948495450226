import React from 'react';
import {
  Container,
  TabsContainer,
  ButtonsContainer,
  CancelButton,
  LeftSideContainer,
  DeleteButton,
  DeleteButtonContainer,
} from './styled';
import Tab from './Tab';
import Button from '../../UI/Button';
import { FaTrash } from 'react-icons/fa';

const PackageLibraryNavbar = ({
  children,
  tabs = [],
  selectedTabId,
  setSelectedTabId,
  showDeleteButton = false,
  onCancel = () => {},
  onSave = () => {},
  onDelete = () => {},
}) => (
  <Container>
    <LeftSideContainer>{children}</LeftSideContainer>
    <TabsContainer>
      {tabs.map(({ id, IconComponent, text }) => (
        <Tab
          key={id}
          IconComponent={IconComponent}
          text={text}
          onClick={() => setSelectedTabId(id)}
          active={id === selectedTabId}
        />
      ))}
    </TabsContainer>
    <ButtonsContainer>
      <DeleteButtonContainer onClick={onDelete}>
        {showDeleteButton && (
          <>
            <FaTrash />
            <DeleteButton>Delete</DeleteButton>
          </>
        )}
      </DeleteButtonContainer>

      <CancelButton onClick={onCancel}>Cancel</CancelButton>

      <Button size="s" onClick={onSave}>
        Save
      </Button>
    </ButtonsContainer>
  </Container>
);

export default PackageLibraryNavbar;
