import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Container, Title, DraggableContainer } from './styled';
import DraggableItem from '../../../../../../components/UI/DraggableItem';

const PackageLibraryCreateSidebar = ({ id, items }) => (
  <Droppable droppableId={id} isDropDisabled>
    {(provided) => (
      <Container ref={provided.innerRef}>
        <Title>Page Options</Title>
        {items.map((item, index) => (
          <DraggableContainer key={item.id}>
            <DraggableItem id={`${id}-${item.id}`} index={index}>
              {item.title}
            </DraggableItem>
          </DraggableContainer>
        ))}
      </Container>
    )}
  </Droppable>
);

export default PackageLibraryCreateSidebar;
