import React from 'react';
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Flex, Box } from 'rebass';
import AnswerBox from './AnswerBox';
import MediaElement from './MediaElement';
import { Link } from '../../../UI';
import { InputGroup, Label, MediaItemContainer, TextAreaShortHorizontal } from './styled';
import { useGetAssets } from '../../../../contexts/api/asset';
import { markupInContent } from '../../../../utils/markup';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf'

const QuestionVerticalLayout = ({ answers, answerIndicator, content, setContent, handleChangeAnswerText, openMediaPicker }) => {
    const { assets, hasLoaded } = useGetAssets();
    const AnswerLetters = Object.keys(answerIndicator);
    const markup = markupInContent(content)

    const contentAsset = useMemo(() => {
        return markup
            ? assets.find(asset => asset.id === markup.content)
            : null
    }, [assets, markup])

    return hasLoaded && (
        <Flex flexDirection="column" mx="auto" maxWidth="700px">
            <Box minWidth='460px' mx='auto' paddingBottom={8}>
                <InputGroup>
                    <Label alignSelf="center" >Content</Label>
                    {contentAsset
                        ? <MediaItemContainer className={`content vert`}>
                            <MediaElement asset={contentAsset} />
                            <small>{contentAsset.name}</small>
                        </MediaItemContainer>
                        : <TextAreaShortHorizontal
                            placeholder="Insert text here (including markups) or click below to add media as a content."
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    }
                    <div className="links flexStart">
                        <Link anchor href={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link> &nbsp; | &nbsp;
                        {contentAsset
                            ? <Link to="#" onClick={() => setContent('')}>Remove Media File</Link>
                            : <Link to="#" onClick={() => openMediaPicker()}>Add Media File</Link>
                        }
                    </div>
                </InputGroup>
            </Box>
            <Flex className='vert' flexDirection="column" maxWidth="700px" mx="auto" py={3}>
                <Label alignSelf="center" > Answers</Label>
                <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
                    {answers.map((answer, index) => (
                        <AnswerBox
                            key={index}
                            width={1 / 2} display="flex" flexDirection="column" px={10} py={3}
                            answerText={answer.text}
                            onChange={(e) => handleChangeAnswerText(index, e.target.value)}
                            onSelectAsset={() => openMediaPicker(index)}
                            onRemoveAsset={() => handleChangeAnswerText(index, '')}
                            letter={AnswerLetters[index]} />
                    ))}
                </Flex>

            </Flex>
        </Flex >
    )
}

QuestionVerticalLayout.propTypes = {
    answers: PropTypes.array.isRequired,
    answerIndicator: PropTypes.object.isRequired,
    content: PropTypes.string.isRequired,
    setContent: PropTypes.func.isRequired,
    handleChangeAnswerText: PropTypes.func.isRequired,
}

export default QuestionVerticalLayout