import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const DeleteStudentContext = React.createContext();

function DeleteStudentProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const deleteStudent = useCallback(async (studentId) => {
    try {
      setLoading(true);

      await axiosInstance.delete(`/students/${studentId}`);

      return { success: true, error: null };
    } catch (error) {
      return { success: false, error };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    deleteStudent,
  };

  return (
    <DeleteStudentContext.Provider value={value}>
      {children}
    </DeleteStudentContext.Provider>
  );
}

/**
 * @returns {{
 *  deleteStudent: (studentId: string) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useDeleteStudent() {
  const context = React.useContext(DeleteStudentContext);

  if (context === undefined) {
    throw new Error(
      'useDeleteStudent must be used within a DeleteStudentContext',
    );
  }

  return context;
}

export { DeleteStudentProvider, useDeleteStudent };
