import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const GetAssetsContext = React.createContext();

function GetAssetsProvider({ children }) {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const getAssets = useCallback(async (folder = 'media') => {
    try {
      setLoading(true);
      const response = await axiosInstance({
        method: 'GET',
        url: '/assets',
        params: {
          folder,
        },
      });

      setAssets(getAssetsFromResponse(response));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    assets,
    loading,
    hasLoaded,
    error,
    getAssets,
  };

  return (
    <GetAssetsContext.Provider value={value}>
      {children}
    </GetAssetsContext.Provider>
  );
}

function getGenericTypeFromMime(mimetype) {
  if (mimetype.indexOf('image') === 0) {
    return 'image';
  }

  if (mimetype.indexOf('audio') === 0) {
    return 'audio';
  }

  if (mimetype.indexOf('video') === 0) {
    return 'video';
  }

  if (mimetype === 'application/pdf') {
    return 'pdf';
  }

  if (mimetype === 'application/json') {
    return 'json';
  }

  return null;
}

function getAssetsFromResponse(response) {
  return (response?.data?.assets || []).map((asset) => ({
    id: asset.id,
    name: asset.title,
    folder: asset.folder,
    src: asset.url,
    mimetype: asset.mimetype,
    title: asset.title,
    type: getGenericTypeFromMime(asset.mimetype),
  }));
}

/**
 * @returns {{
 *  error: Error,
 *  getAssets: () => Promise<void>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 *  assets: object[],
 * }}
 */
function useGetAssets() {
  const context = React.useContext(GetAssetsContext);

  if (context === undefined) {
    throw new Error('useGetAssets must be used within a GetAssetsProvider');
  }

  if (!context.loading && !context.hasLoaded) {
    context.getAssets();
  }

  return context;
}

export { GetAssetsProvider, useGetAssets };
