import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';
import { getErrorMessage } from '../../../utils/request';

const DeleteQuestionGroupContext = React.createContext();

function DeleteQuestionGroupProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const deleteQuestionGroup = useCallback(async (id) => {
    try {
      setLoading(true);

      await axiosInstance({
        method: 'DELETE',
        url: `/question-groups/${id}`,
      });

      return {
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    hasLoaded,
    errorMessage,
    deleteQuestionGroup,
  };

  return (
    <DeleteQuestionGroupContext.Provider value={value}>
      {children}
    </DeleteQuestionGroupContext.Provider>
  );
}

/**
 * @returns {{
 *  errorMessage: string,
 *  deleteQuestionGroup: () => Promise<{ success: boolean; errorMessage: string; }>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 * }}
 */
function useDeleteQuestionGroup() {
  const context = React.useContext(DeleteQuestionGroupContext);

  if (context === undefined) {
    throw new Error(
      'useDeleteQuestionGroup must be used within a DeleteQuestionGroupProvider',
    );
  }

  return context;
}

export { DeleteQuestionGroupProvider, useDeleteQuestionGroup };
