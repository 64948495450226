import styled from 'styled-components/macro';

export const Message = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  color: ${({ color, theme }) => color || theme.colors.red};
  animation: fadeIn 0.15s ease-out;

  font-size: ${({ fontSize, theme }) => fontSize || '12px'};
`;
