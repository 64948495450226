import React, { useState, useCallback } from "react";
import { createPortal } from "react-dom";
import Snackbar from "../../components/UI/Snackbar";


const SnackbarContext = React.createContext();

const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState('success');
  const [message, setMessage] = useState('');

  /**
   * @function showSnackbar
   * Opens the global snackbar with a message
   * @param {String} message
   * @param {"success | "warning" | "error" | "info" } variant
   */
  const showSnackbar = useCallback((message, variant = 'success') => {
    setVariant(variant)
    setMessage(message)
    setOpen(true)
  }, [])

  const value = {
    open,
    variant,
    message,
    showSnackbar,
  }
  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {createPortal(
        <Snackbar
          open={open}
          variant={variant}
          message={message}
          handleClose={() => setOpen(false)}
        />,
        document.getElementById('root')
      )}
    </SnackbarContext.Provider>
  )
}


/**
 * @returns {{
 *  open: Boolean,
 *  variant: String,
 *  message: String,
 *  showSnackbar : {(message : String, variant: "success" | "warning" | "error" | "info"  )}
 * }}
 */
const useSnackbar = () => {
  const context = React.useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error(
      'useSnackbar must be used within a SnackbarProvider',
    );
  }

  return context;
}

export { SnackbarProvider, useSnackbar }