import React from 'react';
import PropTypes from 'prop-types';

import routes from '../../routes';
import { Login } from '../Authentication/Login';
import { EmailVerify } from '../Authentication/Registration/EmailVerify';
import { HomePageForStudents } from './PageForStudents';
import { Navigate, useLocation } from 'react-router-dom';

export const Home = (props) => {
  const { error, authenticated, user, loginUser, isEmailVerified } = props;
  const location = useLocation();
  // { state: {error: errorFromLocation, fromResetPassword} }
  const errorFromLocation = location.state && location.state.error;
  const fromResetPassword = location.state && location.state.fromResetPassword;
  if (!authenticated) {
    return <Login error={error || errorFromLocation} loginUser={loginUser} />;
  }

  if (!isEmailVerified) {
    return <EmailVerify />;
  }

  if (user.role !== 'teacher') {
    const title = fromResetPassword
      ? 'Password reset!'
      : 'Welcome to Classed Up!';
    return <HomePageForStudents title={title} />;
  }

  return <Navigate replace to={routes.client.packages} />;
};

Home.propTypes = {
  error: PropTypes.string,
  authenticated: PropTypes.bool,
  user: PropTypes.object,
  loginUser: PropTypes.func,
};
