import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  overflow: auto;
  .wrapper{
    max-width:1440px;
    display:flex;
    flex-direction: column;
    padding: 0 23px;
    width:100%;
    box-sizing: border-box;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: ${({ isNew }) => isNew ? '48px' : '0px'};
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  color: #818181;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-red);
  margin-top:30px;
  padding:5px 8px;
  cursor:pointer;
  align-self: end;
`;

export const DeleteButton = styled.div`
  margin-left: 6px;
  user-select: none;
  margin-top: 2px;
`;

export const PickerContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export const ColorPickerActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Textarea = styled.textarea`
  height: 200px;
  resize: none;
  background-color: #fff;
  padding: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 15px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #424242;
  }

  input,
  textarea {
    margin-bottom: 25px;
    border-radius: 3px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #424242;
    border: solid 1px #b6b6b6;
  }
`;
