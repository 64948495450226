import React from 'react';
import { shape, string, func } from 'prop-types';
import { Container, Image } from './styled';

const ImageSelector = ({
  containerStyle,
  backgroundImageSrc,
  selectedImageSrc,
  selectedImageStyle,
  backgroundImageProps,
  onClick,
}) => {
  return (
    <Container onClick={onClick} style={containerStyle}>
      {backgroundImageSrc ? (
        <Image
          src={backgroundImageSrc}
          alt="Image example..."
          width="100%"
          {...backgroundImageProps}
        />
      ) : null}
      {selectedImageSrc ? (
        <Image
          style={selectedImageStyle}
          src={selectedImageSrc}
          alt="Uploaded image..."
        />
      ) : null}
    </Container>
  );
};

ImageSelector.propTypes = {
  containerStyle: shape({}),
  backgroundImageSrc: string,
  selectedImageSrc: string,
  selectedImageStyle: shape({}),
  onClick: func,
};

ImageSelector.defaultProps = {
  containerStyle: {},
  backgroundImageSrc: '',
  selectedImageSrc: '',
  selectedImageStyle: {},
  onClick: () => {},
};

export default ImageSelector;
