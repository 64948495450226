/**
 * @typedef {{
 *  id?: string;
 *  subject: string;
 *  title: string;
 *  authorName?: string;
 *  description?: string;
 *  contains: string;
 *  primaryBColor?: string;
 *  primaryFColor?: string;
 *  thumbnailImage?: string;
 *  previewImages?: []string;
 *  minAppVersion: string;
 *  packageValidDate?: Date;
 *  packageExpiryDate?: Date;
 *  packageVersion: number;
 *  packageStatus: string;
 *  packageNumber: number;
 *  packageProperties: JSON;
 * }} BackendPackage
 * @typedef {{
 *  title: string;
 *  subject: string;
 *  description: string;
 *  templateBColor: string;
 *  templateFColor: string;
 *  packageCardImage: string;
 *  packageImages: []string;
 *  packageStatus: String
 * }} PackageProperties
 *
 * @typedef {{
 * properties: PackageProperties;
 * id?: string;
 * build?: object;
 * students?: object[];
 * }} FrontEndPackage
 *
 */

import {
  imageContentDeTransformer,
  imageContentTransformer,
} from '../../../utils/mediaLibrary';

/**
 * @typedef {
 *   'flashCards' |  'html' |  'pdf' |  'questions' |  'noteGame'
 * } PageFormats
 */
export const PageFormats = {
  FLASH_CARDS: 'flashCards',
  HTML: 'html',
  PDF: 'pdf',
  QUESTIONS: 'questions',
  NOTE_GAME: 'noteGame',
};

/**
 * @param {FrontEndPackage} packageState
 * @returns {BackendPackage} the payload
 */
export function preparePackage(packageState) {
  /**
   * @type {BackEndQuestion}
   */
  const { properties } = packageState;
  const payload = {
    title: properties.title,
    subject: properties.subject,
    description: properties.description,
    primaryBColor: properties.templateBColor,
    primaryFColor: properties.templateFColor,
    thumbnailImage: imageContentTransformer(properties.packageCardImage),
    previewImages: properties.packageImages.map(imageContentTransformer),
  };

  return payload;
}

/**
 *
 * @param {BackendPackage} packageObj
 * @returns {FrontEndPackage} packageObjState
 */
export function preparePackageForFrontEnd(packageObj) {
  const {
    id,
    title,
    subject,
    description,
    primaryBColor,
    primaryFColor,
    thumbnailImage,
    previewImages,
    packageStatus,
  } = packageObj;

  return {
    properties: {
      title,
      subject,
      description,
      templateBColor: primaryBColor,
      templateFColor: primaryFColor,
      packageCardImage: imageContentDeTransformer(thumbnailImage),
      packageImages:
        (Array.isArray(previewImages) &&
          previewImages.map(imageContentDeTransformer)) ||
        [],
      packageStatus: packageStatus
    },
    id,
  };
}
