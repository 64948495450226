import React from 'react';
import { object, func } from 'prop-types';
import { PictureAsPdf } from "@material-ui/icons";
import { Container } from './styled';
import { FaTrash } from 'react-icons/fa';
import { useModal } from '../../../contexts/ui';

const PDFSelector = ({ asset, onClick, onRemove }) => {
  const { openModal } = useModal()

  const removeMedia = () => {
    openModal({
      title: 'Remove PDF file',
      content: `Do you want to remove "${asset?.title}?"`,
      actionLabel: 'Remove',
      actionColor: 'red',
      action: onRemove
    })
  }
  return <Container onClick={!asset ? onClick : removeMedia}>
    <PictureAsPdf />
    {asset && (
      <span><FaTrash /> Remove PDF</span>
    )}
  </Container>
}



PDFSelector.propTypes = {
  asset: object,
  onClick: func.isRequired,
  onDeselect: func.isRequired
};

PDFSelector.defaultProps = {
  asset: null,
  onClick: () => { },
  onDeselect: () => { }
};

export default PDFSelector