import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * @typedef {{
 *    stop: Function;
 *    debounce: Function;
 * }} DelayedSideEffectReturn
 * @param {Number} delay settimeout delay in ms
 * @param {Function} sideEffectCb side affect to call after timer completes
 * @param {any[]} sideEffectArguments arguments passed to side effect
 * @returns {DelayedSideEffectReturn}
 */
export const useDelayedSideEffectOnChange = (delay, sideEffectCb) => {
  let timer = useRef();

  const [triggered, setTriggered] = useState(false);

  // ensures the debounce is for the latest version of the cb incase it originated from a component
  const debounce = useCallback(
    () =>
      setTimeout(() => {
        sideEffectCb();
        setTriggered(false);
      }, delay),
    [delay, sideEffectCb],
  );

  const stop = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  }, [timer]);

  useEffect(() => {
    stop();
    if (triggered) {
      timer.current = debounce();
    }
  }, [debounce, stop, triggered]);

  return {
    debounce: useCallback(() => {
      setTriggered(true);
    }, []),
    stop,
  };
};
