import React from 'react';
import { Container, Input, Label } from './styled';
import ImageSelector from '../UI/ImageSelector';

const Uploader = ({
  id,
  onChange,
  multiple = false,
  imageSrc,
  backgroundImage,
  imageStyle,
  style,
  accept = 'image/*',
  children,
}) => {
  const isValidFormat = (file) =>
    accept
      .split(',')
      .some((type) =>
        new RegExp(type.replace('*', '[^\\/,]+')).test(file.type),
      );

  const handleOnChange = (e) => {
    const files = Array.from(e.target.files);

    const file = files && files[0];

    if (file) {
      if (!isValidFormat(file)) {
        return;
      }

      onChange(file);
    }
  };

  return (
    <Container>
      <Label htmlFor={id}>
        <ImageSelector
          containerStyle={style}
          backgroundImageSrc={backgroundImage}
          selectedImageSrc={imageSrc}
          selectedImageStyle={imageStyle}
        />
        {children}
      </Label>
      <Input
        accept={accept}
        type="file"
        id={id}
        onChange={handleOnChange}
        multiple={multiple}
      />
    </Container>
  );
};

export default Uploader;
