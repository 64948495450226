import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { space } from 'styled-system';
import { Link } from 'react-router-dom';
import { Flex } from 'rebass';
import routes from '../../routes';
import Navigation from '../Navigation';
import { Logo, Container } from '../UI';
import HeaderWrapper from '../UI/HeaderWrapper';

export const Header = ({ links, render }) => (
  <HeaderWrapper position="fixed" zIndex={2}>
    <HeaderContainer>
      <GridHeader>
        <StyledLink
          my={6}
          mr={8}
          to={routes.client.home}
          aria-label="home"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Logo alt="Classed Up" />
        </StyledLink>

        <Navigation links={links} />

        <Flex ml="auto" alignItems="center">
          {render()}
        </Flex>
      </GridHeader>
    </HeaderContainer>
  </HeaderWrapper>
);

const StyledLink = styled(Link)`
  ${space};
`;

const GridHeader = styled.div`
  width: 100%;
  display: grid;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 15% auto 15%;
  }
`;

const HeaderContainer = styled(Container)`
  overflow: visible;
`;

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  render: PropTypes.func,
};

Header.defaultProps = {
  render: () => null,
};

export const TEST_IDS = {
  container: 'header-container',
  betalink: 'header-betalink',
};

export default Header;
