import React, { useState } from 'react';
import { Container, ButtonsContainer, Input, MediaTitle } from './styled';
import { ActionLabel } from '../../../UI/ActionLabel';
import { Loading } from '../../../UI/Loaders';
import { useGetAssets, useUpdateAsset } from '../../../../contexts/api/asset';
import { useSnackbar } from '../../../../contexts/ui';
import strings from '../../../../constants/strings';

const MediaPreviewEdit = ({ media }) => {
  const { loading, updateAsset } = useUpdateAsset();
  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useState(media.title);
  const { showSnackbar } = useSnackbar()
  const { getAssets } = useGetAssets()
  const handleCancel = () => {
    setEditing(false);
  };

  const handleSave = async () => {
    const result = await updateAsset(media.id, { title });
    await getAssets();
    if (result.success) {
      showSnackbar(strings.en.mediaLibrary.uploadAsset.successMessage);
    } else {
      showSnackbar(strings.en.mediaLibrary.uploadAsset.errorMessage, 'error');
    }
    setEditing(false);
  };

  return (
    <Container>
      {editing ? (
        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      ) : (
        <MediaTitle>{title}</MediaTitle>
      )}
      {loading && <Loading />}
      <ButtonsContainer>
        {editing ? (
          <>
            <ActionLabel onClick={handleCancel}>Cancel</ActionLabel>
            <ActionLabel onClick={handleSave}>Save</ActionLabel>
          </>
        ) : (
          <ActionLabel onClick={() => setEditing(true)}>Rename</ActionLabel>
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default MediaPreviewEdit;
