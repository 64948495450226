import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';
import routes from '../../../routes';

const UpdateStudentContext = React.createContext();

function UpdateStudentProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const updateStudent = useCallback(async (studentId, firstName, lastName) => {
    try {
      setLoading(true);

      await axiosInstance.patch(routes.api.user(studentId), { firstName, lastName });

      return { studentId, success: true, error: null };
    } catch (error) {
      return { studentId, success: false, error };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    updateStudent,
  };

  return (
    <UpdateStudentContext.Provider value={value}>
      {children}
    </UpdateStudentContext.Provider>
  );
}

/**
 * @returns {{
 *  updateStudent: (studentId: string firstName: string lastName: string) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useUpdateStudent() {
  const context = React.useContext(UpdateStudentContext);

  if (context === undefined) {
    throw new Error(
      'useUpdateStudent must be used within a UpdateStudentContext',
    );
  }

  return context;
}

export { UpdateStudentProvider, useUpdateStudent };