import React from 'react';
import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

export const StyledLink = styled(RouterLink)`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: none;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

/**
 * a functional link component that renders a reach router link if the path is not a web uri
 * @param {Object} props
 * usage is <Link to={...}>blah</Link>
 */
export const Link = ({ to, anchor = false, children, ...rest }) => {
  if (anchor || /^https?/.test(to)) {
    return (
      <StyledAnchor href={to} {...rest}>
        {children}
      </StyledAnchor>
    );
  }

  return (
    <StyledLink to={to} data-testid="react.router.link" {...rest}>
      {children}
    </StyledLink>
  );
};

export const LinkButton = styled.button`
  background: none;
  border: none;

  cursor: pointer;

  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : 'var(--color-blue)')};
`;

export default Link;
