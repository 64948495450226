import React, { useEffect, useState } from 'react';
import LoadMore from '../UI/LoadMore';
import StudentGrid from './Grid';
import { Line, Subtitle } from './styled';

const StudentPaginatedList = ({ pageSize, students = [], title = '' }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [studentsToDisplay, setStudentsToDisplay] = useState([]);

  useEffect(() => {
    setPageNumber(1);
  }, []);

  const loadMore = () => {
    if (students.length > studentsToDisplay.length) {
      setPageNumber((current) => current + 1);
    }
  };

  useEffect(() => {
    setStudentsToDisplay(students.slice(0, pageSize * pageNumber));
  }, [pageNumber, pageSize, students]);

  const showLoadMore =
    students.length > 0 && students.length > studentsToDisplay.length;

  if (students?.length === 0) {
    return null;
  }

  return (
    <>
      {title && (
        <>
          <Line />
          <Subtitle>{title}</Subtitle>
        </>
      )}
      <StudentGrid students={studentsToDisplay} />
      {showLoadMore && <LoadMore loadMore={loadMore} />}
    </>
  );
};

export default StudentPaginatedList;
