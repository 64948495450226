import * as actionTypes from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  questionGroups: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUESTION_GROUPS_START:
      return { ...state, error: null, loading: true };
    case actionTypes.GET_QUESTION_GROUPS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        questionGroups: action.payload.questionGroups,
      };
    case actionTypes.ADD_QUESTION_GROUP:
      return {
        ...state,
        questionGroups: state.questionGroups.concat(
          action.payload.questionGroup,
        ),
      };
    case actionTypes.GET_QUESTION_GROUPS_FAIL:
      return { ...state, error: action.payload.message, loading: false };

    case actionTypes.DELETE_QUESTION_FROM_GROUP:
      const questionGroups = state.questionGroups.map(group => {
        if (group.id === action.payload.groupId) {
          group.numQuestions -= 1;
        }
        return group;
      });
      return { ...state, questionGroups };

    case actionTypes.DELETE_QUESTION_GROUP_START:
      return {
        ...state,
        questionGroups: state.questionGroups.map(group => {
          if (group.id === action.payload.groupId) {
            return { ...group, isDeleting: true };
          } else {
            return group;
          }
        }),
      };

    case actionTypes.DELETE_QUESTION_GROUP_SUCCESS:
      return {
        ...state,
        questionGroups: state.questionGroups.filter(
          group => group.id !== action.payload.groupId,
        ),
      };
    case actionTypes.DELETE_QUESTION_GROUP_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export default reducer;
