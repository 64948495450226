import React from 'react';
import { Container as DefaultContainer } from './styled';

const Picture = ({
  imgSrc,
  initials,
  quantity,
  color,
  Container = DefaultContainer,
}) => {
  return <Container color={color}>{quantity || initials}</Container>;
};

export default Picture;
