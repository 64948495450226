import React from 'react';
import styled from 'styled-components/macro';
import { ExternalLayout } from '../../Layout';
import { signOut } from '../../../utils/auth';
import { H3 } from './styles';
import { Button } from '../../UI';

export const EmailSent = () => {
  return (
    <ExternalLayout>
      <Container>
        <h1>Email sent!</h1>
        <H3>Verify your email to reset your password.</H3>
        <Button color="white" width={400} mt={5} onClick={signOut}>
          Back to Login Page
        </Button>
      </Container>
    </ExternalLayout>
  );
};

const Container = styled.div`
  margin: 0 70px;
`;
