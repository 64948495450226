import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbar from '@material-ui/core/Snackbar';
import WarningIcon from '@material-ui/icons/Warning';
import { StyledSnackbarContent } from './styled';
import { COLORS } from '../../../theme';

const variantIcon = {
  success: CheckIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const iconColor = {
  success: COLORS.green,
  warning: COLORS.offwhite,
  error: COLORS.red,
  info: COLORS.blue,
};

function MySnackbarContent(props) {
  const { message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <StyledSnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar">
          <Icon />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      icon={iconColor[variant]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const Snackbar = ({ open, message = '', variant, bgColor, handleClose }) => {
  return (
    <div>
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MySnackbarContent
          onClose={handleClose}
          variant={variant}
          color={bgColor}
          message={message}
        />
      </MuiSnackbar>
    </div>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  bgColor: PropTypes.string,
  handleClose: PropTypes.func,
};

export default Snackbar;
