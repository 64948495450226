import React from 'react';
import { Link } from 'react-router-dom';
import StyledModal from '../../../../UI/Modal/styled';
import { Backdrop } from '../styled';

const NotFound = () => (
  <>
    <StyledModal
      id="Package.Create.ErrorBoundary"
      className={`wrapper fade-in`}
      role="dialog"
      modalSize="lg"
    >
      <div className="box-dialog">
        <div className="box-header">
          <h4 className="box-title">404: Package Not Found :(</h4>
        </div>
        <div className="box-content">
          <p>
            No Package Found. <Link to="/packages">Go Back.</Link>
          </p>
        </div>
      </div>
    </StyledModal>
    <Backdrop />
  </>
);

export default NotFound;
