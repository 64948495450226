import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from 'rebass';
import styled from 'styled-components/macro';
import { Navlink } from '../UI/Navlink';
import { useMatch, useLocation, useNavigate } from 'react-router-dom';

const LI = styled(Flex)`
  list-style: none;
`;

const StyledNavLink = styled(Navlink)`
  && {
    border-bottom-width: 3px;
    border-left-width: 3px;
  }
`;

const StyledFlex = styled(Flex)`
  && {
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.md} {
      flex-direction: row;
    }
  }
`;

export const Navigation = ({ links }) => (
  <StyledFlex
    as="ul"
    pl={0}
    m={0}
    alignItems="center"
    justifyContent="space-around"
  >
    {links.map((l) => (
      <LI key={l.to} as="li" alignItems="center" fontSize={[4, 3, 3, 4]}>
        <StyledNavLink py={7} px={4} to={l.to} data-testid={l.testId}>
          {l.text}
        </StyledNavLink>
      </LI>
    ))}
  </StyledFlex>
);

Navigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

export function Match({ path, children }) {
  let match = useMatch(path);
  let location = useLocation();
  let navigate = useNavigate();
  return children({ match, location, navigate });
}

export default Navigation;
