import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '../../../../UI';
import { Container, Subtitle } from './styled';
import { FaPlus, FaTrash } from 'react-icons/fa';
import theme from '../../../../../theme';
import PackageCard from '../../../../Package/Card';
import { useGetAssets } from '../../../../../contexts/api/asset';
import { LoadingCaption } from '../../../../UI/Loaders';



const StudentLibraryPackages = ({
  student,
  packages,
  onPackageAdd,
  onPackageRemove,
}) => {
  const { assets, loading } = useGetAssets();

  const packagesWithSrc = useMemo(() => {
    return packages.map(p => {
      const asset = assets.find(asset => asset.id === p.thumbnailImage);
      const thumbnailImage = asset ? asset.src : null;
      const studentsAssignedToPackage = student?.packages.find(sp => sp.id === p.id);
      return {
        ...p, thumbnailImage, badgeText: studentsAssignedToPackage ? 'Assigned' : ''
      }
    })
  }, [assets, packages, student]);

  const handleRemove = (packageId) => {
    window.scrollTo({ top: 0 });
    onPackageRemove(packageId);
  };

  const handleAdd = (packageId) => {
    window.scrollTo({ top: 0 });
    onPackageAdd(packageId);
  };

  const isAssignedToPackage = (packageId) => student?.packages.find((p) => p.id === packageId)

  const actionComponent = (packageId) => {
    return isAssignedToPackage(packageId)
      ? <FaTrash color={theme.colors.blue} />
      : <FaPlus color={theme.colors.blue} />
  };

  const packageOnClick = (packageId) => isAssignedToPackage(packageId) ? handleRemove(packageId) : handleAdd(packageId)

  return loading ? <LoadingCaption /> : (
    <Container>
      <Subtitle>Packages</Subtitle>
      <Grid mx={-2} width="100%">
        {packagesWithSrc.map((p) => (
          <PackageCard onClick={() => packageOnClick(p.id)} {...p} key={p.id} customAction={actionComponent(p.id)} />
        ))}
      </Grid>
    </Container>
  );
};

StudentLibraryPackages.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  student: PropTypes.shape({
    packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  }),
  onPackageSelected: PropTypes.func,
};

export default StudentLibraryPackages;
