import React from 'react';
import { arrayOf, shape, string, oneOf, func } from 'prop-types';
import Media, { types } from '../Media';
import { NoMediaContainer, NoMediaMessage } from './styled';
import permMediaSVG from '../../../assets/images/perm-media-24-px.svg';

const MediaLibraryContent = ({ media, onClickMedia, isSelected }) => {
  if (media.length) {
    return media.map((m) => (
      <Media
        key={m.id}
        onClick={() => onClickMedia(m)}
        selected={isSelected(m)}
        media={m}
      />
    ));
  }

  return (
    <NoMediaContainer>
      <img style={{ height: 250 }} src={permMediaSVG} alt="Empty" />
      <NoMediaMessage>No media files</NoMediaMessage>
    </NoMediaContainer>
  );
};

MediaLibraryContent.propTypes = {
  media: arrayOf(
    shape({
      id: string,
      src: string,
      name: string,
      type: oneOf(Object.values(types)),
      thumbnail: string,
    }),
  ),
  onClickMedia: func,
  isSelected: func,
};

MediaLibraryContent.defaultProps = {
  media: [],
  onClickMedia: () => {},
  isSelected: () => {},
};

export default MediaLibraryContent;
