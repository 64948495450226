import Axios from 'axios';
import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { UPLOAD_FOLDER } from '../../../constants/application';

const UploadAssetContext = React.createContext();

function UploadAssetProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);

  const uploadAsset = useCallback(async (file) => {
    try {
      setLoading(true);
      const { name, type } = file;
      const response = await axiosInstance.post('/assets', {
        filename: name,
        folder: UPLOAD_FOLDER,
        mimetype: type,
        title: name,
      });

      setProcessing(true);

      const { signedRequest } = response.data;
      const instance = Axios.create();

      // take signed request and upload to s3
      await instance.put(signedRequest, file);

      return {
        asset: getAssetFromResponse(response),
        success: true,
        error: null,
      };
    } catch (error) {
      return { success: false, error: getErrorFromResponse(error.response) };
    } finally {
      setLoading(false);
      setProcessing(false);
    }
  }, []);

  const value = {
    loading,
    processing,
    uploadAsset,
  };

  return (
    <UploadAssetContext.Provider value={value}>
      {children}
    </UploadAssetContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  title: string;
 *  mimetype: string;
 * }} Asset
 */

/**
 * @returns {{
 *  loading: boolean,
 *  processing: boolean,
 *  uploadAsset: (asset: File) => Promise<{ success: boolean; error: Error; asset: Asset}>
 * }}
 */

function useUploadAsset() {
  const context = React.useContext(UploadAssetContext);

  if (context === undefined) {
    throw new Error('useUploadAsset must be used within a UploadAssetProvider');
  }

  return context;
}

function getAssetFromResponse(response) {
  if (!response.data) {
    return null;
  }

  const asset = response.data;
  return {
    id: asset.id,
    name: asset.title,
    folder: asset.folder,
    src: asset.url,
    mimetype: asset.mimetype,
    title: asset.title,
    type: (asset.mimetype || '').split('/')[0],
  };
}

function getErrorFromResponse(response) {
  return response.data.error.title;
}

export { UploadAssetProvider, useUploadAsset };
