import Layout from '../../../Layout';
import React, { useEffect, useMemo, useState } from 'react';
import { CancelButton, SaveButton, Button } from '../../../UI/Button';
import { Link } from '../../../UI';
import { Flex, Box } from 'rebass';
import PropTypes from 'prop-types';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  ExtraOptionsSidebar,
  ExtraOptions,
  LabelLeft,
  Select,
  Radio,
  Checkbox,
  Label,
  Choices,
  InputGroup,
  QuestionField,
} from './styled';
import QuestionLayout from './QuestionHorizontalLayout';
import QuestionVerticalLayout from './QuestionVerticalLayout';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import { FaTrash } from 'react-icons/fa';
import MediaLibrarySelector from '../../../MediaLibrary/Selector';
import { assetContentTransformer } from '../../../../utils/mediaLibrary';
import { QUESTION_CONTENT_TYPES } from '../../../../constants/application';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf'


/**
 * @typedef {import('../../../../contexts/api/question/common').Answer} Answer;
 */

/**
 * @type {Answer[]}
 */
const defaultAnswers = () => [
  { text: '', isCorrect: false },
  { text: '', isCorrect: false },
  { text: '', isCorrect: false },
  { text: '', isCorrect: false },
];

const AnswerIndicator = {
  A: 0,
  B: 1,
  C: 2,
  D: 3,
};

const maxNumberOfAnswers = 4;

const TestLibraryQuestionsMultipleChoice = ({
  answers: initialAnswers,
  content: initialContent = '',
  difficulties = [],
  difficulty: initialDifficulty = '',
  layoutOrientation: initialLayoutOrientation = 'vert',
  loading = false,
  onCancel = () => { },
  onDelete = () => { },
  onSave = () => { },
  questionNumber,
  question: initialQuestion = '',
  showButton = false,
  type = QUESTION_CONTENT_TYPES.text,
  onNext,
  onPrevious
}) => {
  const [mediaPicker, setMediaPicker] = useState({ open: false, answerIndex: null, showTypes: [] })

  const [question, setQuestion] = useState(initialQuestion);
  useEffect(() => {
    setQuestion(initialQuestion);
  }, [initialQuestion]);

  const [content, setContent] = useState(initialContent);
  useEffect(() => {
    setContent(initialContent);
  }, [initialContent]);

  const [difficulty, setQuestionDifficulty] = useState(initialDifficulty);
  useEffect(() => {
    setQuestionDifficulty(initialDifficulty);
  }, [initialDifficulty]);

  const [layoutOrientation, setLayoutOrientation] = useState(
    initialLayoutOrientation,
  );
  useEffect(() => {
    setLayoutOrientation(initialLayoutOrientation);
  }, [initialLayoutOrientation]);

  const safeInitialAnswers = useMemo(
    () => getSafeAnswers(initialAnswers, defaultAnswers(), maxNumberOfAnswers),
    [initialAnswers],
  );

  const [answers, setAnswers] = useState(safeInitialAnswers);
  useEffect(() => {
    setAnswers(safeInitialAnswers);
  }, [safeInitialAnswers]);

  const initialAllowMultipleAnswers = useMemo(
    () => hasMoreThanOneCorrectAnswer(safeInitialAnswers),
    [safeInitialAnswers],
  );
  const [allowMultipleAnswers, setAllowMultipleAnswers] = useState(
    initialAllowMultipleAnswers,
  );

  useEffect(() => {
    setAllowMultipleAnswers(initialAllowMultipleAnswers);
  }, [initialAllowMultipleAnswers]);

  useEffect(() => {
    setAnswers((currentAnswers) => {
      if (countCorrectAnswers(currentAnswers) > 1 && !allowMultipleAnswers) {
        // set all to false
        return currentAnswers.map((answer) => ({
          ...answer,
          isCorrect: false,
        }));
      }

      // no change.
      return currentAnswers;
    });
  }, [allowMultipleAnswers]);

  const handleChangeAnswerText = (index, text) => {
    setAnswers((current) => {
      const newValues = [...current];
      newValues[index].text = text;
      return newValues;
    });
  };

  const handleChangeMultipleCorrectAnswers = (index, isCorrect) => {
    setAnswers((current) => {
      const newValues = [...current];
      newValues[index].isCorrect = isCorrect;
      return newValues;
    });
  };

  const handleChangeSingleCorrectAnswer = (index, isCorrect) => {
    setAnswers((current) => {
      const newValues = current.map((answer) => ({
        ...answer,
        isCorrect: false,
      }));
      newValues[index].isCorrect = isCorrect;
      return newValues;
    });
  };

  const handleChangeAnswerIsCorrect = allowMultipleAnswers
    ? handleChangeMultipleCorrectAnswers
    : handleChangeSingleCorrectAnswer;

  const handleChangeLayoutOrientation = (event) => {
    setLayoutOrientation(event.target.value);
  };

  const handleChangeDifficulty = (e) => {
    setQuestionDifficulty(e.target.value);
  };

  const payload = {
    question,
    content,
    questionNumber,
    difficulty,
    answers,
    layoutOrientation,
  };

  const handleSave = () => {
    onSave(payload);
  };

  const openMediaPicker = (answerIndex = null) => {
    const showTypes = [mediaTypes.IMAGE, mediaTypes.AUDIO]
    if (!answerIndex) showTypes.push(mediaTypes.VIDEO)

    setMediaPicker({ ...mediaPicker, open: true, answerIndex, showTypes })
  }

  const closeMediaPicker = () => {
    setMediaPicker({ ...mediaPicker, open: false })
  }

  const insertAsset = (asset) => {
    if (mediaPicker.answerIndex === null) {
      setContent(assetContentTransformer(asset))
    } else {
      handleChangeAnswerText(mediaPicker.answerIndex, assetContentTransformer(asset))
    }
    closeMediaPicker()
  }

  return (
    <Layout noBodyPadding>
      {mediaPicker.open && <MediaLibrarySelector
        onSelectMedia={(a) => insertAsset(a)}
        onClose={closeMediaPicker}
        showTypes={mediaPicker.showTypes}
      />}
      <Container>
        <TopContainer>
          <Title> Multiple Choice Question </Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>

            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>
        <Flex mb={3} px="5%" py="35px">
          <Box flexGrow={1}>
            <ContentContainer>
              <InputGroup>
                <Label>Question</Label>
                <QuestionField rows={2} value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="links flexStart">
                  <Link anchor href={ClassedUpMarkupGuide} name="markup-guide" target="_blank" rel="noreferrer">Markup guide</Link>
                </div>
              </InputGroup>
              {layoutOrientation === 'vert' && (
                <QuestionVerticalLayout
                  answers={answers}
                  answerIndicator={AnswerIndicator}
                  content={content}
                  setContent={setContent}
                  handleChangeAnswerText={handleChangeAnswerText}
                  orientation={layoutOrientation}
                  openMediaPicker={openMediaPicker}
                />
              )}
              {layoutOrientation === 'horiz' && (
                <QuestionLayout
                  answers={answers}
                  answerIndicator={AnswerIndicator}
                  content={content}
                  setContent={setContent}
                  handleChangeAnswerText={handleChangeAnswerText}
                  orientation={layoutOrientation}
                  openMediaPicker={openMediaPicker}
                />
              )}
            </ContentContainer>
          </Box>
          <ExtraOptionsSidebar>
            <ExtraOptions>
              <LabelLeft>Layout Display</LabelLeft>
              <Radio
                type="radio"
                name="layoutOrientation-vertical"
                value="vert"
                onChange={handleChangeLayoutOrientation}
                checked={layoutOrientation === 'vert'}
              />
              {' Vertical '}
              <Radio
                type="radio"
                name="layoutOrientation-horizontal"
                value="horiz"
                onChange={handleChangeLayoutOrientation}
                checked={layoutOrientation === 'horiz'}
              />
              {' Horizontal '}
              <LabelLeft>Multiple Correct Answer </LabelLeft>
              <Radio
                type="radio"
                name="allowMultipleCorrectAnswer"
                value={true}
                onChange={(e) => setAllowMultipleAnswers(e.target.checked)}
                checked={allowMultipleAnswers}
              />
              {'Yes '}
              <Radio
                type="radio"
                name="questionDifficulty"
                value="no"
                onChange={(e) => setAllowMultipleAnswers(!e.target.checked)}
                checked={!allowMultipleAnswers}
              />
              {'No'}
              <LabelLeft>Correct Answers </LabelLeft>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.A,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.A].isCorrect}
                  value={answers[AnswerIndicator.A].isCorrect}
                />
                {` A`}
              </Choices>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.B,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.B].isCorrect}
                  value={answers[AnswerIndicator.B].isCorrect}
                />
                {` B`}
              </Choices>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.C,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.C].isCorrect}
                  value={answers[AnswerIndicator.C].isCorrect}
                />
                {` C`}
              </Choices>
              <Choices>
                <Checkbox
                  type="checkbox"
                  onChange={(e) =>
                    handleChangeAnswerIsCorrect(
                      AnswerIndicator.D,
                      e.target.checked,
                    )
                  }
                  checked={answers[AnswerIndicator.D].isCorrect}
                  value={answers[AnswerIndicator.D].isCorrect}
                />
                {` D`}
              </Choices>
              <LabelLeft>Question Difficulty Level</LabelLeft>
              <Select
                id="difficulty"
                name="difficulty"
                value={difficulty}
                onChange={handleChangeDifficulty}
              >
                <option key="-" value="">
                  {'Level'}
                </option>
                {difficulties.map((difficultyOption) => (
                  <option key={difficultyOption} value={difficultyOption}>
                    {difficultyOption}
                  </option>
                ))}
              </Select>
            </ExtraOptions>
          </ExtraOptionsSidebar>
        </Flex>
      </Container>
    </Layout>
  );
};

/**
 * @param {Answer[]} initialAnswers
 * @param {Answer[]} defaultAnswers
 * @param {number} maxNumberOfAnswers
 * @returns {Answer[]}
 */
function getSafeAnswers(initialAnswers, defaultAnswers, maxNumberOfAnswers) {
  if (initialAnswers?.length > maxNumberOfAnswers) {
    // eslint-disable-next-line no-console
    console.warn(`Maximum number of answers exceeded, truncating answers`, {
      answers: initialAnswers,
    });
  }

  return [...(initialAnswers || []), ...defaultAnswers]
    .slice(0, maxNumberOfAnswers)
    .map(getSafeAnswer);
}

/**
 * @param {Answer} answer
 * @returns {Answer}
 */
function getSafeAnswer(answer) {
  let safeAnswer = {};
  if (typeof answer?.text === 'string') {
    safeAnswer.text = answer.text;
  } else {
    // eslint-disable-next-line no-console
    console.warn('Invalid answer:', { answer });
  }
  safeAnswer.isCorrect = Boolean(answer?.isCorrect);
  return safeAnswer;
}

/**
 * @param {Answer[]} answers the array of answers
 * @returns {boolean} true if has more than one correct answer
 */
function hasMoreThanOneCorrectAnswer(answers) {
  if (!Array.isArray(answers)) return 0;

  let count = 0;
  for (const answer of answers) {
    if (answer.isCorrect) {
      count++;
    }

    if (count > 1) {
      return true;
    }
  }

  return false;
}

/**
 * @param {Answer[]} answers
 */
function countCorrectAnswers(answers) {
  return answers.reduce((acc, answer) => {
    if (answer.isCorrect) {
      return acc + 1;
    }
    return acc;
  }, 0);
}

TestLibraryQuestionsMultipleChoice.propTypes = {
  difficulties: PropTypes.arrayOf(PropTypes.string),
  questionNumber: PropTypes.arrayOf(PropTypes.string),
};

export default TestLibraryQuestionsMultipleChoice;
