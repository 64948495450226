import { mapToArray } from './application';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : 'http://localhost:3000/v1';

// axios request header configuration
export const FILE_REQUEST_HEADERS = {
  'Content-Type': 'multipart/form-data',
};

export const HTTP_STATUS = {
  200: 200,
  OK: 200,
};

export const API_REQUEST_CONFIG = {
  REQUEST_DELAY: 350,
};

export const API_RESOURCES = {
  packages: 'packages',
  students: 'students',
  mediaLibrary: 'mediaLibrary',
  questionGroups: 'questionGroups',
  users: 'users',
};

export const API_RESOURCES_LIST = mapToArray(API_RESOURCES);
