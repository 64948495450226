import React, { useCallback, useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Flex } from 'rebass';
import ColorPicker from '../../../../UI/ColorPicker';
import {
  Container,
  FormContainer,
  FormGroup,
  Subtitle,
  DeleteButton,
  ButtonContainer,
  PickerContainer,
  Textarea,
  InputContainer,
} from './styled';
import { Label } from '../../../../UI/InputField';
import { types } from '../../../../MediaLibrary/Media';
import packageCardSVG from '../../../../../assets/images/package-card-image.svg';
import packageSVG from '../../../../../assets/images/package-image.svg';
import ImageSelector from '../../../../UI/ImageSelector';
import MediaLibrarySelector from '../../../../MediaLibrary/Selector';
import ColorLabel from '../../../../UI/Labels/ColorLabel';
import { usePackageStore } from '../../../../../contexts/reducers/package';
import { useGetAssets } from '../../../../../contexts/api/asset';
import strings from '../../../../../constants/strings';
import {
  useDeletePackage,
  usePackages,
} from '../../../../../contexts/api/package';
import { useModal, useSnackbar } from '../../../../../contexts/ui';
import { useNavigate } from 'react-router-dom';
import routes from '../../../../../routes';

let onMediaSelectedCallback = () => {};

const PackageLibraryCreateContentProperties = () => {
  const [isSelectingMedia, setIsSelectingMedia] = useState(false);
  const { state, updatePackage } = usePackageStore();
  const [packageCardImagePreview, setPackageCardImagePreview] = useState(null);
  const [packageImagesPreview, setPackageImagesPreview] = useState([null]);
  const navigate = useNavigate();
  const { assets } = useGetAssets();
  const { getPackages } = usePackages();
  const { deletePackage } = useDeletePackage();
  const { showSnackbar } = useSnackbar();
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    if (state.properties.packageCardImage && assets) {
      const asset = assets.find(
        (asset) => asset.id === state.properties.packageCardImage,
      );

      if (asset) {
        setPackageCardImagePreview(asset.src);
      }
    }
    return () => {
      setPackageCardImagePreview(null);
    };
  }, [assets, state.properties.packageCardImage]);

  useEffect(() => {
    if (state.properties.packageImages && assets) {
      const asset = assets.find(
        (asset) => asset.id === state.properties.packageImages[0],
      );

      if (asset) {
        setPackageImagesPreview([asset.src]);
      }
    }
    return () => {
      setPackageImagesPreview([null]);
    };
  }, [
    assets,
    state.properties.packageCardImage,
    state.properties.packageImages,
  ]);

  const wrappedUpdatePackage = useCallback(
    (prop, value) => {
      updatePackage({ properties: { ...state.properties, [prop]: value } });
    },
    [updatePackage, state.properties],
  );

  const onRemovePackageCardImage = () => {
    wrappedUpdatePackage('packageCardImage', '');
    setPackageCardImagePreview(null);
  };

  const onRemovePackageImages = () => {
    wrappedUpdatePackage('packageImages', []);
    setPackageImagesPreview([null]);
  };

  const onUploadPackageCardImage = (picture) => {
    wrappedUpdatePackage('packageCardImage', picture);
  };

  const onUploadPackageImages = (picture) => {
    wrappedUpdatePackage('packageImages', [picture]);
  };

  const openMediaSelector = (callback) => {
    setIsSelectingMedia(true);
    onMediaSelectedCallback = callback;
  };
  // need to get what type of image this is (packageImage or packageCardImage) and update the preview image
  // for respective type with the right asset from the getAssets() context
  const selectMedia = (m) => {
    onMediaSelectedCallback(m.id);
    closeMediaSelector();
  };

  const closeMediaSelector = () => {
    setIsSelectingMedia(false);
    onMediaSelectedCallback = () => {};
  };

  if (isSelectingMedia) {
    return (
      <MediaLibrarySelector
        showTypes={[types.IMAGE]}
        onSelectMedia={selectMedia}
        onClose={closeMediaSelector}
      />
    );
  }

  const handleDeleteConfirm = async () => {
    const { success, message } = await deletePackage(state.id);
    const variant = success ? 'success' : 'error';
    showSnackbar(message, variant);
    if (success) {
      await getPackages();
      navigate(routes.client.packages);
    }
  };

  const handleDelete = () => {
    openModal({
      title: strings.en.generic.delete,
      content: strings.en.confirmModal.package.confirmDelete,
      action: handleDeleteConfirm,
      actionLabel: 'Delete',
      actionColor: 'red',
    });
  };

  const PickerContentTemplateColour = () => (
    <PickerContainer>
      <ColorPicker
        color={state.properties.templateBColor}
        onChangeComplete={(newColor) => {
          wrappedUpdatePackage('templateBColor', newColor.hex);
        }}
      />
    </PickerContainer>
  );

  const PickerContentTextColour = () => (
    <PickerContainer>
      <ColorPicker
        type="circle"
        color={state.properties.templateFColor}
        onChangeComplete={(newColor) => {
          wrappedUpdatePackage('templateFColor', newColor.hex);
        }}
      />
    </PickerContainer>
  );

  const openTemplateColorPicker = () => {
    openModal({
      title: 'Select template colour',
      content: <PickerContentTemplateColour />,
      action: closeModal,
      actionLabel: 'OK',
      cancelAction: false,
    });
  };

  const openTextColorPicker = () => {
    openModal({
      title: 'Select text colour',
      content: <PickerContentTextColour />,
      action: closeModal,
      actionLabel: 'OK',
      cancelAction: false,
    });
  };

  return (
    <Container>
      <div className="wrapper">
        {state.id && (
          <ButtonContainer>
            <FaTrash />
            <DeleteButton onClick={handleDelete}>Delete Package</DeleteButton>
          </ButtonContainer>
        )}
        <FormContainer isNew={!state.id}>
          <FormGroup>
            <InputContainer>
              <label htmlFor="create-package-title">Title</label>
              <input
                id="create-package-title"
                onChange={(e) => {
                  wrappedUpdatePackage('title', e.target.value);
                }}
                value={state.properties.title}
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="create-package-subject">Subject</label>
              <input
                id="create-package-subject"
                onChange={(e) => {
                  wrappedUpdatePackage('subject', e.target.value);
                }}
                value={state.properties.subject}
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="create-package-description">Description</label>
              <Textarea
                id="create-package-description"
                onChange={(e) => {
                  wrappedUpdatePackage('description', e.target.value);
                }}
                value={state.properties.description}
              />
            </InputContainer>

            <Flex my={3} flexDirection="column">
              <Flex flexDirection="row" justifyContent="flex-start" alignItems="center" style={{ gap: 30 }}>
                <div>
                  <Label as="label" mb={3}>
                    Template Colour
                  </Label>
                  <ColorLabel
                    hex={state.properties.templateBColor}
                    onClick={openTemplateColorPicker}
                  />
                </div>
                <div>
                  <Label as="label" mb={3}>
                    Text Colour
                  </Label>
                  <ColorLabel
                    hex={state.properties.templateFColor}
                    onClick={openTextColorPicker}
                  />
                </div>
              </Flex>
            </Flex>

            <Flex my={3} flexDirection="column">
              <Label as="label" mb={3}>
                Preview:
              </Label>
              <div
                style={{
                  background: state.properties.templateBColor,
                  color: state.properties.templateFColor,
                  border: `1px solid ${state.properties.templateFColor}`,
                  height: 30,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Preview of the text colour against the template colour.
              </div>
            </Flex>
          </FormGroup>
          <FormGroup>
            <Flex my={3} flexDirection="column" alignItems="flex-start">
              <Label as="label" mb={3}>
                Package Cover Image
              </Label>
              <Subtitle>This image will appear on the Package card.</Subtitle>
              <Flex alignItems="flex-start">
                <ImageSelector
                  containerStyle={{ marginTop: 15, width: 270, height: 270 }}
                  backgroundImageSrc={packageCardSVG}
                  selectedImageSrc={packageCardImagePreview}
                  selectedImageStyle={{
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    width: 268,
                    height: 130,
                    margin: 1,
                  }}
                  onClick={() => openMediaSelector(onUploadPackageCardImage)}
                />
                {packageCardImagePreview && (
                  <ButtonContainer
                    onClick={onRemovePackageCardImage}
                    style={{ padding: '17px 10px' }}
                  >
                    <FaTrash />
                  </ButtonContainer>
                )}
              </Flex>
            </Flex>
            <Flex my={3} flexDirection="column" alignItems="flex-start">
              <Label as="label" mb={3}>
                Package Details Image
              </Label>
              <Subtitle>
                This image will appear in the Package Details screen.
              </Subtitle>
              <Flex alignItems="flex-start">
                <ImageSelector
                  containerStyle={{ marginTop: 15, height: 270, width: 470 }}
                  backgroundImageSrc={packageSVG}
                  selectedImageSrc={packageImagesPreview[0]}
                  selectedImageStyle={{
                    borderBottomLeftRadius: 15,
                    borderTopRightRadius: 15,
                    margin: '36px 1px 1px 1px',
                    height: 233,
                    width: 268,
                  }}
                  onClick={() => openMediaSelector(onUploadPackageImages)}
                />
                {packageImagesPreview[0] && (
                  <ButtonContainer
                    onClick={onRemovePackageImages}
                    style={{ padding: '17px 10px' }}
                  >
                    <FaTrash />
                  </ButtonContainer>
                )}
              </Flex>
            </Flex>
          </FormGroup>
        </FormContainer>
      </div>
    </Container>
  );
};

export default PackageLibraryCreateContentProperties;
