export const GET_QUESTION_GROUPS = 'TEST_LIBRARY.GET_QUESTION_GROUPS';
export const GET_QUESTION_GROUPS_START =
  'TEST_LIBRARY.GET_QUESTION_GROUPS_START';
export const GET_QUESTION_GROUPS_FAIL = 'TEST_LIBRARY.GET_QUESTION_GROUPS_FAIL';
export const GET_QUESTION_GROUPS_SUCCESS =
  'TEST_LIBRARY.GET_QUESTION_GROUPS_SUCCESS';
export const ADD_QUESTION_GROUP = 'TEST_LIBRARY.ADD_QUESTION_GROUP';
export const DELETE_QUESTION_FROM_GROUP =
  'TEST_LIBRARY.DELETE_QUESTION_FROM_GROUP';
export const DELETE_QUESTION_GROUP_START =
  'TEST_LIBRARY.DELETE_QUESTION_GROUP_START';
export const DELETE_QUESTION_GROUP_SUCCESS =
  'TEST_LIBRARY.DELETE_QUESTION_GROUP_SUCCESS';
export const DELETE_QUESTION_GROUP_FAIL =
  'TEST_LIBRARY.DELETE_QUESTION_GROUP_FAIL';
