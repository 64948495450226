import Layout from '../../../Layout';
import React, { useEffect, useState } from 'react';
import { AddButton, CancelButton, SaveButton, Button } from '../../../UI/Button';
import { Link } from '../../../UI';
import { Flex, Box } from 'rebass';
import { useXarrow } from 'react-xarrows';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  Label,
  InputGroup,
  QuestionField,
} from './styled';
import MatchFieldAnswerBox from './MatchField';
import MediaLibrarySelector from '../../../MediaLibrary/Selector';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import { FaTrash } from 'react-icons/fa';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import { assetContentTransformer } from '../../../../utils/mediaLibrary';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf';
import { Letters } from '../../../../contexts/api/question/common';
import ConnectPointsContainer, {
  getIdxFromId,
} from '../../../UI/ConnectPointsContainer';
import SelectableXArrow from '../../../UI/SelectableXArrow';

const Position = {
  LEFT: 'left',
  RIGHT: 'right',
};

const TestLibraryQuestionsMatchingLines = ({
  onCancel = () => {},
  onDelete = () => {},
  onSave = () => {},
  questionNumber,
  question: initialQuestion,
  showButton = false,
  matchLeftItems: initialLeftItems,
  matchRightItems: initialRightItems,
  matchAnswers: initialMatchAnswers,
  onNext,
  onPrevious
}) => {
  const updateXarrow = useXarrow();
  const [mediaPicker, setMediaPicker] = useState({
    open: false,
    answerIndex: null,
    showTypes: [],
    position: null,
  });

  const [question, setQuestion] = useState(initialQuestion);
  useEffect(() => {
    setQuestion(initialQuestion || '');
  }, [initialQuestion]);

  const [matchLeftItems, setMatchLeftItems] = useState(initialLeftItems);
  useEffect(() => {
    setMatchLeftItems(initialLeftItems || [{ text: '' }]);
  }, [initialLeftItems]);

  const [matchRightItems, setMatchRightItems] = useState(initialRightItems);
  useEffect(() => {
    setMatchRightItems(initialRightItems || [{ text: '' }]);
  }, [initialRightItems]);

  const [arrows, setArrows] = useState([]);

  const updateArrows = (matchesArray) => {
    const matchedArrows = [];

    matchesArray.forEach(({ leftItem, rightItem }) => {
      matchedArrows.push({
        props: {
          start: `container-matchField-left-idx-${leftItem}`,
          end: `container-matchField-right-idx-${rightItem}`,
        },
      });
    });

    return matchedArrows;
  };

  const [matchAnswers, setMatchAnswers] = useState();

  useEffect(() => {
    setMatchAnswers(initialMatchAnswers || []);
    // set our arrows based on the matches array
    if (initialMatchAnswers && initialMatchAnswers.length > 0) {
      const matchedArrows = updateArrows(initialMatchAnswers);
      setArrows(matchedArrows);
      updateXarrow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMatchAnswers]);

  // selected: { props: { start: string, end: string }, type:"arrow"}
  const [selected, setSelected] = useState(null);

  // check to see if matchAnswers has a match with the index and position.
  // We need to update the answerMatches array to make sure all objects that had
  // the item is not a part of the array anymore
  const removeAnswerMatches = (index, position) => {
    if (matchAnswers.length > 0) {
      const filteredMatchAnswers = matchAnswers.filter(
        ({ leftItem, rightItem }) => {
          if (position === Position.LEFT) {
            return leftItem !== index;
          } else if (position === Position.RIGHT) {
            return rightItem !== index;
          }

          return true;
        },
      );
      const udpatedMatchArrows = updateArrows(filteredMatchAnswers);
      setMatchAnswers(filteredMatchAnswers);
      setArrows(udpatedMatchArrows);
    }
  };

  // remove a singular answerMatch based on a deleted arrow
  // deletedArrow: { props: { start: string, end: string }, type:"arrow"}
  const removeAnswerMatch = (deletedArrow) => {
    if (matchAnswers.length > 0) {
      const { props } = deletedArrow;
      let leftIdx = null;
      let rightIdx = null;

      const startIdx = getIdxFromId(props.start);
      const endIdx = getIdxFromId(props.end);

      if (startIdx && startIdx[1] === 'left') {
        leftIdx = Number(startIdx[2]);
      } else if (startIdx && startIdx[1] === 'right') {
        rightIdx = Number(startIdx[2]);
      }

      if (endIdx && endIdx[1] === 'left') {
        leftIdx = Number(endIdx[2]);
      } else if (endIdx && endIdx[1] === 'right') {
        rightIdx = Number(endIdx[2]);
      }

      const filteredMatchAnswers = matchAnswers.filter(
        ({ leftItem, rightItem }) =>
          (leftItem !== leftIdx && rightItem !== rightIdx) ||
          (leftItem !== leftIdx && rightItem === rightIdx) ||
          (leftItem === leftIdx && rightItem !== rightIdx),
      );

      setMatchAnswers(filteredMatchAnswers);
    }
  };

  const addItem = (position) => {
    if (position === Position.LEFT) {
      setMatchLeftItems((current) => {
        const newValues = [...current, { text: '' }];
        return newValues;
      });
    } else if (position === Position.RIGHT) {
      setMatchRightItems((current) => {
        const newValues = [...current, { text: '' }];
        return newValues;
      });
    }
    updateXarrow();
  };

  const removeItem = (index, position) => {
    if (position === Position.LEFT) {
      setMatchLeftItems((current) => current.filter((_, idx) => index !== idx));
    } else if (position === Position.RIGHT) {
      setMatchRightItems((current) =>
        current.filter((_, idx) => index !== idx),
      );
    }

    // remove all matches that the item was apart of
    removeAnswerMatches(index, position);
    updateXarrow();
  };

  const handleSave = () => {
    const payload = {
      question,
      questionNumber,
      matchLeftItems,
      matchRightItems,
      matchAnswers,
    };
    onSave(payload);
  };

  const openMediaPicker = (answerIndex = null, position) => {
    const showTypes = [mediaTypes.IMAGE, mediaTypes.AUDIO];
    if (!answerIndex) showTypes.push(mediaTypes.VIDEO);

    setMediaPicker({
      ...mediaPicker,
      open: true,
      answerIndex,
      showTypes,
      position,
    });
  };

  const closeMediaPicker = () => {
    setMediaPicker({ ...mediaPicker, open: false });
  };

  const handleChangeMatchAnswer = (index, text, position) => {
    if (position === Position.LEFT) {
      setMatchLeftItems((current) => {
        const newValues = [...current];
        newValues[index].text = text;
        return newValues;
      });
    } else if (position === Position.RIGHT) {
      setMatchRightItems((current) => {
        const newValues = [...current];
        newValues[index].text = text;
        return newValues;
      });
    }
  };

  const insertAsset = (asset) => {
    handleChangeMatchAnswer(
      mediaPicker.answerIndex,
      assetContentTransformer(asset),
      mediaPicker.position,
    );
    closeMediaPicker();
  };

  const addArrow = ({ start, end, leftIdx, rightIdx }) => {
    // check to see if the start and end are the same
    if (start === end) return;

    // check to see if the start and end are already connected
    const isAlreadyConnected = arrows.some(
      ({ props }) => props.start === start && props.end === end,
    );
    if (isAlreadyConnected) return;

    // check to see if start is on the same side as end or vice versa
    const startIdx = getIdxFromId(start);
    const endIdx = getIdxFromId(end);

    if (startIdx && endIdx && startIdx[1] === endIdx[1]) return;

    // check to see if the start or end are already connected to something
    const isStartConnected = arrows.some(
      ({ props }) => props.start === start || props.end === start,
    );
    const isEndConnected = arrows.some(
      ({ props }) => props.start === end || props.end === end,
    );

    if (isStartConnected || isEndConnected) return;

    // if we get here, we can add the arrow
    setArrows([...arrows, { props: { start, end } }]);
    setMatchAnswers((currentMatches) => {
      const newMatches = [
        ...currentMatches,
        {
          leftItem: leftIdx,
          rightItem: rightIdx,
        },
      ];
      return newMatches;
    });
  };

  const removeArrow = (selectedArrow) => {
    if (selectedArrow) {
      setArrows((currentArrows) =>
        currentArrows.filter(
          (arrow) =>
            !(
              arrow.props.start === selectedArrow.props.start &&
              arrow.props.end === selectedArrow.props.end
            ),
        ),
      );
      // We need to remove corresponding answerMatch from array
      removeAnswerMatch(selectedArrow);
      setSelected(null);
    }
  };

  const contextOptions = [
    {
      label: 'Delete',
      onClick: () => removeArrow(selected),
      Icon: <FaTrash />,
      style: {
        color: '#cb4242',
      },
    },
  ];

  return (
    <Layout noBodyPadding>
      {mediaPicker.open && (
        <MediaLibrarySelector
          onSelectMedia={(a) => insertAsset(a)}
          onClose={closeMediaPicker}
          showTypes={mediaPicker.showTypes}
        />
      )}
      {arrows &&
        arrows.map((arrow, i) => (
          <SelectableXArrow
            key={`${arrow.props.start}-${arrow.props.end}${i}`}
            arrow={arrow}
            setSelected={setSelected}
            hasContextMenu={true}
            contextOptions={contextOptions}
          />
        ))}
      <Container>
        <TopContainer>
          <Title>Matching Question</Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>
            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>

        <Flex mb={3} px="5%" py="35px">
          <Box flexGrow={1}>
            <ContentContainer>
              <InputGroup>
                <Label>Question</Label>
                <QuestionField
                  rows={2}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="links flexStart">
                  <Link
                    anchor
                    href={ClassedUpMarkupGuide}
                    name="markup-guide"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Markup guide
                  </Link>
                </div>
              </InputGroup>
              <Flex
                flexDirection="row"
                width="100%"
                justifyContent="space-between"
                mt={5}
              >
                {/* Left Side */}
                <Flex flexDirection="column">
                  <Label alignSelf="center">Left Items</Label>
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {matchLeftItems &&
                      matchLeftItems.length > 0 &&
                      matchLeftItems.map((item, index) => (
                        <div
                          key={`container-matchField-left-idx-${index}`}
                          style={{
                            marginTop: '15px',
                            marginBottom: '15px',
                            width: '390px',
                          }}
                        >
                          <ConnectPointsContainer
                            handler="right"
                            containerId={`container-matchField-left-idx-${index}`}
                            addArrow={addArrow}
                          >
                            <MatchFieldAnswerBox
                              key={`answerbox-matchField-left-idx-${index}`}
                              width={1}
                              display="flex"
                              flexDirection="column"
                              text={item.text}
                              onChange={(e) =>
                                handleChangeMatchAnswer(
                                  index,
                                  e.target.value,
                                  Position.LEFT,
                                )
                              }
                              onSelectAsset={() =>
                                openMediaPicker(index, Position.LEFT)
                              }
                              onRemoveAsset={() =>
                                handleChangeMatchAnswer(
                                  index,
                                  '',
                                  Position.LEFT,
                                )
                              }
                              onDeleteItem={() =>
                                removeItem(index, Position.LEFT)
                              }
                              identifier={Letters[index]}
                            />
                          </ConnectPointsContainer>
                        </div>
                      ))}
                    <AddButton
                      onClick={() => addItem(Position.LEFT)}
                      fullWidth
                      center
                    >
                      Add Left Item
                    </AddButton>
                  </Flex>
                </Flex>
                {/* Right Side */}
                <Flex flexDirection="column">
                  <Label alignSelf="center">Right Items</Label>
                  <Flex flexDirection="column">
                    {matchRightItems &&
                      matchRightItems.length > 0 &&
                      matchRightItems.map((item, index) => (
                        <div
                          key={`container-matchField-right-idx-${index}`}
                          style={{
                            marginTop: '15px',
                            marginBottom: '15px',
                            width: '390px',
                          }}
                        >
                          <ConnectPointsContainer
                            handler="left"
                            containerId={`container-matchField-right-idx-${index}`}
                            addArrow={addArrow}
                          >
                            <MatchFieldAnswerBox
                              key={`answerbox-matchField-right-idx-${index}`}
                              width={1}
                              display="flex"
                              flexDirection="column"
                              text={item.text}
                              onChange={(e) =>
                                handleChangeMatchAnswer(
                                  index,
                                  e.target.value,
                                  Position.RIGHT,
                                )
                              }
                              onSelectAsset={() =>
                                openMediaPicker(index, Position.RIGHT)
                              }
                              onRemoveAsset={() =>
                                handleChangeMatchAnswer(
                                  index,
                                  '',
                                  Position.RIGHT,
                                )
                              }
                              onDeleteItem={() => {
                                removeItem(index, Position.RIGHT);
                              }}
                              identifier={`${index + 1}`}
                            />
                          </ConnectPointsContainer>
                        </div>
                      ))}
                    <AddButton
                      onClick={() => addItem(Position.RIGHT)}
                      fullWidth
                      center
                    >
                      Add Right Item
                    </AddButton>
                  </Flex>
                </Flex>
              </Flex>
            </ContentContainer>
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default React.memo(TestLibraryQuestionsMatchingLines);
