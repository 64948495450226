import React from 'react';
import PropTypes from 'prop-types';
import { typography, space, color, layout, size } from 'styled-system';
import styled from 'styled-components/macro';
import MatSelect from '@material-ui/core/Select';
import { lightThinBorder, borderRadius } from '../../styleTokens';
import { MenuItem, FormControl, InputLabel } from '@material-ui/core';

const StyledLabel = styled(InputLabel)`
  text-transform: capitalize;
  letter-spacing: 0.8px;
  ${typography};
  ${space};
  ${color};
  ${layout};
  ${size};
`;

export const StyledFormControl = styled(FormControl)`
  ${typography};
  ${space};
  ${color};
  ${layout};
  ${size};
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(MatSelect)`
  ${lightThinBorder};
  ${borderRadius};
  font-weight: 400;
  box-sizing: border-box;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.89px;
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  color: ${({ theme }) => theme.colors.darkgrey};
  outline: none;
  ::placeholder {
    font-style: italic;
  }
  ${typography};
  ${space};
  ${color};
  ${layout};
  ${size};
  appearance: none;
  text-transform: capitalize;
  option {
    text-transform: capitalize;
  }
`;

export const SelectField = ({ options, label, id, ...rest }) => {
  const menuItems = options.map(({ text, value }) => (
    <MenuItem value={value} key={`${value}-${text}`} centerRipple={false}>
      {text}
    </MenuItem>
  ));
  return (
    <StyledFormControl my={3} {...rest}>
      <StyledLabel
        fontSize={[3, 2, 3]}
        fontWeight="bold"
        htmlFor={id}
        color="darkgrey"
      >
        {label}
      </StyledLabel>
      {menuItems}
    </StyledFormControl>
  );
};

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, text: PropTypes.string }),
  ),
  label: PropTypes.string,
  id: PropTypes.string,
};

export default SelectField;
