import React from 'react';
import { CreateQuestionProvider, useCreateQuestion } from './createQuestion';
import { DeleteQuestionProvider, useDeleteQuestion } from './deleteQuestion';
import {
  // GetQuestionProvider,
  useGetQuestion,
} from './question';
import { QuestionsProvider, useQuestions } from './questions';
import { UpdateQuestionProvider, useUpdateQuestion } from './updateQuestion';

function QuestionProvider({ children }) {
  return (
    <DeleteQuestionProvider>
      {/* <GetQuestionProvider> */}
      <QuestionsProvider>
        <UpdateQuestionProvider>
          <CreateQuestionProvider>{children}</CreateQuestionProvider>
        </UpdateQuestionProvider>
      </QuestionsProvider>
      {/* </GetQuestionProvider> */}
    </DeleteQuestionProvider>
  );
}

export {
  QuestionProvider,
  useCreateQuestion,
  useDeleteQuestion,
  useGetQuestion,
  useQuestions,
  useUpdateQuestion,
};
