import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: ${({ color }) => color || '#2cabe0'};

  font-size: 28px;
  font-weight: 200;
  color: #ffffff;
`;
