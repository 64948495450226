import React from 'react';
import ConnectPointsWrapper from './ConnectPointsWrapper';

const containerStyle = {
  border: '1px solid black',
  position: 'relative',
  padding: '20px 20px',
  zIndex: 0,
};

// id pattern needs to have:
// right-idx-${index}
// left-idx-${index}
export const getIdxFromId = (id) => {
  const regex = /(right|left)-idx-(\d)/;
  const found = id.match(regex);
  return found; // [fullMatch, position, index]
};

const ConnectPointsContainer = ({
  handler,
  addArrow,
  containerId,
  children,
}) => {
  return (
    <div
      id={containerId}
      style={containerStyle}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        const startId = e.dataTransfer.getData('arrow');

        if (startId === containerId) {
          // do nothing
        } else {
          let leftIdx = null;
          let rightIdx = null;

          const startIdx = getIdxFromId(startId);
          const endIdx = getIdxFromId(containerId);

          if (startIdx && startIdx[1] === 'left') {
            leftIdx = startIdx[2];
          } else if (startIdx && startIdx[1] === 'right') {
            rightIdx = startIdx[2];
          }

          if (endIdx && endIdx[1] === 'left') {
            leftIdx = endIdx[2];
          } else if (endIdx && endIdx[1] === 'right') {
            rightIdx = endIdx[2];
          }

          const refs = {
            start: startId,
            end: containerId,
            leftIdx: Number(leftIdx),
            rightIdx: Number(rightIdx),
          };

          addArrow(refs);
        }
      }}
    >
      {children}
      {/* Passing the `containerRef` to `ConnectPointsWrapper` to draw line from point */}
      <ConnectPointsWrapper {...{ containerId, handler }} />
    </div>
  );
};

export default ConnectPointsContainer;
