import React from 'react';
import { VerificationLayout } from '../../Layout';

export const EmailVerify = () => {
  return (
    <VerificationLayout
      title="Verify your email"
      subtitle="You are almost there! We have sent a verification email to your email address."
    />
  );
};
