import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Box } from 'rebass';
import { Button } from './Button';
import styled from 'styled-components/macro';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { uuid } from '../../utils/uuid';

const DropDownList = styled(Box)`
  color: darkgrey;
  cursor: default;
  position: absolute;
  background: white;
  list-style-type: none;
  top: ${({ offsetTop }) => (offsetTop ? offsetTop : '5px')};
  right: ${({ offsetRight }) => (offsetRight ? offsetRight : 'auto')};
  left: ${({ offsetLeft }) => (offsetLeft ? offsetLeft : 'auto')};
  border-radius: 3px;
  min-width: 300px;
  font-size: 18px;
  padding: 0px;
  box-shadow: 0 0 0 1px rgba(89, 105, 128, 0.1),
    0 3px 20px 0 rgba(89, 105, 128, 0.3), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  > :not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  a {
    display: flex;
    align-items: center;
  }
  svg {
    margin-right: 20px;
  }
`;

const StyledButton = styled(Button)`
  padding: 7px 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const DropdownMenu = props => {
  const [open, setOpen] = useState(false);
  const { title, offsetTop, offsetRight, offsetLeft, ...rest } = props;
  return (
    <Flex style={{ position: 'relative' }}>
      <StyledButton {...rest} onClick={() => setOpen(!open)}>
        <Text marginRight="10px">{title}</Text>{' '}
        {open ? <FaAngleUp /> : <FaAngleDown />}
      </StyledButton>
      {open && (
        <DropDownList
          as="ul"
          offsetTop={offsetTop}
          offsetRight={offsetRight}
          offsetLeft={offsetLeft}
        >
          {props.children.map((elem, index) => (
            <Box key={uuid(index)} as="li" padding="10px">
              {elem}
            </Box>
          ))}
        </DropDownList>
      )}
    </Flex>
  );
};

DropdownMenu.propTypes = {
  title: PropTypes.string,
  offsetTop: PropTypes.string,
  offsetLeft: PropTypes.string,
  offsetRight: PropTypes.string,
};
export default DropdownMenu;
