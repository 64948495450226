/**
 * turns an object in to an array
 * useful for object based enums that you want as a list
 * @param {Object} map
 * @returns {Array}
 */
export const mapToArray = (map) =>
  Object.keys(map).reduce((array, key) => array.concat(map[key]), []);

export const PERSONA_ROLES = {
  teacher: 'teacher',
  student: 'student',
};

export const LOCAL_STORAGE = {
  AUTH: 'auth',
};

export const UPLOAD_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
};

export const UPLOAD_FOLDER = 'media';

export const QUESTION_TYPES = {
  text: 'text',
  image: 'image',
  piano: 'piano',
  audio: 'audio',
  video: 'video',
  match: 'match',
  cloze: 'cloze',
};

// this is for the content picker in the question editor
export const QUESTION_CONTENT_TYPES = {
  text: 'text',
  staff: 'staff', // musical staff \staff(g2,1,3/4,C4/1)
  image: 'image',
  audio: 'audio',
  video: 'video',
  math: 'math', // \latex(y=sqrt(sin(x))
};

export const DEFAULT_QUESTION_CONTENT_TYPE = QUESTION_CONTENT_TYPES.text;

export const PAGE_TYPES = {
  intro: 'intro',
  lessonPage: 'lessonPage',
  flashCards: 'flashCards',
  testSection: 'testSection',
};

export const SUB_PAGE_TYPES = {
  testQuestion: 'testQuestion',
};

// not to be confused with the above page types, the above ones are used to render the page type options
// in the package editor side bar, the below ones are the 'type' field assigned to a created/updated page
export const PAGE_BUILDER_TYPES = {
  flashCards: 'flashCards',
  html: 'html',
  pdf: 'pdf',
  questions: 'questions',
};

export const QUESTION_PAGE_BUILDER_QUESTION_ORDER_TYPES = {
  ADAPTIVE: 'adaptive',
  NUMBER: 'number',
  RANDOM: 'random',
  RANDOM_PROP_DIST: 'random-prop-dist',
  RANDOM_EVEN_DIST: 'random-even-dist',
};

export const QUESTION_PAGE_BUILDER_QUESTION_ORDER_TYPES_LIST = mapToArray(
  QUESTION_PAGE_BUILDER_QUESTION_ORDER_TYPES,
);

export const QUESTION_PAGE_BUILDER_QUESTION_TEST_TYPES = {
  TIMED: 'timed',
  UNTIMED: 'untimed',
};

export const QUESTION_PAGE_BUILDER_QUESTION_TEST_TYPES_LIST = mapToArray(
  QUESTION_PAGE_BUILDER_QUESTION_TEST_TYPES,
);

export const EDITOR_MODES = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
};

export const QUESTION_VIEW_FORMATS = {
  horizontal: 'horizontal',
  vertical: 'vertical',
};

export const QUESTION_FORM_CONFIG = {
  MIN_DIFFICULTY: 1,
  MAX_DIFFICULTY: 10,
  MC_ORDER_FORMAT: {
    // list out quetsions abcd, or 1234
    byLetter: ['a', 'b', 'c', 'd'],
    byNumber: [1, 2, 3, 4],
  },
  MC_ANSWERS: 4, // number of multiple choice answers
};

export let QUESTION_DIFFICULTIES = [];

for (
  let i = QUESTION_FORM_CONFIG.MIN_DIFFICULTY;
  i <= QUESTION_FORM_CONFIG.MAX_DIFFICULTY;
  i++
) {
  QUESTION_DIFFICULTIES.push({ value: i, text: i.toString() });
}

// initial values
export const MC_FORM_SCHEMA = {
  answers: [
    {
      answer: '',
      isCorrect: false,
    },
    {
      answer: '',
      isCorrect: false,
    },
  ],
  multiAnswer: false,
  difficulty: QUESTION_FORM_CONFIG.MIN_DIFFICULTY,
  question: '',
  format: QUESTION_VIEW_FORMATS.horizontal,
  type: QUESTION_TYPES.text,
  text: '',
  questionNumber: null,
  id: null,
};

export const QUESTION_PAGE_FORM_SCHEMA = {
  title: '',
  header: '',
  testType: QUESTION_PAGE_BUILDER_QUESTION_TEST_TYPES_LIST[0],
  duration: 30,
  questionOrder: QUESTION_PAGE_BUILDER_QUESTION_ORDER_TYPES_LIST[0],
  pageNumber: 0,
  id: null,
  type: PAGE_BUILDER_TYPES.questions,
};

export const STUDENT_FORM_SCHEMA = {
  students: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

// there are some places where many droppable contexts exist and so we need to discern between them
export const COMMON_DROPPABLE_IDS = {
  PACKAGE_BUILDER_TEST_SET_RECEIVER: 'Package.Builder.Test.Set.Receiver',
  PACKAGE_BUILDER_TEST_SET_EMITTER: 'Package.Builder.Test.Set.Emitter',
  PACKAGE_BUILD_PAGE_EMITTER: 'Package.Builder.Page.Emitter',
  PACKAGE_BUILD_PAGE_RECEIVER: 'Package.Builder.Page.Receiver',
  PACKAGE_BUILDER_STUDENT_EMITTER: 'Package.Builder.student.Emitter',
  PACKAGE_BUILDER_STUDENT_RECIEVER: 'Package.Builder.student.Receiver',
};

export const COMMON_DRAGGABLE_IDS = {
  PACKAGE_BUILDER_TEST_SET_ITEM: 'Package.Builder.Test.Set.Item',
  PACKAGE_BUILDER_PAGE_TYPE: 'Package.Builder.Page.Type',
  PACKAGE_BUILDER_STUDENT: 'Package.Builder.Student',
};
