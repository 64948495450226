import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const AddQuestionGroupToPage = React.createContext();

function AddQuestionGroupToPageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const addQuestionGroupToPage = useCallback(
    async (id, questionGroupId, questionGroupNumber) => {
      try {
        setLoading(true);
        await axiosInstance.put(
          `/pages/${id}/question-groups/${questionGroupId}`,
          { questionGroupNumber },
        );

        return {
          success: true,
          error: null,
        };
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setErrorMessage(errorMessage);
        return { success: false, errorMessage };
      } finally {
        setLoading(false);
        setHasLoaded(true);
      }
    },
    [],
  );

  const value = {
    loading,
    addQuestionGroupToPage,
    errorMessage,
    hasLoaded,
  };

  return (
    <AddQuestionGroupToPage.Provider value={value}>
      {children}
    </AddQuestionGroupToPage.Provider>
  );
}

/**
 *
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  addQuestionGroupToPage: (id: string, questionGroupId: string, questionGroupNumber: number) => Promise<{ success: boolean; errorMessage: string; }>
 *  errorMessage: string
 * }}
 */

function useAddQuestionGroupToPage() {
  const context = React.useContext(AddQuestionGroupToPage);

  if (context === undefined) {
    throw new Error(
      'useAddQuestionGroupToPage must be used within a AddQuestionGroupToPageProvider',
    );
  }

  return context;
}

export { AddQuestionGroupToPageProvider, useAddQuestionGroupToPage };
