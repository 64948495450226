import React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { FaSpinner } from 'react-icons/fa';
import { Flex } from 'rebass';
import { useAddQuestionGroupToPage } from '../../../../../../../contexts/api/question-group/addQuestionGroupToPage';
import { useGetQuestionGroupsInPage } from '../../../../../../../contexts/api/question-group/getQuestionGroupsInPage';
import { useQuestionGroups } from '../../../../../../../contexts/api/question-group/questionGroups';
import { PackageTestPageStoreContext } from '../../../../../../../contexts/reducers/package/TestPage';
import { reorder } from '../../../../../../../utils/array';
import { boardMove } from '../../../../../../../utils/dnd';
import DraggableItem from '../../../../../../UI/DraggableItem';
import { useSnackbar } from '../../../../../../../contexts/ui';
import {
  testPageDroppableId,
  testPageSidebarDroppableId,
} from '../../constants';
import {
  DraggableContainer,
  DropArea,
  DropAreaContainer,
  ItemsContainer,
  Subtitle,
  Title as DropTitle,
} from '../../styled';
import QuestionGroup from './QuestionGroup';
import {
  QuestionGroupSidebar,
  QuestionGroupContainer,
  QuestonGroupIconContainer,
} from './styled';
import { differenceBy } from 'lodash';
import { useDeleteQuestionGroupFromPage } from '../../../../../../../contexts/api/question-group/deleteQuestionGroupFromPage';
import { useReorderQuestionGroupsInPage } from '../../../../../../../contexts/api/question-group/reorderQuestionGroupsInPage';
//import styled from 'styled-components/macro';

const PackageLibraryPageTestPageQuestionGroups = () => {
  const {
    questionGroups: allQuestionGroups,
    loading: getQuestionGroupsLoading,
    error: getQuestionGroupsError,
  } = useQuestionGroups();
  const {
    reorderQuestionGroupsInPage,
    error: reorderQuestionGroupsInPageError,
  } = useReorderQuestionGroupsInPage();
  const { showSnackbar } = useSnackbar();
  const {
    state: { questionGroups: draggedItems, id },
    update,
  } = useContext(PackageTestPageStoreContext);

  const setDraggedItems = useCallback(
    (questionGroups) => {
      update({ questionGroups: questionGroups });
    },
    [update],
  );

  const draggedItemIds = useMemo(() => {
    if (draggedItems) {
      return draggedItems.map(({ id }) => id);
    }
    return [];
  }, [draggedItems]);

  useEffect(() => {
    const handleReorder = async () => {
      if (id && draggedItemIds.length) {
        reorderQuestionGroupsInPage(id, draggedItemIds);
      }
    };
    if (id) {
      handleReorder();
    }
  }, [draggedItemIds, id, reorderQuestionGroupsInPage]);
  // question groups available in side bar is the difference between dragged items and all the questions available
  const questionGroups = useMemo(() => {
    return differenceBy(allQuestionGroups, draggedItems, 'id');
  }, [allQuestionGroups, draggedItems]);

  const {
    getQuestionGroupsInPage,
    loading: getQuestionGroupsInPageLoading,
    error: getQuestionGroupsInPageError,
  } = useGetQuestionGroupsInPage();

  const { addQuestionGroupToPage, error: addQuestionGroupError } =
    useAddQuestionGroupToPage();

  const {
    deleteQuestionGroupFromPage,
    loading: deleteQuestionGroupLoading,
    error: deleteQuestionGroupError,
  } = useDeleteQuestionGroupFromPage();

  useEffect(() => {
    if (id) {
      getQuestionGroupsInPage(id).then(({ questionGroups }) => {
        setDraggedItems(questionGroups);
      });
    }
  }, [getQuestionGroupsInPage, id, setDraggedItems]);

  const loading =
    getQuestionGroupsInPageLoading ||
    getQuestionGroupsLoading ||
    deleteQuestionGroupLoading;
  const apiError =
    getQuestionGroupsInPageError ||
    getQuestionGroupsError ||
    addQuestionGroupError ||
    deleteQuestionGroupError ||
    reorderQuestionGroupsInPageError;

  useEffect(() => {
    if (apiError) {
      showSnackbar(apiError, 'error');
    }
  }, [apiError, showSnackbar]);

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    const transformCb = (el) => ({ ...el });
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === testPageSidebarDroppableId &&
      source.droppableId !== testPageSidebarDroppableId
    ) {
      // removing a question group
      const moveResult = boardMove(
        draggedItems,
        questionGroups,
        source,
        destination,
        transformCb,
      );
      setDraggedItems(moveResult[testPageDroppableId]);

      const questionGroupInPage = draggedItems[source.index];
      if (id) {
        await deleteQuestionGroupFromPage(id, questionGroupInPage.id);
      }
    }

    if (destination.droppableId === testPageDroppableId) {
      // adding a question group
      if (source.droppableId !== testPageDroppableId) {
        const moveResult = boardMove(
          questionGroups,
          draggedItems,
          source,
          destination,
          transformCb, // when creating a new item in the droppable, perserve question group id
        );
        const questionGroup = questionGroups[source.index];
        setDraggedItems(moveResult[testPageDroppableId]);
        if (id) {
          await addQuestionGroupToPage(id, questionGroup.id, source.index);
        }
      } else {
        // reordering
        const items = reorder(draggedItems, source.index, destination.index);
        setDraggedItems(items);
      }
    }
  };

  // const h2 = styled.h2`
  //   text-align: left;
  //   margin: 0 0 20px;
  //   font-family: Roboto;
  //   font-size: 24px;
  //   font-weight: normal;
  //   font-stretch: normal;
  //   font-style: normal;
  //   line-height: normal;
  //   letter-spacing: normal;
  //   color: #424242;
  // `;

  const EmptySidebarMessages = ({ isDraggingOver }) => {
    if (getQuestionGroupsError) {
      return <p>Not able to get Question Groups</p>;
    }
    if (allQuestionGroups.length === 0) {
      return <p>No Question Groups Available</p>;
    }
    if (questionGroups.length === 0 && !isDraggingOver) {
      return <p>All Questions Groups Added</p>;
    }
    return null;
  };

  return (
    <Flex height="calc(100vh - 350px)">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={testPageSidebarDroppableId}>
          {(provided, snapshot) => (
            <QuestionGroupSidebar>
              <Flex justifyContent="space-between" alignItems="center" mb={10}>
                <h2 className="title">Question Groups</h2>
                {loading && <FaSpinner />}
              </Flex>
              <div
                style={{
                  overflow: 'auto',
                  height: '100%',
                  padding: '5px 15px 0 5px',
                }}
              >
                <div ref={provided.innerRef}>
                  {questionGroups.length > 0 ? questionGroups.map((q, index) => (
                    <DraggableItem
                      id={`${testPageSidebarDroppableId}-${q.id}`}
                      index={index}
                      key={q.id}
                      DragContentContainer={({ children }) => children}
                      DragIconContainer={QuestonGroupIconContainer}
                      DragContainer={QuestionGroupContainer}
                    >
                      <QuestionGroup {...q} numQuestions={q.numQuestions} />
                    </DraggableItem>
                  )) : (
                    <EmptySidebarMessages
                      isDraggingOver={snapshot.isDraggingOver}
                    />
                  )}
                  {provided.placeholder}
                </div>
              </div>
            </QuestionGroupSidebar>
          )}
        </Droppable>
        <div style={{ backgroundColor: '#f7f7f7', width: '100%', height: '100%', marginLeft: '10px' }}>
          <Droppable droppableId={testPageDroppableId}>
            {(provided, snapshot) => (
              <DropAreaContainer style={{ margin: '0 auto' }}>
                <ItemsContainer ref={provided.innerRef}>
                  <DraggableContainer>
                    {draggedItems.map((item, index) => (
                      <DraggableItem
                        key={item.id}
                        id={`${testPageDroppableId}-${item.id}`}
                        index={index}
                        DragContentContainer={({ children }) => children}
                        DragIconContainer={QuestonGroupIconContainer}
                        DragContainer={QuestionGroupContainer}
                        large
                      >
                        <QuestionGroup {...item} />
                      </DraggableItem>
                    ))}
                  </DraggableContainer>
                  {snapshot.isDraggingOver ? null : (
                    <DropArea>
                      <DropTitle>Drag & Drop Page Option</DropTitle>
                      <Subtitle>Then, click to customize</Subtitle>
                    </DropArea>
                  )}
                  {provided.placeholder}
                </ItemsContainer>
              </DropAreaContainer>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </Flex>
  );
};

export default PackageLibraryPageTestPageQuestionGroups;
