import routes from '../routes';

export const MAIN_NAV_LINKS = [
  {
    to: routes.client.packages,
    text: 'Packages',
    testId: 'home-packages',
  },
  {
    to: routes.client.students,
    text: 'Students',
    testId: 'home-students',
  },
  {
    to: routes.client.testLibrary,
    text: 'Question Groups',
    testId: 'home-test-library',
  },
  {
    to: routes.client.mediaLibrary,
    text: 'Media Library',
    testId: 'home-media-library',
  },
];

export const SETTINGS_LINKS = [
  {
    to: routes.client.profile,
    exact: true,
    text: 'Profile',
    testId: 'settings-profile',
  },
  {
    to: routes.client.billingSettings,
    text: 'Billing',
    testId: 'settings-billing',
  },
  {
    to: routes.client.changePassword,
    text: 'Password',
    testId: 'settings-password',
  },
];
