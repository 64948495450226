import { COLORS } from './theme';
// reusable style tokens that can be placed within styled-components or emotion js
// usage is
/**
 * import {containerCoversAbsolutely} from '..';
 *
 * const Div = styled.div`
 *  ${containerCoversAbsolutely};
 *  z-index: 1000;
 * `
 *
 * const Foo = () => (<Div>hello world!</Div>);
 */
export const containerCoversAbsolutely = `
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute !important;
`;

export const boxShadow = `
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
`;

export const lightThinBorder = `
  border: 1px solid ${COLORS.lightgrey};
`;

export const borderRadius = `
  border-radius: 6px;
`;

export const dashedBorder = `
  border: 3px dashed ${COLORS.lightgrey};
`;

export const headerDimension = '5em';
export const headerHeight = `min-height: ${headerDimension}`;
// used when required to offset a fixed or absolute container below the default header
export const absoluteContainerOffset = `top: ${headerDimension}`;

export const transitionAllEaseInOut = `
  transition: all .25s ease-in-out;
`;

export const idealWidthHeightRatio = `
  max-width: 372px;
  min-height: 220px;
`;
