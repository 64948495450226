import styled from 'styled-components/macro';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0 50px;
  height: 100px;
  background-color: #ffffff;
  border-bottom: solid 1px #b6b6b6;
`;

export const TabsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 480px;
  justify-content: space-between;
`;

export const LeftSideContainer = styled.div`
  max-width: 380px;
  flex-grow: 1;
  margin-right: 35px;
  width: 33%;
  left: 100px;
`;

export const CancelButton = styled.div`
  color: #2cabe0;
  font-size: 16px;
  cursor: pointer;
`;

export const DeleteButton = styled.div`
  margin-left: 6px;
  user-select: none;
  margin-top: 2px;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color-red);
`;
