import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const DeleteAssetsContext = React.createContext();

function DeleteAssetsProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState(null);

  const deleteAssets = useCallback(async (assetsToDelete) => {
    try {
      setLoading(true);

      await Promise.all(assetsToDelete.map(deleteSingleAsset));

      return {
        success: true,
        error: null,
      };
    } catch (error) {
      setError(error);
      return {
        success: false,
        error,
      };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    hasLoaded,
    error,
    deleteAssets,
  };

  return (
    <DeleteAssetsContext.Provider value={value}>
      {children}
    </DeleteAssetsContext.Provider>
  );
}

async function deleteSingleAsset(asset) {
  const response = await axiosInstance({
    method: 'DELETE',
    url: `/assets/${asset.id}`,
  });
  return response;
}

/**
 * @returns {{
 *  error: Error,
 *  deleteAssets: () => Promise<{ success: boolean; error: Error; }>,
 *  hasLoaded: boolean,
 *  loading: boolean,
 * }}
 */
function useDeleteAssets() {
  const context = React.useContext(DeleteAssetsContext);

  if (context === undefined) {
    throw new Error(
      'useDeleteAssets must be used within a DeleteAssetsProvider',
    );
  }

  return context;
}

export { DeleteAssetsProvider, useDeleteAssets };
