import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Flex, Box } from 'rebass';
import { InputField, SaveButton } from '../UI';
import { useProfile } from '../../contexts/api/settings';
import { useSnackbar } from '../../contexts/ui';

export const TEST_IDS = {
  container: 'settings-profile',
  email: 'settings-email',
  firstName: 'settings-first-name',
  lastName: 'settings-last-name',
  submit: 'settings-submit',
};

const Div = styled.div`
  position: relative;
  margin-bottom: 10px;  
`;

const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.blue};
  text-align: left;
  margin-bottom: 40px;
`

const Profile = ({ email, firstName: initialFirstName, lastName: initialLastName, userId }) => {
  const { updateProfile, loading } = useProfile()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { showSnackbar } = useSnackbar()

  useEffect(() => {
    setFirstName(initialFirstName)
    setLastName(initialLastName)
  }, [initialFirstName, initialLastName])

  const canSubmit = () => {
    const hasChanged = firstName.trim() !== initialFirstName || lastName.trim() !== initialLastName
    const isNotEmpty = firstName.trim() !== '' && lastName.trim() !== ''
    return (isNotEmpty && hasChanged) && !loading
  }

  const handleSubmit = async () => {
    const { success, message } = await updateProfile(userId, { firstName, lastName })
    const variant = success ? 'success' : 'error';
    showSnackbar(message, variant)
  }

  return (
    <Flex column data-testid={TEST_IDS.container}>
      <Box>
        <Heading>Profile</Heading>
        <Div>
          <InputField
            label="First Name"
            id="signup-first-name"
            width={400}
            data-testid={TEST_IDS.firstName}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Div>
        <Div>
          <InputField
            label="Last Name"
            id="signup-last-name"
            width={400}
            data-testid={TEST_IDS.lastName}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Div>

        <Div>
          <InputField
            label="email"
            data-testid={TEST_IDS.email}
            id="profile-email"
            width={400}
            defaultValue={email}
            disabled
          />
        </Div>
        <SaveButton
          noIcon
          variant="primary"
          mt={3}
          disabled={!canSubmit()}
          data-testid={TEST_IDS.submit}
          onClick={handleSubmit}
        >
          Save Changes
        </SaveButton>
      </Box>
    </Flex>
  )
};

export default Profile;
