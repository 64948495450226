// AUTHENTICATION REDUCER
import { LOCAL_STORAGE } from '../../constants/application';
import { getDataFromLocalStorage } from '../../utils/localStorage';
import * as actionTypes from './actionTypes';

const tokens = getDataFromLocalStorage(LOCAL_STORAGE.AUTH) || {}

export const initialState = {
  error: null,
  loading: false,
  tokensPresent: tokens.hasOwnProperty('token') && tokens.hasOwnProperty('refreshToken'),
  user: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload.updates } }
    case actionTypes.REGISTER_START:
      return { ...state, loading: true, error: null };
    case actionTypes.REGISTER_FAIL:
      return { ...state, loading: false, error: action.payload.message };
    case actionTypes.REGISTER_SUCCESS:
      return { user: action.payload.user, loading: false, error: null };
    case actionTypes.LOGIN_START:
      return { ...state, loading: true, error: null };
    case actionTypes.LOGIN_FAIL:
      return { ...state, loading: false, error: action.payload.message };
    case actionTypes.LOGIN_SUCCESS:
      return { user: action.payload.user, loading: false, error: null };
    case actionTypes.FORGOT_PASSWORD_START:
      return { ...state, loading: true, error: null };
    case actionTypes.FORGOT_PASSWORD_FAIL:
      return { ...state, loading: false, error: action.payload.message };
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return { loading: false, error: null };
    case actionTypes.TOKEN_CHECK_START:
      return { ...state, loading: true, error: null }
    case actionTypes.TOKEN_CHECK_FAIL:
      return { ...state, loading: false, error: action.payload.message, tokensPresent: false };
    case actionTypes.TOKEN_CHECK_SUCCESS:
      return { ...state, user: action.payload.user, loading: false, error: null, tokensPresent: true };
    default:
      return state;
  }
};

export default reducer;
