import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const DeletePageContext = React.createContext();

function DeletePageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const deletePage = useCallback(async (pageId) => {
    try {
      setLoading(true);
      const response = await axiosInstance.delete(`/pages/${pageId}`);

      return {
        page: getPageFromResponse(response),
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    deletePage,
    errorMessage,
  };

  return (
    <DeletePageContext.Provider value={value}>
      {children}
    </DeletePageContext.Provider>
  );
}

/**
 * @returns {{
 *  loading: boolean,
 *  deletePage: (pageId: String) => Promise<{ success: boolean; errorMessage: string; }>
 *  errorMessage: string
 * }}
 */

function useDeletePage() {
  const context = React.useContext(DeletePageContext);

  if (context === undefined) {
    throw new Error('useDeletePage must be used within a DeletePageProvider');
  }

  return context;
}

function getPageFromResponse(response) {
  if (!response.data) {
    return null;
  }

  const pkg = response.data;
  return {
    id: pkg.id,
    name: pkg.name,
  };
}

export { DeletePageProvider, useDeletePage };
