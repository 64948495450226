import { Flex, Box } from 'rebass';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Layout from '../../../Layout';
import React, { useState } from 'react';
import { AddButton, CancelButton, SaveButton, Button } from '../../../UI/Button';
import { Link } from '../../../UI';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  Label,
  InputGroup,
  QuestionField,
} from './styled';
import { Letters } from '../../../../contexts/api/question/common';
import OrderFieldAnswerBox from './OrderField';
import MediaLibrarySelector from '../../../MediaLibrary/Selector';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import { FaTrash } from 'react-icons/fa';
import { types as mediaTypes } from '../../../MediaLibrary/Media';
import { assetContentTransformer } from '../../../../utils/mediaLibrary';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf';

const grid = 8;

const getListStyle = (isDraggingOver) => ({
  border: '1px solid #d3d3d3',
  padding: grid,
  borderColor: isDraggingOver ? '#d3d3d3' : '#ededed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '8px',
  overflow: 'hidden',
});
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,

  // change background colour if dragging
  background: isDragging ? '#d3d3d3' : '#ededed',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const TestLibraryQuestionsOrder = ({
  onCancel = () => {},
  onDelete = () => {},
  onSave = () => {},
  questionNumber,
  question: initialQuestion,
  showButton = false,
  orderItems: initialOrderItems = [],
  orderAnswers: initialOrderAnswers = [],
  onNext,
  onPrevious
}) => {
  const initialIndex = initialOrderItems.length ? initialOrderItems.length : 0;

  const [mediaPicker, setMediaPicker] = useState({
    open: false,
    answerIndex: null,
    showTypes: [],
    position: null,
  });

  const [idx, setIdx] = useState(initialIndex);
  const [question, setQuestion] = useState(initialQuestion);
  const [orderItems, setOrderItems] = useState(initialOrderItems);
  const [orderAnswers, setOrderAnswers] = useState(initialOrderAnswers);

  // Using the index values in the orderAnswers array. Create a new array with the correct order of the items
  const sortedItems = orderAnswers.map((order) => {
    return orderItems[order];
  });

  const [orderedItems, setOrderedItems] = useState(sortedItems ?? orderItems);

  const handleSave = () => {
    // set the orderAnswers array to the indexes of the items
    const newOrderAnswers = orderedItems.map((item) => {
      return orderItems.findIndex((orderItem) => orderItem.id === item.id);
    });

    setOrderAnswers(newOrderAnswers);

    const payload = {
      question,
      questionNumber,
      orderItems,
      orderAnswers: newOrderAnswers,
    };
    onSave(payload);
  };

  const handleAddOption = () => {
    setOrderItems([...orderItems, { id: idx, text: '' }]);
    setOrderedItems([...orderedItems, { id: idx, text: '' }]);
    setIdx(idx + 1);
  };

  const handleRemoveOption = (id) => {
    const index = orderItems.findIndex((item) => item.id === id);
    const newOrderItems = [...orderItems];
    newOrderItems.splice(index, 1);
    setOrderItems(newOrderItems);
    setOrderedItems(newOrderItems);
  };

  const handleOptionChange = (id, value) => {
    const index = orderItems.findIndex((item) => item.id === id);
    const newOrderItems = [...orderItems];
    newOrderItems[index].text = value;
    setOrderItems(newOrderItems);
  };

  const openMediaPicker = (answerIndex = null, position) => {
    const showTypes = [mediaTypes.IMAGE, mediaTypes.AUDIO];
    if (!answerIndex) showTypes.push(mediaTypes.VIDEO);

    setMediaPicker({
      ...mediaPicker,
      open: true,
      answerIndex,
      showTypes,
      position,
    });
  };

  const closeMediaPicker = () => {
    setMediaPicker({ ...mediaPicker, open: false });
  };

  const insertAsset = (asset) => {
    handleOptionChange(mediaPicker.answerIndex, assetContentTransformer(asset));
    closeMediaPicker();
  };

  const redorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === 'order-items') {
      const newOrderItems = redorder(
        orderItems,
        result.source.index,
        result.destination.index,
      );
      setOrderItems(newOrderItems);
    } else if (result.source.droppableId === 'ordered-items') {
      const newOrderedItems = redorder(
        orderedItems,
        result.source.index,
        result.destination.index,
      );
      setOrderedItems(newOrderedItems);
    }
  };

  return (
    <Layout noBodyPadding>
      {mediaPicker.open && (
        <MediaLibrarySelector
          onSelectMedia={(a) => insertAsset(a)}
          onClose={closeMediaPicker}
          showTypes={mediaPicker.showTypes}
        />
      )}
      <Container>
        <TopContainer>
          <Title>Order Question</Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>
            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>

        <Flex mb={3} px="5%" py="35px">
          <Box flexGrow={1}>
            <ContentContainer>
              <InputGroup>
                <Label>Question</Label>
                <QuestionField
                  rows={2}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="links flexStart">
                  <Link
                    anchor
                    href={ClassedUpMarkupGuide}
                    name="markup-guide"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Markup guide
                  </Link>
                </div>
              </InputGroup>
              <Flex
                flexDirection="column"
                width="100%"
                justifyContent="space-between"
                mt={5}
              >
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Flex flexDirection="column">
                    <div>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={3}
                      >
                        <h3>Display Order</h3>
                        <div>
                          <Label>Drag and drop to reorder</Label>
                        </div>
                        <AddButton onClick={handleAddOption} center>
                          Add Item
                        </AddButton>
                      </Flex>
                      <Droppable
                        droppableId="order-items"
                        direction="horizontal"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {orderItems.map((item, index) => {
                              return (
                                <Draggable
                                  draggableId={`order-${item.id}`}
                                  index={index}
                                  key={`key-order-${item.id}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                      )}
                                    >
                                      <OrderFieldAnswerBox
                                        key={`answerbox-orderField-idx-${index}`}
                                        width={1}
                                        display="flex"
                                        flexDirection="column"
                                        text={item.text}
                                        onChange={(e) =>
                                          handleOptionChange(
                                            item.id,
                                            e.target.value,
                                          )
                                        }
                                        onSelectAsset={() =>
                                          openMediaPicker(item.id)
                                        }
                                        onRemoveAsset={() =>
                                          handleOptionChange(item.id, '')
                                        }
                                        onDeleteItem={() =>
                                          handleRemoveOption(item.id)
                                        }
                                        identifier={Letters[item.id]}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                    <div style={{ marginBottom: 10, marginTop: 10 }} />
                    <div>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={3}
                      >
                        <h3>Correct Order</h3>
                        <div>
                          <Label>Drag and drop to reorder</Label>
                        </div>
                      </Flex>
                      <Droppable
                        droppableId="ordered-items"
                        direction="hotizontal"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {orderedItems.map((item, index) => {
                              return (
                                <Draggable
                                  draggableId={`ordered-${item.id}`}
                                  index={index}
                                  key={`key-ordered-${item.id}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                      )}
                                    >
                                      <div>{Letters[item.id]}</div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </Flex>
                </DragDropContext>
              </Flex>
            </ContentContainer>
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default React.memo(TestLibraryQuestionsOrder);
