import React from 'react';
import {
  DeleteQuestionGroupProvider,
  useDeleteQuestionGroup,
} from './deleteQuestionGroup';
import { QuestionGroupsProvider, useQuestionGroups } from './questionGroups';
import {
  UpdateQuestionGroupProvider,
  useUpdateQuestionGroup,
} from './updateQuestionGroup';
import {
  CreateQuestionGroupProvider,
  useCreateQuestionGroup,
} from './createQuestionGroup';
import { UpdateQuestionGroupOrderProvider } from './updateQuestionGroupOrder';
import { DeleteQuestionGroupFromPageProvider } from './deleteQuestionGroupFromPage';
import { AddQuestionGroupToPageProvider } from './addQuestionGroupToPage';
import { GetQuestionGroupsInPageProvider } from './getQuestionGroupsInPage';
import { ReorderQuestionGroupsInPageProvider } from './reorderQuestionGroupsInPage';

function QuestionGroupProvider({ children }) {
  return (
    <CreateQuestionGroupProvider>
      <DeleteQuestionGroupProvider>
        <QuestionGroupsProvider>
          <UpdateQuestionGroupProvider>
            <UpdateQuestionGroupOrderProvider>
              <DeleteQuestionGroupFromPageProvider>
                <AddQuestionGroupToPageProvider>
                  <GetQuestionGroupsInPageProvider>
                    <ReorderQuestionGroupsInPageProvider>
                      {children}
                    </ReorderQuestionGroupsInPageProvider>
                  </GetQuestionGroupsInPageProvider>
                </AddQuestionGroupToPageProvider>
              </DeleteQuestionGroupFromPageProvider>
            </UpdateQuestionGroupOrderProvider>
          </UpdateQuestionGroupProvider>
        </QuestionGroupsProvider>
      </DeleteQuestionGroupProvider>
    </CreateQuestionGroupProvider>
  );
}

export {
  QuestionGroupProvider,
  useCreateQuestionGroup,
  useDeleteQuestionGroup,
  useQuestionGroups,
  useUpdateQuestionGroup,
};
