import React from 'react';
import { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { FaTrash } from 'react-icons/fa';
import { Flex } from 'rebass';
import Layout from '../../../../../../Layout';
import { CancelButton, SaveButton } from '../../../../../../UI/Button';
import {
  Container,
  TopContainer,
  Title,
  DeleteButtonContainer,
  DeleteButton,
  ContentContainer,
  InputGroup,
  Label,
  Input,
  TextArea,
  Col,
} from './styled';
import PDFSelector from '../../../../../../UI/PDFSelector';
import { PackagePdfPageStoreContext } from '../../../../../../../contexts/reducers/package/PdfPage';
import { useModal, useSnackbar } from '../../../../../../../contexts/ui';
import MediaLibrarySelector from '../../../../../../MediaLibrary/Selector';
import { types } from '../../../../../../MediaLibrary/Media';
import {
  prepareBackendPdfPageForFrontend,
  preparePdfPageForBackend,
} from '../../../../../../../contexts/api/pages/common';
import { PageFormats } from '../../../../../../../contexts/api/package/common';
import { usePages } from '../../../../../../../contexts/api/pages';
import { useCreatePage } from '../../../../../../../contexts/api/pages/createPage';
import { useUpdatePage } from '../../../../../../../contexts/api/pages/updatePage';
import { useDeletePage } from '../../../../../../../contexts/api/pages/deletePage';
import routes from '../../../../../../../routes';

import { useGetAssets } from '../../../../../../../contexts/api/asset';
import NotFound from '../../../../FromScratch/NotFound';
import strings from '../../../../../../../constants/strings';
import { useLocation, useNavigate } from 'react-router-dom';
const PackageLibraryPagePdfPage = ({ id: packageId, pageId }) => {
  const isEditMode = Boolean(pageId);
  const location = useLocation();
  const navigate = useNavigate();
  // handle page number setting
  const search = useMemo(
    () => new URLSearchParams(location.search.slice(1)),
    [location.search],
  );
  const onCancel = () => {
    navigate(routes.client.editPackages(packageId));
  };

  const { state, update, reset } = useContext(PackagePdfPageStoreContext);

  const { pages, loading, getPages } = usePages(packageId);
  const { createPage } = useCreatePage();
  const { updatePage } = useUpdatePage();
  const { deletePage } = useDeletePage();

  const { assets } = useGetAssets();
  const { showSnackbar } = useSnackbar();
  const { openModal } = useModal();

  const [isSelectingMedia, setIsSelectingMedia] = useState(false);
  const [loadPageError, setLoadPageError] = useState(false);

  // reset page form state if:
  // state id and page id in url mismatch
  // or if switched to 'create' mode from 'edit'
  useEffect(() => {
    if (
      (state.id !== null && pageId && state.id !== pageId) ||
      (!pageId && /pages\/pdf-page\/new$/i.test(location.pathname))
    ) {
      reset();
    }
  }, [pageId, location.pathname, state.id, reset]);

  useEffect(() => {
    if (
      search.has('pageNumber') &&
      state.pageNumber !== search.get('pageNumber')
    ) {
      const pageNumber = search.get('pageNumber');
      if (!isNaN(parseInt(pageNumber))) {
        update({ pageNumber });
      }
    }
  }, [search, state.pageNumber, update]);

  useEffect(() => {
    if (pageId && pageId !== state.id && pages.length > 0 && !loading) {
      const loadedPage = pages.find((p) => p.id === pageId);

      if (!loadedPage) {
        setLoadPageError(true);
      } else {
        const preparedPage = prepareBackendPdfPageForFrontend(loadedPage);
        const asset = assets.find((a) => a.id === preparedPage.properties.pdf);
        update({ ...preparedPage, asset });
        setLoadPageError(false);
      }
    }
  }, [loading, pageId, pages, setLoadPageError, state, update, assets]);

  //load pdf asset into component state from id
  useEffect(() => {
    if (state.properties.pdf && assets) {
      const asset = assets.find((asset) => asset.id === state.properties.pdf);
      if (asset) {
        update({ asset });
      }
    }
    return () => {
      update({ asset: null });
    };
  }, [assets, state.properties.pdf, update]);

  const updateProperties = useCallback(
    (prop, value) => {
      update({ properties: { ...state.properties, [prop]: value } });
    },
    [update, state.properties],
  );

  const openMediaSelector = (callback) => {
    setIsSelectingMedia(true);
  };

  const closeMediaSelector = (callback) => {
    setIsSelectingMedia(false);
  };

  const selectMedia = (asset) => {
    updateProperties('pdf', asset.id);
    update({ asset });
    closeMediaSelector();
  };

  const savePage = async () => {
    const payload = preparePdfPageForBackend(state, PageFormats.PDF, packageId);
    const { success, errorMessage, page } = !isEditMode
      ? await createPage(packageId, payload)
      : await updatePage(pageId, payload);

    await getPages(packageId);

    if (success) {
      showSnackbar('Page saved');
    } else {
      showSnackbar(errorMessage, 'error');
    }
    setTimeout(() => {
      navigate(routes.client.editPdfPage(packageId, page.id));
    }, 250);
  };

  const deletePageCall = async () => {
    const { success, errorMessage } = await deletePage(pageId);
    if (success) {
      navigate(routes.client.editPackages(packageId));
      showSnackbar('Page deleted!');
    } else {
      showSnackbar(errorMessage, 'error');
    }
  };

  const handleDelete = () => {
    openModal({
      title: `Delete`,
      content: strings.en.confirmModal.testPage.confirmDelete,
      action: deletePageCall,
      actionLabel: 'Delete',
      actionColor: 'red',
    });
  };

  return (
    <Layout>
      <Container id="pdfpage">
        <TopContainer>
          <Title>PDF Page</Title>
          <Flex justifyContent="flex-end">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CancelButton
                onClick={onCancel}
                size="s"
                style={{
                  border: 'none',
                  marginRight: '10px',
                  minWidth: '95px',
                }}
              >
                . Cancel
              </CancelButton>
              <SaveButton size="s" noIcon onClick={savePage}>
                Save
              </SaveButton>
            </div>
          </Flex>
        </TopContainer>
        {pageId && (
          <DeleteButtonContainer>
            <FaTrash />
            <DeleteButton onClick={handleDelete}>Delete PDF Page</DeleteButton>
          </DeleteButtonContainer>
        )}
        <ContentContainer>
          <div>
            <InputGroup>
              <Label>Title</Label>
              <Input
                type="text"
                value={state.properties.title}
                onChange={(e) => updateProperties('title', e.target.value)}
              />
            </InputGroup>
          </div>
          <Flex width="100%" justifyContent="space-between">
            <Col>
              <InputGroup>
                <Label className="with-subtitle">
                  PDF - Notes
                  <span>
                    This is a space to add notes about your PDF.
                    <br />
                    These notes are not going to be showed to students.
                  </span>
                </Label>

                <TextArea
                  value={state.properties.notes}
                  onChange={(e) => updateProperties('notes', e.target.value)}
                ></TextArea>
              </InputGroup>
            </Col>
            <Col style={{ padding: '0 30px' }}>
              <InputGroup>
                <Label className="with-subtitle">
                  {state.asset
                    ? state.asset?.title
                    : 'Select the PDF file from Media Libray'}
                </Label>
                <PDFSelector
                  asset={state.asset}
                  onClick={openMediaSelector}
                  onRemove={() => update({ asset: null })}
                />
              </InputGroup>
            </Col>
          </Flex>
        </ContentContainer>
        {loadPageError &&
          ReactDOM.createPortal(
            <NotFound />,
            document.getElementById('pdfpage'),
          )}
      </Container>
      {isSelectingMedia && (
        <MediaLibrarySelector
          showTypes={[types.PDF]}
          onSelectMedia={selectMedia}
          onClose={closeMediaSelector}
          style={{ padding: 0, left: 0 }}
        />
      )}
    </Layout>
  );
};

export default PackageLibraryPagePdfPage;
