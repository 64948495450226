import React from 'react';

const QuestionGroup = ({ name, numQuestions, description, color }) => {
  let numQuestionsText = '';
  if (numQuestions) {
    numQuestionsText = numQuestions < 10 ? '0' + numQuestions : numQuestions;
  } else {
    numQuestionsText = '--';
  }
  return (
    <>
      <div className="circle" style={{ backgroundColor: color }}>
        <span>{numQuestionsText}</span>
      </div>
      <div className="content">
        <h2>{name}</h2>
        {description && <h4>{description}</h4>}
      </div>
    </>
  );
};

export default QuestionGroup;
