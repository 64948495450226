import React, { useEffect, useMemo, useState } from 'react';
import { Container, CardsContainer, StyledCard, TabsContainer } from './styled';
import Tabs from '../../../../UI/Tabs';
import {
  useLinkStudentToPackage,
  useStudents,
  useUnlinkStudentFromPackage,
} from '../../../../../contexts/api/student';
import { usePackageStore } from '../../../../../contexts/reducers/package';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useModal, useSnackbar } from '../../../../../contexts/ui';
import strings from '../../../../../constants/strings';

const tabs = ['Assigned Students', 'All Students'];

const PackageLibraryCreateContentStudents = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { students: rawStudents, pendingInvitation: pendingStudents, error, getStudents } = useStudents();
  const { linkStudentToPackage } = useLinkStudentToPackage();
  const { unlinkStudentFromPackage } = useUnlinkStudentFromPackage();
  const { showSnackbar } = useSnackbar();
  const { openModal } = useModal()

  const {
    state: { id: packageId },
  } = usePackageStore();
  const errorMessage = error?.message || '';

  const students = useMemo(() => {
    const allStudents = [...rawStudents, ...pendingStudents];
    return allStudents.map((student) => {
      const { name, id, email, packages, needsEmailVerified } = student;
      return {
        name,
        id,
        email,
        isVerified: !needsEmailVerified,
        assigned: packages.findIndex((p) => p.id === packageId) > -1,
      };
    });
  }, [rawStudents, pendingStudents, packageId]);

  useEffect(() => {
    if (errorMessage) { showSnackbar(errorMessage, 'error') }
  }, [errorMessage, showSnackbar]);

  const getStudentsFromSelection = () => {
    switch (selectedTabIndex) {
      case 0:
        return students.filter((student) => student.assigned);
      case 1:
        return students;
      default:
        return students;
    }
  };

  const removePackage = async (studentId) => {
    const result = await unlinkStudentFromPackage(studentId, packageId);
    await getStudents();

    if (result.success) {
      showSnackbar(strings.en.students.removePackage.successMessage);
    } else {
      showSnackbar(strings.en.students.removePackage.errorMessage, 'error');
    }
  };

  const addPackage = async (studentId) => {
    const result = await linkStudentToPackage(studentId, packageId);
    await getStudents();

    if (result.success) {
      showSnackbar(strings.en.students.addPackage.successMessage);
    } else {
      showSnackbar(strings.en.students.addPackage.errorMessage, 'error');
    }
  };

  const handleLinkOrUnlinkStudent = (student) => {
    return student.assigned
      ? openModal({
        action: () => removePackage(student.id),
        actionLabel: 'Unassign',
        cancelLabel: 'Cancel',
        actionColor: 'red',
        title: strings.en.students.removePackage.title,
        content: strings.en.students.removePackage.content,
      })
      : openModal({
        action: () => addPackage(student.id),
        actionLabel: 'Assign',
        cancelLabel: 'Cancel',
        title: strings.en.students.addPackage.title,
        content: strings.en.students.addPackage.content,
      })
  };

  return (
    <Container>
      <Tabs
        Container={TabsContainer}
        tabs={tabs}
        onSelectTab={setSelectedTabIndex}
        selectedTabIndex={selectedTabIndex}
      />
      <CardsContainer>
        {getStudentsFromSelection().map((student) => {
          const iconProp = student.assigned ? <FaTrash color="var(--color-red)" /> : <FaPlus color="var(--color-blue)" />;
          return (
            <StyledCard
              badgeText={!student.isVerified && 'Pending invitation'}
              icon={iconProp}
              key={student.id}
              title={student.name}
              subtitle={student.email}
              onClick={() => handleLinkOrUnlinkStudent(student)}
            />
          )
        })}
      </CardsContainer>
    </Container>
  );
};

export default PackageLibraryCreateContentStudents;
