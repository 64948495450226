import {
  REGISTER_FAIL,
  REGISTER_START,
  REGISTER_SUCCESS,
  LOGIN_FAIL,
  LOGIN_START,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_CHECK_START,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_CHECK_SUCCESS,
  RESET_PASSWORD_CHECK_FAIL,
  TOKEN_CHECK_START,
  TOKEN_CHECK_SUCCESS,
  TOKEN_CHECK_FAIL,
  UPDATE_USER,
} from './actionTypes';
import axios, { axiosInterceptors } from '../../axios';
import routes from '../../routes';
import {
  deleteDataFromLocalStorage,
  saveDataInLocalStorage,
} from '../../utils/localStorage';
import { LOCAL_STORAGE } from '../../constants/application';
import { getErrorMessage } from '../../utils/request';

export const updateUser = (updates) => ({
  type: UPDATE_USER,
  payload: { updates },
});

export const registerFail = (message) => ({
  type: REGISTER_FAIL,
  payload: { message },
});

export const registerStart = () => ({
  type: REGISTER_START,
});

export const registerSuccess = (user) => ({
  type: REGISTER_SUCCESS,
  payload: { user },
});

export const registerThunk = (userData, navigate) => async (dispatch) => {
  dispatch(registerStart());
  try {
    const response = await axios.post(routes.api.register, userData);
    dispatch(registerSuccess(response.data));
    if(navigate) {
      navigate(routes.client.home);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    dispatch(registerFail(getErrorMessage(e) || 'Failed to register'));
  }
};

export const loginFail = (message) => ({
  type: LOGIN_FAIL,
  payload: { message },
});

export const loginStart = () => ({
  type: LOGIN_START,
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: { user },
});

export const loginThunk =
  (loginData, loginRoute = routes.api.login) =>
  async (dispatch) => {
    dispatch(loginStart());

    try {
      const response = await axios.post(loginRoute, loginData);
      const { user, token, status, refreshToken } = response.data;

      saveDataInLocalStorage(LOCAL_STORAGE.AUTH, { token, refreshToken });
      axiosInterceptors.use.authInterceptor();
      // interceptor handles token refresh

      dispatch(loginSuccess(user));
      return status;
    } catch (e) {
      dispatch(loginFail(getErrorMessage(e) || 'Failed to login'));
      return null;
    }
  };

/**
 * Request a forgot password email
 * @param {Object} data Payload
 * @param {String} [data.email] User email
 */
export const forgotPasswordThunk = (data, navigate) => async (dispatch) => {
  dispatch({ type: FORGOT_PASSWORD_START });
  try {
    await axios.post(routes.api.forgotPassword, data);
    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
    if(navigate) {
      navigate(routes.client.forgotPasswordEmailSent);
    }
  } catch (e) {
    const message = getErrorMessage(e) || 'Unexpected error';
    dispatch({ type: FORGOT_PASSWORD_FAIL, payload: { message } });
  }
};

/**
 * Request a forgot password check
 * - this returns a user friendly message based on the status
 * @see getResetPasswordCheckErrorMessage
 *
 * @param {Object} data Payload
 * @param {String} [data.email]
 * @param {string} [data.code]
 *
 * @returns {String} Status
 */
export const resetPasswordCheckThunk = (data) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_CHECK_START });

  try {
    const response = await axios.post(routes.api.resetPasswordCheck, data);
    const { status } = response.data;
    dispatch({ type: RESET_PASSWORD_CHECK_SUCCESS });
    return getResetPasswordCheckErrorMessage(status);
  } catch (e) {
    const message = getErrorMessage(e) || 'Unexpected error';
    dispatch({ type: RESET_PASSWORD_CHECK_FAIL, payload: { message } });
    return message;
  }
};

function getResetPasswordCheckErrorMessage(status) {
  switch (status) {
    case 'expired':
      return 'Registration code has expired.';
    case 'invalid':
      return 'Registration code is invalid.';
    case 'success':
      return null;
    default:
      throw new Error('Unexpected error');
  }
}

export const tokenCheckStart = () => ({
  type: TOKEN_CHECK_START,
});

export const tokenCheckFail = (message) => ({
  type: TOKEN_CHECK_FAIL,
  payload: { message },
});

export const tokenCheckSuccess = (user) => ({
  type: TOKEN_CHECK_SUCCESS,
  payload: { user },
});

export const tokenCheckThunk = (test) => async (dispatch) => {
  dispatch(tokenCheckStart());

  try {
    axiosInterceptors.use.authInterceptor();
    const response = await axios.get(routes.api.authenticate);
    const { data: user, status } = response;
    dispatch(tokenCheckSuccess(user));
    return status;
  } catch (e) {
    deleteDataFromLocalStorage(LOCAL_STORAGE.AUTH);
    dispatch(TOKEN_CHECK_FAIL(getErrorMessage(e) || 'Failed to authenticate'));
    return null;
  }
};
