import { uuid } from './uuid';

export const boardMove = (
  source,
  destination,
  droppableSource,
  droppableDestination,
  transformFn = defaultTransformFn,
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(
    droppableDestination.index,
    0,
    transformFn(removed, droppableDestination.index),
  );

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

function defaultTransformFn(el, index) {
  return { ...el, id: uuid() };
}
