import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const CreatePackageContext = React.createContext();

function CreatePackageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const createPackage = useCallback(async (payload) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post('/packages', payload);

      return {
        package: getPackageFromResponse(response),
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    createPackage,
    hasLoaded,
    errorMessage,
  };

  return (
    <CreatePackageContext.Provider value={value}>
      {children}
    </CreatePackageContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  name: string;
 * }} Package
 */

/**
 * @returns {{
 *  loading: boolean,
 *  createPackage: (package: Package) => Promise<{ package: Package; success: boolean; errorMessage: string; }>
 *  hasLoaded: boolean,
 *  errorMessage: string
 * }}
 */

function useCreatePackage() {
  const context = React.useContext(CreatePackageContext);

  if (context === undefined) {
    throw new Error(
      'useCreatePackage must be used within a CreatePackageProvider',
    );
  }

  return context;
}

function getPackageFromResponse(response) {
  if (!response.data) {
    return null;
  }

  const pkg = response.data;
  return {
    id: pkg.id,
    name: pkg.name,
  };
}

export { CreatePackageProvider, useCreatePackage };
