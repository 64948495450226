import React from 'react';
import styled from 'styled-components/macro';
import { layout, position } from 'styled-system';
import { Box, Flex } from 'rebass';
import HeaderWrapper from '../UI/HeaderWrapper';
import { headerDimension } from '../../styleTokens';

const FixedBox = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  min-height: 100vh;
  bottom: 0;
`;

const AbsoluteWrapper = styled(Flex)`
  position: absolute;
  ${position}
  ${layout}
`;

/**
 * returns a containenr that covers the view port with a fixed container
 * usage is <FullScreenLayout renderHeader={() => <div>...</div>}><Container></Container></FullscreenLayout>
 * @param {Object} props
 * @param {Function} props.renderHeader render props for rendering a custom header
 * @deprecated This component is an outdated layout, so it will eventually stop being used.
 */
export const FullScreenLayout = ({ children, renderHeader, ...rest }) => {
  return (
    <FixedBox bg="offwhite" zIndex={5} {...rest}>
      <HeaderWrapper zIndex={6}>{renderHeader()}</HeaderWrapper>
      <AbsoluteWrapper top={headerDimension} bottom={0} left={0} right={0}>
        {children}
      </AbsoluteWrapper>
    </FixedBox>
  );
};

FullScreenLayout.defaultProps = {
  renderHeader: () => <div />,
};
