import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DropAreaContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  max-height: ${props => props.maxHeight ? props.maxHeight : '100%'};
  height: calc(100vh - 100px);
`;

export const DropArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 4px);
  min-height: 65px;
  padding: 18px 0 16px;
  border: 2px dashed #979797;
  border-radius: 5px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #818181;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  color: #818181;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  overflow: auto;
  padding: 60px 0;

  ::-webkit-scrollbar {
    visibility: hidden;
  }
`;

export const DraggableContainer = styled.div`
  width: calc(100% - 4px);
`;

export const DragContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > * {
    margin-left: 20px;
  }
`;

export const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  border: solid 1px #ededed;
  height: 44px;
`;

export const IconImage = styled.img`
  height: 20px;
`;

export const Level = styled.span`
  font-size: 10px;
  color: #2cabe0;
`;
