import React, { useRef, useState } from 'react';
import Xarrow, { Xwrapper } from 'react-xarrows';
import ContextMenu from './ContextMenu';

const SelectableXArrow = ({
  setSelected,
  arrow: { props },
  contextOptions,
  hasContextMenu = true,
}) => {
  const [state, setState] = useState({ color: 'coral' });

  const arrowRef = useRef();
  let color = state.color;

  const defProps = {
    passProps: {
      className: 'xarrow',
      onMouseEnter: (e) => {
        e.stopPropagation();
        setSelected({
          props: { start: props.start, end: props.end },
          type: 'arrow',
        });
        setState({ color: 'IndianRed' });
      },
      onMouseLeave: (e) => {
        setState({ color: 'coral' });
      },
      onClick: (e) => {
        e.stopPropagation();
        setSelected({
          props: { start: props.start, end: props.end },
          type: 'arrow',
        });
      },
      cursor: 'pointer',
    },
  };

  return (
    <Xwrapper>
      <div ref={arrowRef}>
        <Xarrow
          {...{ ...defProps, ...props, ...state, color }}
          showHead={false}
          showTail={false}
        />
      </div>
      {hasContextMenu && (
        <ContextMenu targetRef={arrowRef} options={contextOptions} />
      )}
    </Xwrapper>
  );
};

export default React.memo(SelectableXArrow);
