import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Container,
  DropArea,
  Title,
  Subtitle,
  DropAreaContainer,
  ItemsContainer,
  DraggableContainer,
} from './styled';
import Sidebar from './Sidebar';
import { reorder } from '../../../../../utils/array';
import { boardMove } from '../../../../../utils/dnd';
import DraggableItem from '../../../../../components/UI/DraggableItem';
import { uuid } from '../../../../../utils/uuid';
import { droppableId, pageTypes, sidebarDroppableId } from './constants';
import routes from '../../../../../routes';
import { useNavigate } from 'react-router-dom';
import { usePackageStore } from '../../../../../contexts/reducers/package';
import { Loading } from '../../../../UI/Loaders';
import {
  prepareBackendQuestionPageForFrontendPageItem,
  usePages,
} from '../../../../../contexts/api/pages';
import { PageFormats } from '../../../../../contexts/api/package/common';
import { useReorderPages } from '../../../../../contexts/api/pages/reorderPage';
import { useSnackbar } from '../../../../../contexts/ui';
/**
 * @type
 * @param {*} type
 * @param {*} index
 * @returns {import('../../../../../contexts/api/pages/common').FrontEndPageItem}
 */
function newPage(type, index) {
  return {
    title: `${type.title} - Untitled`,
    type: type.type,
    pageNumber: index + 1,
    id: uuid(),
    isNew: true,
  };
}

/**
 * @typedef {{
 *  add: Function
 *  edit: Function
 * }} CrudRoutes
 * @param {String} type
 * @returns {CrudRoutes}
 */
function crudRoutesFromPageType(type) {
  switch (type) {
    case PageFormats.QUESTIONS:
      return {
        add: routes.client.addQuestionPage,
        edit: routes.client.editQuestionPage,
      };
    case PageFormats.PDF:
      return {
        add: routes.client.addPdfPage,
        edit: routes.client.editPdfPage,
      };
    default:
      return {
        add: routes.client.addQuestionPage,
        edit: routes.client.editQuestionPage,
      };
  }
}

const PackageLibraryCreateContentBuild = () => {
  const [draggedItems, setDraggedItems] = useState([]);
  const { state } = usePackageStore();
  const { loading, error, pages, getPages } = usePages(state.id);
  const { showSnackbar } = useSnackbar();
  const { error: reorderPagesError, reorderPages } = useReorderPages();
  const navigate = useNavigate();
  const apiError = error || reorderPagesError;

  useEffect(() => {
    if (apiError) {
      showSnackbar(apiError, 'error');
    }
  }, [apiError, showSnackbar]);

  const draggedItemIds = useMemo(() => {
    return draggedItems.map((di) => di.id);
  }, [draggedItems]);

  useEffect(() => {
    const handleReorder = async () => {
      if (state.id && draggedItemIds.length) {
        reorderPages(state.id, draggedItemIds);
      }
    };
    if (state.id) {
      handleReorder();
    }
  }, [draggedItemIds, state.id, reorderPages]);

  useEffect(() => {
    if (state.id !== null) {
      getPages(state.id);
    }
  }, [getPages, state.id]);

  useEffect(() => {
    if (!loading && state.id !== null) {
      const formattedItems = pages.map((p) => {
        if (p.type === PageFormats.QUESTIONS) {
          return prepareBackendQuestionPageForFrontendPageItem(p);
        } else {
          return {
            title: p.title,
            pageNumber: p.pageNumber,
            id: p.id,
            isNew: p.isNew,
            type: p.type,
          };
        }
      });
      setDraggedItems(formattedItems);
    }
  }, [loading, pages, state.id]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === droppableId) {
      if (source.droppableId !== droppableId) {
        const moveResult = boardMove(
          pageTypes,
          draggedItems,
          source,
          destination,
          newPage,
        );

        setDraggedItems(moveResult[droppableId]);
      } else {
        const items = reorder(draggedItems, source.index, destination.index);
        setDraggedItems(items);
      }
    }
  };

  const handleClickPage = (item, index) => {
    if (!state.id) {
      return;
    }
    const { add, edit } = crudRoutesFromPageType(item.type);

    if (item.isNew) {
      navigate(add(state.id, index + 1));
    } else {
      navigate(edit(state.id, item.id));
    }
  };

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Sidebar id={sidebarDroppableId} items={pageTypes} />
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <DropAreaContainer>
              <ItemsContainer ref={provided.innerRef}>
                {loading && <Loading />}
                {draggedItems.map((item, index) => (
                  <DraggableContainer
                    key={item.id}
                    onClick={() => handleClickPage(item, index)}
                  >
                    <DraggableItem
                      id={`${droppableId}-${item.id}`}
                      index={index}
                      large
                    >
                      {item.title}
                    </DraggableItem>
                  </DraggableContainer>
                ))}

                {snapshot.isDraggingOver ? null : (
                  <DropArea>
                    <Title>Drag & Drop Page Option</Title>
                    <Subtitle>Then, click to customize</Subtitle>
                  </DropArea>
                )}
                {provided.placeholder}
              </ItemsContainer>
            </DropAreaContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

export default PackageLibraryCreateContentBuild;
