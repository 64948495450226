import styled from 'styled-components/macro';
import { COLORS } from '../../../theme';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: ${({ large }) => (large ? '100px' : '60px')};
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 #d7d7d7;
  background-color: #ffffff;
  padding-right: 45px;
  font-size: 24px;
  color: #818181;
  margin-bottom: 40px;
  transition: color 0.1s;
  :hover {
    color: ${COLORS.blue};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 15px;
  color: #2cabe0;
`;
