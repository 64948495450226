import React from 'react';
import TestLibraryQuestionsTextAnswer, { ANSWER_TYPE } from '../TextAnswer';

const TestLibraryQuestionsLongAnswer = (props) => {
  return (
    <TestLibraryQuestionsTextAnswer answerType={ANSWER_TYPE.long} {...props} />
  );
};

export default TestLibraryQuestionsLongAnswer;
