import React from 'react';
import styled from 'styled-components/macro';
import LoginForm from './LoginForm';
import { ExternalLayout } from '../Layout';
import { Link } from '../UI';
import routes from '../../routes';

export const Login = (props) => {
  const { error, loginUser } = props;

  return (
    <ExternalLayout forTeachers>
      <LoginForm error={error} loginUser={(userData) => loginUser(userData)} />
      <ForgotMessage>
        <Link to={routes.client.forgotPassword}>Forgot Password</Link>
      </ForgotMessage>
      <SignupMessage>
        Don&apos;t have an Account?{' '}
        <Link to={routes.client.registration}>Sign Up!</Link>
      </SignupMessage>
    </ExternalLayout>
  );
};

const ForgotMessage = styled.div`
  margin: 20px 3px 91px 17px;
  opacity: 0.87;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
`;

const SignupMessage = styled.div`
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: center;
`;
