import styled from 'styled-components/macro';

export const Subtitle = styled.div`
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 24px;
`;

export const Line = styled.hr`
  width: 99%;
  margin: 80px 0;
  border-width: 0.8px;
  border-color: rgba(0, 0, 0, 0.1);
`;
