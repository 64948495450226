import styled from 'styled-components/macro';

export const Container = styled.div`
  overflow: auto;
  width: 30%;
  background-color: #ffffff;
  border: solid 1px #b6b6b6;
  border-top: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  margin: 40px 0;
  font-size: 24px;
  color: #2cabe0;
`;

export const DraggableContainer = styled.div`
  width: 80%;
`;
