import styled from 'styled-components/macro';

export const H3 = styled.h3`
  max-width: 400px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.44px;
  text-align: center;
`;
