import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './assets/index.css';
import reportWebVitals from './reportWebVitals';
import App from './containers/App';

import store from './store';
import { ApiProvider } from './contexts/api';
import { StoreProvider } from './contexts/reducers';
import { UiProvider } from './contexts/ui';

ReactDOM.render(
  <StoreProvider>
    <ApiProvider>
      <Provider store={store}>
        <UiProvider>
          <App />
        </UiProvider>
      </Provider>
    </ApiProvider>
  </StoreProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
