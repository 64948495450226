import React from 'react';
import { Box, Flex } from 'rebass';
import { Link } from '../../../UI';
import { Input } from './styled';
import { Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';


export const allowables = ['+', '-', '*', '/', '(', ')'];

const MathField = ({
  text,
  identifier,
  onChange,
  onDeleteItem,
  ...rest
}) => {

  const handleChange = (e) => {
    // check if the value contains only numbers and allowed characters
    const value = e.target.value;

    if (value.match(/^[0-9]+$/) || allowables.includes(value) || value === '') {
      onChange(e);
    }
  }

  return (
    <Box {...rest}>

      <Input
        onChange={handleChange}
        value={text}
        style={{
          width: '100px',
        }}
      />
      <Flex mt={2} flexDirection='row' justifyContent="space-between" alignItems="base">
        <div className="links">
          <Link to="#" onClick={onDeleteItem}>
            Remove Item
          </Link>
        </div>
        <div>
          <Tooltip 
            title="Only Numbers and '+', '-', '*', '/', '(', ')' are valid"
            arrow
          >
            <HelpIcon fontSize='small' />
          </Tooltip>
        </div>
      </Flex>
    </Box>
  );
};

export default React.memo(MathField);
