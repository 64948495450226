import {
  position,
  layout,
  color,
  typography,
  border,
  flex,
} from 'styled-system';
import styled from 'styled-components/macro';

// wraps any component with styled system;
const withStyledSystem = (wrappedComponent) => styled(wrappedComponent)`
  ${position};
  ${layout};
  ${color};
  ${typography};
  ${border};
  ${flex};
`;

export default withStyledSystem;
