import React, { useState } from 'react';
import { Box, Flex } from 'rebass';
import { InputField, HelperText, SaveButton } from '../UI';
import { passwordsMatch } from '../../utils/form';
import styled from 'styled-components/macro';
import { useChangePassword } from '../../contexts/api/settings';
import { useSnackbar } from '../../contexts/ui';

export const TEST_IDS = {
  container: 'settings-change-password',
  oldPassword: 'settings-change-password-old-password',
  password: 'settings-change-password-password',
  confirmPassword: 'settings-change-password-confirm-password',
  confirmPasswordHelper: 'settings-change-password-confirm-password-helper',
  submit: 'settings-change-password-submit',
};

const Div = styled.div`
  position: relative;
`;

const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.blue};
  text-align: left;
  margin-bottom: 40px;
`

const ChangePassword = ({ email }) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { loading, changePassword } = useChangePassword()
  const { showSnackbar } = useSnackbar()

  const canSubmit = () => {
    const isNotEmpty = oldPassword.trim() !== '' && newPassword.trim() !== '' && confirmPassword.trim() !== ''
    return !loading && (isNotEmpty && !passwordsMatch({ password: newPassword, confirmPassword }))
  }

  const handleSubmit = async () => {
    const passwordData = { oldPassword, newPassword, email }
    const { success, message } = await changePassword(passwordData)
    const variant = success ? 'success' : 'error';
    showSnackbar(message, variant)
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  return (
    <Flex data-testid={TEST_IDS.container}>
      <Box>
        <Heading>Password</Heading>
        <Div>
          <InputField
            label="Old password"
            data-testid={TEST_IDS.password}
            id="change-oldpassword"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </Div>
        <Div>
          <InputField
            label="Password"
            data-testid={TEST_IDS.password}
            id="change-password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Div>
        <Div>
          <InputField
            label="Confirm Password"
            data-testid={TEST_IDS.confirmPassword}
            id="change-confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => { setConfirmPassword(e.target.value) }}
          />
          {(newPassword && confirmPassword) && (
            <HelperText data-testid={TEST_IDS.confirmPasswordHelper}>
              {passwordsMatch({ password: newPassword, confirmPassword })}
            </HelperText>
          )}
        </Div>
        <SaveButton
          noIcon
          variant="primary"
          mt={3}
          disabled={!canSubmit()}
          data-testid={TEST_IDS.submit}
          onClick={handleSubmit}
        >
          Save Changes
        </SaveButton>
      </Box>
    </Flex>
  )
};

export default ChangePassword;
