import React from 'react';
import { bool, func, string } from 'prop-types';
import { StyledStudentContainer } from './styled';
import { TextField } from '@material-ui/core';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaMinus,
  FaPlus,
  FaSyncAlt,
} from 'react-icons/fa';
import theme from '../../../theme';
import strings from '../../../constants/strings';
import { Loading } from '../../UI/Loaders';

const StudentInvitationFormRow = ({
  id,
  firstName,
  lastName,
  email,
  handleChange,
  handleBlur,
  addStudent,
  removeStudent,
  tryAgain,
  loading,
  requestError,
  formErrors,
  success,
  removeable,
}) => {
  const disabled = success || loading;
  return (
    <StyledStudentContainer>
      <TextField
        label="First Name"
        name="firstName"
        variant="outlined"
        value={firstName}
        style={{ width: '300px' }}
        size="small"
        onChange={(e) => handleChange(e, id)}
        disabled={disabled}
      />
      <TextField
        label="Last Name"
        name="lastName"
        variant="outlined"
        value={lastName}
        style={{ width: '300px' }}
        size="small"
        onChange={(e) => handleChange(e, id)}
        disabled={disabled}
      />
      <TextField
        label="Email"
        error={formErrors && formErrors['email']}
        name="email"
        variant="outlined"
        value={email}
        style={{ width: '300px' }}
        onChange={(e) => handleChange(e, id)}
        onBlur={(e) => handleBlur(e, id)}
        helperText={
          formErrors &&
          formErrors['email'] &&
          strings.en.students.inviteStudents.emailError
        }
        size="small"
        disabled={disabled}
      />
      <Actions
        id={id}
        error={requestError}
        loading={loading}
        success={success}
        addStudent={addStudent}
        removeStudent={removeStudent}
        removeable={removeable}
        tryAgain={tryAgain}
      />
    </StyledStudentContainer>
  );
};

function Actions({
  error,
  id,
  loading,
  success,
  addStudent,
  removeStudent,
  tryAgain,
  removeable,
}) {
  if (loading) {
    return <Loading />;
  }

  if (success) {
    return <FaCheckCircle color={theme.colors.green} />;
  }

  if (error) {
    return (
      <>
        <FaExclamationCircle color={theme.colors.red} title={error} />
        <FaSyncAlt
          color={theme.colors.blue}
          title={strings.en.generic.tryAgain}
          onClick={tryAgain}
        />
      </>
    );
  }

  return (
    <>
      <FaPlus color={theme.colors.blue} onClick={addStudent} />
      {removeable && <FaMinus color={theme.colors.blue} onClick={() => removeStudent(id)} />}
    </>
  );
}

StudentInvitationFormRow.propTypes = {
  id: string.isRequired,
  firstName: string,
  lastName: string,
  email: string,
  handleChange: func.isRequired,
  addStudent: func.isRequired,
  removeStudent: func.isRequired,
  tryAgain: func.isRequired,
  loading: bool,
  requestError: string,
  success: bool,
};

export default StudentInvitationFormRow;
