import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const DropAreaContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  height: 100%;
`;

export const DropArea = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: calc(100% - 4px);
  min-height: 65px;
  padding: 18px 0 16px;
  border: 2px dashed #979797;
  border-radius: 5px;
`;

export const Title = styled.div`
  font-size: 24px;
  color: #818181;
`;

export const Subtitle = styled.div`
  font-size: 16px;
  color: #818181;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  overflow: auto;
  padding: 30px 0;

  ::-webkit-scrollbar {
    visibility: hidden;
  }
`;

export const DraggableContainer = styled.div`
  width: calc(100% - 4px);
`;
