import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Container, ContentContainer, IconContainer } from './styled';
import { FaGripHorizontal } from 'react-icons/fa';

export const DraggableItem = ({
  id,
  children,
  index,
  large,
  renderPropsBySnapshot,
  DragContainer = Container,
  DragContentContainer = ContentContainer,
  DragIconContainer = IconContainer,
  ...props
}) => (
  <Draggable {...props} draggableId={id} index={index}>
    {(provided) => (
      <DragContainer
        large={large}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <DragContentContainer>{children}</DragContentContainer>
        <DragIconContainer>
          <FaGripHorizontal />
        </DragIconContainer>
      </DragContainer>
    )}
  </Draggable>
);

export default DraggableItem;
