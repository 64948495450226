import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Container,
  DropArea,
  Title,
  Subtitle,
  DropAreaContainer,
  ItemsContainer,
  DraggableContainer,
  DragContentContainer,
} from './styled';
import TestLibraryQuestionGroupBuildSidebar from './Sidebar';
import { reorder } from '../../../../utils/array';
import { boardMove } from '../../../../utils/dnd';
import DraggableItem from '../../../UI/DraggableItem';
import { useNavigate } from 'react-router-dom';
import { uuid } from '../../../../utils/uuid';
import routes from '../../../../routes';
import QuestionItem from './QuestionItem';
import {
  droppableId,
  questionIcons,
  questionTypes,
  sidebarDroppableId,
} from './constants';

const TestLibraryQuestionGroupBuild = ({
  questionGroupId,
  existingQuestions,
  onQuestionOrderChange,
}) => {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState(existingQuestions);
  useEffect(() => {
    setQuestions(existingQuestions);
  }, [existingQuestions]);

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === droppableId) {
      if (source.droppableId !== droppableId) {
        const moveResult = boardMove(
          questionTypes,
          questions,
          source,
          destination,
          questionGroupFromType,
        );
        setQuestions(moveResult[droppableId]);
      } else {
        const items = reorder(questions, source.index, destination.index);
        setQuestions(items);
        // should probably set a state to prevent more board changes while question order handler is loading
        onQuestionOrderChange(items);
      }
    }
  };

  const handleClickQuestion = (item, index) => {
    if (!questionGroupId) {
      return;
    }

    if (item.isNew) {
      navigate(
        routes.client.addQuestion(questionGroupId, item.questionFormat, index),
      );
    } else {
      navigate(routes.client.editQuestion(questionGroupId, item.id));
    }
  };

  return (
    <Container>
      <DragDropContext onDragEnd={handleDragEnd}>
        <TestLibraryQuestionGroupBuildSidebar
          id={sidebarDroppableId}
          items={questionTypes}
          icons={questionIcons}
        />
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <DropAreaContainer>
              <ItemsContainer ref={provided.innerRef}>
                {questions.map((item, index) => (
                  <DraggableContainer
                    key={item.id}
                    onClick={() => handleClickQuestion(item, index)}
                  >
                    <DraggableItem
                      id={`${droppableId}-${item.id}`}
                      index={index}
                      large
                      DragContentContainer={DragContentContainer}
                    >
                      <QuestionItem
                        difficulty={item.difficulty}
                        icon={questionIcons[item.questionFormat]}
                        question={item.question}
                        questionNumber={index}
                      />
                    </DraggableItem>
                  </DraggableContainer>
                ))}
                {snapshot.isDraggingOver ? null : (
                  <DropArea>
                    <Title>Drag & Drop Question Template</Title>
                    <Subtitle>Then, click to customize</Subtitle>
                  </DropArea>
                )}
                {provided.placeholder}
              </ItemsContainer>
            </DropAreaContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

function questionGroupFromType(type, index) {
  return {
    question: `Untitled ${type.content}`,
    questionFormat: type.questionFormat,
    questionNumber: index + 1,
    id: uuid(),
    isNew: true,
  };
}

export default TestLibraryQuestionGroupBuild;
