import React from 'react';
import PropTypes from 'prop-types';
import {
  FaPenNib,
  FaVideo,
  FaImage,
  FaMusic,
  FaRulerHorizontal,
  FaCubes,
  FaQuestion,
} from 'react-icons/fa';
import withStyledSystem from '../withStyledSystem';
import { QUESTION_TYPES } from '../../constants/application';

export const QuestionTypeIcon = ({ type, ...rest }) => {
  switch (type) {
    case QUESTION_TYPES.text:
      return <FaPenNib {...rest} />;
    case QUESTION_TYPES.image:
      return <FaImage {...rest} />;
    case QUESTION_TYPES.audio:
      return <FaMusic {...rest} />;
    case QUESTION_TYPES.piano:
      return <FaRulerHorizontal {...rest} />;
    case QUESTION_TYPES.match:
      return <FaCubes {...rest} />;
    case QUESTION_TYPES.video:
      return <FaVideo {...rest} />;
    default:
      return <FaQuestion {...rest} />;
  }
};

QuestionTypeIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(QUESTION_TYPES)),
};

export default withStyledSystem(QuestionTypeIcon);
