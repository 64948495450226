import { Flex, Box } from 'rebass';
import { FaTrash } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Layout from '../../../Layout';
import { Link } from '../../../UI';
import React, { useState } from 'react';
import { AddButton, CancelButton, SaveButton, Button } from '../../../UI/Button';
import {
  Container,
  TopContainer,
  Title,
  ContentContainer,
  Label,
  InputGroup,
  QuestionField,
  Input,
  FlexItem,
} from './styled';
import MathFieldAnswerBox from './MathField';
import { DeleteButton, DeleteButtonContainer } from '../../Navbar/styled';
import ClassedUpMarkupGuide from '../../../../assets/pdf/ClassedUpMarkupGuide.pdf';

const grid = 8;

const getListStyle = (isDraggingOver) => ({
  border: '1px solid #d3d3d3',
  padding: grid,
  borderColor: isDraggingOver ? '#d3d3d3' : '#ededed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  gap: '8px',
  overflow: 'hidden',
});
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,

  // change background colour if dragging
  background: isDragging ? '#d3d3d3' : '#ededed',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const TestLibraryQuestionsMath = ({
  onCancel = () => {},
  onDelete = () => {},
  onSave = () => {},
  questionNumber,
  question: initialQuestion,
  showButton = false,
  mathItems: initialMathItems = [],
  mathAnswer: initialMathAnswer = "0",
  mathNumAnswerItems: initialMathNumAnswerItems = 0,
  onNext,
  onPrevious
}) => {
  const initialIndex = initialMathItems.length ? initialMathItems.length : 0;

  const [idx, setIdx] = useState(initialIndex);
  const [question, setQuestion] = useState(initialQuestion);
  const [mathItems, setMathItems] = useState(initialMathItems);
  const [mathAnswer, setMathAnswer] = useState(initialMathAnswer);
  const [mathNumAnswerItems, setMathNumAnswerItems] = useState(initialMathNumAnswerItems);

  const handleSave = () => {

    // if mathItems length is less than the mathNumAnswerItems, then alert the user
    if(mathItems.length < mathNumAnswerItems) {
      alert(`You have ${mathItems.length} items, but you specified ${mathNumAnswerItems} items in the answer. Please add more items or change the number of items in the answer.`);
      return;
    }

    // if mathItems have empty values, then alert the user
    const emptyItems = mathItems.filter(item => item.text === '');
    if(emptyItems.length > 0) {
      alert(`You have ${emptyItems.length} items with empty values. Please fill in the empty values or remove them.`);
      return;
    }

    const payload = {
      question,
      questionNumber,
      mathItems,
      mathAnswer,
      mathNumAnswerItems,
    };

    onSave(payload);
  };

  const handleAddOption = () => {
    setMathItems([...mathItems, { id: idx, text: '' }]);
    setIdx(idx + 1);
  };

  const handleMathAnswerChange = (e) => {
    const val = e.target.value;
    if(val && val.trim() !== '') {
      setMathAnswer(val);
    }
  }

  const handleMathNumAnswerItemsChange = (e) => {
    const val = e.target.value;
    if(val && val.trim() !== '') {
      // parse number out of string
      const parsed = parseInt(e.target.value);
      setMathNumAnswerItems(parsed);
    }
  }

  const handleRemoveOption = (id) => {
    const index = mathItems.findIndex((item) => item.id === id);
    const newMathItems = [...mathItems];
    newMathItems.splice(index, 1);
    setMathItems(newMathItems);
  };

  const handleOptionChange = (id, value) => {
    const index = mathItems.findIndex((item) => item.id === id);
    const newMathItems = [...mathItems];
    newMathItems[index].text = value;
    setMathItems(newMathItems);
  };

  const redorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.source.droppableId === 'math-items') {
      const newMathItems = redorder(
        mathItems,
        result.source.index,
        result.destination.index,
      );
      setMathItems(newMathItems);
    }
  };

  return (
    <Layout noBodyPadding>
      <Container>
        <TopContainer>
          <Title>Math Question</Title>
          {
            (onNext || onPrevious) && showButton ? (
              <Flex justifyContent="space-between" style={{ gap: 12 }} alignItems="center">
                {
                  onPrevious && (
                    <Button onClick={onPrevious} size="s" noIcon>
                      Previous Question
                    </Button>
                  )
                }
                {
                  onNext && (
                    <Button onClick={onNext} size="s" noIcon>
                      Next Question
                    </Button>
                  )
                }
              </Flex>
            ) : null
          }
          <Flex justifyContent="flex-end">
            <DeleteButtonContainer onClick={onDelete}>
              {showButton && (
                <>
                  <FaTrash />
                  <DeleteButton>Delete</DeleteButton>
                </>
              )}
            </DeleteButtonContainer>
            <CancelButton
              onClick={onCancel}
              size="s"
              style={{ border: 'none' }}
            >
              Cancel
            </CancelButton>
            <SaveButton onClick={handleSave} size="s" noIcon>
              Save
            </SaveButton>
          </Flex>
        </TopContainer>

        <Flex mb={3} px="5%" py="35px">
          <Box flexGrow={1}>
            <ContentContainer>
              <InputGroup>
                <Label>Question</Label>
                <QuestionField
                  rows={2}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
                <div className="links flexStart">
                  <Link
                    anchor
                    href={ClassedUpMarkupGuide}
                    name="markup-guide"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Markup guide
                  </Link>
                </div>
              </InputGroup>
              <Flex
                flexDirection="column"
                width="100%"
                justifyContent="space-between"
                mt={5}
              >
                <div style={{ marginBottom: 10, marginTop: 10 }}>
                <Flex flexDirection="row" justifyContent="center" alignItems="center">
                  <FlexItem>
                    <Label>Answer</Label>
                    <Input
                      value={mathAnswer}
                      onChange={handleMathAnswerChange}
                    />
                  </FlexItem>
                  <FlexItem>
                    <Label># of Items in Answer</Label>
                    <Input
                      value={mathNumAnswerItems}
                      onChange={handleMathNumAnswerItemsChange}
                      type='number'
                    />
                  </FlexItem>
                </Flex>
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Flex flexDirection="column">
                    <div>
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        marginBottom={3}
                      >
                        <h3>Display Order</h3>
                        <div>
                          <Label>Drag and drop to reorder</Label>
                        </div>
                        <AddButton onClick={handleAddOption} center>
                          Add Item
                        </AddButton>
                      </Flex>
                      <Droppable
                        droppableId="math-items"
                        direction="horizontal"
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {mathItems.map((item, index) => {
                              return (
                                <Draggable
                                  draggableId={`order-${item.id}`}
                                  index={index}
                                  key={`key-order-${item.id}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style,
                                      )}
                                    >
                                      <MathFieldAnswerBox
                                        key={`answerbox-mathField-idx-${index}`}
                                        display="flex"
                                        flexDirection="column"
                                        text={item.text}
                                        onChange={(e) =>
                                          handleOptionChange(
                                            item.id,
                                            e.target.value,
                                          )
                                        }
                                        onDeleteItem={() =>
                                          handleRemoveOption(item.id)
                                        }
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </Flex>
                </DragDropContext>
              </Flex>
            </ContentContainer>
          </Box>
        </Flex>
      </Container>
    </Layout>
  );
};

export default React.memo(TestLibraryQuestionsMath);
