import React from 'react';
import styled, { keyframes } from 'styled-components'
import { MODAL_SIZES } from '../../../theme/theme'
import { ModalActionButton } from '../Button'

const slideDownAnimation = keyframes`
  0% { opacity: 0; transform: translateY(-100px); }
  100% { opacity: 1; transform: translateY(0px); }
`
const ModalBox = styled.div`
display: flex;
flex-direction: column;
flex: 0 1 ${({ modalSize }) => modalSize}px;
max-height: 100%;
${({ modalType }) => modalType === 'confirm' ? 'margin-top: 15vh;' : 'align-self: center;'}
background-color: #fff;
border-radius: 3px;
height:fit-content;
animation-name: ${slideDownAnimation};
animation-duration: 0.5s;
animation-iteration-count: 1;
animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
.modal-header{
  padding: 16px;
  border-bottom: 1px solid #c7c7c7;
  h3{
    text-align: left;
    font-size: 18px;
    font-weight: normal;
    color: #424242;
  }
}
.modal-body{
  flex-shrink:1;
  padding:24px 16px;
  color: #424242;
  overflow-y: auto;
}
hr{
  border-bottom: 0 none;
}
p{
  margin-top: 0;
  &:last-child{
    margin-bottom: 0;
  }
}
.modal-footer{
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  button{
    margin-left:10px;
  }
}
`

export const Overlay = styled.div`
position: fixed;
width: 100%;
left: 0;
top: 0;
height: 100%;
padding: 15px;
display: ${({ visible }) => visible ? 'flex' : 'none'};
flex-diraction: column;
justify-content: center;
background: rgba(26, 26, 26, 0.8);
backdrop-filter: blur(10px);
box-sizing: border-box; 
z-index: 3;
`

export const ContextModal = ({ open = false, title = '', content = '', action, actionLabel = '', actionColor = 'blue', cancelLabel = '', onClose, size, type, showCancelAction }) => {

  const handleActionClick = async () => {
    await action()
    onClose()
  }
  const Title = () => typeof title === 'string' ? <h3>{title}</h3> : title;
  const Content = () => {
    if (typeof content === 'string') {
      return content.split('\n').map((string, i) => string ? <p key={i}>{string}</p> : <br key={i} />)
    }
    return content
  }

  const modalSize = MODAL_SIZES[size] || MODAL_SIZES['xl']

  return open && (
    <ModalBox modalSize={modalSize} modalType={type} >
      <div className="modal-header">
        <Title />
      </div>
      <div className="modal-body">
        <Content />
      </div>
      <div className="modal-footer">
        {showCancelAction && <ModalActionButton onClick={onClose} ghost >{cancelLabel}</ModalActionButton>}
        {action && <ModalActionButton onClick={handleActionClick} color={actionColor} size="s">{actionLabel}</ModalActionButton>}
      </div>
    </ModalBox>
  )
}
