import React from 'react';
import { PackageStoreProvider } from './package';
import { PackageTestPageStoreProvider } from './package/TestPage';
import { PackagePdfPageStoreProvider } from './package/PdfPage';

export function StoreProvider({ children }) {
  return (
    <PackageStoreProvider>
      <PackageTestPageStoreProvider>
        <PackagePdfPageStoreProvider>
          {children}
        </PackagePdfPageStoreProvider>
      </PackageTestPageStoreProvider>
    </PackageStoreProvider>
  );
}
