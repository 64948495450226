import styled from 'styled-components/macro';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export const StyledSnackbarContent = styled(SnackbarContent)`
  && {
    background-color: ${(props) =>
    props.color ? props.color : props.theme.colors.darkgrey};
    border-radius: 5px;

    #client-snackbar svg {
      fill: ${(props) => props.icon};
      margin-right: 16px;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
