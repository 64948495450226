export const ADD_QUESTION_GROUP = 'TEST_LIBRARY_EDITOR.ADD_QUESTION_GROUP';
export const ADD_QUESTION_GROUP_START =
  'TEST_LIBRARY_EDITOR.ADD_QUESTION_GROUP_START';
export const ADD_QUESTION_GROUP_FAIL =
  'TEST_LIBRARY_EDITOR.ADD_QUESTION_GROUP_FAIL';
export const ADD_QUESTION_GROUP_SUCCESS =
  'TEST_LIBRARY_EDITOR.ADD_QUESTION_GROUP_SUCCESS';

export const UPDATE_QUESTION_GROUP =
  'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_GROUP';
export const UPDATE_QUESTION_GROUP_START =
  'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_GROUP_START';
export const UPDATE_QUESTION_GROUP_FAIL =
  'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_GROUP_FAIL';
export const UPDATE_QUESTION_GROUP_SUCCESS =
  'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_GROUP_SUCCESS';

export const RESET = 'TEST_LIBRARY_EDITOR.RESET';

export const SET_TEST_LIBRARY_EDITOR =
  'TEST_LIBRARY_EDITOR.SET_TEST_LIBRARY_EDITOR';

export const ADD_QUESTION = 'TEST_LIBRARY_EDITOR.ADD_QUESTION';
export const ADD_QUESTION_START = 'TEST_LIBRARY_EDITOR.ADD_QUESTION_START';
export const ADD_QUESTION_FAIL = 'TEST_LIBRARY_EDITOR.ADD_QUESTION_FAIL';
export const ADD_QUESTION_SUCCESS = 'TEST_LIBRARY_EDITOR.ADD_QUESTION_SUCCESS';

export const DELETE_QUESTION_START =
  'TEST_LIBRARY_EDITOR.DELETE_QUESTION_START';
export const DELETE_QUESTION_FAIL = 'TEST_LIBRARY_EDITOR.DELETE_QUESTION_FAIL';
export const DELETE_QUESTION_SUCCESS =
  'TEST_LIBRARY_EDITOR.DELETE_QUESTION_SUCCESS';

export const UPDATE_QUESTION = 'TEST_LIBRARY_EDITOR.UPDATE_QUESTION';
export const UPDATE_QUESTION_START =
  'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_START';
export const UPDATE_QUESTION_FAIL = 'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_FAIL';
export const UPDATE_QUESTION_SUCCESS =
  'TEST_LIBRARY_EDITOR.UPDATE_QUESTION_SUCCESS';

export const REORDER_QUESTIONS = 'TEST_LIBRARY_EDITOR.REORDER_QUESTIONS';
export const SET_QUESTIONS = 'TEST_LIBRARY_EDITOR.SET_QUESTIONS';

export const GET_QUESTION_GROUP_QUESTIONS =
  'TEST_LIBRARY_EDITOR.GET_QUESTION_GROUP_QUESTIONS';
export const GET_QUESTION_GROUP_QUESTIONS_START =
  'TEST_LIBRARY_EDITOR.GET_QUESTION_GROUP_QUESTIONS_START';
export const GET_QUESTION_GROUP_QUESTIONS_FAIL =
  'TEST_LIBRARY_EDITOR.GET_QUESTION_GROUP_QUESTIONS_FAIL';
export const GET_QUESTION_GROUP_QUESTIONS_SUCCESS =
  'TEST_LIBRARY_EDITOR.GET_QUESTION_GROUP_QUESTIONS_SUCCESS';
