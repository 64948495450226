import React from 'react';
import {
  prepareTestPageForBackend,
  prepareBackendQuestionPageForFrontendPageItem,
  prepareBackendQuestionPageForFrontend,
} from './common';
import { CreatePageProvider } from './createPage';
import { UpdatePageProvider } from './updatePage';
import { PagesProvider as PageProvider, usePages } from './pages';
import { ReorderPagesProvider } from './reorderPage';
import { DeletePageProvider } from './deletePage';

function PagesProvider({ children }) {
  return (
    <PageProvider>
      <CreatePageProvider>
        <UpdatePageProvider>
          <DeletePageProvider>
            <ReorderPagesProvider>{children}</ReorderPagesProvider>
          </DeletePageProvider>
        </UpdatePageProvider>
      </CreatePageProvider>
    </PageProvider>
  );
}

export {
  usePages,
  PagesProvider,
  prepareTestPageForBackend,
  prepareBackendQuestionPageForFrontendPageItem,
  prepareBackendQuestionPageForFrontend,
};
