import React from 'react';
import CSVReader from 'react-csv-reader';
import { camelcase } from 'stringcase';

const StudentImportCSV = ({ children, onError, onFileLoaded }) => {
  const handleFileLoaded = (data, info) => {
    if (validStudents(data)) {
      return onFileLoaded(data, info);
    }

    return onError(getGenericError());
  };
  return (
    <CSVReader
      cssInputClass="csv-input"
      cssLabelClass="csv-label"
      label={<>{children}</>}
      onFileLoaded={handleFileLoaded}
      onError={onError}
      parserOptions={parserOptions}
    />
  );
};

const parserOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader,
};

function validStudents(students) {
  if (!Array.isArray(students)) {
    return false;
  }

  return students.every((s) => s.firstName && s.lastName && s.email);
}

function getGenericError() {
  return new Error(
    'Invalid student data! Make sure to provide a first name, a last name and an email for each student.',
  );
}

function transformHeader(header) {
  return camelcase(header.toLocaleLowerCase().replace(/\W/g, '_'));
}

export default StudentImportCSV;
