import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAuthError,
  selectAuthenticated,
  selectUser,
  selectIsEmailVerified,
} from './Authentication/selectors';
import { loginThunk, tokenCheckThunk } from './Authentication/actions';
import MediaLibrary from '../components/MediaLibrary';
import Settings from './Settings';
import StatusPage from '../components/StatusPage';
import Registration from './Authentication/Registration';
import Home from '../components/Home';
import routes from '../routes';
import { getDataFromLocalStorage } from '../utils/localStorage';
import { LOCAL_STORAGE } from '../constants/application';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { ResourceStalenessProvider } from '../components/ResourceStalenessContext';
import { GlobalStyle } from '../theme/GlobalStyle';
import { ForgotPassword } from './Authentication/ForgotPassword';
import { ResetPassword } from './Authentication/ResetPassword';
import { EmailVerifyCallback } from './Authentication/EmailVerifyCallback';
import { EmailSent } from '../components/Authentication/ForgotPassword/EmailSent';
import TestLibrary from '../components/TestLibrary';
import TestLibraryQuestionGroup from '../components/TestLibrary/QuestionGroup';
import PackageLibrary from '../components/Package/Library';
import PackageCreateFromScratch from '../components/Package/Create/FromScratch';
import StudentLibrary from '../components/Student/Library';
import StudentEdit from '../components/Student/Edit';
import TestLibraryQuestion from '../components/TestLibrary/Question';
import PackageLibraryPageTestPage from '../components/Package/Create/Content/Build/Page/TestPage';
import PackageLibraryPagePdfPage from '../components/Package/Create/Content/Build/Page/PdfPage';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

const App = () => {
  const error = useSelector(selectAuthError);
  const authenticated = useSelector(selectAuthenticated);
  const user = useSelector(selectUser);
  const isEmailVerified = useSelector(selectIsEmailVerified);
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getDataFromLocalStorage(LOCAL_STORAGE.AUTH);
    if (auth?.token) {
      dispatch(tokenCheckThunk());
    }
  }, [dispatch]);

  return (
    <React.Fragment>
      <GlobalStyle />
      <ResourceStalenessProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path={routes.client.home}
              element={
                <Home
                  authenticated={authenticated}
                  error={error}
                  isEmailVerified={isEmailVerified}
                  loginUser={(userData) => dispatch(loginThunk(userData))}
                  user={user}
                />
              }
            />
            <Route
              path={routes.client.registration}
              element={<Registration />}
            />
            <Route
              path={routes.client.verifyemail}
              element={<EmailVerifyCallback />}
            />
            <Route
              path={routes.client.forgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={routes.client.forgotPasswordEmailSent}
              element={<EmailSent />}
            />
            <Route
              path={routes.client.resetPassword}
              element={<ResetPassword />}
            />
            <Route
              path={routes.client.mediaLibrary}
              element={
                <ProtectedRoute
                  component={MediaLibrary}
                  path={routes.client.mediaLibrary}
                />
              }
            />
            <Route
              path={routes.client.packages}
              element={
                <ProtectedRoute
                  component={PackageLibrary}
                  path={routes.client.packages}
                />
              }
            />
            <Route
              path={routes.client.addPackages}
              element={
                <ProtectedRoute
                  component={PackageLibrary}
                  path={routes.client.addPackages}
                />
              }
            />
            <Route
              path={routes.client.addPackagesFromScratch}
              element={
                <ProtectedRoute
                  component={PackageCreateFromScratch}
                  path={routes.client.addPackagesFromScratch}
                />
              }
            />
            <Route
              path={routes.client.editPackages()}
              element={
                <ProtectedRoute
                  component={PackageCreateFromScratch}
                  path={routes.client.editPackages()}
                />
              }
            />
            <Route
              path={`${routes.client.settings}/*`}
              element={
                <ProtectedRoute
                  component={Settings}
                  path={`${routes.client.settings}/*`}
                />
              }
            />
            <Route
              path={routes.client.students}
              element={
                <ProtectedRoute
                  component={StudentLibrary}
                  path={routes.client.students}
                />
              }
            />
            <Route
              path={routes.client.editStudents()}
              element={
                <ProtectedRoute
                  component={StudentEdit}
                  path={routes.client.editStudents()}
                />
              }
            />
            <Route
              path={routes.client.inviteStudents}
              element={
                <ProtectedRoute
                  component={StudentLibrary}
                  path={routes.client.inviteStudents}
                />
              }
            />
            <Route
              path={routes.client.addStudentsManually}
              element={
                <ProtectedRoute
                  component={StudentLibrary}
                  path={routes.client.addStudentsManually}
                />
              }
            />
            <Route
              path={routes.client.testLibrary}
              element={
                <ProtectedRoute
                  component={TestLibrary}
                  path={routes.client.testLibrary}
                />
              }
            />
            <Route
              path={routes.client.addQuestionGroup}
              element={
                <ProtectedRoute
                  component={TestLibraryQuestionGroup}
                  path={routes.client.addQuestionGroup}
                />
              }
            />
            <Route
              path={routes.client.editQuestionGroup()}
              element={
                <ProtectedRoute
                  component={TestLibraryQuestionGroup}
                  path={routes.client.editQuestionGroup()}
                />
              }
            />
            <Route
              path={routes.client.addQuestion()}
              element={
                <ProtectedRoute
                  component={TestLibraryQuestion}
                  path={routes.client.addQuestion()}
                />
              }
            />
            <Route
              path={routes.client.editQuestion()}
              element={
                <ProtectedRoute
                  component={TestLibraryQuestion}
                  path={routes.client.editQuestion()}
                />
              }
            />
            <Route
              path={routes.client.addQuestionPage()}
              element={
                <ProtectedRoute
                  component={PackageLibraryPageTestPage}
                  path={routes.client.addQuestionPage()}
                />
              }
            />
            <Route
              path={routes.client.editQuestionPage()}
              element={
                <ProtectedRoute
                  component={PackageLibraryPageTestPage}
                  path={routes.client.editQuestionPage()}
                />
              }
            />

            <Route
              path={routes.client.addPdfPage()}
              element={
                <ProtectedRoute
                  component={PackageLibraryPagePdfPage}
                  path={routes.client.addPdfPage()}
                />
              }
            />
            <Route
              path={routes.client.editPdfPage()}
              element={
                <ProtectedRoute
                  component={PackageLibraryPagePdfPage}
                  path={routes.client.editPdfPage()}
                />
              }
            />
            <Route
              path={routes.client.NotFound}
              element={<StatusPage.NotFound path={routes.client.notFound} />}
            />
          </Routes>
        </BrowserRouter>
      </ResourceStalenessProvider>
    </React.Fragment>
  );
};

export default App;
