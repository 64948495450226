// USERS REDUCER
// import * as actionTypes from './actionTypes';
import * as studentActionTypes from '../../legacy/containers/Students/actionTypes';
import { reduceListToMapById, reduceListToListOfIds } from '../../utils/utils';

export const initialState = {
  error: null,
  loading: false,
  users: [],
  studentIds: [],
  students: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case studentActionTypes.ADD_STUDENTS_START:
      return { ...state, loading: true, error: null };
    case studentActionTypes.ADD_STUDENTS_FAIL:
      return { ...state, loading: false, error: action.payload.message };
    case studentActionTypes.ADD_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        studentIds: [
          ...state.studentIds,
          ...reduceListToListOfIds(action.payload.students),
        ],
        students: {
          ...state.students,
          ...reduceListToMapById(action.payload.students),
        },
      };
    case studentActionTypes.GET_STUDENTS_START:
      return { ...state, loading: true, error: null };
    case studentActionTypes.GET_STUDENTS_FAIL:
      return { ...state, loading: false, error: action.payload.message };
    case studentActionTypes.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        studentIds: reduceListToListOfIds(action.payload.students),
        students: reduceListToMapById(action.payload.students),
      };
    default:
      return state;
  }
};

export default reducer;
