import { createGlobalStyle } from 'styled-components/macro';
import { COLORS } from './theme';

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-red: ${COLORS.red};
    --color-blue: ${COLORS.blue};
    --color-teal: ${COLORS.teal};
    --color-green: ${COLORS.green};
    --color-darkgrey: ${COLORS.darkgrey};
    --color-lightgrey: ${COLORS.lightgrey};
    --color-midgrey: ${COLORS.midgrey};
    --color-grey: ${COLORS.grey};
    --color-offwhite: ${COLORS.offwhite};
    --color-white: ${COLORS.white};
    --color-transparent: ${COLORS.transparent};
  }

  h1 {
    font-size: 20px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 34px;
    }
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--color-blue);
  }

  h2 {
    font-size: 20px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 34px;
    }

    font-weight: normal;
    text-align: center;
    color: var(--color-grey);
  }

  h3 {
    margin: 0;
    font-size: 18px;
    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 24px;
    }
    font-weight: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--color-grey);
  }

  input {
    height: 50px;
    border-radius: 8px;
    border: solid 1px var(--color-lightgrey);
    background-color: var(--color-white);
    font-size: 20px;
    margin: 5px 0 10px;
    padding: 0 20px;
  }

  label {
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 0.5px;
    color: var(--color-darkgrey);
  }

  a {
    color: var(--color-blue);
  }

  p {
    margin-block-start: 0.2em;
    margin-block-end: 0.2em;
    ${({ theme }) => theme.mediaQueries.md} {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }
`;
