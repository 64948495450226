import React from 'react';
import { IconImage, Level, LevelContainer, Line } from './styled';

const QuestionItem = ({ difficulty, icon, question, questionNumber }) => {
  const level = difficulty || '-';
  return (
    <>
      <LevelContainer>
        <span>{questionNumber + 1}</span>
        {difficulty && difficulty !== 'undefined' && (
          <Level>{`Level ${level}`}</Level>
        )}
      </LevelContainer>
      <Line />
      <IconImage src={icon} alt="Question Icon" />
      <span>{question}</span>
    </>
  );
};

export default QuestionItem;
