import React from 'react';
import PropTypes from 'prop-types';
import bgSVG from '../../assets/images/bgGraphic.svg';
import styled from 'styled-components/macro';
import theme from '../../theme';
import { containerCoversAbsolutely } from '../../styleTokens';
import { space, color } from 'styled-system';

const Container = styled.div`
  ${color}
  ${space}
  padding: ${({ theme }) => theme.space[8]}
    ${({ theme }) => theme.space[3]};
  color: #fff;
  text-align: center;
  position: relative;
  * {
    opacity: initial;
    z-index: 2;
    position: relative;
  }
`;

const Background = styled.div`
  background-size: 1500px;
  background-attachment: fixed;
  background-image: url(${bgSVG});
  opacity: 0.34 !important;
  z-index: 1 !important;
  ${containerCoversAbsolutely};
`;

const Div = styled.div`
  ${containerCoversAbsolutely};
  z-index: ${({ theme }) => theme.zIndices[2]};
  display: block;
`;
/**
 * a wrapper that provide the colored background against a offwhite svg background imgae
 * usage would be <DecorativeBg><div> your content here </div></DecorativeBg>
 * @param {Object} props
 * @param {String} props.color styled system color (allows for bg and color to be applied as props)
 */
export const DecorativeBg = ({ children, ...rest }) => (
  <Container {...rest}>
    <Background />
    <Div>{children}</Div>
  </Container>
);

DecorativeBg.propTypes = {
  color: PropTypes.oneOf(Object.keys(theme.colors)),
  children: PropTypes.node,
};

export default DecorativeBg;
