import styled from 'styled-components/macro';
import { Box } from 'rebass';

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin-top: 12px;
  padding: 0 0 70px;
  box-shadow: 0 2px 4px 0 #b6b6b6;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  min-height: 92px;
  align-items: center;
  padding: 0 5%;
  background-color: #fff;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  width: 370px;
  height: 39px;
  font-size: 30px;
  color: #2cabe0;
`;
export const ExtraOptionsSidebar = styled(Box)`
  width: 100%;
  max-width: 300px;
  padding-left: 35px;
`;
export const ContentContainer = styled.div`
  padding: 43px;
  flex-grow: 1;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #c9c9c9;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  min-height: 680px;
  .links {
    display: flex;
    font-size: 12px;
    color: #2cabe0;
    clear: both;
    margin-top: 5px;
    &.flexStart {
      align-self: flex-start;
    }
    &.flexEnd {
      align-self: flex-end;
    }
  }
  .linksChoices {
    padding: 0px;
    margin: -20px -10px;
    height: 40px;
    font-size: 12px;
    color: #2cabe0;
    clear: both;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : '700px')};
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const Input = styled.input`
  height: auto;
  font-size: 16px;
  padding: 7px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  :focus-visible {
    outline-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Label = styled.label`
  height: 26px;
  display: flex;
  margin-bottom: 5px;
  font-size: 20px;
  color: #424242;
  align-self: ${({ alignSelf }) => alignSelf || 'center'};
  justify-content: ${({ alignSelf }) => alignSelf || 'center'};
`;

export const LabelLeft = styled(Label)`
  text-align: left;
  margin: 10px 32px 3px 0px;
  display: flex;
  align-items: left;
  justify-content: left;
`;

export const TextAreaShortHorizontal = styled.textarea`
  resize: vertical;
  width: 100%;
  height: 149px;
  margin: 0 auto;
  padding: 9px 11px 8px 12px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  font-family: inherit;
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  ::placeholder,
  ::-webkit-input-placeholder {
    width: 365px;
    height: 130px;
    font-size: 12px;
    color: #b6b6b6;
  }
  :focus-visible {
    outline-color: ${({ theme }) => theme.colors.blue};
  }
  textarea + div {
    width: 388px;
    margin: 0 auto;
    display: flex;
    border: 1px solid #c9c9c9;
  }
`;

export const QuestionField = styled.textarea`
  resize: vertical;
  box-sizing: border-box;
  width: 100%;
  padding: 9px 10px 9px 11px;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  font-family: inherit;
  min-height: 50px;
  :focus-visible {
    outline-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const ExtraOptions = styled.div`
  margin: 33px 0px;
`;

export const Choices = styled.span`
  display: table;
  padding: 1px 1px;
  margin: 0;
`;

export const Select = styled.select`
  width: 193px;
  height: 30px;
  margin: 5px 77px 0 0;
  padding: 3px 0 3px 0px;
  border-radius: 1px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
`;

export const Radio = styled.input`
  width: 13px;
  height: 13px;
  margin: 8px 5px 50px 2px;
`;

export const Checkbox = styled.input`
  width: 15px;
  height: 15px;
`;

export const AnswerItem = styled.textarea`
  font-family: inherit;
  resize: vertical;
  background-repeat: no-repeat;
  background-position: 10px center;
  padding: 15px 10px 1px 30px;
  width: 100%;
  min-height: 63px;
  height: 100%;
  border-radius: 3px;
  border: solid 1px #c9c9c9;
  background-color: #fff;
  box-sizing: border-box;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 12px;
    color: #b6b6b6;
  }
  :focus-visible {
    outline-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const AnswerLabelChoice = styled.b`
  font-family: inherit;
  top: 5px;
  margin-left: ${(props) => (props.vertical ? '-5px' : '0px')};
  left: ${(props) => (props.vertical ? '0' : '-5px')};

  position: absolute;
  font-size: 18px;
  color: #424242;
`;

export const AnswerContainer = styled(Box)`
  &:last-child {
    padding-bottom: 0;
  }
`;

export const FlexItem = styled.div`
  margin-left: 5px;
  margin-right: 5px;
`;

export const MediaItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px #c9c9c9;
  border-radius: 3px;
  background-color: #fff;
  padding: 10px 10px 10px
    ${({ answerLetter }) => (answerLetter ? '30px' : '10px')};
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 10px center;
  box-sizing: border-box;
  width: 100%;
  small {
    font-size: 12px;
    color: #b6b6b6;
    border-top: 1px solid #c9c9c9;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    padding-top: 10px;
    margin-top: 10px;
    text-overflow: ellipsis;
  }
  img,
  video,
  audio {
    width: 100%;
    box-sizing: border-box;
  }
  img {
    max-height: 130px;
    object-fit: cover;
  }
  &.content {
    img {
      max-height: 300px;
      object-fit: contain;
    }
    &.horiz {
      flex-grow: 1;
      justify-content: space-between;
      img {
        max-height: 330px;
      }
    }
  }
`;
