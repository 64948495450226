import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const UpdateQuestionGroupContext = React.createContext();

function UpdateQuestionGroupProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateQuestionGroup = useCallback(async (id, payload) => {
    try {
      setLoading(true);
      const response = await axiosInstance.put(
        `/question-groups/${id}`,
        payload,
      );

      return {
        questionGroup: getQuestionGroupFromResponse(response),
        success: true,
        error: null,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    updateQuestionGroup,
    errorMessage,
    hasLoaded,
  };

  return (
    <UpdateQuestionGroupContext.Provider value={value}>
      {children}
    </UpdateQuestionGroupContext.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  name: string;
 * }} QuestionGroup
 */

/**
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  updateQuestionGroup: (id: string, payload: QuestionGroup) => Promise<{ success: boolean; errorMessage: string; questionGroup?: QuestionGroup}>
 *  errorMessage: string
 * }}
 */

function useUpdateQuestionGroup() {
  const context = React.useContext(UpdateQuestionGroupContext);

  if (context === undefined) {
    throw new Error(
      'useUpdateQuestionGroup must be used within a UpdateQuestionGroupProvider',
    );
  }

  return context;
}

function getQuestionGroupFromResponse(response) {
  if (!response.data) {
    return null;
  }

  const questionGroup = response.data;
  return {
    id: questionGroup.id,
    name: questionGroup.name,
  };
}


export { UpdateQuestionGroupProvider, useUpdateQuestionGroup };
