import React from 'react';
import { bool, string } from 'prop-types';
import styled from 'styled-components/macro';
import { signOut } from '../../utils/auth';
import { LinkButton } from '../UI/Link';
import { ExternalLayout } from './ExternalLayout';
import appStoreIcon from '../../assets/images/app-store.svg';
import qrCodeIcon from '../../assets/images/qrcode.svg';

export const VerificationLayout = ({
  title,
  subtitle,
  forTeachers = false,
  downloadLink = false,
}) => {
  return (
    <ExternalLayout forTeachers={forTeachers}>
      <Container>
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
        {downloadLink && (
          <IconContainer>
            <a
              href="https://apps.apple.com/app/classed-up/id1153433683"
              target="blank"
            >
              <Icon src={appStoreIcon} />
            </a>
          </IconContainer>
        )}
        <QRCodeContainer>
          <QRCodeIcon src={qrCodeIcon} />
        </QRCodeContainer>
        {forTeachers && (
          <MessageContainer>
            <LinkButton onClick={signOut}>Back to Sign in</LinkButton>
          </MessageContainer>
        )}
      </Container>
    </ExternalLayout>
  );
};

const QRCodeContainer = styled.div`
  display: flex;
  margin: 50px;
  justify-content: center;
`;

const IconContainer = styled.div`
  display: flex;
  margin: 50px;
  justify-content: center;
`;

const QRCodeIcon = styled.img`
  height: 250px;
`;

const Icon = styled.img`
  height: 38px;
`;

VerificationLayout.propTypes = {
  downloadLink: bool,
  forTeachers: bool,
  title: string.isRequired,
  subtitle: string.isRequired,
};

const Container = styled.div`
  margin: 0 70px;

  > :first-child {
    padding: 0 0 48px;
  }
`;

const MessageContainer = styled.div`
  opacity: 0.87;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.25px;
  text-align: center;
`;
