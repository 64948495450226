import styled from 'styled-components/macro';
import React from 'react';
import { typography, space, color, layout } from 'styled-system';
import { Link } from 'react-router-dom';
import { Match } from '../Navigation';

export const StyledLink = styled(Link)`
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.darkgrey};
  :visited {
    color: ${({ theme }) => theme.colors.darkgrey};
  }

  border-top: none;
  border-right: none;
  border-bottom: none;
  text-decoration: none;
  font-weight: normal;
  text-transform: uppercase;
  display: block;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: transparent;
  padding: ${({ theme }) => theme.space[2]};
  margin: 0 ${({ theme }) => theme.space[2]};
  ${({ theme }) => theme.mediaQueries.md} {
    display: inline-block;
    border-left: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }
  &.active {
    color: ${({ theme }) => theme.colors.blue};
    border-bottom-color: ${({ theme }) => theme.colors.blue};
    border-left-color: ${({ theme }) => theme.colors.blue};

    ${color};
  }
  ${typography};
  ${layout};
  ${space};
`;

export const Navlink = ({ exact, to, children, className, ...rest }) => {
  return (
    <Match path={exact ? to : `${to}/*`}>
      {(props) => (
        <StyledLink
          to={to}
          {...rest}
          className={props.match ? `active ${className}` : className}
        >
          {children}
        </StyledLink>
      )}
    </Match>
  );
};

export default Navlink;
