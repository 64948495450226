import React from 'react';
import { GetAssetsProvider, useGetAssets } from './getAssets';
import { UpdateAssetProvider, useUpdateAsset } from './updateAsset';
import { UploadAssetProvider, useUploadAsset } from './uploadAsset';
import { DeleteAssetsProvider, useDeleteAssets } from './deleteAssets';

function AssetProvider({ children }) {
  return (
    <GetAssetsProvider>
      <UpdateAssetProvider>
        <UploadAssetProvider>
          <DeleteAssetsProvider>{children}</DeleteAssetsProvider>
        </UploadAssetProvider>
      </UpdateAssetProvider>
    </GetAssetsProvider>
  );
}

export {
  AssetProvider,
  useDeleteAssets,
  useGetAssets,
  useUpdateAsset,
  useUploadAsset,
};
