import React from 'react';
import { getInitials } from '../../../utils/string';
import {
  Container,
  Title,
  Subtitle,
  TextContainer,
  IconContainer,
  BadgeContainer,
} from './styled';
import Picture from './Picture';

const Card = ({
  quantity,
  color,
  title,
  subtitle,
  badgeText,
  icon,
  CardContainer = Container,
  CardTitle = Title,
  PictureContainer,
  ...props
}) => {
  return (
    <CardContainer {...props}>
      {badgeText ? <BadgeContainer>{badgeText}</BadgeContainer> : null}
      <Picture
        initials={getInitials(title)}
        quantity={quantity}
        color={color}
        Container={PictureContainer}
      />
      <TextContainer>
        <CardTitle>{title}</CardTitle>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TextContainer>
      {icon && (
        <IconContainer>
          {icon}
        </IconContainer>
      )}
    </CardContainer>
  );
};

export default Card;
