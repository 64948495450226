import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../axios';
import strings from '../../../constants/strings';
import { updateUser } from '../../../containers/Authentication/actions';
import routes from '../../../routes';

const ProfileContext = React.createContext();

function ProfileProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const updateProfile = useCallback(async (userId, userData) => {
    try {
      setLoading(true);
      await axiosInstance.patch(routes.api.user(userId), userData);
      dispatch(updateUser(userData)) // have to use redux to update logged in user
      return { userData, success: true, message: strings.en.actions.profileUpdate.success };
    } catch (error) {
      return { userData, success: false, message: strings.en.actions.profileUpdate.fail };
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const value = {
    loading,
    updateProfile,
  };

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  );
}

/**
 * @returns {{
 *  updateProfile: (userData: Object) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useProfile() {
  const context = React.useContext(ProfileContext);

  if (context === undefined) {
    throw new Error(
      'useProfile must be used within a ProfileContext',
    );
  }

  return context;
}

export { ProfileProvider, useProfile };