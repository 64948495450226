import React from 'react';
import styled from 'styled-components/macro';
import strings from '../../constants/strings';
import NoStudentsIcon from '../../assets/images/students.svg';

export const StudentEmptyList = ({ onClick }) => (
  <Container onClick={onClick}>
    <Image src={NoStudentsIcon} />
    <Title>{strings.en.students.noStudents.title}</Title>
  </Container>
);

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 700px;
    padding: 100px;
  }
`;

const Title = styled.h2``;

const Image = styled.img`
  width: 200px;
  margin-bottom: 50px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: unset;
  }
`;
