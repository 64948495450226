import React from 'react';
import PropTypes from 'prop-types';
import bgGraphic from '../../assets/images/bgGraphic.svg';
import { CompactCard } from '../UI';
import { Text, Box, Image } from 'rebass';
import styled from 'styled-components';

const StyledCard = styled(CompactCard)`
  :hover {
    outline: solid 1px #2cabe0;
    cursor: pointer;
  }
`;
export const PackageCard = ({
  id,
  title,
  subject,
  customAction,
  thumbnailImage,
  badgeText,
  ...rest
}) => (
  <Box {...rest} data-testid={id} px={4} py={3}>
    <StyledCard
      badgeText={badgeText}
      m={0}
      title={title}
      image={() =>
        thumbnailImage ? (
          <Image
            src={thumbnailImage}
            alt={title}
            width="100%"
            maxHeight="48%"
            style={{ objectFit: 'cover', objectPosition: 'center top' }}
          />
        ) : (
          <Box
            flex="0 0 160px"
            bg="red"
            maxHeight="45%"
            style={{
              backgroundImage: `url(${bgGraphic})`,
              backgroundSize: 'cover',
            }}
          />
        )
      }
      description={() => (
        <Text as="div" color="grey" mt={2} textAlign="left">
          <Text
            as="div"
            fontWeight={600}
            letterSpacing={1}
            style={{ textTransform: 'uppercase' }}
          >
            {subject}
          </Text>
        </Text>
      )}
      customAction={customAction}
    />
  </Box>
);

PackageCard.propTypes = {
  title: PropTypes.string,
  subject: PropTypes.string,
  numTestSets: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PackageCard.defaultProps = {
  numTestSets: '',
};
export default PackageCard;
