import axiosInstance from '../../../axios';
import React, { useCallback, useState } from 'react';
import { getErrorMessage } from '../../../utils/request';

const GetQuestionGroupInPage = React.createContext();

function GetQuestionGroupsInPageProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getQuestionGroupsInPage = useCallback(async (id) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/pages/${id}/question-groups`);

      return {
        success: true,
        error: null,
        questionGroups: data,
      };
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      return { success: false, errorMessage };
    } finally {
      setLoading(false);
      setHasLoaded(true);
    }
  }, []);

  const value = {
    loading,
    getQuestionGroupsInPage,
    errorMessage,
    hasLoaded,
  };

  return (
    <GetQuestionGroupInPage.Provider value={value}>
      {children}
    </GetQuestionGroupInPage.Provider>
  );
}

/**
 * @typedef {{
 *  id: string;
 *  name: string;
 *  numQuestions: number;
 *  questionGroupNumber: number;
 * }} QuestionGroup
 * @returns {{
 *  loading: boolean,
 *  hasLoaded: boolean,
 *  getQuestionGroupsInPage: (id: string, questionGroupId: string) => Promise<{ success: boolean; errorMessage: string; questionGroups: QuestionGroup[] }>
 *  errorMessage: string
 * }}
 */

function useGetQuestionGroupsInPage() {
  const context = React.useContext(GetQuestionGroupInPage);

  if (context === undefined) {
    throw new Error(
      'useGetQuestionGroupsInPage must be used within a GetQuestionGroupsInPageProvider',
    );
  }

  return context;
}

export { GetQuestionGroupsInPageProvider, useGetQuestionGroupsInPage };
