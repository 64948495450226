// route config
import { API_BASE_URL } from './constants/api';

const ADD_PACKAGES = '/packages/create';
const INVITE_STUDENTS = '/students/invite';

export const routes = {
  client: {
    unauthorized: '/401',
    notFound: '/404',
    addPackages: ADD_PACKAGES,
    addPackagesFromScratch: `${ADD_PACKAGES}/from-scratch`,
    addStudentsManually: '/students/invite/add-manually',
    addQuestionGroup: '/test-library/create',
    addQuestion: (questionGroupId, questionFormat, questionNumber) =>
      `/test-library/${
        questionGroupId ? questionGroupId : ':questionGroupId'
      }/new-question/${questionFormat ? questionFormat : ':questionFormat'}/${
        !isNaN(questionNumber) ? questionNumber : ':questionNumber'
      }`,
    addQuestionPage: (packageId, pageNumber) =>
      `/packages/${packageId ? packageId : ':id'}/pages/question-page/new${
        pageNumber !== undefined ? '?pageNumber=' + pageNumber : ''
      }`,
    editQuestionPage: (packageId, pageId) =>
      `/packages/${packageId ? packageId : ':id'}/pages/question-page/${
        pageId ? pageId : ':pageId'
      }`,
    addPdfPage: (packageId, pageNumber) =>
      `/packages/${packageId ? packageId : ':id'}/pages/pdf-page/new${
        pageNumber !== undefined ? '?pageNumber=' + pageNumber : ''
      }`,
    editPdfPage: (packageId, pageId) =>
      `/packages/${packageId ? packageId : ':id'}/pages/pdf-page/${
        pageId ? pageId : ':pageId'
      }`,
    addTestSet: '/test-library/create',
    editPackages: (id) => `/packages/${id ? id : ':id'}/edit`,
    editStudents: (id) => `/students/${id ? id : ':id'}/edit`,
    editTestSet: (id) => `/test-library/${id ? id : ':id'}/edit`, // TODO: remove this.
    editQuestionGroup: (id) => `/test-library/${id ? id : ':id'}/edit`,
    editQuestion: (questionGroupId, questionId) =>
      `/test-library/${
        questionGroupId ? questionGroupId : ':questionGroupId'
      }/question/${questionId ? questionId : ':questionId'}`,
    forgotPassword: 'forgot-password',
    forgotPasswordEmailSent: '/email-sent',
    home: '/',
    inviteStudents: INVITE_STUDENTS,
    mediaLibrary: '/media-library',
    packages: '/packages',
    registration: '/registration',
    resetPassword: 'reset-password',
    settings: '/settings',
    profile: '/settings/',
    billingSettings: '/settings/billing',
    changePassword: '/settings/password',
    students: '/students',
    testLibrary: '/test-library',
    testSet: (id) => `/test-library/${id}`,
    verifyemail: '/verifyemail',
  },
  api: {
    asset: (id) => `/assets/${id}`,
    authenticate: '/login/check',
    baseURL: API_BASE_URL,
    changePassword: '/changepassword',
    forgotPassword: '/login/forgot',
    login: '/login',
    package: (id) => `/packages/${id}`,
    packagePages: (id) => `/packages/${id}/pages`,
    packagePublish: (id) => `/packages/${id}/publish`,
    packages: '/packages',
    packageStudents: (packageId) => `packages/${packageId}/students`,
    packageStudent: (packageId, studentId) =>
      `packages/${packageId}/students/${studentId}`,
    pageQuestionGroups: (id) => `/pages/${id}/question-groups`,
    page: (id) => `/pages/${id}`,
    pageQuestionGroup: (pageId, questionGroupId) =>
      `/pages/${pageId}/question-groups/${questionGroupId}`,
    questionGroup: (id) => `/question-groups/${id}`,
    questionGroupQuestions: (id) => `/question-groups/${id}/questions`,
    questionGroups: '/question-groups',
    questions: (id) => `/questions/${id}`,
    register: '/register',
    resetPassword: '/resetpassword',
    resetPasswordCheck: '/resetpassword/check',
    students: '/students',
    tokenRefresh: '/token',
    uploads: '/assets',
    verifyemail: '/verifyemail',
    user: (id) => `/users/${id}`,
  },
};

export default routes;
