import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
export const selectAuth = createSelector(
  state => state.auth,
  auth => auth,
);

export const selectUser = createSelector(selectAuth, auth => auth.user);

export const selectAuthError = createSelector(selectAuth, auth => auth.error);

export const selectAuthLoading = createSelector(
  selectAuth,
  auth => auth.loading,
);

export const selectTokensPresent = createSelector(
  selectAuth,
  auth => auth.tokensPresent,
);

export const selectAuthenticated = createSelector(
  selectUser,
  user => !isEmpty(user), // for now a authenticated user is defined as having a user object
);

export const selectIsEmailVerified = createSelector(selectUser, user => {
  const result =
    Boolean(user?.emailVerifiedDate) || user?.needsEmailVerified === false;

  return result;
});

export const selectPasswordChanged = createSelector(selectUser, user =>
  Boolean(user?.needsPasswordChanged),
);
