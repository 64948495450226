import { useEffect, useCallback, useState } from 'react';

// This overrides the right click context menu with your own menu.
// props: {targetRef: ref}
const useContextMenu = ({ targetRef }) => {
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);

  const handleContextMenu = useCallback(
    (event) => {
      if (targetRef.current && targetRef.current.contains(event.target)) {
        event.preventDefault();
        setAnchorPoint({ x: event.pageX, y: event.pageY });
        setShow(true);
      }
    },
    [setShow, setAnchorPoint, targetRef],
  );

  const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  });

  return { anchorPoint, show };
};

export default useContextMenu;
