import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const LinkStudentToPackageContext = React.createContext();

function LinkStudentToPackageProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const linkStudentToPackage = useCallback(async (studentId, packageId) => {
    try {
      setLoading(true);

      await axiosInstance.put(`/packages/${packageId}/students/${studentId}`);

      return { studentId, packageId, success: true, error: null };
    } catch (error) {
      return { studentId, packageId, success: false, error };
    } finally {
      setLoading(false);
    }
  }, []);

  const value = {
    loading,
    linkStudentToPackage,
  };

  return (
    <LinkStudentToPackageContext.Provider value={value}>
      {children}
    </LinkStudentToPackageContext.Provider>
  );
}

/**
 * @returns {{
 *  linkStudentToPackage: (studentId: string, packageId: string) => Promise<void>,
 *  loading: boolean
 * }}
 */
function useLinkStudentToPackage() {
  const context = React.useContext(LinkStudentToPackageContext);

  if (context === undefined) {
    throw new Error(
      'useLinkStudentToPackage must be used within a LinkStudentToPackageContext',
    );
  }

  return context;
}

export { LinkStudentToPackageProvider, useLinkStudentToPackage };
