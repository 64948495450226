import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from '../../UI';
import TestLibraryQuestionGroupCard from './Card';
import routes from '../../../routes';

const TestLibraryQuestionGroupGrid = ({ questionGroups }) => (
  <Container>
    {questionGroups.map((q) => (
      <StyledLink to={routes.client.editQuestionGroup(q.id)} key={q.id}>
        <TestLibraryQuestionGroupCard {...q} />
      </StyledLink>
    ))}
  </Container>
);

TestLibraryQuestionGroupGrid.propTypes = {
  students: PropTypes.arrayOf(PropTypes.object),
};

const Container = styled.div`

  display: inline-flex;
  flex-wrap: wrap;
  padding:24px;
`;

const StyledLink = styled(Link)`
  margin: 12px;
  :hover {
    text-decoration: none;
  }
`;

export default TestLibraryQuestionGroupGrid;
