import React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Flex } from 'rebass';
import { PackageTestPageStoreContext } from '../../../../../../../contexts/reducers/package/TestPage';
import imagePlaceholder from '../../../../../../../assets/images/bgImagePreviewSmall.png';
import ImageSelector from '../../../../../../UI/ImageSelector';
import { questionOrders, restartModes } from '../../constants';
import {
  Checkbox,
  ContentContainer,
  HelperText,
  Input,
  InputGroup,
  Label,
  NumInput,
  Radio,
  RadioLabel,
} from './styled';

import MediaLibrarySelector from '../../../../../../MediaLibrary/Selector';
import { types } from '../../../../../../MediaLibrary/Media';
import { useGetAssets } from '../../../../../../../contexts/api/asset';
import { FaTrash } from 'react-icons/fa';
import { ButtonContainer } from '../../../Properties/styled';
let onMediaSelectedCallback = () => null;

const PackageLibraryPageTestPageProperties = ({ debounce }) => {
  const { state, update } = useContext(PackageTestPageStoreContext);
  const {
    title,
    header,
    numQuestions,
    restartMode,
    questionOrder,
    showInstantFeedback,
    showResultsAnswers,
    testDuration,
  } = state.properties;
  const { assets } = useGetAssets();
  const [isSelectingMedia, setIsSelectingMedia] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [backgroundImagePreview, setBackgroundImagePreview] = useState(null);
  const durationInMinutes = testDuration ? testDuration / 60 : testDuration;
  const minutesToSeconds = (minutes) => minutes * 60;

  useEffect(() => {
    if (state.properties.image && assets) {
      const asset = assets.find((asset) => asset.id === state.properties.image);

      if (asset) {
        setImagePreview(asset.src);
      }
    }
    return () => {
      setImagePreview(null);
    };
  }, [assets, state.properties.image, state.properties.packageCardImage]);

  useEffect(() => {
    if (state.properties.backgroundImage && assets) {
      const asset = assets.find(
        (asset) => asset.id === state.properties.backgroundImage,
      );

      if (asset) {
        setBackgroundImagePreview(asset.src);
      }
    }
    return () => {
      setBackgroundImagePreview(null);
    };
  }, [assets, state.properties.backgroundImage]);

  const updateProperties = useCallback(
    (prop, value) => {
      update({ properties: { ...state.properties, [prop]: value } });
    },
    [update, state.properties],
  );

  const onUploadImage = (picture) => {
    updateProperties('image', picture);
  };

  const onUploadBackgroundImage = (picture) => {
    updateProperties('backgroundImage', picture);
  };

  const openMediaSelector = (callback) => {
    setIsSelectingMedia(true);
    onMediaSelectedCallback = callback;
  };
  // need to get what type of image this is (packageImage or packageCardImage) and update the preview image
  // for respective type with the right asset from the getAssets() context
  const selectMedia = (m) => {
    onMediaSelectedCallback(m.id);
    closeMediaSelector();
  };

  const closeMediaSelector = () => {
    setIsSelectingMedia(false);
    onMediaSelectedCallback = () => {};
  };

  const handleRemovePreviewImage = () => {
    setImagePreview(null);
    update({ properties: { ...state.properties, image: null } });
  };
  const handleRemoveBackgroundImage = () => {
    setBackgroundImagePreview(null);
    update({ properties: { ...state.properties, backgroundImage: null } });
  };

  if (isSelectingMedia) {
    return (
      <MediaLibrarySelector
        showTypes={[types.IMAGE]}
        onSelectMedia={selectMedia}
        onClose={closeMediaSelector}
        style={{ padding: 0, left: '0' }}
      />
    );
  }

  return (
    <ContentContainer>
      <Flex justifyContent="space-between">
        <div>
          <InputGroup>
            <Label>Header</Label>
            <Input
              type="text"
              value={header}
              onChange={(e) => {
                updateProperties('header', e.target.value);
                debounce();
              }}
            />
          </InputGroup>
          <InputGroup>
            <Label>Title</Label>
            <Input
              type="text"
              value={title}
              onChange={(e) => {
                e.persist();
                debounce();
                updateProperties('title', e.target.value);
              }}
            />
          </InputGroup>
          <Label as="label" mb={3}>
            Image
          </Label>
          <Flex alignItems="flex-start" marginBottom="30px">
            <ImageSelector
              containerStyle={{ marginTop: 15, width: 255, height: 206 }}
              backgroundImageSrc={imagePlaceholder}
              backgroundImageProps={{
                style: {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                },
              }}
              selectedImageSrc={imagePreview}
              selectedImageStyle={{
                borderRadius: 15,
                width: 268,
                height: 210,
                margin: 1,
              }}
              onClick={() => openMediaSelector(onUploadImage)}
            />
            {imagePreview && (
              <ButtonContainer
                onClick={handleRemovePreviewImage}
                style={{ padding: '17px 20px' }}
              >
                <FaTrash />
              </ButtonContainer>
            )}
          </Flex>
          <Label as="label" mb={3}>
            Background Image
          </Label>
          <Flex alignItems="flex-start" marginBottom="30px">
            <ImageSelector
              containerStyle={{ marginTop: 15, width: 255, height: 206 }}
              backgroundImageSrc={imagePlaceholder}
              selectedImageSrc={backgroundImagePreview}
              backgroundImageProps={{
                style: {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                },
              }}
              selectedImageStyle={{
                borderRadius: 15,
                width: 268,
                height: 210,
                margin: 1,
              }}
              onClick={() => openMediaSelector(onUploadBackgroundImage)}
            />
            {backgroundImagePreview && (
              <ButtonContainer
                onClick={handleRemoveBackgroundImage}
                style={{ padding: '17px 20px' }}
              >
                <FaTrash />
              </ButtonContainer>
            )}
          </Flex>
        </div>
        <div style={{ paddingLeft: '30px', width: '470px' }}>
          <InputGroup>
            <Label>Number of Questions</Label>
            <HelperText>
              The number of questions in the test. If there are more questions
              available, only this many will appear in the student&apos;s test.
            </HelperText>
            <NumInput
              type="number"
              value={numQuestions}
              onChange={(e) => {
                updateProperties('numQuestions', e.target.value);
                debounce();
              }}
            />
          </InputGroup>

          <InputGroup>
            <Label as="div">Question Order</Label>
            <HelperText>
              The order that questions will appear in the student&apos;s test.
              Questions will be taken from the specified Question Groups.
            </HelperText>
            <Radio
              type="radio"
              id={`TestPagePropertiesQuestionOrder-${questionOrders.RANDOM}`}
              name="question-order"
              checked={questionOrder === questionOrders.RANDOM}
              onChange={(e) => {
                updateProperties('questionOrder', questionOrders.RANDOM);
                debounce();
              }}
            />
            <RadioLabel
              htmlFor={`TestPagePropertiesQuestionOrder-${questionOrders.RANDOM}`}
            >
              Random
            </RadioLabel>
            <Radio
              type="radio"
              id={`TestPagePropertiesQuestionOrder-${questionOrders.IN_ORDER}`}
              name="question-order"
              checked={questionOrder === questionOrders.IN_ORDER}
              onChange={(e) => {
                updateProperties('questionOrder', questionOrders.IN_ORDER);
                debounce();
              }}
            />
            <RadioLabel
              htmlFor={`TestPagePropertiesQuestionOrder-${questionOrders.IN_ORDER}`}
            >
              In-Order
            </RadioLabel>
            <Radio
              type="radio"
              id={`TestPagePropertiesQuestionOrder-${questionOrders.ADAPTIVE}`}
              name="question-order"
              checked={questionOrder === questionOrders.ADAPTIVE}
              onChange={(e) => {
                updateProperties('questionOrder', questionOrders.ADAPTIVE);
                debounce();
              }}
            />
            <RadioLabel
              htmlFor={`TestPagePropertiesQuestionOrder-${questionOrders.ADAPTIVE}`}
            >
              Adaptive
            </RadioLabel>
            <br />
            <Radio
              type="radio"
              id={`TestPagePropertiesQuestionOrder-${questionOrders.RANDOM_PROP_DIST}`}
              name="question-order"
              checked={questionOrder === questionOrders.RANDOM_PROP_DIST}
              onChange={(e) => {
                updateProperties(
                  'questionOrder',
                  questionOrders.RANDOM_PROP_DIST,
                );
                debounce();
              }}
            />
            <RadioLabel
              htmlFor={`TestPagePropertiesQuestionOrder-${questionOrders.RANDOM_PROP_DIST}`}
            >
              Random - Proportional Question Group Distribution
            </RadioLabel>
            <br />
            <Radio
              type="radio"
              id={`TestPagePropertiesQuestionOrder-${questionOrders.RANDOM_EVEN_DIST}`}
              name="question-order"
              checked={questionOrder === questionOrders.RANDOM_EVEN_DIST}
              onChange={(e) => {
                updateProperties(
                  'questionOrder',
                  questionOrders.RANDOM_EVEN_DIST,
                );
                debounce();
              }}
            />
            <RadioLabel
              htmlFor={`TestPagePropertiesQuestionOrder-${questionOrders.RANDOM_EVEN_DIST}`}
            >
              Random - Even Question Group Distribution
            </RadioLabel>
          </InputGroup>

          <InputGroup>
            <Label as="div">Display Answers</Label>
            <HelperText>
              Show the correct answer immediately after the student has answered
              a question, or show the correct answers when the student has
              completed the test. Leave boxes unchecked to not display any
              correct answers.
            </HelperText>
            <Flex alignItems="center">
              <Checkbox
                type="checkbox"
                id={`TestPagePropertiesDisplayAnswer-immediately`}
                name="display-answer-instant-feedback"
                checked={showInstantFeedback}
                onChange={(e) => {
                  updateProperties('showInstantFeedback', !showInstantFeedback);
                  debounce();
                }}
              />
              <RadioLabel
                htmlFor={`TestPagePropertiesDisplayAnswer-immediately`}
              >
                Immediately
              </RadioLabel>
              <Checkbox
                type="checkbox"
                id={`TestPagePropertiesDisplayAnswer-atEnd`}
                name="display-answer-results"
                checked={showResultsAnswers}
                onChange={(e) => {
                  updateProperties('showResultsAnswers', !showResultsAnswers);
                  debounce();
                }}
              />
              <RadioLabel htmlFor={`TestPagePropertiesDisplayAnswer-atEnd`}>
                At End
              </RadioLabel>
            </Flex>
          </InputGroup>

          <InputGroup>
            <Label as="div">Test Restart</Label>
            <HelperText>
              When a student starts the test for a second time, this specifies
              whether the test restarts with a new set of questions, continue
              from the previous set of quetsions, or prompt the student whether
              to restart or not.
            </HelperText>
            <Radio
              type="radio"
              id={`TestPagePropertiesRestartMode-${restartModes.ALWAYS}`}
              name="restart-mode"
              checked={restartMode === restartModes.ALWAYS}
              onChange={(e) => {
                updateProperties('restartMode', restartModes.ALWAYS);
                debounce();
              }}
            />
            <RadioLabel
              ariaFor={`TestPagePropertiesRestartMode-${restartModes.ALWAYS}`}
            >
              Always
            </RadioLabel>
            <Radio
              type="radio"
              id={`TestPagePropertiesRestartMode-${restartModes.NEVER}`}
              name="restart-mode"
              checked={restartMode === restartModes.NEVER}
              onChange={(e) => {
                updateProperties('restartMode', restartModes.NEVER);
                debounce();
              }}
            />
            <RadioLabel
              ariaFor={`TestPagePropertiesRestartMode-${restartModes.NEVER}`}
            >
              Never
            </RadioLabel>
            <Radio
              type="radio"
              id={`TestPagePropertiesRestartMode-${restartModes.PROMPT}`}
              name="restart-mode"
              checked={restartMode === restartModes.PROMPT}
              onChange={(e) => {
                updateProperties('restartMode', restartModes.PROMPT);
                debounce();
              }}
            />
            <RadioLabel
              ariaFor={`TestPagePropertiesRestartMode-${restartModes.PROMPT}`}
            >
              Prompt
            </RadioLabel>
          </InputGroup>

          <InputGroup>
            <Label>
              Test Duration <span>(Optional)</span>
            </Label>
            <HelperText>
              Specify a time limit for the student to complete the test. Once
              the time limit has been reached, the student will be unable to
              answer any more questions.
            </HelperText>
            <NumInput
              type="number"
              min="0"
              value={durationInMinutes || ''}
              onChange={(e) => {
                const { value } = e.target;
                const newDuration = value > 0 ? minutesToSeconds(value) : null;
                updateProperties('testDuration', newDuration);
                debounce();
              }}
            />
            <span style={{ marginLeft: '10px' }}>minutes</span>
          </InputGroup>
        </div>
      </Flex>
    </ContentContainer>
  );
};
export default PackageLibraryPageTestPageProperties;
