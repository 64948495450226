import React, { useCallback, useState } from 'react';
import axiosInstance from '../../../axios';

const InviteStudentsContext = React.createContext();

function InviteStudentsProvider({ children }) {
  const [invites, setInvites] = useState([]);

  const initializeInvites = (students) => {
    setInvites(() => {
      const updated = [];
      students.forEach((student) => {
        updated.push({
          student,
        });
      });

      return updated;
    });
  };

  const updateInvite = (index, data) => {
    setInvites((current) => {
      const updated = current.slice();
      updated[index] = {
        ...updated[index],
        ...data,
      };

      return updated;
    });
  };

  const sendSingleInvite = useCallback(async (student, index) => {
    try {
      updateInvite(index, { loading: true });

      const response = await axiosInstance.post('/students', student);

      updateInvite(index, {
        student: getStudentFromResponse(response),
        success: true,
      });
    } catch (error) {
      updateInvite(index, {
        error: getErrorFromResponse(error.response),
        success: false,
      });
    } finally {
      updateInvite(index, { loading: false });
    }
  }, []);

  const sendInvites = useCallback(
    async (students) => {
      initializeInvites(students);

      await Promise.all(students.map(sendSingleInvite));
    },
    [sendSingleInvite],
  );

  const resetInvites = useCallback(() => {
    setInvites([]);
  }, []);

  const value = {
    invites,
    sendInvites,
    sendSingleInvite,
    resetInvites,
  };

  return (
    <InviteStudentsContext.Provider value={value}>
      {children}
    </InviteStudentsContext.Provider>
  );
}

/**
 * @typedef {{
 *  firstName: string,
 *  lastName: string,
 *  email: string
 * }} Student
 */

/**
 * @typedef {{
 *  loading: boolean,
 *  student: object,
 *  error: Error,
 *  success: boolean
 * }} Invite
 */

/**
 * @returns {{
 *  sendInvites: (students: Student[]) => Promise<void>,
 *  sendSingleInvite: (student: Student, index: number) => Promise<void>,
 *  resetInvites: () => void,
 *  invites: Invite[]
 * }}
 */
function useInviteStudents() {
  const context = React.useContext(InviteStudentsContext);

  if (context === undefined) {
    throw new Error(
      'useInviteStudents must be used within a InviteStudentsProvider',
    );
  }

  return context;
}

function getStudentFromResponse(response) {
  return response.data || null;
}

function getErrorFromResponse(response) {
  return response.data.error.title;
}

export { InviteStudentsProvider, useInviteStudents };
