import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { Box, Text } from 'rebass';
import { typography, space, borders, color } from 'styled-system';
import { FaSpinner } from 'react-icons/fa';

export const Spinner = styled(FaSpinner)`
  ${typography};
  ${space};
  ${borders};
  ${color};
  animation: spin 1s ease-in-out infinite;
  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }

    to {
      transform: rotateZ(360deg);
    }
  }
`;

const BounceAnimation = keyframes`
  0% { 
    opacity: 1;
   }
  50% { 
    opacity: 0;
  }
  100% { 
    opacity: .75
  }
`;

const DotWrapper = styled.span`
  display: inline-block;
`;

const Dot = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.darkgrey};
  border-radius: 50%;
  width: 0.35em;
  height: 0.35em;
  margin: 0 5px;
  /* Animation */
  animation: ${BounceAnimation} 1s ease-out infinite;
  animation-delay: ${(props) => props.delay};
`;

export const Loading = (props) => (
  <DotWrapper {...props}>
    <Dot delay="0s" />
    <Dot delay=".25s" />
    <Dot delay=".35s" />
  </DotWrapper>
);

export const LoadingCaption = ({ ...rest }) => (
  <Box style={{ textAlign: 'center' }} {...rest}>
    <Text as="h2" color="darkgrey" fontSize={[5, 4, 5]}>
      Just a moment <Loading />
    </Text>
  </Box>
);
